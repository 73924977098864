import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Field, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import v4 from 'uuid/v4';
import {FieldWithValidation} from '../../common/fields/FormField';
import {ModalBody, ModalFooter, ModalHeader, ModalWindow} from '../../common/modal/Modal';
import {required} from '../../common/validationCommons';
import {InputCheckBox} from '../../common/fields/InputCheckbox';
import {BoldPrimaryButton, SecondaryButton} from '../../common/Button';
import {StyledForm} from '../../common/StyledComponents';
import buttonMessages from '../../../intl/common/buttonMessages';
import subjectSettingsMessages from '../../../intl/admin/subjectSettingsMessages';

const Button = styled(SecondaryButton)`
  margin-right: 10px;
`;

class SubjectSettingsDialog extends Component {
    constructor(props) {
        super(props);
    }

    save = data => {
        const subjectId = v4();
        this.props.saveModal(data.valueDefault, data.valueLocal, data.vinObligation, data.refNoObligation, subjectId);
    };

    close = () => {
        this.props.reset();
        this.props.closeModal();
    };

    render() {
        const {show, handleSubmit, dirty, invalid, intl: {formatMessage}, subjectKey} = this.props;

        return (
            <ModalWindow isOpen={show}
                         onRequestClose={this.close}
                         shouldCLoseOnOverlayClick={false}>
                <StyledForm onSubmit={handleSubmit(this.save)}>
                    <ModalHeader>
                        <FormattedMessage {...subjectSettingsMessages.MODAL_TITLE}/>
                    </ModalHeader>

                    <ModalBody>
                        <div className="form-group mb-0">
                            <div className="row">
                                <div className="col-3">
                                    <label>
                                        <FormattedMessage {...subjectSettingsMessages.SUBJECT_DEFAULT}/>
                                    </label>
                                </div>
                                <div className="col-9">
                                    <Field name="valueDefault"
                                           component={FieldWithValidation}
                                           type="text"
                                           placeholder={formatMessage(subjectSettingsMessages.SUBJECT_DEFAULT_PLACEHOLDER)}
                                           value="text"
                                           className="form-control"
                                           validate={[required]}
                                           isSmall/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3 py-2">
                                    <label>
                                        <FormattedMessage {...subjectSettingsMessages.SUBJECT_LOCAL}/>
                                    </label>
                                </div>
                                <div className="col-9 py-2">
                                    <Field name="valueLocal"
                                           component={FieldWithValidation}
                                           type="text"
                                           placeholder={formatMessage(subjectSettingsMessages.SUBJECT_LOCAL_PLACEHOLDER)}
                                           value="text"
                                           className="form-control"
                                           isSmall/>
                                </div>
                            </div>
                            {['commercial', 'distribution'].includes(subjectKey) &&
                                <div className="row">
                                    <div className="col-3">
                                        <label>
                                            <FormattedMessage {...subjectSettingsMessages.VIN_OBLIGATION}/>
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <InputCheckBox field="vinObligation"/>
                                    </div>
                                </div>
                            }
                            {['marketingQuestion', 'priceVerification', 'nameVerification'].includes(subjectKey) &&
                                <div className="row">
                                    <div className="col-3">
                                        <label>
                                            <FormattedMessage {...subjectSettingsMessages.REFERENCE_NUMBER_OBLIGATION}/>
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <InputCheckBox field="refNoObligation"/>
                                    </div>
                                </div>
                            }
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="row">
                            <div className="col">
                                <Button type="button"
                                        className="btn"
                                        onClick={this.close}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </Button>
                                <BoldPrimaryButton type="submit"
                                                   className="btn"
                                                   disabled={!dirty || invalid}>
                                    <FormattedMessage {...buttonMessages.SAVE}/>
                                </BoldPrimaryButton>
                            </div>
                        </div>
                    </ModalFooter>
                </StyledForm>
            </ModalWindow>
        )
    }
}

SubjectSettingsDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    intl: PropTypes.any.isRequired,
    subjectKey: PropTypes.any,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveModal: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'addSubjectSettingsModalForm',
    enableReinitialize: true,
})(injectIntl(SubjectSettingsDialog))
