import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {MARKETING_STATISTICS_PENDING} from '../../actions/marketing/actionMarketingStatistics';

export const marketingStatistics = (state = {
    correlationId: '',
    isLoading: false,
    data: [],
}, action) => {
    switch (action.type) {
        case MARKETING_STATISTICS_PENDING: {
            const {correlationId} = action.payload;
            return Object.assign({}, state, {
                isLoading: true, correlationId
            });
        }
        case marketingWebsocketCommands.MARKETING_STATISTICS_SEND: {
            const {correlationId, result: {data}} = action.payload;
            if (correlationId !== state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isLoading: false, correlationId, data
                });
            }
        }
        case marketingWebsocketCommands.MARKETING_STATISTICS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId !== state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isLoading: false, correlationId
                });
            }
        }
        default: {
            return state;
        }
    }
};
