import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const MARKETING_TICKET_CREATE = 'MARKETING_TICKET_CREATE';
export const MARKETING_TICKET_UPDATE = 'MARKETING_TICKET_UPDATE';
export const MARKETING_TICKET_RESET = 'MARKETING_TICKET_RESET';

export const fetchMarketingTicket = (uuid) => dispatch => {
    return dispatch({
        type: marketingWebsocketCommands.MARKETING_GET_TICKET,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_GET_TICKET,
        payload: {
            ticket: {
                uuid: uuid,
            }
        }
    });
};

export const createMarketingTicket = (data) => dispatch => {
    return dispatch({
        type: MARKETING_TICKET_CREATE,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_CREATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const updateMarketingTicket = (data) => dispatch => {
    return dispatch({
        type: MARKETING_TICKET_UPDATE,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_UPDATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const resetMarketingTicket = () => dispatch => {
    return dispatch({
        type: MARKETING_TICKET_RESET,
    });
};
