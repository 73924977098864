import {put, takeEvery, select} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {COMMERCIAL_TICKET_RESET} from '../actions/commercial/actionCommercialTicket';
import get from 'get-value';
import {commercialWebsocketCommands} from '../constants/commercialWebsocketCommands';

export function* handleTicketError(action) {
    const stateCorrelationId = get(yield select(state => state.commercialTicket), `correlationId`, {default: undefined});
    const {correlationId} = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('/commercial'));
    yield put({type: COMMERCIAL_TICKET_RESET});
}

function* commercialTicketSaga() {
    yield takeEvery(commercialWebsocketCommands.COMMERCIAL_TICKET_UPDATE_FATAL, handleTicketError);
    yield takeEvery(commercialWebsocketCommands.COMMERCIAL_TICKET_CREATE_FATAL, handleTicketError);
    yield takeEvery(commercialWebsocketCommands.COMMERCIAL_TICKET_SEND_ERROR, handleTicketError);
}

export default commercialTicketSaga;
