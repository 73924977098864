import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    COMMERCIAL_LIST_PATH, DISTRIBUTION_LIST_PATH, MARKETING_LIST_PATH
} from '../../routes/paths';
import {domains} from '../../constants/Utils';
import Table from '../common/table/Table';
import ValidFormattedMessage from '../common/ValidFormattedMessage';
import statisticsMessages from '../../intl/common/statisticsMessages';

const DOMAIN_PATH = {
    [domains.DISTRIBUTION]: DISTRIBUTION_LIST_PATH,
    [domains.COMMERCIAL]: COMMERCIAL_LIST_PATH,
    [domains.MARKETING]: MARKETING_LIST_PATH,
};

function convertToTableData(data, domain) {
    const result = [];
    if (data) {
        data.forEach(row => result.push({
            status: <ValidFormattedMessage message={`TABLE_ROW_${domain}_${row.status}`}
                                           intlMessages={statisticsMessages}/>,
            status_orig: row.status,
            total: row.total
        }));
    }
    return result;
}

function renderTotal(table) {
    return <span className="font-weight-bold">{
        table.data.length === 0 ? 0 : table.data.map(row => row.total).reduce((acc, cur) => acc + cur)
    }</span>;
}

function StatisticsTable({domain, data, group}) {
    return <Table
        withPagination={false}
        filterableCustom={false}
        columns={[
            {
                id: 'status',
                accessor: 'status',
                message: statisticsMessages.TABLE_HEADER_STATUS,
                Cell: row => {
                    return <Link
                        to={`${DOMAIN_PATH[domain]}?status=${row.original.status_orig}${group ? '&group=' + group : ''}`}>{row.value}</Link>
                },
                Footer: <span className="font-weight-bold">
                    <FormattedMessage {...statisticsMessages.TABLE_FOOTER_TOTAL}/>
                </span>
            },
            {
                id: 'total',
                accessor: 'total',
                message: statisticsMessages.TABLE_HEADER_TOTAL,
                Footer: renderTotal
            }
        ]}
        data={convertToTableData(data, domain)}
        defaultSorting={[{id: 'status'}]}
        domain={domain}
    />
}

StatisticsTable.propTypes = {
    domain: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
    group: PropTypes.string.isRequired
};

export default connect(null, {})(injectIntl(StatisticsTable));
