import {
    USER_ROLE_FETCH_ERROR,
    USER_ROLE_FETCH_PENDING,
    USER_ROLE_FETCH_SUCCESS,
    USER_ROLE_SAVE_ERROR,
    USER_ROLE_SAVE_PENDING,
    USER_ROLE_SAVE_SUCCESS,
    USER_TO_BE_CREATED
} from '../actions/actionRole';
import {moduleRoles} from '../utils/roles';

export const profile = (state = {
    isLoading: true,
    userDetail: {},
    userRoles: [],
    userToBeCreated: false
}, action) => {
    switch (action.type) {
        case USER_ROLE_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case USER_ROLE_FETCH_SUCCESS: {
            const {roles} = action.payload;
            const userRoles = [];
            if (moduleRoles.isPowerUserDistribution(roles)) {
                userRoles.push('DB_POWER_USER');
            }
            if (moduleRoles.isAdminDistribution(roles)) {
                userRoles.push('DB_ADMIN');
            }
            if (moduleRoles.isEditorDistribution(roles)) {
                userRoles.push('DB_EDITOR');
            }
            if (moduleRoles.isDealerDistribution(roles)) {
                userRoles.push('DB_DEALER');
            }
            if (moduleRoles.isPowerUserCommercial(roles)) {
                userRoles.push('CC_POWER_USER');
            }
            if (moduleRoles.isAdminCommercial(roles)) {
                userRoles.push('CC_ADMIN');
            }
            if (moduleRoles.isEditorCommercial(roles)) {
                userRoles.push('CC_EDITOR');
            }
            if (moduleRoles.isDealerCommercial(roles)) {
                userRoles.push('CC_DEALER');
            }
            if (moduleRoles.isPowerUserMarketing(roles)) {
                userRoles.push('MA_POWER_USER');
            }
            if (moduleRoles.isAdminMarketing(roles)) {
                userRoles.push('MA_ADMIN');
            }
            if (moduleRoles.isEditorMarketing(roles)) {
                userRoles.push('MA_EDITOR');
            }
            if (moduleRoles.isDealerMarketing(roles)) {
                userRoles.push('MA_DEALER');
            }
            return Object.assign({}, state, {isLoading: false, userDetail: action.payload, userRoles});
        }
        case USER_ROLE_FETCH_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case USER_ROLE_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true, userToBeCreated: false});
        }
        case USER_ROLE_SAVE_SUCCESS: {
            return Object.assign({}, state, {isLoading: false});
        }
        case USER_ROLE_SAVE_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case USER_TO_BE_CREATED: {
            return Object.assign({}, state, {userToBeCreated: true});
        }
        default:
            return state;
    }
};
