import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const MARKETING_HISTORY_FETCH = 'MARKETING_HISTORY_FETCH';

export const fetchMarketingHistory = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: MARKETING_HISTORY_FETCH,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_HISTORY_GET,
        payload: {
            pageSize: pageSize,
            sorted: sorted,
            cursor: cursor,
            isNextFromPivot: isNextFromPivot,
            filtered: filtered
        }
    });
};
