import React from 'react';
import {
    MARKETING_EXPORT_PATH,
    MARKETING_FULL_TEXT_SEARCH_PATH,
    MARKETING_HISTORY_PATH,
    MARKETING_LIST_PATH,
    MARKETING_NEW_GENERAL_QUESTION_PATH,
    MARKETING_NEW_SPARE_PART_NAME_VERIFICATION_PATH,
    MARKETING_NEW_SPARE_PART_PRICE_VERIFICATION_PATH,
    MARKETING_NOTIFICATION_PATH,
} from '../../../../routes/paths';
import {
    GENERAL_QUESTION_FORM,
    SPARE_PART_NAME_VERIFICATION_FORM,
    SPARE_PART_PRICE_VERIFICATION_FORM
} from '../../../../constants/formConstants';
import {domains} from '../../../../constants/Utils';
import {FormattedMessage} from 'react-intl';
import {moduleRoles} from '../../../../utils/roles';
import menuMessages from '../../../../intl/common/menuMessages';
import formTypeMessages from '../../../../intl/modules/formTypeMessages';

export const MarketingMenuEditor = [
    {
        label: <FormattedMessage {...menuMessages.LIST}/>,
        to: MARKETING_LIST_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.HISTORY}/>,
        to: MARKETING_HISTORY_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.FULL_TEXT_SEARCH}/>,
        to: MARKETING_FULL_TEXT_SEARCH_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.NOTIFICATION}/>,
        to: MARKETING_NOTIFICATION_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.EXPORT}/>,
        to: MARKETING_EXPORT_PATH
    }
];

export const getMarketingDealerMenu = roles => {
    const result = [
        {
            label: <FormattedMessage {...menuMessages.LIST}/>,
            to: MARKETING_LIST_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.HISTORY}/>,
            to: MARKETING_HISTORY_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.FULL_TEXT_SEARCH}/>,
            to: MARKETING_FULL_TEXT_SEARCH_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.NOTIFICATION}/>,
            to: MARKETING_NOTIFICATION_PATH
        },
    ];

    const marketingNewSubmenu = buildMarketingNewSubmenu(roles);
    if (buildMarketingNewSubmenu.length > 0) {
        result.unshift(
            {
                label: <FormattedMessage {...menuMessages.NEW}/>,
                content: marketingNewSubmenu
            }
        );
    }
    return result;
};

const buildMarketingNewSubmenu = roles => {
    const result = [];

    const formTypes = moduleRoles.getDealerForms(domains.MARKETING, roles).map(formType => formType.group);

    if (formTypes.includes(GENERAL_QUESTION_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[GENERAL_QUESTION_FORM]}/>,
                to: MARKETING_NEW_GENERAL_QUESTION_PATH
            }
        );
    }
    if (formTypes.includes(SPARE_PART_PRICE_VERIFICATION_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[SPARE_PART_PRICE_VERIFICATION_FORM]}/>,
                to: MARKETING_NEW_SPARE_PART_PRICE_VERIFICATION_PATH
            }
        );
    }
    if (formTypes.includes(SPARE_PART_NAME_VERIFICATION_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[SPARE_PART_NAME_VERIFICATION_FORM]}/>,
                to: MARKETING_NEW_SPARE_PART_NAME_VERIFICATION_PATH
            }
        );
    }
    return result;
};

