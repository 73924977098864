import React from 'react';
import styled from 'styled-components';
import renaultLogo from '../images/renault-group-logo.png';

const Image = styled.img`
  padding: 0;
  margin: 0;
  width: 106px;
  height: 60px;
`;
Image.displayName = 'Image';

const ImageWrapper = styled.div`
  margin-left: 15px;
`;

const HeadTitle = styled.div`
  margin-left: 30px;
`;

const Title = styled.h1`
  margin: 0;
  margin-top: 5px;
`;
Title.displayName = 'Title';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  padding: 8px;

  @media (max-height: 450px) {
    display: none;
  };
`;


const Header = () => (
    <HeaderWrapper className="">
        <ImageWrapper className="">
            <Image src={renaultLogo}/>
        </ImageWrapper>
        <HeadTitle className="">
            <Title>Dialog: Hotline - DCA</Title>
        </HeadTitle>
    </HeaderWrapper>
);

export default Header;
