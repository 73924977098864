import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    TITLE: {
        id: 'admin.notification.title',
        defaultMessage: 'E-mail Notification Setting'
    },
    SUBJECT: {
        id: 'admin.notification.subject',
        defaultMessage: 'Subject'
    },
    MESSAGE: {
        id: 'admin.notification.message',
        defaultMessage: 'Message'
    }
});

export default messages;
