import get from 'get-value';
import {MARKETING_HISTORY_FETCH} from '../../actions/marketing/actionMarketingHistory';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const marketingHistory = (state = {
    isLoading: false,
    isTableLoading: false,
    listData: [],
    pages: 0
}, action) => {
    switch (action.type) {
        case MARKETING_HISTORY_FETCH: {
            return Object.assign({}, state, {isTableLoading: true});
        }
        case marketingWebsocketCommands.MARKETING_HISTORY_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isTableLoading: false,
                    listData: get(action, 'payload.result.data', {default: []}),
                    pages: get(action, 'payload.result.pages', {default: 0}),
                    correlationId
                });
            }
        }
        case marketingWebsocketCommands.MARKETING_HISTORY_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {isTableLoading: false, correlationId});
            }
        }
        default: {
            return state;
        }
    }
};
