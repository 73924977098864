export const supportProperties = {
    DEFAULT: {
        responsible: 'REYNOLDS & REYNOLDS',
        phone: '04 76 84 30 30',
        email: 'support-dcsnet_fr@reyrey.com'
    },
    CZ: {
        responsible: 'Subsidiary HelpDesk',
        phone: '+420 222 339 400',
        email: 'helpdesk.cz@renault.cz'
    },
    DE: {
        responsible: 'H\u00e4ndler Service Center IT',
        phone: '02232-739162',
        email: 'haendlerhelpdesk.deutschland@renault.de'
    },
    ES: {
        responsible: 'Helpdesk',
        phone: '901116298',
        email: '--------'
    },
    FR: {
        responsible: 'REYNOLDS & REYNOLDS',
        phone: '04 76 84 30 30',
        email: 'support-dcsnet_fr@reyrey.com'
    },
    GB: {
        responsible: 'ISGO SERVICE DESK U.K',
        phone: '0844 561 7177',
        email: 'isgo.servicedesk@renault.co.uk'
    },
    HU: {
        responsible: 'Subsidiary HelpDesk',
        phone: '+36 1 2882000',
        email: 'dealer.support@renault.hu'
    },
    IE: {
        responsible: 'ISGO SERVICE DESK U.K',
        phone: '0844 561 7177',
        email: 'isgo.servicedesk@renault.co.uk'
    },
    IT: {
        responsible: 'SERVICE DESK RENAULT ITALIA',
        phone: '800905469',
        email: 'service.desk@renault.it'
    },
    PL: {
        responsible: 'https://dcs.renault.com/hdi/homeService.do',
        phone: '+48 22 541 10 20',
        email: 'serwis.rnfi@renault.com.pl'
    },
    PT: {
        responsible: 'Helpdesk Portugal',
        phone: '+351 218 361 200',
        email: 'Helpdesk.Portugal@renault.pt'
    },
    SE: {
        responsible: 'Renault Nordic IT',
        phone: '0858575200',
        email: 'Dealers.Nordic-IT-Helpdesk@renault.com'
    },
    SI: {
        responsible: 'Service Desk',
        phone: '+386 1 582 82 82',
        email: 'sos@telekom.si'
    },
    SK: {
        responsible: 'Subsidiary HelpDesk',
        phone: '+421 220 839 977',
        email: 'helpdesk.sk@renault.sk'
    },
    NL: {
        responsible: 'Renault Servicedesk',
        phone: '+31 20 354 93 99',
        email: 'servicedesk.benelux@renault.com'
    },
};

export const localeToCodeMapping = {
    'cs-CZ': 'CZ',
    'de-DE': 'DE',
    'es-ES': 'ES',
    'fr-FR': 'FR',
    'en-GB': 'GB',
    'hu-HU': 'HU',
    'en-IE': 'IE',
    'it-IT': 'IT',
    'pl-PL': 'PL',
    'pt-PT': 'PT',
    'sv-SE': 'SE',
    'sl-SI': 'SI',
    'sk-SK': 'SK',
    'nl-NL': 'NL',
};
