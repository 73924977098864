import get from 'get-value';
import {domains} from '../constants/Utils.js';
import {countryGroups, getFormTypes} from '../constants/Utils';

export const hasAtLeastOneRoleSet = (roles, allowedValues) => {
    return Object.keys(roles)
        .filter(role => roles[role] === true && allowedValues.includes(role))
        .length > 0;
};

export const getRoles = (roles) => {
    return Object.keys(roles)
        .map(role => ({group: role, value: roles[role]}))
        .filter(item => item.value === true)
        .sort((option1, option2) => option1.group.localeCompare(option2.group));
};

export const moduleRoles = {
    isPowerUser(roles, domain) {
        if (domain) {
            return get(roles, `${domain}.powerUser`, {default: false});
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.powerUser`, {default: false}))
                .find(isPowerUser => isPowerUser);
        }
    },
    isPowerUserDistribution(roles) {
        return this.isPowerUser(roles, domains.DISTRIBUTION);
    },
    isPowerUserCommercial(roles) {
        return this.isPowerUser(roles, domains.COMMERCIAL);
    },
    isPowerUserMarketing(roles) {
        return this.isPowerUser(roles, domains.MARKETING);
    },

    isAdmin(roles, domain) {
        if (domain) {
            return hasAtLeastOneRoleSet(
                get(roles, `${domain}.admin.groupPermission`, {default: {}}),
                Object.values(countryGroups)
            );
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.admin.groupPermission`, {default: {}}))
                .find(roles => hasAtLeastOneRoleSet(roles, Object.values(countryGroups)));
        }
    },
    isAdminDistribution(roles) {
        return this.isAdmin(roles, domains.DISTRIBUTION)
    },
    isAdminCommercial(roles) {
        return this.isAdmin(roles, domains.COMMERCIAL)
    },
    isAdminMarketing(roles) {
        return this.isAdmin(roles, domains.MARKETING)
    },

    isEditor(roles, domain) {
        if (domain) {
            return hasAtLeastOneRoleSet(
                get(roles, `${domain}.editor.groupPermission`, {default: {}}),
                Object.values(countryGroups)
            );
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.editor.groupPermission`, {default: {}}))
                .find(roles => hasAtLeastOneRoleSet(roles, Object.values(countryGroups)));
        }
    },
    isEditorDistribution(roles) {
        return this.isEditor(roles, domains.DISTRIBUTION);
    },
    isEditorCommercial(roles) {
        return this.isEditor(roles, domains.COMMERCIAL);
    },
    isEditorMarketing(roles) {
        return this.isEditor(roles, domains.MARKETING);
    },

    isDealer(roles, domain) {
        if (domain) {
            return hasAtLeastOneRoleSet(
                get(roles, `${domain}.dealer.formsPermission`, {default: {}}),
                getFormTypes(domain)
            );
        }
        return false;
    },
    isDealerDistribution(roles) {
        return this.isDealer(roles, domains.DISTRIBUTION);
    },
    isDealerCommercial(roles) {
        return this.isDealer(roles, domains.COMMERCIAL);
    },
    isDealerMarketing(roles) {
        return this.isDealer(roles, domains.MARKETING);
    },

    getAdminGroups(domain, roles) {
        return getRoles(get(roles, `${domain}.admin.groupPermission`, {default: {}}));
    },
    getDealerForms(domain, roles) {
        return getRoles(get(roles, `${domain}.dealer.formsPermission`, {default: {}}));
    },
    getEditorGroups(domain, roles) {
        return getRoles(get(roles, `${domain}.editor.groupPermission`, {default: {}}));
    }
};
