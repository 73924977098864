import React from 'react';
import {withRouter} from 'react-router-dom';
import {domains} from '../../constants/Utils';
import {ADMINISTRATION_PATH, COMMERCIAL_PATH, DISTRIBUTION_PATH, MARKETING_PATH} from '../../routes/paths';

export const Domain = withRouter(({component: Component, ...props}) => {
    let domain;
    if (props.location.pathname.startsWith(DISTRIBUTION_PATH) || props.location.pathname.startsWith(`${ADMINISTRATION_PATH}${DISTRIBUTION_PATH}`)) {
        domain = domains.DISTRIBUTION;
    }
    if (props.location.pathname.startsWith(COMMERCIAL_PATH) || props.location.pathname.startsWith(`${ADMINISTRATION_PATH}${COMMERCIAL_PATH}`)) {
        domain = domains.COMMERCIAL;
    }
    if (props.location.pathname.startsWith(MARKETING_PATH) || props.location.pathname.startsWith(`${ADMINISTRATION_PATH}${MARKETING_PATH}`)) {
        domain = domains.MARKETING;
    }
    return <Component domain={domain} {...props}/>;
});
