import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import set from 'set-value';
import {
    GENERAL_QUESTION_FORM, SPARE_PART_NAME_VERIFICATION_FORM, SPARE_PART_PRICE_VERIFICATION_FORM
} from '../../../constants/formConstants';
import {createMarketingTicket, resetMarketingTicket} from '../../../actions/marketing/actionMarketingTicket';
import {initNewMarketingTicket, transformNewMarketingTicketForSave} from '../Utils';
import {clearAlerts} from '../../../actions/alertsActions';
import {createRights} from '../../../constants/marketingTicketRights';
import {createReqFields} from '../../../constants/marketingRequiredFields';
import UploadingMarketingAttachment from './UploadingMarketingAttachment';
import GeneralQuestionMasterForm from './forms/GeneralQuestionMasterForm';
import SparePartPriceVerificationMasterForm from './forms/SparePartPriceVerificationMasterForm';
import SparePartNameVerificationMasterForm from './forms/SparePartNameVerificationMasterForm';
import checkRoles from '../../common/roleChecker/RoleChecker';
import NoBirComponent from '../../common/NoBirComponent';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import PageHeader from '../../common/PageHeader';
import Loader from '../../Loader';
import formTypeMessages from '../../../intl/modules/formTypeMessages';

class NewMarketingTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetMarketingTicket();
    }

    handleSubmit = (values) => {
        const dataForSend = transformNewMarketingTicketForSave(values.newValues);
        this.props.createMarketingTicket(dataForSend);
        this.setState({ticketSubmittedAndShouldRedirect: true});
    };

    render() {
        const {
            type,
            ticketData,
            attachmentsData,
            isLoadingSubjects
        } = this.props;

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingMarketingAttachment attachments={attachmentsData.attachmentsForUpload}
                                                 attachmentsUrl={ticketData.newAttachments}
                                                 correlationId={ticketData.correlationId}
                                                 isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                                 formType={type}/>
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating
            && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/marketing/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (!type
            || this.state.ticketSubmittedAndShouldRedirect
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        const initializationValue = initNewMarketingTicket(this.props.userDetail);
        if (!initializationValue) {
            return <NoBirComponent formType={type}/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<ValidFormattedMessage message={type} intlMessages={formTypeMessages}/>}/>
                <div>
                    {type === GENERAL_QUESTION_FORM &&
                    <GeneralQuestionMasterForm form={type}
                                               initialValues={set(initializationValue, 'ticketSection.type', GENERAL_QUESTION_FORM)}
                                               userRights={createRights}
                                               reqFields={createReqFields[type]}
                                               enableReinitialize={true}
                                               handleSubmit={this.handleSubmit}/>
                    }
                    {type === SPARE_PART_PRICE_VERIFICATION_FORM &&
                    <SparePartPriceVerificationMasterForm form={type}
                                                          initialValues={set(initializationValue, 'ticketSection.type', SPARE_PART_PRICE_VERIFICATION_FORM)}
                                                          userRights={createRights}
                                                          reqFields={createReqFields[type]}
                                                          enableReinitialize={true}
                                                          handleSubmit={this.handleSubmit}/>
                    }
                    {type === SPARE_PART_NAME_VERIFICATION_FORM &&
                    <SparePartNameVerificationMasterForm form={type}
                                                         initialValues={set(initializationValue, 'ticketSection.type', SPARE_PART_NAME_VERIFICATION_FORM)}
                                                         userRights={createRights}
                                                         reqFields={createReqFields[type]}
                                                         enableReinitialize={true}
                                                         handleSubmit={this.handleSubmit}/>
                    }
                </div>
            </div>
        )
    }
}

NewMarketingTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    createMarketingTicket: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    userDetail: PropTypes.object.isRequired,
    ticketData: PropTypes.object.isRequired,
    attachmentsData: PropTypes.object.isRequired,
    clientIds: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    userDetail: state.profile.userDetail,
    ticketData: state.marketingTicket,
    attachmentsData: state.marketingAttachment,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    createMarketingTicket,
    resetMarketingTicket,
})(NewMarketingTicketPage)), ['MA_DEALER']);
