import {apigeeWebsocketCommands} from '../constants/apigeeWebsocketCommands';

export const DHS_DATA_SET = 'DHS_DATA_SET';
export const DHS_DATA_RESET = 'DHS_DATA_RESET';

export const dhsDataSetActionCreator = () => {
    return {
        type: DHS_DATA_SET,
    }
};
export const dhsDataResetActionCreator = () => {
    return {
        type: DHS_DATA_RESET,
    }
};

export const fetchDhsInformationActionCreator = (vin, country) => {
    return {
        type: apigeeWebsocketCommands.DHS_DATA_GET,
        apigeeWebsocket: true,
        command: apigeeWebsocketCommands.DHS_DATA_GET,
        payload: {
            vin,
            country
        },
    }
};
