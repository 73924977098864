import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {moduleRoles} from '../../utils/roles';
import DistributionContent from './DistributionContent';
import CommercialContent from './CommercialContent';
import MarketingContent from './MarketingContent';
import renaultCar from '../../images/car.jpg';
import mainPageMessages from '../../intl/common/mainPageMessages';

const Img = styled.img`
    height: 35%;
    width: 35%;
`;
Img.displayName = 'Img';

class HomePage extends Component {
    render() {

        const {userDetail: {roles}} = this.props;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h1 className="text-center"><FormattedMessage {...mainPageMessages.TITLE} /></h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="text-center">
                            <Img src={renaultCar}/>
                        </div>
                    </div>
                </div>
                {<>
                    <div className="row">
                        {(moduleRoles.isDealerDistribution(roles) || moduleRoles.isEditorDistribution(roles)) &&
                        <div className="col-12 col-lg-6">
                            <DistributionContent/>
                        </div>
                        }
                        {(moduleRoles.isDealerCommercial(roles) || moduleRoles.isEditorCommercial(roles)) &&
                        <div className="col-12 col-lg-6">
                            <CommercialContent/>
                        </div>
                        }
                        {(moduleRoles.isDealerMarketing(roles) || moduleRoles.isEditorMarketing(roles)) &&
                        <div className="col-12 col-lg-6">
                            <MarketingContent/>
                        </div>
                        }
                    </div>
                </>}

            </div>
        )
    }
}

HomePage.propTypes = {
    userDetail: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
});

export default connect(mapStateToProps, {})(HomePage);
