import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_HEADER_STATUS: {
        id: 'statistics.header.status',
        defaultMessage: 'Status'
    },
    TABLE_HEADER_TOTAL: {
        id: 'statistics.header.total',
        defaultMessage: 'Sum'
    },
    TABLE_ROW_DISTRIBUTION_NEW: {
        id: 'statistics.distribution.new',
        defaultMessage: 'New'
    },
    TABLE_ROW_DISTRIBUTION_WAIT_DEALER: {
        id: 'statistics.distribution.waitDealer',
        defaultMessage: 'In Progress – Dealer'
    },
    TABLE_ROW_DISTRIBUTION_WAIT_SUBSIDIARY: {
        id: 'statistics.distribution.waitSubsidiary',
        defaultMessage: 'In Progress – Subsidiary'
    },
    TABLE_ROW_DISTRIBUTION_WAIT_WORK_IN_PROGRESS: {
        id: 'statistics.distribution.workInProgress',
        defaultMessage: 'Work in Progress'
    },
    TABLE_ROW_DISTRIBUTION_REOPEN: {
        id: 'statistics.distribution.reopen',
        defaultMessage: 'Reopen'
    },
    TABLE_ROW_DISTRIBUTION_CLOSED: {
        id: 'statistics.distribution.closed',
        defaultMessage: 'Closed Distribution'
    },
    TABLE_ROW_COMMERCIAL_NEW: {
        id: 'statistics.commercial.new',
        defaultMessage: 'New'
    },
    TABLE_ROW_COMMERCIAL_WAIT_DEALER: {
        id: 'statistics.commercial.waitDealer',
        defaultMessage: 'In Progress – Dealer'
    },
    TABLE_ROW_COMMERCIAL_WAIT_SUBSIDIARY: {
        id: 'statistics.commercial.waitSubsidiary',
        defaultMessage: 'In Progress – Subsidiary'
    },
    TABLE_ROW_COMMERCIAL_WAIT_WORK_IN_PROGRESS: {
        id: 'statistics.commercial.workInProgress',
        defaultMessage: 'Work in Progress'
    },
    TABLE_ROW_COMMERCIAL_REOPEN: {
        id: 'statistics.commercial.reopen',
        defaultMessage: 'Reopen'
    },
    TABLE_ROW_COMMERCIAL_CLOSED: {
        id: 'statistics.commercial.closed',
        defaultMessage: 'Closed Commercial'
    },
    TABLE_ROW_MARKETING_NEW: {
        id: 'statistics.marketing.new',
        defaultMessage: 'New'
    },
    TABLE_ROW_MARKETING_WAIT_DEALER: {
        id: 'statistics.marketing.waitDealer',
        defaultMessage: 'In Progress – Dealer'
    },
    TABLE_ROW_MARKETING_WAIT_SUBSIDIARY: {
        id: 'statistics.marketing.waitSubsidiary',
        defaultMessage: 'In Progress – Subsidiary'
    },
    TABLE_ROW_MARKETING_WAIT_WORK_IN_PROGRESS: {
        id: 'statistics.marketing.workInProgress',
        defaultMessage: 'Work in Progress'
    },
    TABLE_ROW_MARKETING_REOPEN: {
        id: 'statistics.marketing.reopen',
        defaultMessage: 'Reopen'
    },
    TABLE_ROW_MARKETING_CLOSED: {
        id: 'statistics.marketing.closed',
        defaultMessage: 'Closed Marketing'
    },
    TABLE_FOOTER_TOTAL: {
        id: 'statistics.footer.total',
        defaultMessage: 'Total'
    },
});

export default messages;
