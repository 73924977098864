export const HOME_SELECT_GROUP = 'HOME_SELECT_GROUP';

export const storeSelectedCountryGroup = (countryGroup, domain) => dispatch =>
    dispatch({
        type: HOME_SELECT_GROUP,
        payload: {
            countryGroup,
            domain,
        }
    });
