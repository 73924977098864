import get from 'get-value';
import {DISTRIBUTION_HISTORY_FETCH} from '../../actions/distribution/actionDistributionHistory';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const distributionHistory = (state = {
    isLoading: false,
    isTableLoading: false,
    listData: [],
    pages: 0
}, action) => {
    switch (action.type) {
        case DISTRIBUTION_HISTORY_FETCH: {
            return Object.assign({}, state, {isTableLoading: true});
        }
        case distributionWebsocketCommands.DISTRIBUTION_HISTORY_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isTableLoading: false,
                    listData: get(action, 'payload.result.data', {default: []}),
                    pages: get(action, 'payload.result.pages', {default: 0}),
                    correlationId
                });
            }
        }
        case distributionWebsocketCommands.DISTRIBUTION_HISTORY_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {isTableLoading: false, correlationId});
            }
        }
        default: {
            return state;
        }
    }
};
