import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    CONFIRMATION_DIALOG_BODY: {
        id: 'confirmation.dialog.body',
        defaultMessage: 'Are you sure you want to {action}?'
    },
    CONFIRMATION_DIALOG_TITLE: {
        id: 'confirmation.dialog.title',
        defaultMessage: 'Confirmation'
    },
    CONFIRMATION_DIALOG_ACTION_MANUALLY_REWRITE_DHS: {
        id: 'confirmation.dialog.action.manuallyRewriteDhs',
        defaultMessage: 'manually rewrite data loaded from DHS'
    },
    CONFIRMATION_DIALOG_ACTION_MANUALLY_REWRITE_ASD: {
        id: 'confirmation.dialog.action.manuallyRewriteAsd',
        defaultMessage: 'manually rewrite data loaded from ASD'
    },
    CONFIRMATION_DIALOG_ACTION_DHS_REWRITE: {
        id: 'confirmation.dialog.action.dhsRewrite',
        defaultMessage: 'rewrite the form by the data from DHS'
    },
    CONFIRMATION_DIALOG_ACTION_ASD_REWRITE: {
        id: 'confirmation.dialog.action.dhsRewrite',
        defaultMessage: 'rewrite the form by the data from DHS'
    },
});

export default messages;
