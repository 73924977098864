import get from 'get-value';
import {
    ADMIN_NOTIFICATION_SELECT_GROUP,
    ADMIN_NOTIFICATION_FETCH,
    ADMIN_NOTIFICATION_UPDATE
} from '../../actions/admin/actionAdminNotification';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const adminNotification = (state = {
    isLoading: true,
    emailData: {},
    selectedCountryGroup: ''
}, action) => {
    switch (action.type) {
        case ADMIN_NOTIFICATION_SELECT_GROUP: {
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        }
        case ADMIN_NOTIFICATION_FETCH:
            return Object.assign({}, state, {isLoading: true, emailData: {}});
        case ADMIN_NOTIFICATION_UPDATE: {
            return Object.assign({}, state, {isLoading: true, emailData: {}});
        }

        case distributionWebsocketCommands.DISTRIBUTION_NOTIFICATIONS_SEND:
        case commercialWebsocketCommands.COMMERCIAL_NOTIFICATIONS_SEND:
        case marketingWebsocketCommands.MARKETING_NOTIFICATIONS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                emailData: get(action, 'payload.notification', {default: {}}),
                correlationId
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_NOTIFICATIONS_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_NOTIFICATIONS_ERROR:
        case marketingWebsocketCommands.MARKETING_NOTIFICATIONS_ERROR:
        case distributionWebsocketCommands.DISTRIBUTION_NOTIFICATIONS_SEND_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_NOTIFICATIONS_SEND_ERROR:
        case marketingWebsocketCommands.MARKETING_NOTIFICATIONS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, emailData: {}, correlationId});
        }
        default:
            return state;
    }
};

