import React from 'react';
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import styled from 'styled-components';
import {
    STATUS_HISTORY_SECTION
} from '../../constants/formConstants';
import formStatesMessages from '../../intl/modules/formStatesMessages';
import {formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import ValidFormattedMessage from './ValidFormattedMessage';
import commonMessages from '../../intl/common/commonMessages';

const StyledTd = styled.td`
    &&{
      padding: 0.25rem;
      vertical-align: middle;
    };
`;

const TicketStatusHistory = props => (
    <>
        {(props.history && props.history.length > 0) &&
        <div className="pt-5 d-flex justify-content-center">
            <div className="col-lg-12 col-xl-8">
                <div className="card">
                    <h5 className="card-header text-center">
                        <FormattedMessage {...formStatesMessages.STATUS_HISTORY}/>
                    </h5>
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <tbody>
                            {props.history && props.history.map((row, index) =>
                                <tr key={index}>
                                    <StyledTd><ValidFormattedMessage message={row.status}
                                                                     intlMessages={formStatesMessages}/></StyledTd>
                                    <StyledTd><ValidFormattedMessage message={row.isDealer ? 'DEALER' : 'EDITOR'}
                                                                     intlMessages={commonMessages}/></StyledTd>
                                    <StyledTd>{row.creatorIpn}</StyledTd>
                                    <StyledTd>{row.creatorName}</StyledTd>
                                    <StyledTd><FormattedDate value={new Date(row.created)}/> <FormattedTime
                                        value={new Date(row.created)}/></StyledTd>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        }
    </>
);

const mapStateToProps = (state, props) => ({
    history: formValueSelector(props.form)(state, `${STATUS_HISTORY_SECTION}`),
});

export default connect(mapStateToProps, null)(TicketStatusHistory);
