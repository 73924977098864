import {apigeeWebsocketCommands} from '../constants/apigeeWebsocketCommands';

export const ASD_DATA_SET = 'ASD_DATA_SET';
export const ASD_DATA_RESET = 'ASD_DATA_RESET';

export const asdDataSetActionCreator = () => ({
    type: ASD_DATA_SET,
});

export const asdDataResetActionCreator = () => ({
    type: ASD_DATA_RESET,
});

export const fetchAsdInformationActionCreator = (refNumber, country, lang) => ({
    type: apigeeWebsocketCommands.ASD_DATA_GET,
    apigeeWebsocket: true,
    command: apigeeWebsocketCommands.ASD_DATA_GET,
    payload: {refNumber, country, lang},
});
