import {errorAlert} from '../alertsActions';
import {
    validateAdminRoles,
    validateDealerRolesForDistribution,
    validateDealerRolesForCommercial,
    validateDealerRolesForMarketing,
    validateEditorData, validateExternalEditorData
} from '../../api/validation/role';
import alertMessages from '../../intl/common/alertMessages';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {domains} from '../../constants/Utils';

export const MANAGED_USER = 'MANAGED_USER';
export const INITIAL_ADMIN_COUNTRY_OPTIONS = 'INITIAL_ADMIN_COUNTRY_OPTIONS';
export const SELECTED_ADMIN_COUNTRY_OPTIONS = 'SELECTED_ADMIN_COUNTRY_OPTIONS';
export const ADMIN_COUNTRIES_CHANGED = 'ADMIN_COUNTRIES_CHANGED';
export const ADMIN_RIGHTS_FETCH = 'ADMIN_RIGHTS_FETCH';
export const ADMIN_RIGHTS_SAVE = 'ADMIN_RIGHTS_SAVE';
export const ADMIN_RIGHTS_CLEAR = 'ADMIN_RIGHTS_CLEAR';
export const ADMIN_RIGHTS_FETCH_SUCCESS = 'ADMIN_RIGHTS_FETCH_SUCCESS';
export const INITIAL_EDITOR_COUNTRY_OPTIONS = 'INITIAL_EDITOR_COUNTRY_OPTIONS';
export const SELECTED_EDITOR_COUNTRY_OPTIONS = 'SELECTED_EDITOR_COUNTRY_OPTIONS';
export const EDITOR_COUNTRIES_CHANGED = 'EDITOR_COUNTRIES_CHANGED';
export const EDITOR_RIGHTS_FETCH = 'EDITOR_RIGHTS_FETCH';
export const EDITOR_RIGHTS_SAVE = 'EDITOR_RIGHTS_SAVE';
export const EDITOR_RIGHTS_CLEAR = 'EDITOR_RIGHTS_CLEAR';
export const EDITOR_RIGHTS_FETCH_SUCCESS = 'EDITOR_RIGHTS_FETCH_SUCCESS';
export const INITIAL_EXTERNAL_EDITOR_COUNTRY_OPTIONS = 'INITIAL_EXTERNAL_EDITOR_COUNTRY_OPTIONS';
export const SELECTED_EXTERNAL_EDITOR_COUNTRY_OPTIONS = 'SELECTED_EXTERNAL_EDITOR_COUNTRY_OPTIONS';
export const EXTERNAL_EDITOR_COUNTRIES_CHANGED = 'EXTERNAL_EDITOR_COUNTRIES_CHANGED';
export const EXTERNAL_EDITOR_RIGHTS_FETCH = 'EXTERNAL_EDITOR_RIGHTS_FETCH';
export const EXTERNAL_EDITOR_RIGHTS_SAVE = 'EXTERNAL_EDITOR_RIGHTS_SAVE';
export const EXTERNAL_EDITOR_RIGHTS_CLEAR = 'EXTERNAL_EDITOR_RIGHTS_CLEAR';
export const EXTERNAL_EDITOR_RIGHTS_FETCH_SUCCESS = 'EXTERNAL_EDITOR_RIGHTS_FETCH_SUCCESS';
export const INITIAL_DEALER_FORM_TYPE_OPTIONS = 'INITIAL_DEALER_FORM_TYPE_OPTIONS';
export const SELECTED_DEALER_FORM_TYPE_OPTIONS = 'SELECTED_DEALER_FORM_TYPE_OPTIONS';
export const DEALER_FORM_TYPES_CHANGED = 'DEALER_FORM_TYPES_CHANGED';
export const DEALER_RIGHTS_FETCH = 'DEALER_RIGHTS_FETCH';
export const DEALER_RIGHTS_SAVE = 'DEALER_RIGHTS_SAVE';
export const DEALER_RIGHTS_CLEAR = 'DEALER_RIGHTS_CLEAR';
export const DEALER_RIGHTS_FETCH_SUCCESS = 'DEALER_RIGHTS_FETCH_SUCCESS';
export const RIGHTS_ERROR = 'RIGHTS_ERROR';

export const setManagedUser = managedUser => ({
    type: MANAGED_USER,
    payload: managedUser
});

export const setInitialAdminCountryOptions = countryOptions => ({
    type: INITIAL_ADMIN_COUNTRY_OPTIONS,
    payload: countryOptions
});
export const setSelectedAdminCountryOptions = countryOptions => ({
    type: SELECTED_ADMIN_COUNTRY_OPTIONS,
    payload: countryOptions
});
export const setAdminCountriesChanged = adminCountriesChanged => ({
    type: ADMIN_COUNTRIES_CHANGED,
    payload: adminCountriesChanged
});
export const fetchAdminRightsManagement = (domain, user) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_RIGHTS_FETCH,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_ADMIN_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_RIGHTS_FETCH,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_ADMIN_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_RIGHTS_FETCH,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_ADMIN_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
    }
};
export const saveAdminRightsManagement = (domain, user, adminData) => dispatch => {
    const {error} = validateAdminRoles(adminData);

    if (error) {
        dispatch({type: RIGHTS_ERROR});
        dispatch(errorAlert(alertMessages.RIGHTS_ADMIN_SEND_ERROR, [error.toString()]));
    } else {
        switch (domain) {
            case domains.DISTRIBUTION: {
                return dispatch({
                    type: ADMIN_RIGHTS_SAVE,
                    distributionWebsocket: true,
                    command: distributionWebsocketCommands.DISTRIBUTION_ADMIN_ROLE_SET,
                    payload: {role: {admin: {groupPermission: adminData}, id: user.ipn}}
                });
            }
            case domains.COMMERCIAL: {
                return dispatch({
                    type: ADMIN_RIGHTS_SAVE,
                    commercialWebsocket: true,
                    command: commercialWebsocketCommands.COMMERCIAL_ADMIN_ROLE_SET,
                    payload: {role: {admin: {groupPermission: adminData}, id: user.ipn}}
                });
            }
            case domains.MARKETING: {
                return dispatch({
                    type: ADMIN_RIGHTS_SAVE,
                    marketingWebsocket: true,
                    command: marketingWebsocketCommands.MARKETING_ADMIN_ROLE_SET,
                    payload: {role: {admin: {groupPermission: adminData}, id: user.ipn}}
                });
            }
        }
    }
};
export const clearAdminRightsManagement = () => ({
    type: ADMIN_RIGHTS_CLEAR
});

export const setInitialEditorCountryOptions = countryOptions => ({
    type: INITIAL_EDITOR_COUNTRY_OPTIONS,
    payload: countryOptions
});
export const setSelectedEditorCountryOptions = countryOptions => ({
    type: SELECTED_EDITOR_COUNTRY_OPTIONS,
    payload: countryOptions
});
export const setEditorCountriesChanged = countriesChanged => ({
    type: EDITOR_COUNTRIES_CHANGED,
    payload: countriesChanged
});
export const fetchEditorRightsManagement = (domain, user) => (dispatch) => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: EDITOR_RIGHTS_FETCH,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_EDITOR_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: EDITOR_RIGHTS_FETCH,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_EDITOR_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: EDITOR_RIGHTS_FETCH,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_EDITOR_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
    }
};
export const saveEditorRightsManagement = (domain, user, editorData) => dispatch => {
    const {error} = validateEditorData(editorData);

    if (error) {
        dispatch({type: RIGHTS_ERROR});
        dispatch(errorAlert(alertMessages.RIGHTS_EDITOR_SEND_ERROR, [error.toString()]));
    } else {
        switch (domain) {
            case domains.DISTRIBUTION: {
                return dispatch({
                    type: EDITOR_RIGHTS_SAVE,
                    distributionWebsocket: true,
                    command: distributionWebsocketCommands.DISTRIBUTION_EDITOR_ROLE_SET,
                    payload: {role: {editor: {groupPermission: editorData}, id: user.ipn}}
                });
            }
            case domains.COMMERCIAL: {
                return dispatch({
                    type: EDITOR_RIGHTS_SAVE,
                    commercialWebsocket: true,
                    command: commercialWebsocketCommands.COMMERCIAL_EDITOR_ROLE_SET,
                    payload: {role: {editor: {groupPermission: editorData}, id: user.ipn}}
                });
            }
            case domains.MARKETING: {
                return dispatch({
                    type: EDITOR_RIGHTS_SAVE,
                    marketingWebsocket: true,
                    command: marketingWebsocketCommands.MARKETING_EDITOR_ROLE_SET,
                    payload: {role: {editor: {groupPermission: editorData}, id: user.ipn}}
                });
            }
        }
    }
};
export const clearEditorRightsManagement = () => ({
    type: EDITOR_RIGHTS_CLEAR
});

export const setInitialExternalEditorCountryOptions = countryOptions => ({
    type: INITIAL_EXTERNAL_EDITOR_COUNTRY_OPTIONS,
    payload: countryOptions
});
export const setSelectedExternalEditorCountryOptions = countryOptions => ({
    type: SELECTED_EXTERNAL_EDITOR_COUNTRY_OPTIONS,
    payload: countryOptions
});
export const setExternalEditorCountriesChanged = countriesChanged => ({
    type: EXTERNAL_EDITOR_COUNTRIES_CHANGED,
    payload: countriesChanged
});
export const fetchExternalEditorRightsManagement = (domain, user) => (dispatch) => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: EXTERNAL_EDITOR_RIGHTS_FETCH,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_EXTERNAL_EDITOR_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
    }
};
export const saveExternalEditorRightsManagement = (domain, user, externalEditorData) => dispatch => {
    const {error} = validateExternalEditorData(externalEditorData);

    if (error) {
        dispatch({type: RIGHTS_ERROR});
        dispatch(errorAlert(alertMessages.RIGHTS_EXTERNAL_EDITOR_SEND_ERROR, [error.toString()]));
    } else {
        switch (domain) {
            case domains.DISTRIBUTION: {
                return dispatch({
                    type: EXTERNAL_EDITOR_RIGHTS_SAVE,
                    distributionWebsocket: true,
                    command: distributionWebsocketCommands.DISTRIBUTION_EXTERNAL_EDITOR_ROLE_SET,
                    payload: {role: {externalEditor: {groupPermission: externalEditorData}, id: user.ipn}}
                });
            }
        }
    }
};
export const clearExternalEditorRightsManagement = () => ({
    type: EXTERNAL_EDITOR_RIGHTS_CLEAR
});

export const setInitialDealerFormTypeOptions = formTypeOptions => ({
    type: INITIAL_DEALER_FORM_TYPE_OPTIONS,
    payload: formTypeOptions
});
export const setSelectedDealerFormTypeOptions = formTypeOptions => ({
    type: SELECTED_DEALER_FORM_TYPE_OPTIONS,
    payload: formTypeOptions
});
export const setDealerFormTypesChanged = formTypesChanged => ({
    type: DEALER_FORM_TYPES_CHANGED,
    payload: formTypesChanged
});
export const fetchDealerRightsManagement = (domain, user) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: DEALER_RIGHTS_FETCH,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_DEALER_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: DEALER_RIGHTS_FETCH,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_DEALER_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: DEALER_RIGHTS_FETCH,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_DEALER_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
    }
};
export const saveDealerRightsManagement = (domain, user, dealerData) => dispatch => {
    const {error} = (domain === 'DISTRIBUTION') ? validateDealerRolesForDistribution(dealerData) :
        ((domain === 'COMMERCIAL') ? validateDealerRolesForCommercial(dealerData) : validateDealerRolesForMarketing(dealerData));
    if (error) {
        dispatch({type: RIGHTS_ERROR});
        dispatch(errorAlert(alertMessages.RIGHTS_DEALER_SEND_ERROR, [error.toString()]));
    } else {
        switch (domain) {
            case domains.DISTRIBUTION: {
                return dispatch({
                    type: DEALER_RIGHTS_SAVE,
                    distributionWebsocket: true,
                    command: distributionWebsocketCommands.DISTRIBUTION_DEALER_ROLE_SET,
                    payload: {role: {dealer: {formsPermission: dealerData}, id: user.ipn}}
                })
            }
            case domains.COMMERCIAL: {
                return dispatch({
                    type: DEALER_RIGHTS_SAVE,
                    commercialWebsocket: true,
                    command: commercialWebsocketCommands.COMMERCIAL_DEALER_ROLE_SET,
                    payload: {role: {dealer: {formsPermission: dealerData}, id: user.ipn}}
                })
            }
            case domains.MARKETING: {
                return dispatch({
                    type: DEALER_RIGHTS_SAVE,
                    marketingWebsocket: true,
                    command: marketingWebsocketCommands.MARKETING_DEALER_ROLE_SET,
                    payload: {role: {dealer: {formsPermission: dealerData}, id: user.ipn}}
                })
            }
        }
    }
};
export const clearDealerRightsManagement = () => ({
    type: DEALER_RIGHTS_CLEAR
});

