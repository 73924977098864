import 'react-table/react-table.css';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {debounce} from 'throttle-debounce';
import * as moment from 'moment';
import get from 'get-value';
import {IconEdit, PrimaryIcon} from '../../common/Button';
import {fetchDistributionList} from '../../../actions/distribution/actionDistributionList';
import {editDistributionListButtonVisibility} from '../../../constants/distributionTicketRights';
import {moduleRoles} from '../../../utils/roles';
import Table from '../../common/table/TicketTable';
import formMessages from '../../../intl/modules/formMessages';
import {countryLanguageMapping} from '../../../constants/Utils';

class ListDistributionTable extends Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(props.location.search);
        const loadedState = this.loadStateFromSessionStorage({
            listDefaultPageSizeDB: 10,
            listDefaultSortDB: [{id: 'TICKET_NUMBER', desc: true}],
            listDefaultFiltersDB: [],
        });

        if (urlParams.has('status') && urlParams.has('group')) {
            this.state = {
                ...loadedState,
                listDefaultFiltersDB: [
                    {
                        id: 'STATUS',
                        value: urlParams.get('status') ? [urlParams.get('status')] : '',
                    },
                    {
                        id: 'GROUP',
                        value: urlParams.get('group') === 'ALL' ? '' : urlParams.has('group') ? urlParams.get('group') : ''
                    }
                ]
            }
        } else if (urlParams.has('status') && !urlParams.has('group')) {
            this.state = {
                ...loadedState,
                listDefaultFiltersDB: [
                    {
                        id: 'STATUS',
                        value: urlParams.get('status') ? [urlParams.get('status')] : '',
                    }
                ]
            }
        } else if (!urlParams.has('status') && urlParams.has('group')) {
            this.state = {
                ...loadedState,
                listDefaultFiltersDB: [
                    {
                        id: 'GROUP',
                        value: urlParams.get('group') === 'ALL' ? '' : urlParams.has('group') ? urlParams.get('group') : ''
                    }
                ]
            }
        } else {
            this.state = {
                ...loadedState,
                listDefaultFiltersDB: loadedState.listDefaultFiltersDB.filter(item => item.id !== 'GROUP')
            }
        }
        this.filtering = false;
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleFetchDataDebounced = debounce(1000, this.handleFetchData);
    }

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^list.*DB/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage = () => {
        for (let key in this.state) {
            if (this.state.hasOwnProperty(key) && key.match(/^list.*DB/)) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = state => {
        this.props.fetchDistributionList(
            state.pageSize,
            state.sorted,
            state.cursor,
            state.isNextFromPivot,
            this.normalizeFiltered(state));
        this.filtering = false;
        this.setState({
            listDefaultPageSizeDB: state.pageSize,
            listDefaultSortDB: state.sorted,
            listDefaultFiltersDB: state.filtered
        });
    };

    fetchStrategy = state => {
        this.filtering ? this.handleFetchDataDebounced(state) : this.handleFetchData(state);
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date || filterItem.id === 'CREATION_DATE') {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const {columns, isLoading, data, pages, isNextPage, isNextFromPivot, handleEditClick, userDetail, subjects} = this.props;
        const language = get(userDetail, 'settings.language', 'DEFAULT')
        const translatedData = data.map(oldData => ({
            ...oldData,
            subject: (subjects !== undefined && subjects !== null) ?
                countryLanguageMapping[oldData.group] === language ?
                    [get(get(subjects, `distribution.${oldData.group}`, []).find(subject => subject.uuid === oldData.subject), `languages.local`, oldData.subject) === null ?
                        get(get(subjects, `distribution.${oldData.group}`, [])
                            .find(subject => subject.uuid === oldData.subject), `languages.default`, oldData.subject) :
                        get(get(subjects, `distribution.${oldData.group}`, [])
                            .find(subject => subject.uuid === oldData.subject), `languages.local`, oldData.subject)]
                    : get(get(subjects, `distribution.${oldData.group}`, [])
                        .find(subject => subject.uuid === oldData.subject), `languages.default`, oldData.subject)
                : oldData.subject,
        }));

        const modifiedData = translatedData.map(item => {
            return {
                ...item,
                style: {
                    bold: get(item, 'styleInfo.unreadMessageFromDealer'),
                    red: get(item, 'styleInfo.longTimeNoTouch')
                }
            }
        })

        return (
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'TICKET_NUMBER',
                                    accessor: 'ticketNumber',
                                    message: formMessages.TICKET_NUMBER,
                                    Cell: row => <Link
                                        style={{color: get(row, 'original.style.red') ? 'red' : undefined}}
                                        to={`view/${row.original.id}`}>
                                        {row.value}
                                    </Link>
                                },
                                ...columns]}
                            toolButtons={[
                                {
                                    handleClick: handleEditClick,
                                    component: <PrimaryIcon className="btn btn-sm"
                                                            title="Edit"
                                                            type="button"
                                                            key="editButton">
                                        <IconEdit/>
                                    </PrimaryIcon>
                                }
                            ]}
                            isLoading={isLoading}
                            data={modifiedData}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.filtering = true;
                            }}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={this.state.listDefaultFiltersDB}
                            defaultSorting={this.state.listDefaultSortDB}
                            defaultPageSize={this.state.listDefaultPageSizeDB}
                            buttonVisibility={editDistributionListButtonVisibility[moduleRoles.isDealerDistribution(userDetail.roles) ? 'DEALER' : 'EDITOR']}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ListDistributionTable.propTypes = {
    columns: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number.isRequired,
    fetchDistributionList: PropTypes.func.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    handleEditClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.distributionList.isTableLoading,
    data: state.distributionList.listData,
    pages: state.distributionList.pages,
    isNextPage: state.distributionList.isNextPage,
    isNextFromPivot: state.distributionList.isNextFromPivot,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
    userDetail: state.profile.userDetail,
});

export default connect(mapStateToProps, {
    fetchDistributionList
})(ListDistributionTable)
