import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {extractErrorText} from '../actionUtils';
import {errorAlert} from '../alertsActions';
import {uploadMarketingAttachment} from '../../api/marketing/restMarketingAttachment';
import alertMessages from '../../intl/common/alertMessages';

export const MARKETING_ATTACHMENTS_UPLOADING_ERROR = 'MARKETING_ATTACHMENTS_UPLOADING_ERROR';
export const MARKETING_ATTACHMENTS_UPLOADING = 'MARKETING_ATTACHMENTS_UPLOADING';

export const startUploadingMarketingAttachments = () => dispatch => {
    return dispatch({type: MARKETING_ATTACHMENTS_UPLOADING});
};

export const downloadMarketingAttachment = (uuid, ticketId, name) => dispatch => {
    return dispatch({
        type: marketingWebsocketCommands.MARKETING_DOWNLOAD_PRESIGNED_URL,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_DOWNLOAD_PRESIGNED_URL,
        payload: {
            attachment: {
                uuid,
                ticketId,
                name
            }
        }
    });
};

export const uploadMarketingAttachments = (attachments, correlationId) => dispatch => {
    const promises = [];
    const savedAttachments = [];
    attachments.forEach(attachment => {
        promises.push(
            uploadMarketingAttachment(attachment.url, attachment.localUrl, attachment.name, attachment.contentType).then(
                () => {
                    window.URL.revokeObjectURL(attachment.localUrl);
                    savedAttachments.push(attachment);
                }, error => {
                    dispatch({type: MARKETING_ATTACHMENTS_UPLOADING_ERROR});
                    dispatch(errorAlert(...extractErrorText(error, alertMessages.FILE_UPLOAD_ERROR)));
                }
            )
        )
    });

    return Promise.all(promises).then(() => {
            if (savedAttachments.length > 0) {
                dispatch({
                    type: marketingWebsocketCommands.MARKETING_CREATE_ATTACHMENT,
                    marketingWebsocket: true,
                    command: marketingWebsocketCommands.MARKETING_CREATE_ATTACHMENT,
                    payload: {
                        attachments: savedAttachments,
                        correlationId,
                        uuid: savedAttachments[0].ticketId,
                    }
                })
            } else {
                dispatch({
                    type: MARKETING_ATTACHMENTS_UPLOADING_ERROR,
                })
            }
        }
    );
};
