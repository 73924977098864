import {
    ADMIN_SUBJECT_SETTINGS_SELECT_GROUP,
    ADMIN_SUBJECT_SETTINGS_FETCH,
    ADMIN_SUBJECT_SETTINGS_ADD,
    ADMIN_SUBJECT_SETTINGS_EDIT,
    ADMIN_SUBJECT_SETTINGS_UPDATE,
    ADMIN_SUBJECT_SETTINGS_SWITCH,
    ADMIN_SUBJECT_SETTINGS_REMOVE,
    ADMIN_SUBJECTS_FETCH,
} from '../../actions/admin/actionAdminSubjectSettings';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import get from 'get-value';
import set from 'set-value';
import {move} from '../../utils/utils';

export const adminSubjectSettings = (state = {
    isLoading: true,
    subjectSettings: {},
    edit: -1,
    selectedCountryGroup: ''
}, action) => {
    switch (action.type) {
        case ADMIN_SUBJECT_SETTINGS_SELECT_GROUP:
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        case ADMIN_SUBJECT_SETTINGS_FETCH:
            return Object.assign({}, state, {isLoading: true, subjectSettings: {}});
        case ADMIN_SUBJECT_SETTINGS_ADD:
            return Object.assign({}, state, {isLoading: true});
        case ADMIN_SUBJECT_SETTINGS_EDIT:
            return Object.assign({}, state, {edit: action.payload.index});
        case ADMIN_SUBJECT_SETTINGS_UPDATE:
            return Object.assign({}, state, {isLoading: true, edit: -1,});
        case ADMIN_SUBJECT_SETTINGS_SWITCH: {
            const subjectKey = get(action, `payload.subject.subjectKey`, '');
            let newArray = get(state.subjectSettings.subjects, `${subjectKey}.${state.selectedCountryGroup}`, []).slice();
            move(newArray, action.payload.oldIndex, action.payload.subject.position);
            return Object.assign({}, state, set({}, `subjectSettings.subjects.${subjectKey}.${state.selectedCountryGroup}`, newArray));
        }
        case ADMIN_SUBJECT_SETTINGS_REMOVE:
            return Object.assign({}, state, {isLoading: true});
        case ADMIN_SUBJECTS_FETCH:
            return Object.assign({}, state, {isLoading: true});

        case distributionWebsocketCommands.DISTRIBUTION_SUBJECT_SETTINGS_SEND:
        case commercialWebsocketCommands.COMMERCIAL_SUBJECT_SETTINGS_SEND:
        case marketingWebsocketCommands.MARKETING_SUBJECT_SETTINGS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                subjectSettings: get(action, 'payload', {default: {}}),
                correlationId
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_SUBJECT_SETTINGS_SEND_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_SUBJECT_SETTINGS_SEND_ERROR:
        case marketingWebsocketCommands.MARKETING_SUBJECT_SETTINGS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, subjectSettings: {}, correlationId});
        }
        case distributionWebsocketCommands.DISTRIBUTION_SUBJECT_SETTINGS_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_SUBJECT_SETTINGS_ERROR:
        case marketingWebsocketCommands.MARKETING_SUBJECT_SETTINGS_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId});
        }

        default:
            return state;
    }
};
