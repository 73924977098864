import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import get from 'get-value';
import {DISTRIBUTION_FULL_TEXT_SEARCH_FETCH} from '../../actions/distribution/actionDistributionFullTextSearch';

export const distributionFullTextSearch = (state = {
    correlationId: null,
    isLoading: false,
    isTableLoading: false,
    fullTextData: [],
    pages: 0,
    isNextFromPivot: true,
    isNextPage: false,
    isLoadingLastEditors: false,
    lastEditors: [],
}, action) => {
    switch (action.type) {
        case DISTRIBUTION_FULL_TEXT_SEARCH_FETCH: {
            return Object.assign({}, state, {isTableLoading: true})
            // , fullTextData: []});
        }
        case distributionWebsocketCommands.DISTRIBUTION_FULL_TEXT_SEARCH_FETCH_SUCCESS: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isTableLoading: false,
                    fullTextData: get(action, 'payload.result.data', {default: []}),
                    pages: get(action, 'payload.result.pages', {default: 0}),
                    isNextFromPivot: get(action, 'payload.result.isNextFromPivot', false),
                    isNextPage: get(action, 'payload.result.isNextPage', false),
                    correlationId
                });
            }
        }
        case distributionWebsocketCommands.DISTRIBUTION_FULL_TEXT_SEARCH_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {isTableLoading: false, correlationId});
            }
        }
        default:
            return state;
    }
};
