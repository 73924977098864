import {axiosInstance} from './rest';

export async function getUserRole(ipn) {
    return axiosInstance.get(`administration/roles/${ipn}`);
}

export async function saveUser() {
    return axiosInstance.post(`administration/roles`);
}

export async function getUsers(textSearch) {
    let text_search = '';
    if (textSearch !== undefined && textSearch !== null) {
        text_search = textSearch;
    }
    return axiosInstance.get(`administration/roles`, {params: {text_search}});
}

export async function getDealers(textSearch, domain, formType) {
    let text_search = '';
    if (textSearch !== undefined && textSearch !== null) {
        text_search = textSearch;
    }
    return axiosInstance.get(`administration/dealers`, {params: {text_search, domain, formType}});
}

export async function getEditors(textSearch, domain, group) {
    let text_search = '';
    if (textSearch !== undefined && textSearch !== null) {
        text_search = textSearch;
    }
    return axiosInstance.get(`administration/editors`, {params: {text_search, domain, group}});
}

