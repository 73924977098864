import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const DISTRIBUTION_RESET_REDIRECT = 'DISTRIBUTION_RESET_REDIRECT';
export const DISTRIBUTION_SET_LOCK_TICKET = 'DISTRIBUTION_SET_LOCK_TICKET';

export const lockDistributionTicket = uuid => dispatch =>
    dispatch({
        type: DISTRIBUTION_SET_LOCK_TICKET,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_SET_LOCK,
        payload: {uuid}
    });

export const unlockDistributionTicket = uuid => dispatch =>
    dispatch({
        type: distributionWebsocketCommands.DISTRIBUTION_SET_UNLOCK,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_SET_UNLOCK,
        payload: {uuid}
    });

export const resetRedirectAfterLockDistributionTicket = () => dispatch => dispatch({type: DISTRIBUTION_RESET_REDIRECT});
