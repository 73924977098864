import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'list.title',
        defaultMessage: 'List'
    },
});

export default messages;
