import {
    MARKETING_FETCH_PREFERENCES,
    MARKETING_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
    MARKETING_UPDATE_USER_HISTORY_PREFERENCE,
    MARKETING_UPDATE_USER_LIST_PREFERENCE,
    MARKETING_USER_NOTIFICATION_PREFERENCE_FETCH,
    MARKETING_USER_NOTIFICATION_PREFERENCE_UPDATE
} from '../../actions/marketing/actionMarketingPreferences';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import get from 'get-value';

export const marketingPreferences = (state = {
    isLoading: true,
    menuList: [],
    menuHistory: [],
    menuFullTextSearch: [],
    subjects: [],
    statuses: [],
    segments: []
}, action) => {
    switch (action.type) {
        case MARKETING_UPDATE_USER_LIST_PREFERENCE: {
            return Object.assign({}, state, {menuList: action.payload.preferences.hiddenColumns});
        }
        case MARKETING_UPDATE_USER_HISTORY_PREFERENCE: {
            return Object.assign({}, state, {menuHistory: action.payload.preferences.hiddenColumns});
        }
        case MARKETING_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE: {
            return Object.assign({}, state, {menuFullTextSearch: action.payload.preferences.hiddenColumns});
        }
        case marketingWebsocketCommands.MARKETING_PREFERENCES_SEND: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                ...action.payload.preferences,
                isLoading: false,
                correlationId: action.payload.correlationId
            });
        }
        case marketingWebsocketCommands.MARKETING_PREFERENCES_SEND_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case marketingWebsocketCommands.MARKETING_PREFERENCES_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case MARKETING_FETCH_PREFERENCES: {
            return Object.assign({}, state, {isLoading: true});
        }

        case MARKETING_USER_NOTIFICATION_PREFERENCE_FETCH: {
            return Object.assign({}, state, {isLoading: true, subjects: [], statuses: [], segments: []});
        }
        case MARKETING_USER_NOTIFICATION_PREFERENCE_UPDATE: {
            return Object.assign({}, state, {isLoading: true, subjects: [], statuses: [], segments: []})
        }
        case marketingWebsocketCommands.MARKETING_NOTIFICATIONS_PREFERENCES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                subjects: get(action, 'payload.subjects', {default: []}),
                statuses: get(action, 'payload.statuses', {default: []}),
                segments: get(action, 'payload.segments', {default: []}),
                correlationId
            })
        }
        case marketingWebsocketCommands.MARKETING_NOTIFICATIONS_PREFERENCES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                subjects: [],
                statuses: [],
                segments: [],
                correlationId
            });
        }
        default:
            return state;
    }
};
