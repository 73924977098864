import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import tableMessages from '../../../intl/common/tableMessages';
import {StyledReactTable} from '../../../theme/styledTable'
import TableHeader from './TableHeader';
import {moduleRoles} from '../../../utils/roles';
import buttonMessages from '../../../intl/common/buttonMessages';

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {role: moduleRoles.isDealer(this.props.userRoles, this.props.domain)}
    }

    componentDidMount() {
        const {defaultSorting = []} = this.props;
        this.setState({
            sorted: defaultSorting
        });
    }

    hasData = () => !this.props.isLoading && this.props.data && this.props.data.length !== 0;

    render() {
        const {
            intl, isLoading = false, withPagination = true, columns, data, handleFetchData,
            handleOnFilteredChange, pages, handlerOnSortChange, allowTooltipsInHeader = false
        } = this.props;
        const decoratedColumns = columns.map(
            column =>
                Object.assign({}, column,
                    {
                        Header: () => <TableHeader id={column.id} message={column.message}
                                                   filterable={column.filterable}
                                                   sorted={this.state.sorted ? this.state.sorted : []}/>,

                        filterMethod: (filter, row) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined ?
                                String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                                : true;
                        }
                    })
        );
        return (
            <StyledReactTable
                columns={decoratedColumns}
                manual={handleFetchData !== undefined}
                loading={isLoading}
                data={data}
                pages={pages}
                filterable={(typeof(this.props.filterableCustom) != "undefined") ? this.props.filterable : true}
                showPagination={withPagination && this.hasData()}
                minRows={this.hasData() ? 1 : 4}
                previousText={<FormattedMessage {...buttonMessages.PREVIOUS}/>}
                nextText={<FormattedMessage {...buttonMessages.NEXT}/>}
                noDataText={<FormattedMessage {...tableMessages.NO_DATA}/>}
                pageText={<FormattedMessage {...tableMessages.PAGE}/>}
                ofText={<FormattedMessage {...tableMessages.OF}/>}
                rowsText={intl.formatMessage(tableMessages.ROWS)}
                className="-highlight"
                onFetchData={handleFetchData === undefined ? () => null : handleFetchData}
                onSortedChange={handlerOnSortChange === undefined ? (sorted => this.setState({sorted})) : handlerOnSortChange}
                onFilteredChange={handleOnFilteredChange === undefined ? () => null : handleOnFilteredChange}
                defaultFiltered={this.props.defaultFiltered === undefined ? [] : this.props.defaultFiltered}
                defaultSorted={this.props.defaultSorting === undefined ? [] : this.props.defaultSorting}
                defaultPageSize={this.props.defaultPageSize === undefined ? 10 : this.props.defaultPageSize}
                getTheadFilterThProps={allowTooltipsInHeader ? () => ({style: {overflow: 'inherit'}}) : () => ({})}
            />
        );
    }
}

Table.propTypes = {
    intl: PropTypes.any.isRequired,
    isLoading: PropTypes.bool,
    withPagination: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    handleFetchData: PropTypes.func,
    defaultSorting: PropTypes.array,
    allowTooltipsInHeader: PropTypes.bool
};

export default injectIntl(Table);
