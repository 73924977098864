import {createGlobalStyle} from 'styled-components';
import {PRIMARY_YELLOW, PRIMARY_YELLOW_CLEAR_SHADE} from './colors';

const GlobalStyle = createGlobalStyle`
  body, html {
    min-width: 600px;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .metismenu {
     margin-left: 5px;
     overflow: hidden;
     position: relative;
  }
  .metismenu > .metismenu-container > .metismenu-item {
    padding-bottom: 3px;
  }
  .metismenu > .metismenu-container > .metismenu-item > .metismenu-link {
    line-height: 1.7em;
    font-weight: bold;
  }
  .metismenu > .metismenu-container > .metismenu-item > .metismenu-link .metismenu-state-icon {
    line-height: 1.7em;
  }
  .metismenu::after {
    content: " ";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .metismenu-container,
  .metismenu-item {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .metismenu-container .metismenu-container {
    transition: padding 300ms;
    -webkit-transition: padding 300ms;
  }
  .metismenu-container .metismenu-container .metismenu-item > .metismenu-link {
    height: 0;
    overflow: hidden;
  }
  .metismenu-container .metismenu-container .metismenu-link {
    margin-left: 1em;
  }
  .metismenu-container .metismenu-container .metismenu-container .metismenu-link {
    margin-left: 2em;
  }
  .metismenu-container.visible {
    padding: .2em 0;
    padding-bottom: 0;
  }
  .metismenu-container.visible > .metismenu-item {
    padding-bottom: 2px;
  }
  .metismenu-container.visible > .metismenu-item > .metismenu-link {
    height: auto;
  }
  .metismenu-link {
    color: black;
    transition: height 300ms, color 300ms, background-color 300ms;
    -webkit-transition: height 300ms, color 300ms, background-color 300ms;
    display: flex;
    line-height: 1.7em;
    text-decoration: none;
  }
  .metismenu-link:hover {
    border-radius: .25em;
    color: black;
    text-decoration: none;
    & {
      background: ${PRIMARY_YELLOW_CLEAR_SHADE};
    }
  }
  .metismenu-link.active {
    color: black;
    border-radius: .25em;
    background: ${PRIMARY_YELLOW};
  }
  .metismenu-link.has-active-child {
    border-left: ${PRIMARY_YELLOW} solid 4px;
    font-weight: bold;
  }
  .metismenu-link.has-active-child:hover {
    color: black;
  }
  I.metismenu-icon {
    text-align: center;
    width: 0.7em;
    min-width: 0.7em;
  }
  I.metismenu-state-icon {
    transition: transform 300ms;
    -webkit-transition: transform 300ms;
    margin-left: auto;
    line-height: 1.7em;
    text-align: center;
    width: 1em;
  }
  I.metismenu-state-icon.rotate-minus-90 {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
  }
  #nprogress .bar {
    background: #ffcc33 !important;
    height: 4px !important;
  }
  #nprogress .peg {
    box-shadow: 0 0 10px #ffcc33, 0 0 5px #ffcc33 !important;
  }
  #nprogress .spinner-icon {
    height: 25px !important;
    width: 25px !important;
    border-top-color: #ffcc33 !important;
    border-left-color: #ffcc33 !important;
  }

  .ReactTable .rt-th {
    overflow: visible !important;
  }
  .ReactTable .rt-th .picky__input{
    height: auto;
    border: 1px solid #999999;
    border-radius: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ReactTable .rt-th .picky__dropdown{
    min-width: 100%;
    width: auto;
    top: calc(1.5em + 10px);
  }
  .ReactTable .rt-th .picky__dropdown .option{
    padding: .25em;
    color: #000000;
  }
  .ReactTable .rt-resizer {
    right: 0px !important;
  }
  .ReactTable .picky__placeholder {
    color: #000000;
  }
`;

export default GlobalStyle;
