import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'get-value';
import {
    DISTRIBUTION_PATH,
    DISTRIBUTION_NEW_PATH,
    DISTRIBUTION_NEW_TICKET_PATH,
    DISTRIBUTION_LIST_PATH,
    DISTRIBUTION_HISTORY_PATH,
    DISTRIBUTION_FULL_TEXT_SEARCH_PATH,
    DISTRIBUTION_NOTIFICATION_PATH,
    DISTRIBUTION_EXPORT_PATH,
} from '../../routes/paths';
import {DISTRIBUTION_FORM} from '../../constants/formConstants';
import {fetchSubjects} from '../../actions/admin/actionAdminSubjectSettings';
import {domains} from '../../constants/Utils';
import {moduleRoles} from '../../utils/roles';
import NotFoundPage from '../NotFoundPage';
import ListDistributionPage from './list/ListDistributionPage';
import HistoryDistributionPage from './history/HistoryDistributionPage';
import NewDistributionTicketPage from './ticket/NewDistributionTicketPage';
import FullTextSearchDistributionPage from './fullTextSearch/FullTextSearchDistributionPage';
import ViewDistributionTicketPage from './ticket/ViewDistributionTicketPage';
import EditDistributionTicketPage from './ticket/EditDistributionTicketPage';
import NotificationDistributionPage from './notification/NotificationDistributionPage';
import ExportDistributionPage from './export/ExportDistributionPage';
import {fetchCountryBU} from '../../actions/admin/actionBUAssignment';

const domain = domains.DISTRIBUTION;

class Distribution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managedGroups: get(this.props.loggedUser, `roles.${domain}.dealer`) ?
                [get(this.props.loggedUser, 'group', 'XX')] :
                moduleRoles.getEditorGroups(domain, get(this.props.loggedUser, `roles`, {})).map(({group}) => group)
        }
    }

    componentDidMount() {
        this.props.fetchSubjects(domain, this.state.managedGroups, 'distribution');
        this.props.fetchCountryBU(moduleRoles.isDealerDistribution(get(this.props.loggedUser, `roles`, {})) ? [].concat(get(this.props.loggedUser, `group`, [])) : moduleRoles.getEditorGroups(domain, get(this.props.loggedUser, `roles`, {})).map(({group}) => group));
    }

    render() {
        return (
            <Switch>
                <Route exact path={DISTRIBUTION_PATH}
                       render={() => <Redirect to={DISTRIBUTION_LIST_PATH}/>}/>

                <Route exact path={DISTRIBUTION_LIST_PATH}
                       render={() => <ListDistributionPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={DISTRIBUTION_PATH + '/:view(view)/:ticketUuid(.*)'}
                       component={ViewDistributionTicketPage}/>
                <Route exact path={DISTRIBUTION_PATH + '/:edit(edit)/:ticketUuid(.*)'}
                       component={EditDistributionTicketPage}/>
                <Route exact path={DISTRIBUTION_HISTORY_PATH}
                       render={() => <HistoryDistributionPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={DISTRIBUTION_FULL_TEXT_SEARCH_PATH}
                       render={() => <FullTextSearchDistributionPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={DISTRIBUTION_EXPORT_PATH}
                       render={() => <ExportDistributionPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={DISTRIBUTION_NOTIFICATION_PATH}
                       component={NotificationDistributionPage}/>

                <Route exact path={DISTRIBUTION_NEW_PATH}
                       render={() => <Redirect to={DISTRIBUTION_NEW_TICKET_PATH}/>}/>
                <Route exact path={DISTRIBUTION_NEW_TICKET_PATH}
                       render={() => <NewDistributionTicketPage type={DISTRIBUTION_FORM}/>}/>

                <Route component={NotFoundPage}/>
            </Switch>
        )
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail,
});
export default withRouter(connect(mapStateToProps, {fetchSubjects, fetchCountryBU})(Distribution));
