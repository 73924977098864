import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {fetchNotification, updateNotification} from '../../../actions/admin/actionAdminNotification';
import {Wrapper} from '../../common/StyledComponents';
import NotificationForm from './NotificationForm';
import Loader from '../../Loader';

class NotificationContainer extends Component {
    componentDidMount() {
        this.props.fetchNotification(this.props.domain, this.props.group);
    }

    handleSubmit = ({subject = '', body = ''}) => {
        const {group} = this.props;
        const notification = {
            group: group,
            subject: subject,
            body: body
        };
        this.props.updateNotification(this.props.domain, notification);
    };

    render() {
        const {isLoading, group, data, domain} = this.props;
        if (isLoading) {
            return <Loader/>;
        }

        return (
            <Wrapper classname="container-fluid">
                <NotificationForm onSubmit={this.handleSubmit}
                                  initialValues={data}
                                  enableReintialize={true}
                                  form={`notificvationForm${domain}_${group}`}/>
            </Wrapper>
        )
    }
}

NotificationContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    group: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    fetchNotification: PropTypes.func.isRequired,
    updateNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminNotification.isLoading,
    data: state.adminNotification.emailData
});

export default connect(mapStateToProps, {
    fetchNotification,
    updateNotification
})(NotificationContainer)
