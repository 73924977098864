import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
    FIELD_DEALER_NAME, FIELD_DEALER_NUMBER,
    FORM_FIELD_SUBSTITUTION,
    FORM_FIELD_SUBSTITUTION_USER,
    TICKET_SECTION
} from '../../../../../../constants/formConstants';
import formMessages from '../../../../../../intl/modules/formMessages';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import {handleUserSearch} from '../../../../../../utils/userUtils';
import {handleFetchUsersError} from '../../../../../../actions/actionRole';
import {InputSelectUserFieldWithValidation} from './InputSelectUserFieldWithValidation';
import { change } from 'redux-form';
import {setSubstitutionUser} from '../../../../../../actions/distribution/actionDistributionTicket';
import {domains} from '../../../../../../constants/Utils';
import {handleDealerSearch} from '../../../../../../utils/dealerUtils';
import {moduleRoles} from '../../../../../../utils/roles';
import get from 'get-value';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const domain = domains.DISTRIBUTION;

const SubstitutionSection = (props) => {
    const { readOnly, change, setSubstitutionUser, isSubstituted, form, loggedUser } = props;

    const editorGroups =  moduleRoles.getEditorGroups(domain, get(loggedUser, `roles`, {})).map(({group}) => group);

    const handleUserSelection = user => {
        setSubstitutionUser(user);
        change(`${TICKET_SECTION}.${FORM_FIELD_SUBSTITUTION}`, user.ipn);
        change(`${TICKET_SECTION}.group`, user.group);
        change(`${TICKET_SECTION}.${FIELD_DEALER_NAME}`, user.dealerName);
        change(`${TICKET_SECTION}.${FIELD_DEALER_NUMBER}`, user.dealerNumber);
        change(`${TICKET_SECTION}.type`, form);
    };

    const handleUserSearching = (inputValue, callback) => {
        handleDealerSearch(inputValue, callback, props.handleFetchUsersError, domain, form, editorGroups);
    };

    return (
        <>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-12 pb-2">
                        <InputSelectUserFieldWithValidation
                            label={formMessages.SUBSTITUTION_DEALER}
                            field={FORM_FIELD_SUBSTITUTION_USER}
                            handleUserSelection={handleUserSelection}
                            handleUserSearch={handleUserSearching}
                            isDisabled={readOnly}
                            isSmall
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state, props) => ({
    isSubstituted: state.distributionTicket.substitutionUser,
    loggedUser: state.profile.userDetail,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
    handleFetchUsersError: error => dispatch(handleFetchUsersError(error)),
    setSubstitutionUser: (user) => dispatch(setSubstitutionUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubstitutionSection);
