import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import styled from 'styled-components';
import {StyledReactTable} from '../../../theme/styledTable';
import TableHeader from './TableHeader';
import buttonMessages from '../../../intl/common/buttonMessages';
import tableMessages from '../../../intl/common/tableMessages';
import Pagination from './Pagination';
import get from 'get-value';

const ButtonDiv = styled.div`
  display: table-cell;
`;

class TicketTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
        };
        this.setActivePage = this.setActivePage.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        const {defaultSorting = []} = this.props;
        this.setState({
            sorted: defaultSorting
        });
    }

    setActivePage(page) {
        this.setState({activePage: page})
    };

    hasData = () => !this.props.isLoading && this.props.data && this.props.data.length !== 0;

    fetchData = (state) => {
        this.props.handleFetchData(state);
        const page = state.page === 0 ? 1 : state.page;
        this.setState({activePage: page});
    };

    render() {
        const {
            columns, isLoading = false, data, pages, withPagination = true, intl, handlerOnSortChange, toolButtons = [],
            handleOnFilteredChange, defaultPageSize = 10, allowTooltipsInHeader = false, isNextPage, isNextFromPivot,
        } = this.props;

        const decoratedColumns = columns.map(
            column =>
                Object.assign({}, column,
                    {
                        Header: () => <TableHeader id={column.id}
                                                   message={column.message}
                                                   filterable={column.filterable}
                                                   sorted={this.state.sorted ? this.state.sorted : []}
                        />,
                        filterMethod: (filter, row) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined ?
                                String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                                : true;
                        }
                    })
        );

        if (toolButtons.length > 0) {
            decoratedColumns.unshift({
                id: 'tools',
                accessor: 'tools',
                sortable: false,
                filterable: false,
                maxWidth: toolButtons.length * 41,
                Cell: row => {
                    return <React.Fragment>
                        {
                            toolButtons.map((toolButton, index) => {
                                let toolButtonObject = toolButton;
                                if (toolButtonObject instanceof Function) {
                                    toolButtonObject = toolButtonObject(row);
                                }

                                if (this.props.buttonVisibility && !this.props.buttonVisibility.includes(row.original.status)) {
                                    return <div/>;
                                }
                                return <ButtonDiv key={`${row.original.id}button${index}`}
                                                  onClick={() => toolButtonObject.handleClick(row.original, toolButtonObject.onClickCallback)}>
                                    {toolButtonObject.component}
                                </ButtonDiv>
                            })
                        }
                    </React.Fragment>
                }
            });
        }

        return (
            <StyledReactTable
                columns={decoratedColumns}
                manual
                loading={isLoading}
                data={data}
                pages={pages}
                filterable
                showPagination={withPagination && this.hasData()}
                minRows={5}
                firstText={<FormattedMessage {...buttonMessages.FIRST}/>}
                previousText={<FormattedMessage {...buttonMessages.PREVIOUS}/>}
                nextText={<FormattedMessage {...buttonMessages.NEXT}/>}
                lastText={<FormattedMessage {...buttonMessages.LAST}/>}
                noDataText={<FormattedMessage {...tableMessages.NO_DATA}/>}
                pageText={<FormattedMessage {...tableMessages.PAGE}/>}
                ofText={<FormattedMessage {...tableMessages.OF}/>}
                rowsText={intl.formatMessage(tableMessages.ROWS)}
                className="-highlight"
                onFetchData={(state, instance) => {
                    this.fetchData(state, instance);
                }}
                onSortedChange={handlerOnSortChange === undefined ?
                    (sorted => this.setState({sorted})) : handlerOnSortChange}
                onFilteredChange={handleOnFilteredChange === undefined ? () => null : handleOnFilteredChange}
                defaultFiltered={this.props.defaultFiltered === undefined ? [] : this.props.defaultFiltered}
                defaultSorted={this.props.defaultSorting === undefined ? [] : this.props.defaultSorting}
                defaultPageSize={defaultPageSize}
                getTheadFilterThProps={allowTooltipsInHeader ? () => ({style: {overflow: 'inherit'}}) : () => ({})}
                PaginationComponent={Pagination}
                getPaginationProps={() => {
                    return {
                        isNextPage,
                        isNextFromPivot,
                        activePage: this.state.activePage > pages ? pages : this.state.activePage,
                    }
                }}
                getTrProps={(state, row) => {
                    return {
                        style: {
                            fontWeight: get(row, 'original.style.bold') ? 'bold' : undefined,
                            color: get(row, 'original.style.red') ? 'red' : undefined,
                        }
                    }
                }}
            />
        )
    }
}

TicketTable.propType = {
    columns: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    data: PropTypes.array.isRequired,
    withPagination: PropTypes.bool,
    handleFetchData: PropTypes.func,
    intl: PropTypes.any.isRequired,
    defaultSorting: PropTypes.array,
    allowTooltipsInHeader: PropTypes.bool,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    toolButtons: PropTypes.array,
};

export default injectIntl(TicketTable);
