import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'get-value';
import {
    fetchSubjectSettings, addSubjectSettings, moveSubjectSettings
} from '../../../actions/admin/actionAdminSubjectSettings';
import {Table, Wrapper} from '../../common/StyledComponents';
import {PrimaryButton} from '../../common/Button';
import SubjectSettingsDialog from './SubjectSettingsDialog';
import SubjectSettingsRow from './SubjectSettingsRow';
import Loader from '../../Loader';
import buttonMessages from '../../../intl/common/buttonMessages';
import subjectSettingsMessages from '../../../intl/admin/subjectSettingsMessages';
import adminMessages from '../../../intl/admin/adminMessages';

const Button = styled(PrimaryButton)`
  margin-right: 5px;
`;

class SubjectSettingsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {isOpenSubjectSettingsDialog: false};
    }

    componentDidMount() {
        this.props.fetchSubjectSettings(this.props.domain, this.props.group, this.props.subjectKey);
    }

    showAddModal = () =>
        this.setState({
            isOpenSubjectSettingsDialog: true
        });

    closeModal = () =>
        this.setState({
            isOpenSubjectSettingsDialog: false
        });

    saveModal = (valueDefault = '', valueLocal = '', vinObligation = false, refNoObligation = false, subjectId) => {
        this.setState({
            isOpenSubjectSettingsDialog: false
        });
        const subject = {
            group: this.props.group,
            uuid: subjectId,
            languages: {default: valueDefault, local: valueLocal || ''},
            subjectKey: this.props.subjectKey
        };

        if(['commercial', 'distribution'].includes(this.props.subjectKey)){
            subject['vinObligation'] = vinObligation;
        };
        if (['marketingQuestion', 'priceVerification', 'nameVerification'].includes(this.props.subjectKey)){
            subject['refNoObligation'] = refNoObligation;
        };

        this.props.addSubjectSettings(this.props.domain, subject);
    };

    onDragEnd = (result) => {
        const {subjectSettings, subjectKey, group, domain} = this.props;
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const subject = {
            group: group,
            uuid: get(subjectSettings, `${subjectKey}.${group}`, '')[result.source.index].uuid,
            subjectKey: subjectKey,
            position: result.destination.index
        };
        this.props.moveSubjectSettings(domain, result.source.index, subject)
    };

    render() {
        const {isLoading, subjectSettings, subjectKey, domain, group} = this.props;
        const subjects = (get(subjectSettings, `${subjectKey}.${group}`, [])).filter(function (e) {
            return e.active === true;
        });
        return (
            <Wrapper classname="container-fluid">
                <h5><FormattedMessage {...subjectSettingsMessages.TITLE}/></h5>
                {isLoading && <Loader/>}
                {!isLoading && <>
                    <Button className="btn btn-sm mb-sm-3"
                            onClick={this.showAddModal}>
                        <FormattedMessage {...buttonMessages.ADD}/>
                    </Button>
                    {subjects.length === 0 &&
                    <div>
                        <FormattedMessage {...subjectSettingsMessages.EMPTY}/>
                    </div>
                    }
                    {subjects.length !== 0 &&
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="card rounded">
                            <Table className="table table-hover">
                                <thead className="thead-light">
                                <tr className="d-flex">
                                    <th className="col-1"/>
                                    <th className="col-4">
                                        <FormattedMessage {...subjectSettingsMessages.SUBJECT_DEFAULT}/>
                                    </th>
                                    <th className="col">
                                        <FormattedMessage {...subjectSettingsMessages.SUBJECT_LOCAL}/>
                                    </th>
                                    {['commercial', 'distribution'].includes(subjectKey) &&
                                        <th className="col-2 col-md-1">
                                            <FormattedMessage {...subjectSettingsMessages.VIN}/>
                                        </th>
                                    }
                                    {['marketingQuestion', 'priceVerification', 'nameVerification'].includes(subjectKey) &&
                                        <th className="col-2 col-md-1">
                                            <FormattedMessage {...subjectSettingsMessages.REFERENCE_NUMBER}/>
                                        </th>
                                    }
                                    <th className="col-2">
                                        <FormattedMessage {...adminMessages.ACTION}/>
                                    </th>
                                </tr>
                                </thead>
                                <Droppable droppableId="table">
                                    {provided => {
                                        return (
                                            <tbody ref={provided.innerRef}
                                                   {...provided.droppableProps}>
                                            {subjects.map((subjectSettings, index) =>
                                                <Draggable key={index}
                                                           draggableId={`id-${index}`}
                                                           index={index}>
                                                    {provided => {
                                                        return (
                                                            <SubjectSettingsRow provided={provided}
                                                                                index={index}
                                                                                data={subjectSettings}
                                                                                subjectKey={subjectKey}
                                                                                domain={domain}/>
                                                        )
                                                    }}
                                                </Draggable>
                                            )
                                            }
                                            </tbody>
                                        )
                                    }}
                                </Droppable>
                            </Table>
                        </div>
                    </DragDropContext>
                    }
                    <SubjectSettingsDialog show={this.state.isOpenSubjectSettingsDialog}
                                           subjectKey={subjectKey}
                                           closeModal={this.closeModal}
                                           saveModal={this.saveModal}/>
                </>}
            </Wrapper>
        );
    }
}

SubjectSettingsContainer.propsType = {
    isLoading: PropTypes.bool.isRequired,
    subjectSettings: PropTypes.array.isRequired,
    fetchSubjectSettings: PropTypes.func.isRequired,
    moveSubjectSettings: PropTypes.func.isRequired,
    addSubjectSettings: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminSubjectSettings.isLoading,
    subjectSettings: state.adminSubjectSettings.subjectSettings.subjects,
    fetchSubjectSettings: state.fetchSubjectSettings,
    moveSubjectSettings: state.moveSubjectSettings,
    addSubjectSettings: state.addSubjectSettings,
});

export default connect(mapStateToProps, {
    fetchSubjectSettings, moveSubjectSettings, addSubjectSettings,
})(injectIntl(SubjectSettingsContainer))
