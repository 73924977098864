import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    addPresetResponse,
    fetchPresetResponse,
    movePresetResponse
} from '../../../actions/admin/actionAdminPresetResponse';
import {PrimaryButton} from '../../common/Button';
import {Table, Wrapper} from '../../common/StyledComponents';
import {moduleRoles} from '../../../utils/roles';
import PresetResponseDialog from './PresetResponseDialog';
import PresetResponseRow from './PresetResponseRow';
import Loader from '../../Loader';
import buttonMessages from '../../../intl/common/buttonMessages';
import presetResponseMessages from '../../../intl/admin/presetResponseMessages';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import adminMessages from '../../../intl/admin/adminMessages';

const Button = styled(PrimaryButton)`
  margin-right: 5px;
`;

class PresetResponseContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {isOpenAddPresetResponseDialog: false};
    }

    componentDidMount() {
        this.props.fetchPresetResponse(this.props.domain, this.props.selectedGroup);
    }

    showAddModal = () =>
        this.setState({
            isOpenAddPresetResponseDialog: true,
        });

    closeModal = () =>
        this.setState({
            isOpenAddPresetResponseDialog: false
        });

    saveModal = ({presetName = '', presetMessage = ''}, responseId) => {
        this.setState({
            isOpenAddPresetResponseDialog: false
        });
        const item = {
            presetName: presetName,
            presetMessage: presetMessage,
            uuid: responseId,
            group: this.props.selectedGroup,
        };
        this.props.addPresetResponse(this.props.domain, item);
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }
        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }
        //swap
        const item = {
            group: this.props.selectedGroup,
            position: result.destination.index,
            uuid: this.props.presetResponses[result.source.index].uuid,
        };
        this.props.movePresetResponse(this.props.domain, item, result.source.index);
    };

    render() {
        const {isLoading, presetResponses, domain, intl: {formatMessage}, selectedGroup, roles} = this.props;
        return (
            <Wrapper>
                <h5><FormattedMessage {...presetResponseMessages.PRESET_RESPONSE}/></h5>
                {isLoading && <Loader/>}
                {!isLoading && <>
                    <Button
                        disabled={presetResponses.length > 9}
                        className="btn btn-sm mb-sm-3"
                        onClick={this.showAddModal}>
                        <FormattedMessage {...buttonMessages.ADD}/>
                    </Button>
                    {presetResponses.length === 0 &&
                    <div>
                        <FormattedMessage {...presetResponseMessages.EMPTY}/>
                    </div>
                    }
                    {presetResponses.length !== 0 &&
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="card rounded">
                            <Table className="table table-hover">
                                <thead className="thead-light">
                                <tr className="d-flex">
                                    <th className="col-1"/>
                                    <th className="col-3">
                                        <FormattedMessage {...presetResponseMessages.PRESET_RESPONSE_NAME}/>
                                    </th>
                                    <th className="col-6">
                                        <FormattedMessage {...presetResponseMessages.PRESET_RESPONSE_MESSAGE}/>
                                    </th>
                                    <th className="col-2">
                                        <FormattedMessage {...adminMessages.ACTION}/>
                                    </th>
                                </tr>
                                </thead>
                                <Droppable droppableId="table">
                                    {provided => {
                                        return (
                                            <tbody ref={provided.innerRef}
                                                   {...provided.droppableProps}>
                                            {presetResponses.map((presetResponse, index) =>
                                                <Draggable key={index} draggableId={`id-${index}`} index={index}>
                                                    {provided => {
                                                        return (
                                                            <PresetResponseRow provided={provided}
                                                                               index={index}
                                                                               data={presetResponse}
                                                                               domain={this.props.domain}/>
                                                        )
                                                    }}
                                                </Draggable>
                                            )}
                                            </tbody>
                                        )
                                    }}
                                </Droppable>
                            </Table>
                        </div>
                    </DragDropContext>
                    }
                    <PresetResponseDialog domain={domain}
                                          show={this.state.isOpenAddPresetResponseDialog}
                                          closeModal={this.closeModal}
                                          saveModal={this.saveModal}
                                          group={formatMessage(countryGroupMessages[selectedGroup])}
                                          initialValues={{
                                              groups: [moduleRoles.getAdminGroups(domain, roles)
                                                  .map(group => ({
                                                      value: group.group,
                                                      label: formatMessage(countryGroupMessages[group.group]),
                                                      isFixed: true
                                                  })).find(group => group.value === selectedGroup)]
                                          }}/>
                </>}
            </Wrapper>
        );
    }
}

PresetResponseContainer.propTypes = {
    selectedGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminPresetResponse.isLoading,
    presetResponses: state.adminPresetResponse.presetResponses,
    roles: state.profile.userDetail.roles,
    profile: state.profile.userDetail,
    intl: state.intl
});
export default connect(mapStateToProps, {
    movePresetResponse,
    addPresetResponse,
    fetchPresetResponse
})(injectIntl(PresetResponseContainer));
