import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {change, reduxForm, Field} from 'redux-form';
import PropTypes from 'prop-types';
import get from 'get-value';
import {StatusSelector, SegmentSelector, SubjectSelector} from '../../common/fields/UserNotificationSelectors';
import {StyledDiv} from '../../common/StyledComponents';
import ButtonSection from '../../common/userNotification/ButtonSection';
import formStatesMessages from '../../../intl/modules/formStatesMessages';
import notificationMessages from '../../../intl/modules/notificationMessages';

class NotificationMarketingForm extends Component {
    constructor(props) {
        super(props);
        const {initialValues, intl: {formatMessage}, subjectOptions} = this.props;
        this.state = {
            selectedStatuses: (initialValues.statuses || []).map(status => ({
                value: status,
                label: formStatesMessages[status] ? formatMessage(formStatesMessages[status]) : status
            })),
            selectedSegments: (initialValues.segments || []).map(segment => ({
                value: segment,
                label: segment
            })),
            selectedMarketingQuestion: (initialValues.subjects.marketingQuestion || []).map(subject => ({
                value: subject,
                label: get((subjectOptions.marketingQuestion || []).find(e => e.value === subject), `label`, subject)
            })),
            selectedPriceVerification: (initialValues.subjects.priceVerification || []).map(subject => ({
                value: subject,
                label: get((subjectOptions.priceVerification || []).find(e => e.value === subject), `label`, subject)
            })),
            selectedNameVerification: (initialValues.subjects.nameVerification || []).map(subject => ({
                value: subject,
                label: get((subjectOptions.nameVerification || []).find(e => e.value === subject), `label`, subject)
            })),
        }
    }

    handleStatusChange = newOptions => {
        this.props.change(`statuses`, (newOptions || []).map(e => e.value));
        this.setState({
            selectedStatuses: (newOptions || []).map(status => ({
                value: status.value,
                label: status.label
            })),
        });
    };
    handleSegmentChange = newOptions => {
        this.props.change(`segments`, (newOptions || []).map(e => e.value));
        this.setState({
            selectedSegments: (newOptions || []).map(segment => ({
                value: segment.value,
                label: segment.label
            })),
        });
    };
    handleMarketingQuestionChange = newOptions => {
        this.props.change(`subjects.marketingQuestion`, (newOptions || []).map(e => e.value));
        this.setState({
            selectedMarketingQuestion: (newOptions || []).map(subject => ({
                value: subject.value,
                label: subject.label
            })),
        })
    };
    handlePriceVerificationChange = newOptions => {
        this.props.change(`subjects.priceVerification`, (newOptions || []).map(e => e.value));
        this.setState({
            selectedPriceVerification: (newOptions || []).map(subject => ({
                value: subject.value,
                label: subject.label
            })),
        })
    };
    handleNameVerificationChange = newOptions => {
        this.props.change(`subjects.nameVerification`, (newOptions || []).map(e => e.value));
        this.setState({
            selectedNameVerification: (newOptions || []).map(subject => ({
                value: subject.value,
                label: subject.label
            })),
        })
    };

    handleStatusAllClick = () => {
        this.props.change(`statuses`, (this.props.statusOptions || []).map(e => e.value));
        this.setState({selectedStatuses: this.props.statusOptions || []})
    };
    handleSegmentAllClick = () => {
        this.props.change(`segments`, (this.props.segmentOptions || []).map(e => e.value));
        this.setState({selectedSegments: this.props.segmentOptions || []})
    };
    handleMarketingQuestionAllClick = () => {
        this.props.change(`subjects.marketingQuestion`, (this.props.subjectOptions.marketingQuestion || []).map(e => e.value));
        this.setState({selectedMarketingQuestion: this.props.subjectOptions.marketingQuestion || []})
    };
    handlePriceVerificationAllClick = () => {
        this.props.change(`subjects.priceVerification`, (this.props.subjectOptions.priceVerification || []).map(e => e.value));
        this.setState({selectedPriceVerification: this.props.subjectOptions.priceVerification || []})
    };
    handleNameVerificationAllClick = () => {
        this.props.change(`subjects.nameVerification`, (this.props.subjectOptions.nameVerification || []).map(e => e.value));
        this.setState({selectedNameVerification: this.props.subjectOptions.nameVerification || []})
    };

    handleCancel = () => {
        const {initialValues, intl: {formatMessage}, subjectOptions, reset} = this.props;
        reset();
        this.setState({
            selectedStatuses: (initialValues.statuses || []).map(status => ({
                value: status,
                label: formStatesMessages[status] ? formatMessage(formStatesMessages[status]) : status
            })),
            selectedSegments: (initialValues.segments || []).map(segment => ({
                value: segment,
                label: segment
            })),
            selectedMarketingQuestion: (initialValues.subjects.marketingQuestion || []).map(subject => ({
                value: subject,
                label: get((subjectOptions.marketingQuestion || []).find(e => e.value === subject), `label`, subject)
            })),
            selectedPriceVerification: (initialValues.subjects.priceVerification || []).map(subject => ({
                value: subject,
                label: get((subjectOptions.priceVerification || []).find(e => e.value === subject), `label`, subject)
            })),
            selectedNameVerification: (initialValues.subjects.nameVerification || []).map(subject => ({
                value: subject,
                label: get((subjectOptions.nameVerification || []).find(e => e.value === subject), `label`, subject)
            })),
        })
    };

    render() {
        const {form, statusOptions, segmentOptions, subjectOptions, handleSubmit, intl: {formatMessage}} = this.props;

        return (
            <StyledDiv className="border border-dark px-4 pt-3 pb-2 bg-light">
                {(statusOptions || []).length !== 0 &&
                <div>
                    <div className="row mb-3">
                        <div className="col">
                            <Field name={'statuses'}
                                   label={notificationMessages.STATUS_NOTIFICATION}
                                   component={StatusSelector}
                                   statuses={this.state.selectedStatuses}
                                   options={statusOptions}
                                   placeholder={formatMessage(notificationMessages.STATUS_PLACEHOLDER)}
                                   handleChange={this.handleStatusChange}
                                   handleAllClick={this.handleStatusAllClick}/>
                        </div>
                    </div>
                </div>
                }
                {(segmentOptions || []).length !== 0 &&
                <div>
                    <div className="row mb-3">
                        <div className="col">
                            <Field name={'segments'}
                                   label={notificationMessages.SEGMENT_NOTIFICATION}
                                   component={SegmentSelector}
                                   segments={this.state.selectedSegments}
                                   options={segmentOptions}
                                   placeholder={formatMessage(notificationMessages.SEGMENT_PLACEHOLDER)}
                                   handleChange={this.handleSegmentChange}
                                   handleAllClick={this.handleSegmentAllClick}/>
                        </div>
                    </div>
                </div>
                }
                {(subjectOptions.marketingQuestion || []).length !== 0 &&
                <div>
                    <div className="row mb-3">
                        <div className="col">
                            <Field name={'subjects.marketingQuestion'}
                                   subjectKey={'marketingQuestion'}
                                   component={SubjectSelector}
                                   subjects={this.state.selectedMarketingQuestion}
                                   options={subjectOptions.marketingQuestion}
                                   placeholder={formatMessage(notificationMessages.SUBJECT_PLACEHOLDER)}
                                   handleChange={this.handleMarketingQuestionChange}
                                   handleAllClick={this.handleMarketingQuestionAllClick}/>
                        </div>
                    </div>
                </div>
                }
                {(subjectOptions.priceVerification || []).length !== 0 &&
                <div>
                    <div className="row mb-3">
                        <div className="col">
                            <Field name={'subjects.priceVerification'}
                                   subjectKey={'priceVerification'}
                                   component={SubjectSelector}
                                   subjects={this.state.selectedPriceVerification}
                                   options={subjectOptions.priceVerification}
                                   placeholder={formatMessage(notificationMessages.SUBJECT_PLACEHOLDER)}
                                   handleChange={this.handlePriceVerificationChange}
                                   handleAllClick={this.handlePriceVerificationAllClick}/>
                        </div>
                    </div>
                </div>
                }
                {(subjectOptions.nameVerification || []).length !== 0 &&
                <div>
                    <div className="row mb-3">
                        <div className="col">
                            <Field name={'subjects.nameVerification'}
                                   subjectKey={'nameVerification'}
                                   component={SubjectSelector}
                                   subjects={this.state.selectedNameVerification}
                                   options={subjectOptions.nameVerification}
                                   placeholder={formatMessage(notificationMessages.SUBJECT_PLACEHOLDER)}
                                   handleChange={this.handleNameVerificationChange}
                                   handleAllClick={this.handleNameVerificationAllClick}/>
                        </div>
                    </div>
                </div>
                }
                <ButtonSection form={form}
                               onCancel={this.handleCancel}
                               onSubmit={handleSubmit}/>
            </StyledDiv>
        )
    }
}

NotificationMarketingForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    userDetail: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    segmentOptions: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default reduxForm({})(connect(null, mapDispatchToProps)(injectIntl(NotificationMarketingForm)));


