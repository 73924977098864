import {
    DISTRIBUTION_FORM,
    FIELD_MESSAGE,
    FIELD_SUBJECT,
} from './formConstants';

export const createReqFields = {
    [DISTRIBUTION_FORM]: [
        FIELD_SUBJECT,
        FIELD_MESSAGE,
    ],
};

export const editReqFields = {
    'DEALER': {
        [DISTRIBUTION_FORM]: [
            FIELD_MESSAGE,
        ],
    },
    'EDITOR': {
        [DISTRIBUTION_FORM]: [
            FIELD_MESSAGE,
            FIELD_SUBJECT,
        ],
    }
};
