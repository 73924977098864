export const TOGGLE = 'TOGGLE';

export const toggle = (isToggled) => {
    return (dispatch) => {
        dispatch({
            type: TOGGLE,
            payload: isToggled
        });
    };
};
