import React from 'react';
import styled from 'styled-components';
import validationMessages from '../../../intl/common/validationMessages';
import {FormattedMessage} from 'react-intl';

const ErrorDiv = styled.label`
    display: inherit;
`;

const StyledTextarea = styled.textarea`
    color: black;
`;

export const TextareaWithValidation = (props) => {
    const {input, isDisabled, type, placeholder, maxLength, rows, meta: {touched, error, invalid}} = props;

    return (
        <div>
            <StyledTextarea {...input} type={type} placeholder={placeholder}
                      className={'form-control form-control-sm' + (touched && invalid ? ' is-invalid' : '')}
                      maxLength={maxLength}
                      rows={rows}
                      disabled={isDisabled}
            />
            {touched && error && <ErrorDiv className="invalid-feedback">{validationMessages[error] ?
                <FormattedMessage {...validationMessages[error]}/> : error}</ErrorDiv>}
        </div>
    );
};




