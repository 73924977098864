import v4 from 'uuid/v4';
import get from 'get-value';
import {diff} from 'deep-object-diff';
import {MARKETING_TICKET_STATUS_NEW} from '../../constants/formConstants';
import {MEDIUM} from '../../constants/levelConstants';

export const initLoadedMarketingTicket = (ticketData) => {
    return {
        ticketSection: ticketData.ticket,
        statusHistory: ticketData.logs,
        attachmentSection: {attachments: ticketData.attachments},
        messages: ticketData.messages
    };
};

export const initNewMarketingTicket = (userDetail) => {
    if (!userDetail.dealerNumber || userDetail === '') {
        return undefined;
    }

    return {
        ticketSection: {
            dealerNumber: userDetail.dealerNumber,
            dealerName: userDetail.dealerName,
            group: userDetail.group,
            status: MARKETING_TICKET_STATUS_NEW,
            priority: MEDIUM,
        }
    };
};

export const transformNewMarketingTicketForSave = (values) => {
    const data = {};

    data.ticket = {
        ...values.ticketSection,
        uuid: v4(),
    };
    delete data.ticket.status;

    if (values.messageSection) {
        data.message = {
            message: values.messageSection.message,
            sendCopyTo: values.messageSection.sendCopyTo,
            uuid: v4(),
        };
    }

    if (get(values, 'attachmentSection.newAttachments', {default: false}) && values.attachmentSection.newAttachments.length > 0) {
        data.newAttachments = [];
        values.attachmentSection.newAttachments.forEach(attachments => {
            data.newAttachments.push({
                ...attachments,
                uuid: v4(),
            })
        })
    }

    return data;
};

export const transformUpdatedMarketingTicketForSave = (values) => {
    const {oldValues, newValues} = values;
    const difference = diff(oldValues, newValues);
    const data = {};

    data.ticket = {
        ...get(difference, 'ticketSection'),
        uuid: oldValues.ticketSection.uuid,
        pfxNumber: oldValues.ticketSection.pfxNumber,
        sfxNumber: oldValues.ticketSection.sfxNumber,
        status: newValues.ticketSection.status,
    };

    const messageSection = get(difference, 'messageSection');
    if (messageSection) {
        const {automaticResponse, ...messages} = messageSection;
        if (messages) {
            data.message = {
                uuid: v4(),
                message: get(messages, 'message'),
                sendCopyTo: get(messages, 'sendCopyTo'),
            };
        }
    }

    if (get(difference, 'attachmentSection.newAttachments', {default: false}) && difference.attachmentSection.newAttachments.length > 0) {
        data.newAttachments = [];
        difference.attachmentSection.newAttachments.forEach(attachments => {
            data.newAttachments.push({
                uuid: v4(),
                ...attachments,
            })
        })
    }
    return data;
};
