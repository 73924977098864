import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {FieldArray, formValueSelector} from 'redux-form';
import * as moment from 'moment';
import {
    ATTACHMENT_SECTION, FIELD_ATTACHMENT, FIELD_NEW_ATTACHMENT, TICKET_SECTION
} from '../../../../../../constants/formConstants';
import {downloadMarketingAttachment} from '../../../../../../actions/marketing/actionMarketingAttachment';
import {validTicketFiles} from '../../../../../common/validationCommons';
import {StyledDiv} from '../../../../../common/StyledComponents';
import UploadedAttachments from './UploadedAttachments';
import RenderDropZone from '../../../../../common/dropZone/RenderDropZone';
import formMessage from '../../../../../../intl/modules/formMessages';

const compare = (a, b) => {
    return moment(a.created).isAfter(b.created) ? 1 : -1;
};

const AttachmentSection = (props) => {
    const {attachments = {}, userRights, readOnly, form} = props;
    const dealerAttachments = Object.keys(attachments).map(item => attachments[item]).filter(item => item.isDealer).sort(compare);
    const editorAttachments = Object.keys(attachments).map(item => attachments[item]).filter(item => item.isEditor).sort(compare);

    return (
        <>
            {((userRights.canAddAttach && !readOnly) || (attachments && Object.keys(attachments).length > 0)) && <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessage.ATTACHMENT_SECTION}/>
                </h3>

                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {(dealerAttachments && dealerAttachments.length > 0) &&
                    <UploadedAttachments
                        attachments={dealerAttachments} label={formMessage.DEALER_ATTACHMENT} form={form}/>
                    }
                    {(editorAttachments && editorAttachments.length > 0) &&
                    <UploadedAttachments
                        attachments={editorAttachments} label={formMessage.EDITOR_ATTACHMENT} form={form}/>
                    }
                    {userRights.canAddAttach && !readOnly &&
                    <FieldArray
                        name={FIELD_NEW_ATTACHMENT}
                        component={RenderDropZone}
                        validate={validTicketFiles}/>
                    }
                </StyledDiv>
            </>
            }
        </>
    )
};

const mapStateToProps = (state, props) => ({
    ticketId: formValueSelector(props.form)(state, `${TICKET_SECTION}.uuid`),
    attachments: formValueSelector(props.form)(state, `${ATTACHMENT_SECTION}.${FIELD_ATTACHMENT}`),
});

export default connect(mapStateToProps, {
    downloadMarketingAttachment,
})(AttachmentSection);
