import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';

export const COMMERCIAL_UPDATE_USER_LIST_PREFERENCE = 'COMMERCIAL_UPDATE_USER_LIST_PREFERENCE';
export const COMMERCIAL_UPDATE_USER_HISTORY_PREFERENCE = 'COMMERCIAL_UPDATE_USER_HISTORY_PREFERENCE';
export const COMMERCIAL_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE = 'COMMERCIAL_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE';
export const COMMERCIAL_FETCH_PREFERENCES = 'COMMERCIAL_FETCH_PREFERENCES';
export const COMMERCIAL_USER_NOTIFICATION_PREFERENCE_FETCH = 'COMMERCIAL_USER_NOTIFICATION_PREFERENCE_FETCH';
export const COMMERCIAL_USER_NOTIFICATION_PREFERENCE_UPDATE = 'COMMERCIAL_USER_NOTIFICATION_PREFERENCE_UPDATE';

export const fetchCommercialPreferences = () => dispatch => {
    return dispatch({
        type: COMMERCIAL_FETCH_PREFERENCES,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_PREFERENCES_GET,
    });
};

export const updateCommercialUserListPreference = (preferences) => dispatch => {
    return dispatch({
        type: COMMERCIAL_UPDATE_USER_LIST_PREFERENCE,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_USER_LIST_PREFERENCE_SET,
        payload: {preferences: {hiddenColumns: preferences}}
    });
};

export const updateCommercialUserHistoryPreference = (preferences) => dispatch => {
    return dispatch({
        type: COMMERCIAL_UPDATE_USER_HISTORY_PREFERENCE,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_USER_HISTORY_PREFERENCE_SET,
        payload: {preferences: {hiddenColumns: preferences}}
    });
};

export const updateCommercialUserFullTextSearchPreference = (preferences) => dispatch => {
    return dispatch({
        type: COMMERCIAL_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_USER_FULL_TEXT_SEARCH_PREFERENCE_SET,
        payload: {preferences: {hiddenColumns: preferences}}
    });
};


export const fetchCommercialUserNotificationPreferences = () => dispatch => {
    return dispatch({
        type: COMMERCIAL_USER_NOTIFICATION_PREFERENCE_FETCH,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_USER_NOTIFICATION_PREFERENCE_GET,
    });
};

export const updateCommercialUserNotificationPreference = (preferences) => dispatch => {
    return dispatch({
        type: COMMERCIAL_USER_NOTIFICATION_PREFERENCE_UPDATE,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_USER_NOTIFICATION_PREFERENCE_SET,
        payload: {preferences}
    });
};
