import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import SideMenu from './SideMenu';

class SideBar extends Component {
    render() {
        const {store} = this.props;

        return (
            <Switch>
                <Route path="/administration" render={() => (<SideMenu store={store} administration/>)}/>
                <Route path="/distribution" render={() => (<SideMenu store={store} distribution/>)}/>
                <Route path="/commercial" render={() => (<SideMenu store={store} commercial/>)}/>
                <Route path="/marketing" render={() => (<SideMenu store={store} marketing/>)}/>
            </Switch>
        );
    }
}

SideBar.propTypes = {
    store: PropTypes.any,
};

export default (SideBar);
