import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const DISTRIBUTION_UPDATE_USER_LIST_PREFERENCE = 'DISTRIBUTION_UPDATE_USER_LIST_PREFERENCE';
export const DISTRIBUTION_UPDATE_USER_HISTORY_PREFERENCE = 'DISTRIBUTION_UPDATE_USER_HISTORY_PREFERENCE';
export const DISTRIBUTION_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE = 'DISTRIBUTION_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE';
export const DISTRIBUTION_FETCH_PREFERENCES = 'DISTRIBUTION_FETCH_PREFERENCES';
export const DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_FETCH = 'DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_FETCH';
export const DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_UPDATE = 'DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_UPDATE';

export const fetchDistributionPreferences = () => dispatch => {
    return dispatch({
        type: DISTRIBUTION_FETCH_PREFERENCES,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_PREFERENCES_GET,
    });
};

export const updateDistributionUserListPreference = (preferences) => dispatch => {
    return dispatch({
        type: DISTRIBUTION_UPDATE_USER_LIST_PREFERENCE,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_USER_LIST_PREFERENCE_SET,
        payload: {preferences: {hiddenColumns: preferences}}
    });
};

export const updateDistributionUserHistoryPreference = (preferences) => dispatch => {
    return dispatch({
        type: DISTRIBUTION_UPDATE_USER_HISTORY_PREFERENCE,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_USER_HISTORY_PREFERENCE_SET,
        payload: {preferences: {hiddenColumns: preferences}}
    });
};

export const updateDistributionUserFullTextSearchPreference = (preferences) => dispatch => {
    return dispatch({
        type: DISTRIBUTION_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_USER_FULL_TEXT_SEARCH_PREFERENCE_SET,
        payload: {preferences: {hiddenColumns: preferences}}
    });
};

export const fetchDistributionUserNotificationPreferences = () => dispatch => {
    return dispatch({
        type: DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_FETCH,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_GET,
    });
};

export const updateDistributionUserNotificationPreference = (preferences) => dispatch => {
    return dispatch({
        type: DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_UPDATE,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_SET,
        payload: {preferences}
    });
};
