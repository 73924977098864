import React from 'react'
import {reduxForm} from 'redux-form'
import styled from 'styled-components';
import {PrimaryButton} from '../../../../common/Button';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import buttonMessages from '../../../../../intl/common/buttonMessages';

const Button = styled(PrimaryButton)`
  margin-left: 10px;
`;

const RecordButtonForm = props => {
    const {handleSubmit, pristine, submitting, invalid} = props;
    return (
        <form onSubmit={handleSubmit}>
            {
                (!pristine && !submitting && !invalid) &&
                <Button type="submit" className="btn btn-sm">
                    <FormattedMessage {...buttonMessages.SAVE}/>
                </Button>
            }
        </form>
    )
};

RecordButtonForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({})(RecordButtonForm)
