import 'react-table/react-table.css';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {debounce} from 'throttle-debounce';
import * as moment from 'moment';
import get from 'get-value';
import {IconEdit, PrimaryIcon} from '../../common/Button';
import {fetchCommercialList} from '../../../actions/commercial/actionCommercialList';
import {editCommercialListButtonVisibility} from '../../../constants/commercialTicketRights';
import {moduleRoles} from '../../../utils/roles';
import Table from '../../common/table/TicketTable';
import formMessages from '../../../intl/modules/formMessages';
import {countryLanguageMapping, formNameFromSubjectKey} from '../../../constants/Utils';

class ListCommercialTable extends Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(props.location.search);
        const loadedState = this.loadStateFromSessionStorage({
            listDefaultPageSizeCC: 10,
            listDefaultSortCC: [{id: 'TICKET_NUMBER', desc: true}],
            listDefaultFiltersCC: [],
        });

        if (urlParams.has('status') && urlParams.has('group')) {
            this.state = {
                ...loadedState,
                listDefaultFiltersCC: [
                    {
                        id: 'STATUS',
                        value: urlParams.get('status') ? urlParams.get('status') : '',
                    },
                    {
                        id: 'GROUP',
                        value: urlParams.get('group') === 'ALL' ? '' : urlParams.has('group') ? urlParams.get('group') : ''
                    }
                ]
            }
        } else if (urlParams.has('status') && !urlParams.has('group')) {
            this.state = {
                ...loadedState,
                listDefaultFiltersCC: [
                    {
                        id: 'STATUS',
                        value: urlParams.get('status') ? urlParams.get('status') : '',
                    }
                ]
            }
        } else if (!urlParams.has('status') && urlParams.has('group')) {
            this.state = {
                ...loadedState,
                listDefaultFiltersCC: [
                    {
                        id: 'GROUP',
                        value: urlParams.get('group') === 'ALL' ? '' : urlParams.has('group') ? urlParams.get('group') : ''
                    }
                ]
            }
        } else {
            this.state = {
                ...loadedState,
                listDefaultFiltersCC: loadedState.listDefaultFiltersCC.filter(item => item.id !== 'GROUP')
            }
        }
        this.filtering = false;
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleFetchDataDebounced = debounce(1000, this.handleFetchData);
    }

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^list.*CC/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage = () => {
        for (let key in this.state) {
            if (this.state.hasOwnProperty(key) && key.match(/^list.*CC/)) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = state => {
        this.props.fetchCommercialList(
            state.pageSize,
            state.sorted,
            state.cursor,
            state.isNextFromPivot,
            this.normalizeFiltered(state));
        this.filtering = false;
        this.setState({
            listDefaultPageSizeCC: state.pageSize,
            listDefaultSortCC: state.sorted,
            listDefaultFiltersCC: state.filtered
        });
    };

    fetchStrategy = state => {
        this.filtering ? this.handleFetchDataDebounced(state) : this.handleFetchData(state);
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date || filterItem.id === 'CREATION_DATE') {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const {columns, isLoading, data, pages, isNextPage, isNextFromPivot, handleEditClick, userDetail, subjects} = this.props;
        const language = get(userDetail, 'settings.language', 'DEFAULT')
        const translatedData = data.map(oldData => ({
            ...oldData,
            subject: (subjects !== undefined && subjects !== null) ?
                countryLanguageMapping[oldData.group] === language ?
                    [get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, []).find(subject => subject.uuid === oldData.subject), `languages.local`, oldData.subject) === null ?
                        get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                            .find(subject => subject.uuid === oldData.subject), `languages.default`, oldData.subject)
                        : get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                            .find(subject => subject.uuid === oldData.subject), `languages.local`, oldData.subject)]
                    : get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                        .find(subject => subject.uuid === oldData.subject), `languages.default`, oldData.subject)
                : oldData.subject,
        }));

        return (
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'TICKET_NUMBER',
                                    accessor: 'ticketNumber',
                                    message: formMessages.TICKET_NUMBER,
                                    Cell: row => <Link to={`view/${row.original.id}`}>{row.value}</Link>
                                },
                                ...columns]}
                            toolButtons={[
                                {
                                    handleClick: handleEditClick,
                                    component: <PrimaryIcon className="btn btn-sm"
                                                            title="Edit"
                                                            type="button"
                                                            key="editButton">
                                        <IconEdit/>
                                    </PrimaryIcon>
                                }
                            ]}
                            isLoading={isLoading}
                            data={translatedData}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.filtering = true;
                            }}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={this.state.listDefaultFiltersCC}
                            defaultSorting={this.state.listDefaultSortCC}
                            defaultPageSize={this.state.listDefaultPageSizeCC}
                            buttonVisibility={editCommercialListButtonVisibility[moduleRoles.isDealerCommercial(userDetail.roles) ? 'DEALER' : 'EDITOR']}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ListCommercialTable.propTypes = {
    columns: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number.isRequired,
    fetchCommercialList: PropTypes.func.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    handleEditClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.commercialList.isTableLoading,
    data: state.commercialList.listData,
    pages: state.commercialList.pages,
    isNextPage: state.commercialList.isNextPage,
    isNextFromPivot: state.commercialList.isNextFromPivot,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
    userDetail: state.profile.userDetail,
});

export default connect(mapStateToProps, {
    fetchCommercialList
})(ListCommercialTable)
