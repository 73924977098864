import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'fullTextSearch.title',
        defaultMessage: 'Fulltext Search'
    },
    GENERAL_SECTION: {
        id: 'fullTextSearch.header.generalSection',
        defaultMessage: 'General information'
    },
    TICKET_SECTION: {
        id: 'fullTextSearch.header.ticketSection',
        defaultMessage: 'Ticket information'
    },
});
export default messages;
