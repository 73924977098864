import {defineMessages} from 'react-intl';

const messages = defineMessages({
    PURCHASE: {
        id: 'typeOfPurchase.purchase',
        defaultMessage: 'Purchase'
    },
    RENT: {
        id: 'typeOfPurchase.rent',
        defaultMessage: 'Rent'
    },
});

export default messages;
