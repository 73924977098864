import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getFormInitialValues, reduxForm} from 'redux-form'
import * as PropTypes from 'prop-types';
import get from 'get-value';
import {throttle} from 'throttle-debounce';
import {
    BUTTON_LABEL_CLOSE,
    BUTTON_LABEL_FORWARD_TO_DEALER,
    BUTTON_LABEL_FORWARD_TO_NPDC,
    BUTTON_LABEL_REOPEN,
    BUTTON_LABEL_SAVE, BUTTON_LABEL_WORK_IN_PROGRESS,
    COMMERCIAL_TICKET_STATUS_CLOSED,
    COMMERCIAL_TICKET_STATUS_IN_PROGRESS_DEALER,
    COMMERCIAL_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    COMMERCIAL_TICKET_STATUS_REOPEN, COMMERCIAL_TICKET_STATUS_WORK_IN_PROGRESS,
    FORM_BUTTON_CLOSE,
    FORM_BUTTON_FORWARD_TO_DEALER,
    FORM_BUTTON_FORWARD_TO_NPDC,
    FORM_BUTTON_REOPEN,
    FORM_BUTTON_SAVE, FORM_BUTTON_WORK_IN_PROGRESS,
} from '../../../../../constants/formConstants';
import {RequiredSpan} from '../../../../common/StyledComponents';
import {PrimaryButton} from '../../../../common/Button';
import ValidFormattedMessage from '../../../../common/ValidFormattedMessage';
import buttonMessages from '../../../../../intl/common/buttonMessages';
import ReopenMessageModal from '../../../../common/ReopenMessageModal';
import formMessages from '../../../../../intl/modules/formMessages';

class SubmitButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenReopenMessageModal: false
        }
    }

    showModal = () =>
        this.setState({
            isOpenReopenMessageModal: true,
        });
    closeModal = () =>
        this.setState({
            isOpenReopenMessageModal: false
        });

    saveModal = (message) => {
        const {handleSubmit, initialValues} = this.props;
        this.setState({
            isOpenReopenMessageModal: false
        });
        handleSubmit(values =>
            this.props.onSubmit({
                newValues: {
                    ...values,
                    messageSection: message,
                    ticketSection: {...values.ticketSection, status: COMMERCIAL_TICKET_STATUS_REOPEN}
                },
                oldValues: initialValues,
                submitBy: FORM_BUTTON_REOPEN,
            }))()
    };

    renderBackButton = () => {
        return (
            <PrimaryButton onClick={(e) => {
                e.preventDefault();
                this.props.history.goBack();
            }} className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.BACK}/>
            </PrimaryButton>
        )
    };

    renderButton = (button, message, changeTo) => {
        const {handleSubmit, submitting, initialValues, dirty, onSubmit} = this.props;
        return (
            <PrimaryButton
                onClick={throttle(1000, async (e) => {
                    e.preventDefault();
                    handleSubmit(values =>
                        onSubmit({
                            newValues: {...values, ticketSection: {...values.ticketSection, status: changeTo}},
                            oldValues: initialValues,
                            submitBy: button,
                        }))()
                })}
                className="btn mr-2 float-right"
                disabled={submitting || (!dirty && button === FORM_BUTTON_SAVE)}>
                <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
            </PrimaryButton>
        )
    };

    renderReopenButton = (button, message) => {
        const {submitting, userRights, dirty} = this.props;
        return (
            <PrimaryButton
                onClick={throttle(1000, async (e) => {
                    e.preventDefault();
                    this.showModal()
                })}
                className="btn mr-2 float-right"
                disabled={submitting || (userRights.canReopen ? false : !dirty)}>
                <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
            </PrimaryButton>
        )
    };

    render() {
        const {initialValues, readOnly, userRights} = this.props;

        if (readOnly) {
            return (
                <>
                    <div className="d-flex justify-content-end mt-3">
                        <form className="form-group">
                            {this.renderBackButton()}
                            {userRights.canReopen && initialValues.ticketSection.status === COMMERCIAL_TICKET_STATUS_CLOSED &&
                            this.renderReopenButton(FORM_BUTTON_REOPEN, BUTTON_LABEL_REOPEN, COMMERCIAL_TICKET_STATUS_REOPEN)}
                            {userRights.canSkipModalReopen && initialValues.ticketSection.status === COMMERCIAL_TICKET_STATUS_CLOSED &&
                            this.renderButton(FORM_BUTTON_REOPEN, BUTTON_LABEL_REOPEN, COMMERCIAL_TICKET_STATUS_REOPEN)}
                        </form>
                    </div>
                    <ReopenMessageModal show={this.state.isOpenReopenMessageModal}
                                        closeModal={this.closeModal}
                                        saveModal={this.saveModal}/>
                </>
            )
        }

        return (
            <div className="d-flex justify-content-between mt-3">
                <div><RequiredSpan>{'*\u00A0'}</RequiredSpan><FormattedMessage {...formMessages.IS_MANDATORY}/></div>
                <form className="form-group">
                    {get(initialValues, 'ticketSection.uuid') && this.renderBackButton()}
                    {userRights.canClose &&
                    this.renderButton(FORM_BUTTON_CLOSE, BUTTON_LABEL_CLOSE, COMMERCIAL_TICKET_STATUS_CLOSED)}
                    {userRights.canSave &&
                    this.renderButton(FORM_BUTTON_SAVE, BUTTON_LABEL_SAVE, get(initialValues, 'ticketSection.status'))}
                    {userRights.canForwardToNPDC &&
                    this.renderButton(FORM_BUTTON_FORWARD_TO_NPDC, BUTTON_LABEL_FORWARD_TO_NPDC, COMMERCIAL_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY)}
                    {userRights.canForwardToDealer &&
                    this.renderButton(FORM_BUTTON_FORWARD_TO_DEALER, BUTTON_LABEL_FORWARD_TO_DEALER, COMMERCIAL_TICKET_STATUS_IN_PROGRESS_DEALER)}
                    {userRights.canWorkInProgress &&
                    this.renderButton(FORM_BUTTON_WORK_IN_PROGRESS, BUTTON_LABEL_WORK_IN_PROGRESS, COMMERCIAL_TICKET_STATUS_WORK_IN_PROGRESS)}
                </form>
            </div>
        )
    }
}

SubmitButtons.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    readOnly: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
    userRights: PropTypes.object.isRequired,
};

const mapStateToProps = (state, initialProps) => ({
    initialValues: getFormInitialValues(initialProps.form)(state),
});

export default reduxForm({})(withRouter(connect(mapStateToProps,
    {})(SubmitButtons)));
