import styled from 'styled-components';
import Picky from 'react-picky';

export const RequiredSpan = styled.span`
  &&{color: red};
`;

export const StyledDiv = styled.div`
  border-radius: 5px;
`;

export const StyledPicky = styled(Picky)`
    button:first-child{
        border-radius: 0.3em;
    };
`;

export const StyledA = styled.a`
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  :hover&&{
        color: #ffcc33;
    };
`;

export const StyledHeaderDiv = styled.div`
    &&{background-color: #e9ecef;};
`;

export const StyledInfoDiv = styled.div`
    &&{background-color: #f8f9fa;};
`;

export const StyledDealerInput = styled.div`
    background-color: #e9ecef;
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem;
    width: 100%;
    border: 1px solid #ced4da;
}
`;

export const Table = styled.table`
  width: 100%;
`;

export const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

export const Label = styled.label`
    font-weight: bold;
`;

export const Wrapper = styled.div`
  padding-bottom: 30px;
`;

export const WrapperHome = styled.div`
  background-color: #f9f9f9;
  padding: 10px;
  margin: 10px;
`;

export const MessageCell = styled.td`
    white-space: pre-wrap;
`;

export const StyledInput = styled.input`
    width: 100%;
    &:focus {
    outline: none;
      border:1px solid #ffcc33;
      -webkit-box-shadow: none;
    box-shadow: none;
    }
`;

export const StyledSmall = styled.input`
    color: black;
    font-size: 0.875rem;
`;
export const StyledTextArea = styled.textarea`
    color: black;
    font-size: 0.875rem;
`;

export const Panel = styled.h1`
  font-size: 1.5em;
  text-align: center;
`;

export const StyledSpan = styled.span`
  &&{color: red};
`;
