import {
    ADMIN_PRESET_RESPONSE_SELECT_GROUP,
    ADMIN_PRESET_RESPONSE_FETCH,
    ADMIN_PRESET_RESPONSE_ADD,
    ADMIN_PRESET_RESPONSE_EDIT,
    ADMIN_PRESET_RESPONSE_UPDATE,
    ADMIN_PRESET_RESPONSE_SWITCH,
    ADMIN_PRESET_RESPONSE_REMOVE,
} from '../../actions/admin/actionAdminPresetResponse';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {move} from '../../utils/utils';
import get from 'get-value';

export const adminPresetResponse = (state = {
    isLoading: false,
    presetResponses: [],
    edit: -1,
    selectedCountryGroup: '',
}, action) => {
    switch (action.type) {
        case ADMIN_PRESET_RESPONSE_SELECT_GROUP:
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        case ADMIN_PRESET_RESPONSE_FETCH:
            return Object.assign({}, state, {isLoading: true, presetResponses: []});
        case ADMIN_PRESET_RESPONSE_ADD:
            return Object.assign({}, state, {isLoading: true});
        case ADMIN_PRESET_RESPONSE_EDIT:
            return Object.assign({}, state, {edit: action.payload.index});
        case ADMIN_PRESET_RESPONSE_UPDATE:
            return Object.assign({}, state, {isLoading: true, edit: -1});
        case ADMIN_PRESET_RESPONSE_SWITCH: {
            let newArray = state.presetResponses.slice();
            move(newArray, action.payload.oldIndex, action.payload.newIndex);
            return Object.assign({}, state, {presetResponses: newArray});
        }
        case ADMIN_PRESET_RESPONSE_REMOVE:
            return Object.assign({}, state, {isLoading: true});

        case distributionWebsocketCommands.DISTRIBUTION_PRESET_RESPONSES_SEND:
        case commercialWebsocketCommands.COMMERCIAL_PRESET_RESPONSES_SEND:
        case marketingWebsocketCommands.MARKETING_PRESET_RESPONSES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                presetResponses: get(action, 'payload.presetResponses', {default: []}),
                correlationId
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_PRESET_RESPONSES_SEND_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_PRESET_RESPONSES_SEND_ERROR:
        case marketingWebsocketCommands.MARKETING_PRESET_RESPONSES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, presetResponses: [], correlationId});
        }
        case distributionWebsocketCommands.DISTRIBUTION_PRESET_RESPONSES_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_PRESET_RESPONSES_ERROR:
        case marketingWebsocketCommands.MARKETING_PRESET_RESPONSES_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId});
        }
        default:
            return state;
    }
};
