import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {domains, REQUEST_PARAM_DELIM} from '../../constants/Utils';

export const DISTRIBUTION_BIRS_UPDATE_PENDING = 'DISTRIBUTION_BIRS_UPDATE_PENDING';
export const CLIENT_IDS_CREATE_PENDING = 'CLIENT_IDS_CREATE_PENDING';
export const CLIENT_IDS_DELETE_PENDING = 'CLIENT_IDS_DELETE_PENDING';
export const CLIENT_IDS_UPDATE_RECORD = 'CLIENT_IDS_UPDATE_RECORD';
export const DISTRIBUTION_BIRS_EDIT_RECORD = 'DISTRIBUTION_BIRS_EDIT_RECORD';
export const CLIENT_IDS_DELETE_RECORD = 'CLIENT_IDS_DELETE_RECORD';
export const DISTRIBUTION_BIRS_FETCH_PENDING = 'DISTRIBUTION_BIRS_FETCH_PENDING';
export const DISTRIBUTION_BIRS_LIST_SEND = 'DISTRIBUTION_BIRS_LIST_SEND';
export const ADMIN_CLIENT_IDS_ERROR = 'ADMIN_CLIENT_IDS_ERROR';
export const DISTRIBUTION_BIRS_SET_TEXT_SEARCH = 'DISTRIBUTION_BIRS_SET_TEXT_SEARCH';
export const DISTRIBUTION_BIRS_SET_SORTING = 'DISTRIBUTION_BIRS_SET_SORTING';
export const DISTRIBUTION_BIRS_SET_LINES_PER_PAGE = 'DISTRIBUTION_BIRS_SET_LINES_PER_PAGE';
export const DISTRIBUTION_BIR_SELECT_GROUP = 'DISTRIBUTION_BIR_SELECT_GROUP';
export const DISTRIBUTION_BUSINESS_UNITS_SEND = 'DISTRIBUTION_BUSINESS_UNITS_SEND';


export const DISTRIBUTION_BIRS_SEND = 'DISTRIBUTION_BIRS_SEND';

export const DISTRIBUTION_BIR_COUNTRY_BUSINESS_UNITS = 'DISTRIBUTION_BIR_COUNTRY_BUSINESS_UNITS';

export const fetchCountryBU = (groups) => dispatch => {
    dispatch({
        type: DISTRIBUTION_BIR_COUNTRY_BUSINESS_UNITS,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_SHORT_LIST,
        payload: {groups}
    })
};


export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: DISTRIBUTION_BIR_SELECT_GROUP,
        payload: countryGroup,
    });

export function fetchSettingBUAssignmentData(group, page, perPage) {
    return (dispatch, getState) => {

        const {adminBUAssignment} = getState();
        const {column, order} = adminBUAssignment.sorting;
        const {fulltext} = adminBUAssignment;
        const orderParam = column && order ? `${column}${REQUEST_PARAM_DELIM}${order}` : undefined;

        return dispatch({
            type: DISTRIBUTION_BIRS_FETCH_PENDING,
            distributionWebsocket: true,
            command: distributionWebsocketCommands.DISTRIBUTION_BIRS_FETCH,
            payload: {
                group,
                page,
                perPage,
                fulltext: fulltext ? fulltext : undefined,
                order: orderParam,
            },
        });
    }
}

export function setSorting(column) {
    return (dispatch) => {
        dispatch({type: DISTRIBUTION_BIRS_SET_SORTING, payload: column});
    }
}

export function setTextSearch(fulltext) {
    return (dispatch) => {
        dispatch({type: DISTRIBUTION_BIRS_SET_TEXT_SEARCH, payload: fulltext});
    }
}

export function setLinesPerPage(linesPerPage) {
    return (dispatch) => {
        dispatch({type: DISTRIBUTION_BIRS_SET_LINES_PER_PAGE, payload: linesPerPage});
    }
}

export function editBURecordsState(index) {
    return (dispatch) => {
        dispatch({type: DISTRIBUTION_BIRS_EDIT_RECORD, payload: {index}});
    }
}

export function addBIRtoBU({id, businessUnit}) {
    return (dispatch) => {
        return dispatch({
            type: DISTRIBUTION_BIRS_UPDATE_PENDING,
            distributionWebsocket: true,
            command: distributionWebsocketCommands.DISTRIBUTION_BIR_UPDATE_BU,
            payload: {
                payload: {
                    id,
                    businessUnit,
                }
            },
        });
    }
}
