import {defineMessages} from 'react-intl';

const messages = defineMessages({
    OFFER_FOR_EMPLOYEES: {
        id: 'typeOfOffer.offerForEmployees',
        defaultMessage: 'Offer for employees'
    },
});

export default messages;
