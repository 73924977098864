import {HIGH, LOW, MAJOR, MEDIUM} from '../../constants/levelConstants';
import defineMessages from 'react-intl/src/define-messages';

export const messages = defineMessages({
    [LOW]: {
        id: 'form.level.low',
        defaultMessage: 'Low'
    },
    [MEDIUM]: {
        id: 'form.level.medium',
        defaultMessage: 'Medium'
    },
    [HIGH]: {
        id: 'form.level.high',
        defaultMessage: 'High'
    },
    [MAJOR]: {
        id: 'form.level.major',
        defaultMessage: 'Major'
    },
});

export default messages;
