import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import {COMMERCIAL_FORM, FORM_BUTTON_TAKE_BACK, OFFER_FOR_EMPLOYEES_FORM} from '../../../constants/formConstants';
import {
    fetchCommercialTicket, resetCommercialTicket, updateCommercialTicket
} from '../../../actions/commercial/actionCommercialTicket';
import {
    lockCommercialTicket, resetRedirectAfterLockCommercialTicket, unlockCommercialTicket
} from '../../../actions/commercial/actionCommercialLockTicket';
import {initLoadedCommercialTicket, transformUpdatedCommercialTicketForSave} from '../Utils';
import {formatTicketNumber} from '../../../utils/utils';
import {clearAlerts} from '../../../actions/alertsActions';
import {editRights} from '../../../constants/commercialTicketRights';
import {createReqFields, editReqFields} from '../../../constants/commercialRequiredFields';
import {moduleRoles} from '../../../utils/roles';
import CommercialTicketMasterForm from './forms/CommercialTicketMasterForm';
import UploadingCommercialAttachment from './UploadingCommercialAttachment';
import checkRoles from '../../common/roleChecker/RoleChecker';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import formMessages from '../../../intl/modules/formMessages';
import OfferForEmployeesForm from './forms/OfferForEmployeesForm';

class EditCommercialTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentWillUnmount() {
        const {uuid, redirect} = this.props.lockTicket;
        if (!redirect) {
            this.props.unlockCommercialTicket(uuid);
        }
        this.props.resetRedirectAfterLockCommercialTicket();
    }

    componentDidMount() {
        this.props.resetCommercialTicket();
        this.props.lockCommercialTicket(this.props.match.params.ticketUuid);
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchCommercialTicket(this.props.match.params.ticketUuid);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.ticketSubmittedAndShouldRedirect && !this.state.ticketSubmittedAndShouldRedirect
            && prevProps.ticketData.isTicketCreating && !this.props.ticketData.isTicketCreating
            && this.props.ticketData.isTicketLoading) {
            this.props.lockCommercialTicket(this.props.match.params.ticketUuid);
        }
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const dataForSend = transformUpdatedCommercialTicketForSave(values);
        this.props.updateCommercialTicket(dataForSend);
        if (!([FORM_BUTTON_TAKE_BACK].includes(submitBy))) {
            this.setState({ticketSubmittedAndShouldRedirect: true});
        }
    };

    render() {
        const {ticketData, attachmentData, lockTicket, loggedUser, isLoadingSubjects} = this.props;
        const {ticketSubmittedAndShouldRedirect} = this.state;
        const initializationValue = initLoadedCommercialTicket(ticketData);

        if (lockTicket.redirect) {
            return <Redirect to={`/commercial/view/${lockTicket.uuid}`}/>;
        }

        let type = false;
        if (get(ticketData, 'ticket.type', {default: false})) {
            type = ticketData.ticket.type;
        }

        if (Array.isArray(attachmentData.attachmentsForUpload) && (attachmentData.attachmentsForUpload.length > 0)) {
            return <UploadingCommercialAttachment attachments={attachmentData.attachmentsForUpload}
                                                  attachmentsUrl={ticketData.newAttachments}
                                                  correlationId={ticketData.correlationId}
                                                  isAttachmentUploading={attachmentData.isTicketAttachmentUploading}
                                                  formType={type}/>
        }

        if (ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading &&
            get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/commercial/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !type
            || lockTicket.isTicketLocking
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        const requiredFields = editReqFields[moduleRoles.isEditorCommercial(loggedUser) ? 'EDITOR' : 'DEALER'][type];

        return (
            <div className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={type} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {type === COMMERCIAL_FORM &&
                    <CommercialTicketMasterForm form={type}
                                                initialValues={initializationValue}
                                                userRights={editRights[moduleRoles.isEditorCommercial(loggedUser) ?
                                                    'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                enableReinitialize={true}
                                                keepDirtyOnReinitialize={true}
                                                handleSubmit={this.handleSubmit}
                                                reqFields={createReqFields[type]}/>
                    }
                    {type === OFFER_FOR_EMPLOYEES_FORM &&
                    <OfferForEmployeesForm form={type}
                                                initialValues={initializationValue}
                                                userRights={editRights[moduleRoles.isEditorCommercial(loggedUser) ?
                                                    'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                enableReinitialize={true}
                                                keepDirtyOnReinitialize={true}
                                                handleSubmit={this.handleSubmit}
                                                reqFields={createReqFields[type]}/>
                    }
                </div>
            </div>
        );
    }
}

EditCommercialTicketPage.propTypes = {
    resetRedirectAfterLockCommercialTicket: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    resetCommercialTicket: PropTypes.func.isRequired,
    fetchCommercialTicket: PropTypes.func.isRequired,
    lockCommercialTicket: PropTypes.func.isRequired,
    updateCommercialTicket: PropTypes.func.isRequired,
    unlockCommercialTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.commercialTicket,
    lockTicket: state.commercialLockTicketCheck,
    attachmentData: state.commercialAttachment,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchCommercialTicket,
    updateCommercialTicket,
    resetCommercialTicket,
    lockCommercialTicket,
    resetRedirectAfterLockCommercialTicket,
    unlockCommercialTicket,
})(injectIntl(EditCommercialTicketPage))), ['CC_DEALER', 'CC_EDITOR']);
