import React from 'react';
import Dropzone from 'react-dropzone';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import fileSelectMessages from '../../../intl/common/fileSelectMessages';
import {PRIMARY_YELLOW, PRIMARY_YELLOW_CLEAR_SHADE} from '../../../theme/colors';
import FileIconWithName from './FileIconWithName';

const DropZoneField = styled.div`
  text-align: center;
  border: dashed ${PRIMARY_YELLOW};
  border-radius: 0.3em;
  cursor: pointer;
  :hover {
    background-color: ${PRIMARY_YELLOW_CLEAR_SHADE}20;
  }
`;

const DropMessageSpan = styled.span`
    display: block;
    height: ${props => props.needsFixedHeight ? 'calc(1.5em + .375rem + 2px)' : 'auto'};
`;

const FileSelectionField = props => {
    const {
        intl: {formatMessage},
        input: {value, onChange}, handleAttachmentSelection, showIcon = false, showName = false, needsFixedHeight = false
    } = props;

    return (
        <>
            <Dropzone onDrop={files => {
                const file = files[0];
                if (onChange) {
                    onChange({
                        name: file.name,
                        size: file.size
                    });
                }
                if (handleAttachmentSelection) {
                    handleAttachmentSelection(file);
                }
            }}>
                {({getRootProps, getInputProps, isDragActive}) => {
                    return (
                        <DropZoneField {...getRootProps()}
                                       className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}>
                            <input {...getInputProps()}/>
                            {
                                value ?
                                    <FileIconWithName file={value} showIcon={showIcon} showName={showName}/> :
                                    isDragActive ?
                                        <DropMessageSpan needsFixedHeight={needsFixedHeight}>
                                            {formatMessage(fileSelectMessages.FILE_DROP)}
                                        </DropMessageSpan> :
                                        <DropMessageSpan needsFixedHeight={needsFixedHeight}>
                                            {formatMessage(fileSelectMessages.FILE_SELECT)}
                                        </DropMessageSpan>
                            }
                        </DropZoneField>
                    )
                }}
            </Dropzone>
        </>)
};

FileSelectionField.propTypes = {
    input: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    handleAttachmentSelection: PropTypes.func,
    showIcon: PropTypes.bool,
    showName: PropTypes.bool,
    needsFixedHeight: PropTypes.bool
};

export default injectIntl(FileSelectionField);
