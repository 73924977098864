import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {extractErrorText} from '../actionUtils';
import {errorAlert} from '../alertsActions';
import {uploadDistributionAttachment} from '../../api/distribution/restDistributionAttachment';
import alertMessages from '../../intl/common/alertMessages';

export const DISTRIBUTION_ATTACHMENTS_UPLOADING_ERROR = 'DISTRIBUTION_ATTACHMENTS_UPLOADING_ERROR';
export const DISTRIBUTION_ATTACHMENTS_UPLOADING = 'DISTRIBUTION_ATTACHMENTS_UPLOADING';

export const startUploadingDistributionAttachments = () => dispatch => {
    return dispatch({type: DISTRIBUTION_ATTACHMENTS_UPLOADING});
};

export const downloadDistributionAttachment = (uuid, ticketId, name) => dispatch => {
    return dispatch({
        type: distributionWebsocketCommands.DISTRIBUTION_DOWNLOAD_PRESIGNED_URL,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_DOWNLOAD_PRESIGNED_URL,
        payload: {
            attachment: {
                uuid,
                ticketId,
                name
            }
        }
    });
};

export const uploadDistributionAttachments = (attachments, correlationId) => dispatch => {
    const promises = [];
    const savedAttachments = [];
    attachments.forEach(attachment => {
        promises.push(
            uploadDistributionAttachment(attachment.url, attachment.localUrl, attachment.name, attachment.contentType).then(
                () => {
                    window.URL.revokeObjectURL(attachment.localUrl);
                    savedAttachments.push(attachment);
                }, error => {
                    dispatch({type: DISTRIBUTION_ATTACHMENTS_UPLOADING_ERROR});
                    dispatch(errorAlert(...extractErrorText(error, alertMessages.FILE_UPLOAD_ERROR)));
                }
            )
        )
    });

    return Promise.all(promises).then(() => {
            if (savedAttachments.length > 0) {
                dispatch({
                    type: distributionWebsocketCommands.DISTRIBUTION_CREATE_ATTACHMENT,
                    distributionWebsocket: true,
                    command: distributionWebsocketCommands.DISTRIBUTION_CREATE_ATTACHMENT,
                    payload: {
                        attachments: savedAttachments,
                        correlationId,
                        uuid: savedAttachments[0].ticketId,
                    }
                })
            } else {
                dispatch({
                    type: DISTRIBUTION_ATTACHMENTS_UPLOADING_ERROR,
                })
            }
        }
    );
};
