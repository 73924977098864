import React from 'react';
import {FormattedDate} from 'react-intl';
import get from 'get-value';
import 'react-picky/dist/picky.css';
import * as moment from 'moment';
import tableMessages from '../../../intl/common/tableMessages';
import {
    brandValues,
    getFormStates, getPriority, languageCountryMapping
} from '../../../constants/Utils';
import DatePickerTableComponent from '../../common/datePicker/DatePickerTableComponent';
import commonMessages from '../../../intl/common/commonMessages';
import formStatesMessages from '../../../intl/modules/formStatesMessages';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import formMessages from '../../../intl/modules/formMessages';
import brandMessages from '../../../intl/modules/brandMessages';
import formTicketValuesMessages from '../../../intl/common/formTicketValuesMessages';

export const ColumnLibrary = (props, domain) => {
    const {intl, subjects, managedGroups, userDetail} = props;
    let subjectFilter = [];
    const language = get(userDetail, 'settings.language', 'DEFAULT')
    if (subjects) {
        subjectFilter = [].concat.apply([], Array.prototype.concat(...Object.values(subjects))
            .map(groupSubjects => Array.prototype.concat(...Object.values(
                Object.keys(groupSubjects)
                    .map((key) => groupSubjects[key]
                        .map(e => ({
                            id: get(e, `uuid`, ''),
                            translation: key === languageCountryMapping[language]
                                ? [get(e, `languages.local`, '') === null ?
                                        get(e, `languages.default`, '') :
                                        get(e, `languages.local`, '')]
                                : get(e, `languages.default`, '')
                        }))
                    ))
            )))
    }

    return [
        {
            id: 'CREATION_DATE',
            accessor: 'created',
            message: formMessages.CREATION_DATE,
            Filter: ({filter, onChange}) => {
                let value = filter && filter.value;
                if (typeof value === 'string') {
                    value = moment(value).toDate();
                }
                return <DatePickerTableComponent
                    value={value || undefined}
                    onChange={onChange}
                    filter={filter}/>;
            },
            Cell: e => <FormattedDate value={new Date(e.value)}/>,
        },
        {
            id: 'SUBJECT',
            accessor: 'subject',
            message: formMessages.SUBJECT,
            Filter: ({filter, onChange}) => (
                <select onChange={event => onChange(event.target.value)}
                        style={{width: '100%', border: '1px solid #999999'}}
                        value={filter ? filter.value : ''}>
                    <option value="">
                        {intl.formatMessage(commonMessages.ALL)}
                    </option>
                    {subjectFilter.length && subjectFilter.map((option, index) =>
                        <option key={option.id} value={option.id}>
                            {option.translation}
                        </option>)}
                </select>
            )
        },
        {
            id: 'BRAND',
            accessor: 'brand',
            message: formMessages.BRAND,
            Filter: ({filter, onChange}) => (
                <select onChange={event => onChange(event.target.value)}
                        style={{width: '100%', border: '1px solid #999999'}}
                        value={filter ? filter.value : ''}>
                    <option value="">
                        {intl.formatMessage(commonMessages.ALL)}
                    </option>
                    {brandValues.length && brandValues.map((option) =>
                        <option value={option}>
                            {intl.formatMessage(brandMessages[option])}
                        </option>)}
                </select>
            )
        },
        {
            id: 'DEALER_NAME',
            accessor: 'dealerName',
            message: formMessages.DEALER_NAME,
        },
        {
            id: 'CREATOR_NAME',
            accessor: 'creatorName',
            message: formMessages.CREATOR_NAME,
        },
        {
            id: 'PRIORITY',
            accessor: 'priority',
            message: formMessages.PRIORITY,
            Filter: ({filter, onChange}) => (
                <select onChange={event => onChange(event.target.value)}
                        style={{width: '100%', border: '1px solid #999999'}}
                        value={filter ? filter.value : ''}>
                    <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                    {getPriority.map((key, index) =>
                        <option key={index} value={key}>
                            {formTicketValuesMessages[key] ? intl.formatMessage(formTicketValuesMessages[key]) : key}
                        </option>)}
                </select>
            ),
            Cell: e =>
                <div>{formTicketValuesMessages[e.value] ? intl.formatMessage(formTicketValuesMessages[e.value]) : e.value}</div>,
        },
        {
            id: 'ASSIGNED_TO',
            accessor: 'lastEditor',
            message: formMessages.ASSIGNED_TO,
        },
        {
            id: 'STATUS',
            accessor: 'status',
            message: formMessages.STATUS,
            Filter: ({filter, onChange}) => (
                <select onChange={event => onChange(event.target.value)}
                        style={{width: '100%', border: '1px solid #999999'}}
                        value={filter ? filter.value : ''}>
                    <option value="">
                        {intl.formatMessage(commonMessages.ALL)}
                    </option>
                    {getFormStates(domain).map((key, index) =>
                        <option key={index} value={key}>
                            {formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                        </option>)
                    }
                </select>
            ),
            Cell: e =>
                <div>
                    {formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}
                </div>,
        },
        {
            id: 'GROUP',
            accessor: 'group',
            message: tableMessages.GROUP,
            Filter: ({filter, onChange}) => (
                <select onChange={event => onChange(event.target.value)}
                        style={{width: '100%', border: '1px solid #999999'}}
                        value={filter ? filter.value : ''}>
                    <option value="">
                        {intl.formatMessage(commonMessages.ALL)}
                    </option>
                    {managedGroups.length && managedGroups.map((key, index) =>
                        <option key={index} value={key}>
                            {countryGroupMessages[key] ? intl.formatMessage(countryGroupMessages[key]) : key}
                        </option>)
                    }
                </select>
            ),
            Cell: e =>
                <div>
                    {countryGroupMessages[e.value] ? intl.formatMessage(countryGroupMessages[e.value]) : e.value}
                </div>,
        },
        {
            id: 'VIN',
            accessor: 'vin',
            message: formMessages.VIN,
        },
    ]
};
