import {
    COMMERCIAL_FETCH_PREFERENCES,
    COMMERCIAL_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
    COMMERCIAL_UPDATE_USER_HISTORY_PREFERENCE,
    COMMERCIAL_UPDATE_USER_LIST_PREFERENCE,
    COMMERCIAL_USER_NOTIFICATION_PREFERENCE_FETCH,
    COMMERCIAL_USER_NOTIFICATION_PREFERENCE_UPDATE
} from '../../actions/commercial/actionCommercialPreferences';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import get from 'get-value';

export const commercialPreferences = (state = {
    isLoading: true,
    menuList: [],
    menuHistory: [],
    menuFullTextSearch: [],
    subjects: [],
    statuses: []
}, action) => {
    switch (action.type) {
        case COMMERCIAL_UPDATE_USER_LIST_PREFERENCE: {
            return Object.assign({}, state, {menuList: action.payload.preferences.hiddenColumns});
        }
        case COMMERCIAL_UPDATE_USER_HISTORY_PREFERENCE: {
            return Object.assign({}, state, {menuHistory: action.payload.preferences.hiddenColumns});
        }
        case COMMERCIAL_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE: {
            return Object.assign({}, state, {menuFullTextSearch: action.payload.preferences.hiddenColumns});
        }
        case commercialWebsocketCommands.COMMERCIAL_PREFERENCES_SEND: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                ...action.payload.preferences,
                isLoading: false,
                correlationId: action.payload.correlationId
            });
        }
        case commercialWebsocketCommands.COMMERCIAL_PREFERENCES_SEND_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case commercialWebsocketCommands.COMMERCIAL_PREFERENCES_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case COMMERCIAL_FETCH_PREFERENCES: {
            return Object.assign({}, state, {isLoading: true});
        }

        case COMMERCIAL_USER_NOTIFICATION_PREFERENCE_FETCH: {
            return Object.assign({}, state, {isLoading: true, subjects: [], statuses: [],});
        }
        case COMMERCIAL_USER_NOTIFICATION_PREFERENCE_UPDATE: {
            return Object.assign({}, state, {isLoading: true, subjects: [], statuses: [],})
        }
        case commercialWebsocketCommands.COMMERCIAL_NOTIFICATIONS_PREFERENCES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                subjects: get(action, 'payload.subjects', {default: []}),
                statuses: get(action, 'payload.statuses', {default: []}),
                correlationId
            })
        }
        case commercialWebsocketCommands.COMMERCIAL_NOTIFICATIONS_PREFERENCES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, subjects: [], statuses: [], correlationId});
        }
        default:
            return state;
    }
};
