import {
    BUSINESS_UNIT_FORM, FIELD_BU_COUNTRIES,
    FIELD_BU_NAME,
} from './formConstants';

export const editReqFields = {
    'DEFAULT': {
        [BUSINESS_UNIT_FORM]: [
            FIELD_BU_NAME,
            FIELD_BU_COUNTRIES,
        ],
    },
};

export const createReqFields = {
    'DEFAULT': {
        [BUSINESS_UNIT_FORM]: [
            FIELD_BU_NAME,
            FIELD_BU_COUNTRIES,
        ],
    }
};

