import React, {Component} from 'react';
import {Provider as AlertProvider} from 'react-alert';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    authenticateAndRedirect,
    getAuthenticatedUser,
    processAuthenticationRedirect,
    treatEmptyIdpToken
} from '../utils/auth';
import {fetchUserRole, saveUserRole} from '../actions/actionRole';
import {connectWebsockets} from '../actions/actionWebsocket';
import {axiosFileProgressInstance, initAxios} from '../api/rest';
import AppContent from './AppContent';
import WebsocketHeartbeat from './WebsocketHeartbeat';
import Alert from './common/alert/Alert';
import HotSwappingIntlProvider from '../intl/ConnectedIntlProvider';
import Loader from './Loader';
import GlobalStyle from '../theme/globalStyle';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import get from 'get-value';
import {LOGIN_PATH, LOGOUT_PATH} from "../routes/paths";

class App extends Component {
    componentDidMount() {
        if(process.env.REACT_APP_PROVIDER_SWITCH && (localStorage.getItem("provider") === null || localStorage.getItem("providerExpireAt") < new Date().valueOf())) {
            this.props.history.push(LOGIN_PATH)
        } else {
            if (App.redirectedFromIdp(this.props.history)) {
                this.processAuthenticationRedirectResult();
            } else {
                getAuthenticatedUser().then(authenticatedUser => {
                    if (!authenticatedUser || Object.keys(authenticatedUser).length === 0 || authenticatedUser.expired) {
                        authenticateAndRedirect();
                    } else if (!this.props.userDetail || Object.keys(this.props.userDetail).length === 0) {
                        this.processAuthenticatedUser(authenticatedUser);
                    }
                });
            }
        }
    }

    async componentDidUpdate() {
        if (this.props.userToBeCreated) {
            this.props.saveUserRole();
        }
    }

    static redirectedFromIdp({location}) {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code') || (urlParams.get('state') && urlParams.get('error'));
        return (code && code !== null);
    }

    processAuthenticationRedirectResult = () => {
        processAuthenticationRedirect().then(
            ({user, lastVisitedPath}) => {
                const uid = get(user, 'profile.login') || get(user, 'profile.uid');
                if (!uid) {
                    treatEmptyIdpToken();
                } else {
                    this.processAuthenticatedUser(user);
                    this.props.history.push(lastVisitedPath);
                }
            },
            (err) => this.props.history.push(LOGOUT_PATH, {err})
        );
    };

    processAuthenticatedUser = user => {
        initAxios(user);
        this.props.connectWebsockets(user.access_token);
        this.props.fetchUserRole(
            user.profile.login || user.profile.uid,
            `${user.profile.firstName || user.profile.firstname} ${user.profile.lastName || user.profile.lastname}`
        );
    };

    render() {
        const {isLoading} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        const {store} = this.props;
        return (
            <>
                <GlobalStyle/>
                {loadProgressBar({}, axiosFileProgressInstance)}
                <WebsocketHeartbeat/>
                <HotSwappingIntlProvider {...store}>
                    <AlertProvider template={Alert} position="top right">
                        <AppContent store={store}/>
                    </AlertProvider>
                </HotSwappingIntlProvider>
            </>
        );
    }
}

App.propTypes = {
    connectWebsockets: PropTypes.func.isRequired,
    fetchUserRole: PropTypes.func.isRequired,
    saveUserRole: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    store: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userDetail: PropTypes.object,
    userToBeCreated: PropTypes.bool,
};

const mapStateToProps = state => ({
    isLoading: state.profile.isLoading,
    userDetail: state.profile.userDetail,
    userToBeCreated: state.profile.userToBeCreated,
});

export default withRouter(connect(mapStateToProps, {
    fetchUserRole,
    connectWebsockets,
    saveUserRole
})(App));
