import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const adminBusinessUnit = (state = {
    correlationId: null,
    isBusinessUnitLoading: false,
    isBusinessUnitCreating: false,
    businessUnitSubmittedAndShouldRedirect: false,
    initWithOldData: false,
    created: false,
    businessUnit: {},
}, action) => {
    switch (action.type) {
        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_REDIRECT_RESET: {
            return Object.assign({}, state, {
                businessUnitSubmittedAndShouldRedirect: false,
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isBusinessUnitLoading: false,
                isBusinessUnitCreating: false,
                businessUnitSubmittedAndShouldRedirect: false,
                initWithOldData: false,
                created: false,
                businessUnit: {},
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_UPDATE_PENDING:
        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_CREATE_PENDING: {
            return Object.assign({}, state, {
                isBusinessUnitCreating: true,
                isBusinessUnitLoading: true,
                businessUnitSubmittedAndShouldRedirect: true,
            });
        }

        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_ACCEPTED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isBusinessUnitCreating: false,
                created: true,
                isBusinessUnitLoading: true,
                correlationId
            });
        }

        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_SEND: {
            const { correlationId, businessUnit, error } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isBusinessUnitLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isBusinessUnitLoading: false,
                correlationId,
                businessUnit
            });
        }

        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isBusinessUnitCreating: false,
                isBusinessUnitLoading: false,
                correlationId,
            });
        }

        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isBusinessUnitCreating: false,
                created: false,
                isBusinessUnitLoading: false,
                businessUnitSubmittedAndShouldRedirect: false,
                initWithOldData: true,
                correlationId,
            });
        }

        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_DETAIL_FETCH_PENDING: {
            return Object.assign({}, state, { isBusinessUnitLoading: true });
        }

        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_SAVE_NEW_ERROR:
        case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_SAVE_UPDATE_ERROR: {
            return Object.assign({}, state, { isBusinessUnitLoading: false });
        }

        default:
            return state;
    }
};
