import React from 'react';
import {Field, reduxForm} from 'redux-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import buMessages from '../../../../../intl/admin/businessUnitMessages';

const numberRange = value => {
    if (value > 500) {
        return 500;
    } else if(value > 0){
        return value;
    } else {
        return 10;
    }
};

const FormField = styled(Field)`
    max-width: 30%;
`;

const InputPerPageForm = props => {
    const {handleSubmit, reset} = props;

    return (
        <form onSubmit={handleSubmit} onBlur={reset}>
            <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <FormattedMessage {...buMessages.RECORDS_PER_PAGE}/>
                    </span>
                </div>
                <FormField
                    name="linesPerPage"
                    component="input"
                    type="number"
                    className="form-control"
                    normalize={numberRange}/>
            </div>
        </form>
    )
};

InputPerPageForm.propTypes = {
    reset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({})(InputPerPageForm)
