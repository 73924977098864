import {defineMessages} from 'react-intl';

const messages = defineMessages({
    NO_DATA: {
        id: 'table.noData',
        defaultMessage: 'No data found'
    },
    PAGE: {
        id: 'table.page',
        defaultMessage: 'Page'
    },
    OF: {
        id: 'table.of',
        defaultMessage: 'of'
    },
    ROWS: {
        id: 'table.rows',
        defaultMessage: 'rows'
    },
    CLOSING_DATE: {
        id: 'table.closed',
        defaultMessage: 'Closing date'
    },
    GROUP: {
        id: 'table.countryGroup',
        defaultMessage: 'Country'
    },
    VISIBLE_COLUMNS: {
        id: 'table.visibleColumns',
        defaultMessage: 'Visible Columns'
    },
});

export default messages;
