import {
    FIELD_MESSAGE,
    FIELD_ASSIGNED_TO,
    FIELD_ATTACHMENT,
    FIELD_BRAND,
    FIELD_CREATOR_NAME,
    FIELD_CREATION_DATE,
    FIELD_DEALER_NAME,
    FIELD_DEALER_NUMBER,
    FIELD_DATE_FROM,
    FIELD_MODEL,
    FIELD_NEW_ATTACHMENT,
    FIELD_OPTION,
    FIELD_ORDER_NUMBER,
    FIELD_PFX,
    FIELD_SEND_COPY_TO,
    FIELD_SFX,
    FIELD_STATUS,
    FIELD_SUBJECT,
    FIELD_DATE_TO,
    FIELD_VERSION,
    FIELD_VIN,
    LABEL_BRAND,
    LABEL_CREATION_DATE,
    LABEL_DATE_FROM,
    LABEL_DATE_TO,
    LABEL_DEALER_NAME,
    LABEL_DEALER_NUMBER,
    LABEL_MESSAGE,
    LABEL_MODEL,
    LABEL_OPTION,
    LABEL_ORDER_NUMBER,
    LABEL_SEND_COPY_TO,
    LABEL_STATUS,
    LABEL_SUBJECT,
    LABEL_TICKET_NUMBER,
    LABEL_VERSION,
    LABEL_VIN,
    LABEL_CREATOR_NAME,
    LABEL_ASSIGNED_TO,
    LABEL_NEW_ATTACHMENT,
    LABEL_ATTACHMENT,
    FIELD_EXPORT_TYPE,
    LABEL_EXPORT_TYPE,
    FIELD_AUTOMATIC_RESPONSE,
    LABEL_AUTOMATIC_RESPONSE,
    FIELD_EXPORT_CHARSET,
    LABEL_EXPORT_CHARSET,
    FIELD_TYPE_OF_PURCHASE,
    LABEL_TYPE_OF_PURCHASE,
    FIELD_EMPLOYEE_OF,
    LABEL_EMPLOYEE_OF,
    FIELD_NAME_AND_SURNAME_OF_THE_EMPLOYEE,
    LABEL_NAME_AND_SURNAME_OF_THE_EMPLOYEE,
} from './formConstants';

export const commercialFieldLabelMapping = {
    [FIELD_SUBJECT]: LABEL_SUBJECT,
    [FIELD_BRAND]: LABEL_BRAND,
    [FIELD_MODEL]: LABEL_MODEL,
    [FIELD_VERSION]: LABEL_VERSION,
    [FIELD_ORDER_NUMBER]: LABEL_ORDER_NUMBER,
    [FIELD_VIN]: LABEL_VIN,
    [FIELD_OPTION]: LABEL_OPTION,
    [FIELD_ATTACHMENT]: LABEL_ATTACHMENT,
    [FIELD_NEW_ATTACHMENT]: LABEL_NEW_ATTACHMENT,
    [FIELD_SEND_COPY_TO]: LABEL_SEND_COPY_TO,
    [FIELD_DEALER_NAME]: LABEL_DEALER_NAME,
    [FIELD_DEALER_NUMBER]: LABEL_DEALER_NUMBER,
    [FIELD_MESSAGE]: LABEL_MESSAGE,
    [FIELD_AUTOMATIC_RESPONSE]: LABEL_AUTOMATIC_RESPONSE,
    [FIELD_SFX]: LABEL_TICKET_NUMBER,
    [FIELD_PFX]: LABEL_TICKET_NUMBER,
    [FIELD_DATE_FROM]: LABEL_DATE_FROM,
    [FIELD_DATE_TO]: LABEL_DATE_TO,
    [FIELD_ASSIGNED_TO]: LABEL_ASSIGNED_TO,
    [FIELD_STATUS]: LABEL_STATUS,
    [FIELD_CREATOR_NAME]: LABEL_CREATOR_NAME,
    [FIELD_CREATION_DATE]: LABEL_CREATION_DATE,
    [FIELD_EXPORT_TYPE]: LABEL_EXPORT_TYPE,
    [FIELD_EXPORT_CHARSET]: LABEL_EXPORT_CHARSET,
    [FIELD_TYPE_OF_PURCHASE]: LABEL_TYPE_OF_PURCHASE,
    [FIELD_EMPLOYEE_OF]: LABEL_EMPLOYEE_OF,
    [FIELD_NAME_AND_SURNAME_OF_THE_EMPLOYEE]: LABEL_NAME_AND_SURNAME_OF_THE_EMPLOYEE,
};
