import {defineMessages} from 'react-intl';

const messages = defineMessages({
    'cs-CZ': {
        id: 'location.cs-cz',
        defaultMessage: 'Czechia'
    },
    'fr-FR': {
        id: 'location.fr-fr',
        defaultMessage: 'France'
    },
    'es-ES': {
        id: 'location.es-es',
        defaultMessage: 'Spain'
    },
    'sv-SE': {
        id: 'location.sv-se',
        defaultMessage: 'Sweden'
    },
    'en-GB': {
        id: 'location.en-gb',
        defaultMessage: 'United Kingdom'
    },
    'pl-PL': {
        id: 'location.pl-pl',
        defaultMessage: 'Poland'
    },
    'sl-SI': {
        id: 'location.sl-si',
        defaultMessage: 'Slovenia'
    },
    'en-IE': {
        id: 'location.en-ie',
        defaultMessage: 'Ireland'
    },
    'pt-PT': {
        id: 'location.pt-pt',
        defaultMessage: 'Portugal'
    },
    'de-DE': {
        id: 'location.de-de',
        defaultMessage: 'Germany'
    },
    'it-IT': {
        id: 'location.it-it',
        defaultMessage: 'Italy'
    },
    'sk-SK': {
        id: 'location.sk-sk',
        defaultMessage: 'Slovakia'
    },
    'hu-HU': {
        id: 'location.hu-hu',
        defaultMessage: 'Hungary'
    },
    'nl-NL': {
        id: 'location.nl-nl',
        defaultMessage: 'Netherlands'
    }
});

export default messages;
