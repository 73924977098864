import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    ADMINISTRATION_PATH,
    ADMINISTRATION_DISTRIBUTION_ADMIN_PATH,
    ADMINISTRATION_DISTRIBUTION_DEALER_PATH,
    ADMINISTRATION_DISTRIBUTION_EDITOR_PATH,
    ADMINISTRATION_DISTRIBUTION_TICKET_SETTING_PATH,
    ADMINISTRATION_DISTRIBUTION_NOTIFICATION_PATH,
    ADMINISTRATION_DISTRIBUTION_SUBJECT_SETTING_PATH,
    ADMINISTRATION_DISTRIBUTION_FREQUENT_QUESTION_PATH,
    ADMINISTRATION_DISTRIBUTION_WORKING_HOURS_PATH,
    ADMINISTRATION_COMMERCIAL_ADMIN_PATH,
    ADMINISTRATION_COMMERCIAL_DEALER_PATH,
    ADMINISTRATION_COMMERCIAL_EDITOR_PATH,
    ADMINISTRATION_COMMERCIAL_TICKET_SETTING_PATH,
    ADMINISTRATION_COMMERCIAL_NOTIFICATION_PATH,
    ADMINISTRATION_COMMERCIAL_SUBJECT_SETTING_PATH,
    ADMINISTRATION_COMMERCIAL_FREQUENT_QUESTION_PATH,
    ADMINISTRATION_MARKETING_TICKET_SETTING_PATH,
    ADMINISTRATION_MARKETING_NOTIFICATION_PATH,
    ADMINISTRATION_MARKETING_FREQUENT_QUESTION_PATH,
    ADMINISTRATION_MARKETING_ADMIN_PATH,
    ADMINISTRATION_MARKETING_DEALER_PATH,
    ADMINISTRATION_MARKETING_EDITOR_PATH,
    ADMINISTRATION_MARKETING_WORKING_HOURS_PATH,
    ADMINISTRATION_MARKETING_GENERAL_QUESTION_PATH,
    ADMINISTRATION_MARKETING_SPARE_PART_PRICE_VERIFICATION_PATH,
    ADMINISTRATION_MARKETING_SPARE_PART_NAME_VERIFICATION_PATH,
    ADMINISTRATION_COMMERCIAL_WORKING_HOURS_PATH,
    ADMINISTRATION_DISTRIBUTION_PRESET_RESPONSE_PATH,
    ADMINISTRATION_COMMERCIAL_PRESET_RESPONSE_PATH,
    ADMINISTRATION_MARKETING_PRESET_RESPONSE_PATH,
    ADMINISTRATION_COMMERCIAL_SUBJECT_OFFER_FOR_EMPLOYEES,
    ADMINISTRATION_DISTRIBUTION_EXTERNAL_EDITOR_PATH,
    ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_NEW_PATH,
    ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_LIST_PATH,
    ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH,
    ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_ASSIGNMENT_PATH,
} from '../../routes/paths'
import {Domain} from '../common/Domain';
import checkRoles from '../common/roleChecker/RoleChecker';
import TicketSettingsPage from './ticketSettings/TicketSettingsPage';
import NotificationPage from './notification/NotificationPage';
import SubjectSettingsPage from './subjectSettings/SubjectSettingsPage';
import FrequentQuestionPage from './frequentQuestion/FrequentQuestionPage';
import WorkingHoursPage from './workingHours/WorkingHoursPage';
import AdministrationMainPage from './AdministrationMainPage';
import NotFoundPage from '../NotFoundPage';
import AdminRightsManagementPage from './right/AdminRightsManagementPage';
import EditorRightsManagementPage from './right/EditorRightsManagementPage';
import DealerRightsManagementPage from './right/DealerRightsManagementPage';
import PresetResponsePage from './presetResponse/PresetResponsePage';
import ExternalEditorRightsManagementPage from './right/ExternalEditorRightsManagementPage';
import NewBusinessUnitPage from './businessUnit/detail/NewBusinessUnitPage';
import ViewBusinessUnitPage from './businessUnit/detail/ViewBusinessUnitPage';
import EditBusinessUnitPage from './businessUnit/detail/EditBusinessUnitPage';
import ListBusinessUnitPage from './businessUnit/list/ListBusinessUnitPage';
import BUAssignmentPage from './businessUnit/buAssignment/BUAssignmentPage';

class Administration extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_TICKET_SETTING_PATH}
                       render={() => <Domain component={checkRoles(TicketSettingsPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_NOTIFICATION_PATH}
                       render={() => <Domain component={checkRoles(NotificationPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_SUBJECT_SETTING_PATH}
                       render={() => <Domain subjectKey='distribution'
                                             component={checkRoles(SubjectSettingsPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_FREQUENT_QUESTION_PATH}
                       render={() => <Domain component={checkRoles(FrequentQuestionPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_PRESET_RESPONSE_PATH}
                       render={() => <Domain component={checkRoles(PresetResponsePage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_WORKING_HOURS_PATH}
                       render={() => <Domain component={checkRoles(WorkingHoursPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_ADMIN_PATH}
                       render={() => <Domain component={checkRoles(AdminRightsManagementPage, ['DB_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_DEALER_PATH}
                       render={() => <Domain component={checkRoles(DealerRightsManagementPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_EDITOR_PATH}
                       render={() => <Domain component={checkRoles(EditorRightsManagementPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_EXTERNAL_EDITOR_PATH}
                       render={() => <Domain component={checkRoles(ExternalEditorRightsManagementPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_NEW_PATH}
                       render={() => <Domain component={checkRoles(NewBusinessUnitPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH + '/:view(view)/:businessUnitUuid(.*)'}
                       render={() => <Domain component={checkRoles(ViewBusinessUnitPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH + '/:edit(edit)/:businessUnitUuid(.*)'}
                       render={() => <Domain component={checkRoles(EditBusinessUnitPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_LIST_PATH}
                       render={() => <Domain component={checkRoles(ListBusinessUnitPage, ['DB_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_ASSIGNMENT_PATH}
                       render={() => <Domain component={checkRoles(BUAssignmentPage, ['DB_ADMIN'])}/>}/>

                <Route exact path={ADMINISTRATION_COMMERCIAL_TICKET_SETTING_PATH}
                       render={() => <Domain component={checkRoles(TicketSettingsPage, ['CC_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_COMMERCIAL_NOTIFICATION_PATH}
                       render={() => <Domain component={checkRoles(NotificationPage, ['CC_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_COMMERCIAL_SUBJECT_SETTING_PATH}
                       render={() => <Domain subjectKey='commercial'
                                             component={checkRoles(SubjectSettingsPage, ['CC_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_COMMERCIAL_SUBJECT_OFFER_FOR_EMPLOYEES}
                       render={() => <Domain subjectKey='offerForEmployees'
                                             component={checkRoles(SubjectSettingsPage, ['CC_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_COMMERCIAL_FREQUENT_QUESTION_PATH}
                       render={() => <Domain component={checkRoles(FrequentQuestionPage, ['CC_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_COMMERCIAL_PRESET_RESPONSE_PATH}
                       render={() => <Domain component={checkRoles(PresetResponsePage, ['CC_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_COMMERCIAL_WORKING_HOURS_PATH}
                       render={() => <Domain component={checkRoles(WorkingHoursPage, ['CC_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_COMMERCIAL_ADMIN_PATH}
                       render={() => <Domain component={checkRoles(AdminRightsManagementPage, ['CC_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_COMMERCIAL_DEALER_PATH}
                       render={() => <Domain component={checkRoles(DealerRightsManagementPage, ['CC_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_COMMERCIAL_EDITOR_PATH}
                       render={() => <Domain component={checkRoles(EditorRightsManagementPage, ['CC_ADMIN'])}/>}/>

                <Route exact path={ADMINISTRATION_MARKETING_TICKET_SETTING_PATH}
                       render={() => <Domain component={checkRoles(TicketSettingsPage, ['MA_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_MARKETING_NOTIFICATION_PATH}
                       render={() => <Domain component={checkRoles(NotificationPage, ['MA_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_MARKETING_GENERAL_QUESTION_PATH}
                       render={() => <Domain subjectKey='marketingQuestion'
                                             component={checkRoles(SubjectSettingsPage, ['MA_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_MARKETING_SPARE_PART_PRICE_VERIFICATION_PATH}
                       render={() => <Domain subjectKey='priceVerification'
                                             component={checkRoles(SubjectSettingsPage, ['MA_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_MARKETING_SPARE_PART_NAME_VERIFICATION_PATH}
                       render={() => <Domain subjectKey='nameVerification'
                                             component={checkRoles(SubjectSettingsPage, ['MA_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_MARKETING_FREQUENT_QUESTION_PATH}
                       render={() => <Domain component={checkRoles(FrequentQuestionPage, ['MA_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_MARKETING_PRESET_RESPONSE_PATH}
                       render={() => <Domain component={checkRoles(PresetResponsePage, ['MA_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_MARKETING_WORKING_HOURS_PATH}
                       render={() => <Domain component={checkRoles(WorkingHoursPage, ['MA_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_MARKETING_ADMIN_PATH}
                       render={() => <Domain component={checkRoles(AdminRightsManagementPage, ['MA_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_MARKETING_DEALER_PATH}
                       render={() => <Domain component={checkRoles(DealerRightsManagementPage, ['MA_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_MARKETING_EDITOR_PATH}
                       render={() => <Domain component={checkRoles(EditorRightsManagementPage, ['MA_ADMIN'])}/>}/>

                <Route exact path={ADMINISTRATION_PATH}
                       component={checkRoles(AdministrationMainPage, ['DB_ADMIN', 'DB_POWER_USER', 'CC_ADMIN', 'CC_POWER_USER', 'MA_ADMIN', 'MA_POWER_USER'])}/>
                <Route component={NotFoundPage}/>
            </Switch>
        );
    }
}

Administration.propTypes = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(Administration);
