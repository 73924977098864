import React from 'react';
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';
import {TICKET_SECTION} from '../../../../../../constants/formConstants';
import {downloadCommercialAttachment} from '../../../../../../actions/commercial/actionCommercialAttachment';
import {StyledA, StyledHeaderDiv, StyledInfoDiv} from '../../../../../common/StyledComponents';

const UploadedAttachments = (props) => {
    const {attachments, label, downloadCommercialAttachment, ticketId} = props;

    const downloadFile = (uuid, name) => (e) => {
        e.preventDefault();
        downloadCommercialAttachment(uuid, ticketId, name);
    };

    return (
        <div>
            <StyledHeaderDiv className="border-bottom border-bottom-secondary">
                <label className="col-form-label pl-0 font-weight-bold ml-3">
                    {!!label && label instanceof Object && <FormattedMessage {...label}/>}
                </label>
                <div className="pl-2 py-auto"/>
            </StyledHeaderDiv>

            {attachments.map((att, index) =>
                <div key={index} className="row m-0 align-items-stretch">
                    <StyledInfoDiv className="col-sm-12 col-lg-4 flex-column border-bottom">
                        <div>
                            <FormattedDate value={new Date(att.created)}/>
                            <FormattedTime value={new Date(att.created)}/>{' '}
                            <span className="font-weight-bold">
                                {att.creatorName}</span>
                            {' '}({att.creatorIpn})
                        </div>
                    </StyledInfoDiv>
                    <div className="col border-bottom">
                        <StyledA onClick={downloadFile(att.uuid, att.name)}>{`${att.name}`}</StyledA>
                    </div>
                </div>
            )}
        </div>
    )
};

const mapStateToProps = (state, initialProps) => ({
    ticketId: formValueSelector(initialProps.form)(state, `${TICKET_SECTION}.uuid`)
});

export default connect(mapStateToProps, {
    downloadCommercialAttachment
})(UploadedAttachments)
