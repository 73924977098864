import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import v4 from 'uuid/v4';
import {FieldWithValidation} from '../../common/fields/FormField';
import {ModalBody, ModalFooter, ModalHeader, ModalWindow} from '../../common/modal/Modal';
import {required, validQuestionFiles} from '../../common/validationCommons';
import {uploadFile} from '../../../actions/actionFile';
import {BoldPrimaryButton, SecondaryButton} from '../../common/Button';
import {moduleRoles} from '../../../utils/roles';
import {StyledForm} from '../../common/StyledComponents';
import SelectGroupsField from '../../common/fields/SelectGroupsField';
import FileSelectionField from '../../common/fields/FileSelectionField';
import buttonMessages from '../../../intl/common/buttonMessages';
import frequentQuestionMessages from '../../../intl/admin/frequentQuestionMessages';
import adminMessages from '../../../intl/admin/adminMessages';

const Button = styled(SecondaryButton)`
  margin-right: 10px;
`;

class FrequentQuestionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {attachment: null};
    }

    save = data => {
        const attachmentId = v4();
        const problemId = v4();
        this.props.saveModal(data, problemId, attachmentId, this.state.attachment);
        if (this.state.attachment) {
            this.props.uploadFile(this.props.domain, attachmentId, problemId, this.state.attachment.type, window.URL.createObjectURL(this.state.attachment));
            this.setState({attachment: null});
        }
    };

    close = () => {
        this.props.reset();
        this.props.closeModal();
        this.setState({data: null, attachmentId: null, attachment: null});
    };

    handleAttachmentSelection = attachment => {
        this.setState({attachment});
    };

    render() {
        const {show, handleSubmit, userDetail: {roles}, domain, selectedCountryGroup, dirty, invalid, intl: {formatMessage}} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);

        return (
            <ModalWindow isOpen={show} onRequestClose={this.close} shouldCloseOnOverlayClick={false}>
                <StyledForm onSubmit={handleSubmit(this.save)}>
                    <ModalHeader>
                        <FormattedMessage {...frequentQuestionMessages.MODAL_TITLE}/>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group mb-0">
                            <Field groups={groups}
                                   name="groups"
                                   component={SelectGroupsField}
                                   selectedCountryGroup={selectedCountryGroup}
                                   label={<FormattedMessage {...adminMessages.SELECT_COUNTRIES}/>}/>
                            <div className="row">
                                <div className="col-3 py-2">
                                    <label>
                                        <FormattedMessage {...frequentQuestionMessages.TEXT}/>
                                    </label>
                                </div>
                                <div className="col-9 py-2">
                                    <Field name="message"
                                           component={FieldWithValidation}
                                           type="text"
                                           placeholder={formatMessage(frequentQuestionMessages.PLACEHOLDER)}
                                           value="text"
                                           className="form-control"
                                           validate={[required]}
                                           isSmall/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <label>
                                        <FormattedMessage {...frequentQuestionMessages.ATTACHMENT}/>
                                    </label>
                                </div>
                                <div className="col-9">
                                    <Field name="attachment" component={FileSelectionField}
                                           handleAttachmentSelection={this.handleAttachmentSelection} showIcon
                                           showName needsFixedHeight
                                           validate={validQuestionFiles}/>
                                </div>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="row">
                            <div className="col">
                                <Button type="button" className="btn"
                                        onClick={this.close}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </Button>
                                <BoldPrimaryButton type="submit" className="btn"
                                                   disabled={!dirty || invalid}>
                                    <FormattedMessage {...buttonMessages.SAVE}/>
                                </BoldPrimaryButton>
                            </div>
                        </div>
                    </ModalFooter>
                </StyledForm>
            </ModalWindow>
        )
    }
}

FrequentQuestionDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    intl: PropTypes.any.isRequired,
    userDetail: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveModal: PropTypes.func.isRequired,
    group: PropTypes.string.isRequired,
    selectedCountryGroup: PropTypes.string.isRequired,
    dirty: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
    selectedCountryGroup: state.adminFrequentQuestion.selectedCountryGroup,
    fileUploadUrl: state.mainFiles.fileUploadUrl,
});

export default reduxForm({
    form: 'addFrequentQuestionModalForm',
    enableReinitialize: true,
})(injectIntl(connect(mapStateToProps, {
    uploadFile
})(FrequentQuestionDialog)));
