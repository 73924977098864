import {
    COMMERCIAL_FORM, FIELD_EMPLOYEE_OF,
    FIELD_MESSAGE, FIELD_NAME_AND_SURNAME_OF_THE_EMPLOYEE,
    FIELD_SUBJECT, FIELD_TYPE_OF_PURCHASE, OFFER_FOR_EMPLOYEES_FORM,
} from './formConstants';

export const createReqFields = {
    [COMMERCIAL_FORM]: [
        FIELD_SUBJECT,
        FIELD_MESSAGE,
    ],
    [OFFER_FOR_EMPLOYEES_FORM]: [
        FIELD_SUBJECT,
        FIELD_TYPE_OF_PURCHASE,
        FIELD_EMPLOYEE_OF,
        FIELD_NAME_AND_SURNAME_OF_THE_EMPLOYEE,
        FIELD_MESSAGE,

    ],
};

export const editReqFields = {
    'DEALER': {
        [COMMERCIAL_FORM]: [
            FIELD_MESSAGE,
        ],
    },
    'EDITOR': {
        [COMMERCIAL_FORM]: [
            FIELD_MESSAGE,
        ],
    }
};
