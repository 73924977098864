import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {
    GENERAL_QUESTION_FORM,
    SPARE_PART_NAME_VERIFICATION_FORM,
    SPARE_PART_PRICE_VERIFICATION_FORM,
    FORM_BUTTON_REOPEN
} from '../../../constants/formConstants';
import {
    fetchMarketingTicket, resetMarketingTicket, updateMarketingTicket
} from '../../../actions/marketing/actionMarketingTicket';
import {
    lockMarketingTicket, resetRedirectAfterLockMarketingTicket
} from '../../../actions/marketing/actionMarketingLockTicket';
import {initLoadedMarketingTicket, transformUpdatedMarketingTicketForSave} from '../Utils';
import {formatTicketNumber} from '../../../utils/utils';
import {clearAlerts} from '../../../actions/alertsActions';
import {editRights} from '../../../constants/marketingTicketRights';
import {moduleRoles} from '../../../utils/roles';
import checkRoles from '../../common/roleChecker/RoleChecker';
import SparePartNameVerificationMasterForm from './forms/SparePartNameVerificationMasterForm';
import SparePartPriceVerificationMasterForm from './forms/SparePartPriceVerificationMasterForm';
import GeneralQuestionMasterForm from './forms/GeneralQuestionMasterForm';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import formMessages from '../../../intl/modules/formMessages';

class ViewMarketingTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToEdit: false,
            ticketSubmitted: false,
            storedTicketData: {},
        };
    }

    componentDidMount() {
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchMarketingTicket(this.props.match.params.ticketUuid)
        }
    }

    componentWillUnmount() {
        this.props.resetMarketingTicket();
        this.props.resetRedirectAfterLockMarketingTicket();
    }

    handleSubmit = (values) => {
        const {submitBy} = values;

        this.props.lockMarketingTicket(this.props.match.params.ticketUuid);
        this.setState({ticketSubmitted: true, storedTicketData: transformUpdatedMarketingTicketForSave(values)});
        if (submitBy === FORM_BUTTON_REOPEN) {
            this.setState({redirectToEdit: true});
        }
    };

    render() {
        const {ticketData, loggedUser, lockTicket, isLoadingSubjects} = this.props;
        const {ticketSubmitted, redirectToEdit, storedTicketData} = this.state;

        let type = false;
        if (get(ticketData, 'ticket.type', {default: false})) {
            type = ticketData.ticket.type;
        }

        if (!lockTicket.isTicketLocking && !lockTicket.redirect && ticketSubmitted && redirectToEdit) {
            this.props.updateMarketingTicket(storedTicketData);
            return <Redirect to={{
                pathname: `/marketing/edit/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        } else if (!lockTicket.isTicketLocking && !lockTicket.redirect && ticketSubmitted) {
            this.props.updateMarketingTicket(storedTicketData);
            this.setState({ticketSubmitted: false, storedTicketData: {}});
        }

        const initializationValue = initLoadedMarketingTicket(ticketData);
        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !type
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={type} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {type === GENERAL_QUESTION_FORM &&
                    <GeneralQuestionMasterForm form={type}
                                               initialValues={initializationValue}
                                               userRights={editRights[moduleRoles.isEditorMarketing(loggedUser) ?
                                                   'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                               enableReinitialize={true}
                                               handleSubmit={this.handleSubmit}
                                               reqFields={[]}
                                               readOnly/>
                    }
                    {type === SPARE_PART_PRICE_VERIFICATION_FORM &&
                    <SparePartPriceVerificationMasterForm form={type}
                                                          initialValues={initializationValue}
                                                          userRights={editRights[moduleRoles.isEditorMarketing(loggedUser) ?
                                                              'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                          enableReinitialize={true}
                                                          handleSubmit={this.handleSubmit}
                                                          reqFields={[]}
                                                          readOnly/>
                    }
                    {type === SPARE_PART_NAME_VERIFICATION_FORM &&
                    <SparePartNameVerificationMasterForm form={type}
                                                         initialValues={initializationValue}
                                                         userRights={editRights[moduleRoles.isEditorMarketing(loggedUser) ?
                                                             'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                         enableReinitialize={true}
                                                         handleSubmit={this.handleSubmit}
                                                         reqFields={[]}
                                                         readOnly/>
                    }
                </div>
            </div>
        );
    }
}

ViewMarketingTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    fetchMarketingTicket: PropTypes.func.isRequired,
    resetMarketingTicket: PropTypes.func.isRequired,
    resetRedirectAfterLockMarketingTicket: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.marketingTicket,
    lockTicket: state.marketingLockTicketCheck,
    userDetail: state.profile.userDetail,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchMarketingTicket,
    resetMarketingTicket,
    resetRedirectAfterLockMarketingTicket,
    lockMarketingTicket,
    updateMarketingTicket,
})(injectIntl(ViewMarketingTicketPage))), ['MA_DEALER', 'MA_EDITOR']);

