import React from 'react';
import {FormattedMessage} from 'react-intl';
import buttonMessages from '../../../intl/common/buttonMessages';
import {PrimaryButton} from '../Button';
import Select from 'react-select';
import ValidFormattedMessage from '../ValidFormattedMessage';
import {getSubjectKey} from '../../../constants/Utils';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import {Label} from '../StyledComponents';

export const StatusSelector = (props) => {
    const {label, options, placeholder, handleChange, handleAllClick, statuses} = props;
    return (
        <div>
            <div className="row my-2">
                <div className="col">
                    <Label>
                        <FormattedMessage {...label}/>
                    </Label>
                    <PrimaryButton type="button" className="btn btn-sm ml-3"
                                   onClick={handleAllClick}>
                        <FormattedMessage {...buttonMessages.ALL}/>
                    </PrimaryButton>
                </div>
            </div>
            <Select isMulti isSearchable
                    value={statuses}
                    options={options}
                    placeholder={placeholder}
                    onChange={handleChange}/>
        </div>
    )
};

export const SegmentSelector = (props) => {
    const {label, options, placeholder, handleChange, handleAllClick, segments} = props;
    return (
        <div>
            <div className="row my-2">
                <div className="col">
                    <Label>
                        <FormattedMessage {...label}/>
                    </Label>
                    <PrimaryButton type="button" className="btn btn-sm ml-3"
                                   onClick={handleAllClick}>
                        <FormattedMessage {...buttonMessages.ALL}/>
                    </PrimaryButton>
                </div>
            </div>
            <Select isMulti isSearchable
                    value={segments}
                    options={options}
                    placeholder={placeholder}
                    onChange={handleChange}/>
        </div>
    )
};

export const SubjectSelector = (props) => {
    const {options, placeholder, handleChange, handleAllClick, subjects, subjectKey} = props;
    return (
        <div>
            <div className="row my-2">
                <div className="col">
                    <Label>
                        <FormattedMessage id="notification.title.subjects"
                                          defaultMessage="Subject Notification - {formName}"
                                          values={{
                                              formName: <ValidFormattedMessage message={getSubjectKey[subjectKey]}
                                                                               intlMessages={formTypeMessages}/>
                                          }}/>
                    </Label>
                    <PrimaryButton type="button" className="btn btn-sm ml-3"
                                   onClick={handleAllClick}>
                        <FormattedMessage {...buttonMessages.ALL}/>
                    </PrimaryButton>
                </div>
            </div>
            <Select isMulti isSearchable
                    value={subjects}
                    options={options}
                    placeholder={placeholder}
                    onChange={handleChange}/>
        </div>
    )
};
