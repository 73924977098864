import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {change, getFormInitialValues} from 'redux-form';
import get from 'get-value';
import {
    BUTTON_LABEL_FREQUENT_QUESTION, FIELD_BRAND, FIELD_MODEL, FIELD_SUBJECT, TICKET_SECTION, FIELD_TECHNICAL_TYPE,
    FIELD_CURRENT_SPARE_PART_NAME, FIELD_FAMILY, FIELD_LEVEL_EQUIPMENT, FIELD_PCL_PRICE, FIELD_REFERENCE_NUMBER,
    FIELD_SEGMENT, FIELD_SPARE_PART_LOCATION, BUTTON_LABEL_ASD_LOAD, FIELD_PRIORITY,
} from '../../../../../../constants/formConstants';
import {
    asdDataResetActionCreator,
    asdDataSetActionCreator,
    fetchAsdInformationActionCreator
} from '../../../../../../actions/actionAsdData';
import {validReferenceNumberLength} from '../../../../../common/validationCommons';
import {InputFieldWithValidation} from '../../../../../common/fields/InputFieldWithValidation';
import {TextareaFieldWithValidation} from '../../../../../common/fields/TextareaFieldWithValidation';
import {brandOptions, modelValues, domains, getPriority} from '../../../../../../constants/Utils';
import {PrimaryButton} from '../../../../../common/Button';
import {StyledDiv} from '../../../../../common/StyledComponents';
import FrequentQuestionModal from '../../../../../common/frequentQuestion/FrequentQuestionModal';
import InputSelectFieldWithValidation from '../../../../../common/fields/InputSelectFieldWithValidation';
import formValueSelector from 'redux-form/lib/formValueSelector';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import brandMessages from '../../../../../../intl/modules/brandMessages';
import formMessages from '../../../../../../intl/modules/formMessages';
import Loader from '../../../../../Loader';
import {bindActionCreators} from 'redux';
import formTicketValuesMessages from '../../../../../../intl/common/formTicketValuesMessages';

const domain = domains.MARKETING;

class TicketSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenFrequentQuestionModal: false,
        }
    }

    componentWillUnmount() {
        this.props.asdDataResetActionCreator();
    }

    handleAsdLoad = (e) => {
        const {referenceNumber, group, language} = this.props;
        e.preventDefault();
        this.props.fetchAsdInformationActionCreator(referenceNumber, group, language);
    };

    showModal = () => {
        this.setState({
            isOpenFrequentQuestionModal: true,
        });
    };

    closeModal = () =>
        this.setState({
            isOpenFrequentQuestionModal: false
        });

    fillRefNumberDetailFromAsd = () => {
        const {asdData, change} = this.props;
        change(`${TICKET_SECTION}.${FIELD_PCL_PRICE}`, get(asdData, 'asdData.pclPrice', '') + ' ' + get(asdData, 'asdData.currency', ''));
        change(`${TICKET_SECTION}.${FIELD_FAMILY}`, get(asdData, 'asdData.family', ''));
        change(`${TICKET_SECTION}.${FIELD_SEGMENT}`, get(asdData, 'asdData.segment', ''));
        change(`${TICKET_SECTION}.${FIELD_CURRENT_SPARE_PART_NAME}`, get(asdData, 'asdData.partName', ''));
        this.props.asdDataSetActionCreator();
    };

    render() {
        const {readOnly, userRights, reqFields, subjects, subjectField, group, initialValues, brand, asdData} = this.props;
        const {isOpenFrequentQuestionModal} = this.state;
        const requiredField = subjects ? get(get(subjects, `priceVerification.${group}`, []).find(item => item.uuid === subjectField), 'refNoObligation', false) : false;

        if (asdData.asdSet) {
            this.fillRefNumberDetailFromAsd();
        }

        return (
            <>
                <h3 className="text-left ml-4">
                    <FormattedMessage {...formMessages.TICKET_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white px-4 py-2">
                    <div className="form-row">
                        <div className="col-12 pb-2">
                            <div className="form-row align-items-end">
                                <div className="col-md-12 col-lg-8 pb-2">
                                    <InputSelectFieldWithValidation
                                        label={formMessages.SUBJECT}
                                        field={FIELD_SUBJECT}
                                        customOptions={get(subjects, `priceVerification.${group}`, [])}
                                        isDisabled={!userRights.canEditSubject || readOnly}
                                        isRequired={reqFields.includes(FIELD_SUBJECT) && !readOnly}/>
                                </div>
                                {userRights.canOpenFrequentQuestion && !readOnly &&
                                <>
                                    <div className="pl-2 pb-2">
                                        <PrimaryButton type="button" className="btn btn-sm"
                                                       onClick={this.showModal}
                                                       disabled={asdData.asdLoaded}>
                                            <ValidFormattedMessage message={BUTTON_LABEL_FREQUENT_QUESTION}
                                                                   intlMessages={buttonMessages}/>
                                        </PrimaryButton>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 pb-2">
                            <div className="form-row align-items-end">
                                <div className="col-md-12 col-lg-6 col-xl-4">
                                    <InputFieldWithValidation
                                        label={formMessages.REFERENCE_NUMBER}
                                        field={FIELD_REFERENCE_NUMBER}
                                        maxLength={10}
                                        validations={[validReferenceNumberLength]}
                                        isDisabled={!userRights.canEditReferenceNumber || readOnly || asdData.asdLoaded}
                                        isRequired={requiredField && !readOnly}
                                        isSmall/>
                                </div>
                                {userRights.canEditAsd && !readOnly &&
                                <>
                                    <div className="pl-2 pt-1">
                                        <PrimaryButton type="button" className="btn btn-sm"
                                                       onClick={this.handleAsdLoad}
                                                       disabled={asdData.asdLoaded}>
                                            <ValidFormattedMessage message={BUTTON_LABEL_ASD_LOAD}
                                                                   intlMessages={buttonMessages}/>
                                        </PrimaryButton>
                                    </div>
                                    <div className="pl-2 py-auto">
                                        {asdData.isAsdLoading &&
                                        <Loader isSmall/>
                                        }
                                    </div>
                                </>
                                }
                                <div className="col-md-12 col-lg-12 col-xl-4 offset-xl-2">
                                    <InputSelectFieldWithValidation
                                        label={formMessages.PRIORITY}
                                        field={FIELD_PRIORITY}
                                        notSortedOptions={getPriority}
                                        intlMessages={formTicketValuesMessages}
                                        isDisabled={!userRights.canEditPriority || readOnly}
                                        isRequired={reqFields.includes(FIELD_PRIORITY) && !readOnly}
                                        noEmpty/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.SEGMENT}
                                field={FIELD_SEGMENT}
                                isRequired={requiredField && !readOnly}
                                isDisabled
                                isSmall/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.CURRENT_SPARE_PART_NAME}
                                field={FIELD_CURRENT_SPARE_PART_NAME}
                                maxLength={180}
                                isRequired={requiredField && !readOnly}
                                isDisabled
                                isSmall/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.FAMILY}
                                field={FIELD_FAMILY}
                                maxLength={12}
                                isDisabled
                                isSmall/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.PCL_PRICE}
                                field={FIELD_PCL_PRICE}
                                maxLength={10}
                                isDisabled
                                isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <InputSelectFieldWithValidation
                                label={formMessages.BRAND}
                                field={FIELD_BRAND}
                                options={get(brandOptions, `${get(initialValues, 'ticketSection.group', {default: 'X'})}`, {default: brandOptions.DEFAULT})}
                                intlMessages={brandMessages}
                                isDisabled={!userRights.canEditBrand || readOnly}/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <InputSelectFieldWithValidation
                                label={formMessages.MODEL}
                                field={FIELD_MODEL}
                                options={get(modelValues,
                                    `${get(initialValues, 'ticketSection.group', {default: 'X'})}.${brand}`,
                                    {default: []})}
                                isDisabled={!userRights.canEditModel || readOnly}/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.TECHNICAL_TYPE}
                                field={FIELD_TECHNICAL_TYPE}
                                maxLength={4}
                                isDisabled={!userRights.canEditTechnicalType || readOnly}
                                isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 col-xl-6 pb-2">
                            <TextareaFieldWithValidation
                                label={formMessages.SPARE_PART_LOCATION}
                                field={FIELD_SPARE_PART_LOCATION}
                                isDisabled={!userRights.canEditSparePartLocation || readOnly}
                                rows={2}/>
                        </div>
                        <div className="col-12 col-xl-6 pb-2">
                            <TextareaFieldWithValidation
                                label={formMessages.LEVEL_EQUIPMENT}
                                field={FIELD_LEVEL_EQUIPMENT}
                                isDisabled={!userRights.canEditSparePartLocation || readOnly}
                                rows={2}/>
                        </div>
                    </div>
                </StyledDiv>
                {isOpenFrequentQuestionModal &&
                <FrequentQuestionModal show={isOpenFrequentQuestionModal}
                                       domain={domain}
                                       closeModal={this.closeModal}
                                       group={group}/>
                }
            </>
        )
    }
}

const mapStateToProps = (state, props) => ({
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
    subjectField: formValueSelector(props.form)(state, `${TICKET_SECTION}.${FIELD_SUBJECT}`),
    group: formValueSelector(props.form)(state, `${TICKET_SECTION}.group`),
    initialValues: getFormInitialValues(props.form)(state),
    brand: formValueSelector(props.form)(state, `${TICKET_SECTION}.${FIELD_BRAND}`),
    asdData: state.asdData,
    referenceNumber: formValueSelector(props.form)(state, `${TICKET_SECTION}.${FIELD_REFERENCE_NUMBER}`),
    language: state.intl.language
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            asdDataSetActionCreator,
            fetchAsdInformationActionCreator,
            asdDataResetActionCreator
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketSection);
