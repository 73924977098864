import {put, takeEvery, select} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {DISTRIBUTION_TICKET_RESET} from '../actions/distribution/actionDistributionTicket';
import get from 'get-value';
import {distributionWebsocketCommands} from '../constants/distributionWebsocketCommands';

export function* handleTicketError(action) {
    const stateCorrelationId = get(yield select(state => state.distributionTicket), `correlationId`, {default: undefined});
    const {correlationId} = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('/distribution'));
    yield put({type: DISTRIBUTION_TICKET_RESET});
}

function* distributionTicketSaga() {
    yield takeEvery(distributionWebsocketCommands.DISTRIBUTION_TICKET_UPDATE_FATAL, handleTicketError);
    yield takeEvery(distributionWebsocketCommands.DISTRIBUTION_TICKET_CREATE_FATAL, handleTicketError);
    yield takeEvery(distributionWebsocketCommands.DISTRIBUTION_TICKET_SEND_ERROR, handleTicketError);
}

export default distributionTicketSaga;
