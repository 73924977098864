import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, FormattedTime} from 'react-intl';
import {connect} from 'react-redux';
import {StyledDiv, StyledDealerInput} from '../../../../../common/StyledComponents';
import formMessages from '../../../../../../intl/modules/formMessages';

class DealerSection extends Component {
    render() {
        const {userRights, ticket} = this.props;
        return (
            <>
                {userRights.canShowDealerSection &&
                <StyledDiv className="border border-secondary bg-white mb-4 px-4 py-2">
                    <div className="form-row">
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <label className="col-form-label pl-0 font-weight-bold">
                                <FormattedMessage {...formMessages.DEALER_NAME}/>
                            </label>
                            <StyledDealerInput> {ticket.dealerName} </StyledDealerInput>

                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <label className="col-form-label pl-0 font-weight-bold">
                                <FormattedMessage {...formMessages.DEALER_NUMBER}/>
                            </label>
                            <StyledDealerInput> {ticket.dealerNumber} </StyledDealerInput>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <label className="col-form-label pl-0 font-weight-bold">
                                <FormattedMessage {...formMessages.CREATOR_NAME}/>
                            </label>
                            <StyledDealerInput> {ticket.userName} </StyledDealerInput>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <label className="col-form-label pl-0 font-weight-bold">
                                <FormattedMessage {...formMessages.CREATION_DATE}/>
                            </label>
                            <StyledDealerInput>
                                <FormattedDate value={new Date(ticket.created)}/> <FormattedTime
                                value={new Date(ticket.created)}/>
                            </StyledDealerInput>
                        </div>
                    </div>
                </StyledDiv>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    ticket: state.commercialTicket.ticket
});

export default connect(mapStateToProps, {})(DealerSection);
