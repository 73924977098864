import {axiosInstance} from './rest';
import {apiMappingKey} from '../constants/Utils';

export async function getEmails(textSearch, domain) {
    let text_search = '';
    if (textSearch !== undefined && textSearch !== null) {
        text_search = textSearch;
    }
    return axiosInstance.get(`${apiMappingKey[domain]}/emails`, {params: {text_search}});
}
