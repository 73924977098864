import v4 from 'uuid/v4';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const MARKETING_STATISTICS_PENDING = 'MARKETING_STATISTICS_PENDING';

export const fetchMarketingStatistics = ({dealerNumber, countryGroups}) => dispatch => {
    return dispatch({
        type: MARKETING_STATISTICS_PENDING,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_GET_STATISTICS,
        payload: {dealerNumber, countryGroups, correlationId: v4()}
    });
};
