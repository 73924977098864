import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    NEW: {
        id: 'form.status.new',
        defaultMessage: 'New'
    },
    WAIT_DEALER: {
        id: 'form.status.inProgressDealer',
        defaultMessage: 'In Progress - Dealer'
    },
    WAIT_SUBSIDIARY: {
        id: 'form.status.inProgressSubsidiary',
        defaultMessage: 'In Progress - Subsidiary'
    },
    WAIT_WORK_IN_PROGRESS: {
        id: 'form.status.workInProgress',
        defaultMessage: 'Work in Progress'
    },
    REOPEN: {
        id: 'form.status.reopen',
        defaultMessage: 'Reopen'
    },
    CLOSED: {
        id: 'form.status.closed',
        defaultMessage: 'Closed'
    },
    STATUS_HISTORY: {
        id: 'form.header.statusHistory',
        defaultMessage: 'History of Changes'
    }
});

export default messages;
