import {defineMessages} from 'react-intl';

const messages = defineMessages({
    RENAULT: {
        id: 'brand.renault',
        defaultMessage: 'Renault'
    },
    DACIA: {
        id: 'brand.dacia',
        defaultMessage: 'Dacia'
    },
    NISSAN: {
        id: 'brand.nissan',
        defaultMessage: 'Nissan'
    },
    ALPINE: {
        id: 'brand.alpine',
        defaultMessage: 'Alpine'
    },
});

export default messages;
