export const commercialWebsocketCommands = {
    // route (commands)

    // FILE
    COMMERCIAL_FREQUENT_QUESTION_FILE_DOWNLOAD: 'frequentQuestionAttachmentDownload',
    COMMERCIAL_FREQUENT_QUESTION_FILE_UPLOAD: 'frequentQuestionAttachmentUpload',
    // FREQUENT QUESTION
    COMMERCIAL_FREQUENT_QUESTION_GET: 'getFrequentQuestions',
    COMMERCIAL_FREQUENT_QUESTION_CREATE: 'createFrequentQuestion',
    COMMERCIAL_FREQUENT_QUESTION_MOVE: 'moveFrequentQuestion',
    COMMERCIAL_FREQUENT_QUESTION_DELETE: 'deleteFrequentQuestion',
    COMMERCIAL_FREQUENT_QUESTION_UPDATE: 'updateFrequentQuestion',
    // PRESET RESPONSE
    COMMERCIAL_PRESET_RESPONSE_GET: 'getPresetResponses',
    COMMERCIAL_PRESET_RESPONSE_CREATE: 'createPresetResponse',
    COMMERCIAL_PRESET_RESPONSE_MOVE: 'movePresetResponse',
    COMMERCIAL_PRESET_RESPONSE_DELETE: 'deletePresetResponse',
    COMMERCIAL_PRESET_RESPONSE_UPDATE: 'updatePresetResponse',
    // NOTIFICATION
    COMMERCIAL_NOTIFICATIONS_GET: 'getEmailNotifications',
    COMMERCIAL_NOTIFICATIONS_SET: 'setEmailNotifications',
    // RIGHTS MANAGEMENT
    COMMERCIAL_ADMIN_ROLE_GET: 'getAdminRole',
    COMMERCIAL_ADMIN_ROLE_SET: 'setAdminRole',
    COMMERCIAL_EDITOR_ROLE_GET: 'getEditorRole',
    COMMERCIAL_EDITOR_ROLE_SET: 'setEditorRole',
    COMMERCIAL_DEALER_ROLE_GET: 'getDealerRole',
    COMMERCIAL_DEALER_ROLE_SET: 'setDealerRole',
    // SUBJECT SETTINGS
    COMMERCIAL_SUBJECT_SETTINGS_GET: 'getSubjectSettings',
    COMMERCIAL_SUBJECT_SETTINGS_CREATE: 'createSubjectSettings',
    COMMERCIAL_SUBJECT_SETTINGS_UPDATE: 'updateSubjectSettings',
    COMMERCIAL_SUBJECT_SETTINGS_MOVE: 'moveSubjectSettings',
    COMMERCIAL_SUBJECT_SETTINGS_DELETE: 'deleteSubjectSettings',
    // TICKET SETTINGS
    COMMERCIAL_TICKET_SETTINGS_GET: 'getTicketSettings',
    COMMERCIAL_TICKET_SETTINGS_SET: 'setTicketSettings',
    // WORKING HOURS
    COMMERCIAL_WORKING_HOURS_GET: 'getWorkingHours',
    COMMERCIAL_WORKING_HOURS_SET: 'setWorkingHours',

    // ATTACHMENTS
    COMMERCIAL_CREATE_ATTACHMENT: 'createAttachments',
    COMMERCIAL_DOWNLOAD_PRESIGNED_URL: 'attachmentDownloadPreSignedUrl',
    // DEALERS
    COMMERCIAL_DEALERS_GET: 'getDealers',
    // EXPORT
    COMMERCIAL_EXPORT_GET: 'getExport',
    COMMERCIAL_EXPORT_CREATE: 'createTicketExport',
    COMMERCIAL_EXPORT_DOWNLOAD_URL_GET: 'exportDownloadPreSignedUrl',
    // FULL TEXT SEARCH
    COMMERCIAL_FULL_TEXT_SEARCH_GET: 'getFullTextSearch',
    // HISTORY
    COMMERCIAL_HISTORY_GET: 'getHistory',
    // LIST
    COMMERCIAL_LIST_GET: 'getList',
    // LOCK TICKET
    COMMERCIAL_SET_LOCK: 'setLockTicket',
    COMMERCIAL_SET_UNLOCK: 'setUnlockTicket',
    // PREFERENCES
    COMMERCIAL_PREFERENCES_GET: 'getPreferences',
    COMMERCIAL_USER_LIST_PREFERENCE_SET: 'setUserListPreference',
    COMMERCIAL_USER_HISTORY_PREFERENCE_SET: 'setUserHistoryPreference',
    COMMERCIAL_USER_FULL_TEXT_SEARCH_PREFERENCE_SET: 'setUserFullTextSearchPreference',
    COMMERCIAL_USER_NOTIFICATION_PREFERENCE_GET: 'getUserNotificationPreference',
    COMMERCIAL_USER_NOTIFICATION_PREFERENCE_SET: 'setUserNotificationPreference',
    // STATISTICS
    COMMERCIAL_GET_STATISTICS: 'getStatistics',
    // TICKET
    COMMERCIAL_UPDATE_TICKET: 'updateCommercialTicket',
    COMMERCIAL_CREATE_TICKET: 'createCommercialTicket',
    COMMERCIAL_GET_TICKET: 'getTicket',

    // receive

    // FREQUENT QUESTION
    COMMERCIAL_FREQUENT_QUESTION_ERROR: 'COMMERCIAL_FREQUENT_QUESTIONS_ERROR',
    COMMERCIAL_FREQUENT_QUESTION_SEND: 'COMMERCIAL_FREQUENT_QUESTIONS_SEND',
    COMMERCIAL_FREQUENT_QUESTION_SEND_ERROR: 'COMMERCIAL_FREQUENT_QUESTIONS_SEND_ERROR',
    COMMERCIAL_FREQUENT_QUESTION_ATTACHMENTS_DOWNLOAD: 'COMMERCIAL_FREQUENT_QUESTIONS_ATTACHMENTS_DOWNLOAD',
    COMMERCIAL_FREQUENT_QUESTION_ATTACHMENTS_UPLOAD: 'COMMERCIAL_FREQUENT_QUESTIONS_ATTACHMENTS_UPLOAD',
    // PRESET RESPONSE
    COMMERCIAL_PRESET_RESPONSES_ERROR: 'COMMERCIAL_PRESET_RESPONSES_ERROR',
    COMMERCIAL_PRESET_RESPONSES_SEND: 'COMMERCIAL_PRESET_RESPONSES_SEND',
    COMMERCIAL_PRESET_RESPONSES_SEND_ERROR: 'COMMERCIAL_PRESET_RESPONSES_SEND_ERROR',
    // NOTIFICATION
    COMMERCIAL_NOTIFICATIONS_ERROR: 'COMMERCIAL_NOTIFICATIONS_ERROR',
    COMMERCIAL_NOTIFICATIONS_SEND: 'COMMERCIAL_NOTIFICATIONS_SEND',
    COMMERCIAL_NOTIFICATIONS_SEND_ERROR: 'COMMERCIAL_NOTIFICATIONS_SEND_ERROR',
    // ADMIN RIGHTS MANAGEMENT
    COMMERCIAL_ROLES_SEND_ERROR: 'COMMERCIAL_ROLES_SEND_ERROR',
    COMMERCIAL_ADMIN_ROLE_SEND: 'COMMERCIAL_ADMIN_ROLE_SEND',
    COMMERCIAL_ADMIN_ROLE_SEND_ERROR: 'COMMERCIAL_ADMIN_ROLE_SEND_ERROR',
    COMMERCIAL_EDITOR_ROLE_SEND: 'COMMERCIAL_EDITOR_ROLE_SEND',
    COMMERCIAL_EDITOR_ROLE_SEND_ERROR: 'COMMERCIAL_EDITOR_ROLE_SEND_ERROR',
    COMMERCIAL_DEALER_ROLE_SEND: 'COMMERCIAL_DEALER_ROLE_SEND',
    COMMERCIAL_DEALER_ROLE_SEND_ERROR: 'COMMERCIAL_DEALER_ROLE_SEND_ERROR',
    // SUBJECT SETTINGS
    COMMERCIAL_SUBJECT_SETTINGS_ERROR: 'COMMERCIAL_SUBJECT_SETTINGS_ERROR',
    COMMERCIAL_SUBJECT_SETTINGS_SEND: 'COMMERCIAL_SUBJECT_SETTINGS_SEND',
    COMMERCIAL_SUBJECT_SETTINGS_SEND_ERROR: 'COMMERCIAL_SUBJECT_SETTINGS_SEND_ERROR',
    // TICKET SETTINGS
    COMMERCIAL_TICKET_SETTINGS_ERROR: 'COMMERCIAL_TICKET_SETTINGS_ERROR',
    COMMERCIAL_TICKET_SETTINGS_SEND: 'COMMERCIAL_TICKET_SETTINGS_SEND',
    COMMERCIAL_TICKET_SETTINGS_SEND_ERROR: 'COMMERCIAL_TICKET_SETTINGS_SEND_ERROR',
    // WORKING HOURS
    COMMERCIAL_WORKING_HOURS_ACCEPTED: 'COMMERCIAL_WORKING_HOURS_ACCEPTED',
    COMMERCIAL_WORKING_HOURS_ERROR: 'COMMERCIAL_WORKING_HOURS_ERROR',
    COMMERCIAL_WORKING_HOURS_SEND: 'COMMERCIAL_WORKING_HOURS_SEND',
    COMMERCIAL_WORKING_HOURS_SEND_ERROR: 'COMMERCIAL_WORKING_HOURS_SEND_ERROR',

    // ATTACHMENTS
    COMMERCIAL_ATTACHMENTS_SAVED: 'COMMERCIAL_TICKET_ATTACHMENTS_SAVED',
    COMMERCIAL_ATTACHMENTS_SAVED_ERROR: 'COMMERCIAL_ATTACHMENTS_SAVED_ERROR',
    COMMERCIAL_GENERATED_PRESIGNED_URLS: 'COMMERCIAL_GENERATED_PRESIGNED_URLS',
    COMMERCIAL_ATTACHMENTS_DOWNLOAD: 'COMMERCIAL_TICKET_ATTACHMENTS_DOWNLOAD',
    // DEALERS
    COMMERCIAL_DEALERS_SEND: 'COMMERCIAL_DEALERS_FETCH_SUCCESS',
    COMMERCIAL_DEALERS_SEND_ERROR: 'COMMERCIAL_DEALERS_FETCH_ERROR',
    // EXPORT
    COMMERCIAL_EXPORT_SEND: 'COMMERCIAL_EXPORT_FETCH_SUCCESS',
    COMMERCIAL_EXPORT_SEND_ERROR: 'COMMERCIAL_EXPORT_FETCH_ERROR',
    COMMERCIAL_EXPORT_STATUS: 'COMMERCIAL_EXPORT_STATUS',
    COMMERCIAL_EXPORT_ERROR: 'COMMERCIAL_EXPORT_ERROR',
    COMMERCIAL_EXPORT_DOWNLOAD: 'COMMERCIAL_EXPORT_DOWNLOAD',
    // FULL TEXT SEARCH
    COMMERCIAL_FULL_TEXT_SEARCH_FETCH_SUCCESS: 'COMMERCIAL_FULL_TEXT_SEARCH_FETCH_SUCCESS',
    COMMERCIAL_FULL_TEXT_SEARCH_SEND_ERROR: 'COMMERCIAL_TICKET_FULL_TEXT_SEARCH_SEND_ERROR',
    // HISTORY
    COMMERCIAL_HISTORY_SEND: 'COMMERCIAL_TICKET_HISTORY_SEND',
    COMMERCIAL_HISTORY_SEND_ERROR: 'COMMERCIAL_TICKET_HISTORY_SEND_ERROR',
    // LIST
    COMMERCIAL_LIST_SEND: 'COMMERCIAL_TICKET_LIST_SEND',
    COMMERCIAL_LIST_SEND_ERROR: 'COMMERCIAL_TICKET_LIST_SEND_ERROR',
    // LOCK TICKET
    COMMERCIAL_TICKET_LOCKED: 'COMMERCIAL_TICKET_LOCKED',
    COMMERCIAL_TICKET_LOCKED_ERROR: 'COMMERCIAL_TICKET_LOCKED_ERROR',
    COMMERCIAL_TICKET_LOCKED_FATAL: 'COMMERCIAL_TICKET_LOCKED_FATAL',
    // PREFERENCES
    COMMERCIAL_PREFERENCES_ACCEPTED: 'COMMERCIAL_PREFERENCES_ACCEPTED',
    COMMERCIAL_PREFERENCES_ERROR: 'COMMERCIAL_PREFERENCES_ERROR',
    COMMERCIAL_PREFERENCES_SEND: 'COMMERCIAL_PREFERENCES_SEND',
    COMMERCIAL_PREFERENCES_SEND_ERROR: 'COMMERCIAL_PREFERENCES_SEND_ERROR',
    COMMERCIAL_NOTIFICATIONS_PREFERENCES_SEND: 'COMMERCIAL_NOTIFICATIONS_PREFERENCES_SEND',
    COMMERCIAL_NOTIFICATIONS_PREFERENCES_SEND_ERROR: 'COMMERCIAL_NOTIFICATIONS_PREFERENCES_SEND_ERROR',
    // STATISTICS
    COMMERCIAL_STATISTICS_SEND: 'COMMERCIAL_STATISTICS_SEND',
    COMMERCIAL_STATISTICS_SEND_ERROR: 'COMMERCIAL_STATISTICS_SEND_ERROR',
    // TICKET
    COMMERCIAL_TICKET_SEND: 'COMMERCIAL_TICKET_SEND',
    COMMERCIAL_TICKET_CREATED: 'COMMERCIAL_TICKET_CREATED',
    COMMERCIAL_TICKET_UPDATED: 'COMMERCIAL_TICKET_UPDATED',
    COMMERCIAL_TICKET_UPDATE_FATAL: 'COMMERCIAL_TICKET_UPDATE_FATAL',
    COMMERCIAL_TICKET_CREATE_FATAL: 'COMMERCIAL_TICKET_CREATE_FATAL',
    COMMERCIAL_TICKET_SEND_ERROR: 'COMMERCIAL_TICKET_SEND_ERROR',
};
