import { HOME_SELECT_GROUP } from '../../actions/home/actionHome';

export const home = (state = {
    selectedCountryGroup: {},
}, action) => {
    switch (action.type) {
        case HOME_SELECT_GROUP: {
            const { countryGroup, domain } = action.payload;
            const { selectedCountryGroup } = state;
            return Object.assign({}, state, {
                selectedCountryGroup: {
                    ...selectedCountryGroup,
                    [domain]: countryGroup,
                }
            });
        }
        default: {
            return state;
        }
    }
};
