import React from 'react';
import {FormattedMessage} from 'react-intl';
import formTypeMessages from '../../intl/modules/formTypeMessages';
import ValidFormattedMessage from './ValidFormattedMessage';
import errorMessages from '../../intl/common/errorMessages';
import PageHeader from './PageHeader';

const NoBirComponent = (props) => {
    const {type} = props;
    return (
        <div className="container-fluid">
            <PageHeader title={<ValidFormattedMessage message={type} intlMessages={formTypeMessages}/>}/>
            <div className="container-fluid">
                <FormattedMessage {...errorMessages.NO_DEALER_CODE}/>
            </div>
        </div>
    )
};

export default NoBirComponent;
