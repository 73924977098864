import {
    DISTRIBUTION_BIR_SELECT_GROUP,
    DISTRIBUTION_BIRS_LIST_SEND,
    DISTRIBUTION_BIRS_SET_LINES_PER_PAGE,
    DISTRIBUTION_BIRS_SET_TEXT_SEARCH,
    DISTRIBUTION_BIRS_FETCH_PENDING,
    DISTRIBUTION_BIRS_EDIT_RECORD,
    DISTRIBUTION_BIRS_SEND, DISTRIBUTION_BIRS_UPDATE_PENDING, DISTRIBUTION_BIRS_SET_SORTING,
} from '../../actions/admin/actionBUAssignment';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {computeSorting} from '../../utils/utils.js';

export const adminBUAssignment = (state = {
                                  page: '',
                                  totalCount: '',
                                  linesPerPage: 10,
                                  list: [],
                                  isLoading: true,
                                  sorting: {},
                                  fulltext: '',
                                  edit: -1,
                                  selectedCountryGroup: '',
                                  countryBU: [],
                              },
                              action) => {
        switch (action.type) {
            case distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_SHORT_LIST_SEND: {
                return Object.assign({}, state, {countryBU: action.payload.businessUnits});
            };
            case DISTRIBUTION_BIRS_FETCH_PENDING:
                return Object.assign({}, state, {
                    page: action.payload.page,
                    list: [],
                    isLoading: true,
                    edit: -1,
                });
            case DISTRIBUTION_BIRS_LIST_SEND: {
                return Object.assign({}, state, {
                    totalCount: action.payload.result.totalCount,
                    list: action.payload.result.items,
                    isLoading: false,
                    edit: -1,
                });
            }
            case DISTRIBUTION_BIRS_SEND: {
                const birs = state.list

                const newBIRSList = birs.map(obj => {
                    if (obj.id === action.payload.businessUnit.id) {
                        return {...obj, businessUnit: action.payload.businessUnit.businessUnit};
                    }

                    return obj;
                });

                return Object.assign({}, state, {
                    list: newBIRSList,
                    edit: -1,
                    isLoading: false,
                });
            }

            case DISTRIBUTION_BIRS_SET_TEXT_SEARCH: {
                return Object.assign({}, state, {fulltext: action.payload});
            }
            case DISTRIBUTION_BIRS_SET_SORTING: {
                const sorting = computeSorting(action.payload, state.sorting);
                return Object.assign({}, state, {sorting});
            }
            case DISTRIBUTION_BIRS_SET_LINES_PER_PAGE: {
                return Object.assign({}, state, {linesPerPage: action.payload});
            }
            case DISTRIBUTION_BIRS_EDIT_RECORD: {
                return Object.assign({}, state, {
                    edit: action.payload.index,
                });
            }
            // case CLIENT_IDS_CREATE_PENDING:
            //     return Object.assign({}, state, {
            //         isLoading: true,
            //     });
            case DISTRIBUTION_BIRS_UPDATE_PENDING:
                return Object.assign({}, state, {
                    isLoading: true,
                    index: action.payload.index,
                });
            // case CLIENT_IDS_UPDATE_RECORD: {
            //     const {bir8, region, index, clientId, destinationCode} = action.payload;
            //     let newArray = state.list.map(e => e.bir8 === bir8 ? {
            //         ...e,
            //         region,
            //     } : e);
            //     newArray[index].clientId = clientId;
            //     newArray[index].destinationCode = destinationCode;
            //     return Object.assign({}, state, {
            //         isLoading: false,
            //         edit: -1,
            //         list: newArray,
            //     });
            // }
            // case CLIENT_IDS_DELETE_PENDING:
            //     return Object.assign({}, state, {
            //         isLoading: true,
            //         index: action.payload.index,
            //     });
            // case CLIENT_IDS_DELETE_RECORD: {
            //     let newArray = state.list.slice();
            //     newArray.splice(action.payload.index, 1);
            //     return Object.assign({}, state, {
            //         isLoading: false,
            //         list: newArray,
            //     });
            // }
            case DISTRIBUTION_BIR_SELECT_GROUP: {
                return Object.assign({}, state, {selectedCountryGroup: action.payload});
            }
            default:
                return state;
        }
    }
;

