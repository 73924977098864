import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const createMarketingExport = (exportFilter) => (dispatch) => {
    dispatch({
        type: marketingWebsocketCommands.MARKETING_EXPORT_CREATE,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_EXPORT_CREATE,
        payload: {
            exportFilter
        },
    });
};

export const fetchMarketingExport = () => (dispatch) => {
    dispatch({
        type: marketingWebsocketCommands.MARKETING_EXPORT_GET,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_EXPORT_GET,
        payload: {},
    });
};

export const downloadMarketingExport = (exportRecord) => (dispatch) => {
    dispatch({
        type: marketingWebsocketCommands.MARKETING_EXPORT_DOWNLOAD_URL_GET,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_EXPORT_DOWNLOAD_URL_GET,
        payload: {exportRecord},
    });
};
