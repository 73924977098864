import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import get from 'get-value';
import {IconEdit, PrimaryIcon} from '../../common/Button';
import {fetchMarketingFullTextSearch} from '../../../actions/marketing/actionMarketingFullTextSearch';
import {editMarketingListButtonVisibility} from '../../../constants/marketingTicketRights';
import {countryLanguageMapping, formNameFromSubjectKey} from '../../../constants/Utils';
import {moduleRoles} from '../../../utils/roles';
import Table from '../../common/table/TicketTable';
import formMessages from '../../../intl/modules/formMessages';

class FullTextSearchMarketingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchDefaultPageSizeMA: 10,
            searchDefaultSortMA: [{id: 'TICKET_NUMBER', desc: true}],
        };
        this.handleFetchData = this.handleFetchData.bind(this);
    }

    handleFetchData = state => {
        this.props.fetchMarketingFullTextSearch(
            state.pageSize,
            state.sorted,
            state.cursor,
            state.isNextFromPivot,
            this.normalizeFiltered(state));
        this.setState({
            searchDefaultPageSizeMA: state.pageSize,
            searchDefaultSortMA: state.sorted,
        });
    };


    normalizeFiltered = () => {
        return this.props.searchDefaultFiltersMA
            .map(filterItem => {
                if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const {
            columns, data, pages, isLoading, isNextPage, isNextFromPivot, handleEditClick, userDetail,
            searchDefaultFiltersMA, subjects,
        } = this.props;
        const language = get(userDetail, 'settings.language', 'DEFAULT')
        const translatedData = data.map(oldData => ({
            ...oldData,
            subject: (subjects !== undefined && subjects !== null) ?
                countryLanguageMapping[oldData.group] === language ?
                    [get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, []).find(subject => subject.uuid === oldData.subject), `languages.local`, oldData.subject) === null ?
                        get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                            .find(subject => subject.uuid === oldData.subject), `languages.default`, oldData.subject) :
                        get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                            .find(subject => subject.uuid === oldData.subject), `languages.local`, oldData.subject)]
                    : get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                        .find(subject => subject.uuid === oldData.subject), `languages.default`, oldData.subject)
                : oldData.subject,
        }));

        return (
            <div className="container-fluid text-center mb-5">
                <div className="row">
                    <div className="col">
                        <Table
                            filterable={false}
                            columns={[{
                                id: 'TICKET_NUMBER',
                                accessor: 'ticketNumber',
                                filterable: false,
                                message: formMessages.TICKET_NUMBER,
                                Cell: row => <Link to={`view/${row.original.id}`}>{row.value}</Link>
                            }, ...columns]}
                            toolButtons={[{
                                handleClick: handleEditClick,
                                component: <PrimaryIcon className="btn btn-sm"
                                                        title="Edit"
                                                        type="button"
                                                        key="editButton">
                                    <IconEdit/>
                                </PrimaryIcon>
                            }]}
                            isLoading={isLoading}
                            data={translatedData}
                            pages={pages}
                            handleFetchData={this.handleFetchData}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={searchDefaultFiltersMA}
                            defaultSorting={this.state.searchDefaultSortMA}
                            defaultPageSize={this.state.searchDefaultPageSizeMA}
                            buttonVisibility={
                                editMarketingListButtonVisibility[moduleRoles.isDealerMarketing(userDetail.roles) ?
                                    'DEALER' : 'EDITOR']}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

FullTextSearchMarketingTable.propTypes = {
    columns: PropTypes.array.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    searchDefaultFiltersMA: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    fetchMarketingFullTextSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.marketingFullTextSearch.fullTextData,
    pages: state.marketingFullTextSearch.pages,
    isNextPage: state.marketingFullTextSearch.isNextPage,
    isNextFromPivot: state.marketingFullTextSearch.isNextFromPivot,
    isLoading: state.marketingFullTextSearch.isTableLoading,
    userDetail: state.profile.userDetail,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
});

export default connect(mapStateToProps, {
    fetchMarketingFullTextSearch
})(FullTextSearchMarketingTable)
