import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {COMMERCIAL_FORM, FORM_BUTTON_REOPEN, OFFER_FOR_EMPLOYEES_FORM} from '../../../constants/formConstants';
import {
    fetchCommercialTicket, resetCommercialTicket, updateCommercialTicket
} from '../../../actions/commercial/actionCommercialTicket';
import {
    lockCommercialTicket, resetRedirectAfterLockCommercialTicket
} from '../../../actions/commercial/actionCommercialLockTicket';
import {initLoadedCommercialTicket, transformUpdatedCommercialTicketForSave} from '../Utils';
import {formatTicketNumber} from '../../../utils/utils';
import {clearAlerts} from '../../../actions/alertsActions';
import {editRights} from '../../../constants/commercialTicketRights';
import {moduleRoles} from '../../../utils/roles';
import checkRoles from '../../common/roleChecker/RoleChecker';
import CommercialTicketMasterForm from './forms/CommercialTicketMasterForm';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import formMessages from '../../../intl/modules/formMessages';
import OfferForEmployeesForm from './forms/OfferForEmployeesForm';


class ViewCommercialTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToEdit: false,
            ticketSubmitted: false,
            storedTicketData: {},
        };
    }

    componentDidMount() {
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchCommercialTicket(this.props.match.params.ticketUuid)
        }
    }

    componentWillUnmount() {
        this.props.resetCommercialTicket();
        this.props.resetRedirectAfterLockCommercialTicket();
    }

    handleSubmit = (values) => {
        const {submitBy} = values;

        this.props.lockCommercialTicket(this.props.match.params.ticketUuid);
        this.setState({ticketSubmitted: true, storedTicketData: transformUpdatedCommercialTicketForSave(values)});
        if (submitBy === FORM_BUTTON_REOPEN) {
            this.setState({redirectToEdit: true});
        }
    };

    render() {
        const {ticketData, loggedUser, lockTicket, isLoadingSubjects} = this.props;
        const {ticketSubmitted, redirectToEdit, storedTicketData} = this.state;

        let type = false;
        if (get(ticketData, 'ticket.type', {default: false})) {
            type = ticketData.ticket.type;
        }

        if (!lockTicket.isTicketLocking && !lockTicket.redirect && ticketSubmitted && redirectToEdit) {
            this.props.updateCommercialTicket(storedTicketData);
            return <Redirect to={{
                pathname: `/commercial/edit/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        } else if (!lockTicket.isTicketLocking && !lockTicket.redirect && ticketSubmitted) {
            this.props.updateCommercialTicket(storedTicketData);
            this.setState({ticketSubmitted: false, storedTicketData: {}});
        }

        const initializationValue = initLoadedCommercialTicket(ticketData);
        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !type
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={type} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {type === COMMERCIAL_FORM &&
                    <CommercialTicketMasterForm form={type}
                                                initialValues={initializationValue}
                                                userRights={editRights[moduleRoles.isEditorCommercial(loggedUser) ?
                                                    'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                enableReinitialize={true}
                                                handleSubmit={this.handleSubmit}
                                                reqFields={[]}
                                                readOnly/>
                    }
                    {type === OFFER_FOR_EMPLOYEES_FORM &&
                    <OfferForEmployeesForm form={type}
                                                initialValues={initializationValue}
                                                userRights={editRights[moduleRoles.isEditorCommercial(loggedUser) ?
                                                    'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                enableReinitialize={true}
                                                handleSubmit={this.handleSubmit}
                                                reqFields={[]}
                                                readOnly/>
                    }
                </div>
            </div>
        );
    }
}

ViewCommercialTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    fetchCommercialTicket: PropTypes.func.isRequired,
    resetCommercialTicket: PropTypes.func.isRequired,
    resetRedirectAfterLockCommercialTicket: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.commercialTicket,
    lockTicket: state.commercialLockTicketCheck,
    userDetail: state.profile.userDetail,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchCommercialTicket,
    resetCommercialTicket,
    resetRedirectAfterLockCommercialTicket,
    lockCommercialTicket,
    updateCommercialTicket,
})(injectIntl(ViewCommercialTicketPage))), ['CC_DEALER', 'CC_EDITOR']);
