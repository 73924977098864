import Joi from 'joi';
import {countryGroups, domains, getFormTypes} from '../../constants/Utils';

export function validateUserDetail(userDetail) {
    return Joi.validate(
        userDetail,
        Joi.object().required().keys({
            ipn: Joi.string().required(),
            name: Joi.string().required(),
            roles: Joi.object().required().keys(getRolesSchema()),
            settings: Joi.object().keys(getSettingsSchema()),
            dealerName: Joi.any(),
            dealerNumber: Joi.any(),
            telNo: Joi.any(),
            group: Joi.any(),
            mail: Joi.string().required(),
            charSets: Joi.array().required()
        })
    )
}

export function validateAdminRoles(adminRoles) {
    return Joi.validate(
        adminRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}

export function validateEditorData(editorRoles) {
    return Joi.validate(
        editorRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}

export function validateExternalEditorData(externalEditorRoles) {
    return Joi.validate(
        externalEditorRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}

export function validateDealerRolesForDistribution(dealerRoles) {
    return Joi.validate(
        dealerRoles,
        Joi.object().required().keys(getDistributionFormsSchema())
    );
}

export function validateDealerRolesForCommercial(dealerRoles) {
    return Joi.validate(
        dealerRoles,
        Joi.object().required().keys(getCommercialFormsSchema())
    );
}

export function validateDealerRolesForMarketing(dealerRoles) {
    return Joi.validate(
        dealerRoles,
        Joi.object().required().keys(getMarketingFormsSchema())
    );
}

function getRolesSchema() {
    const result = {};
    result[domains.DISTRIBUTION] = Joi.object().keys({
        powerUser: Joi.object().max(0),
        admin: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        editor: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        externalEditor: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        dealer: Joi.object().keys({
            formsPermission: Joi.object().keys(getDistributionFormsSchema())
        }),
    });
    result[domains.COMMERCIAL] = Joi.object().keys({
        powerUser: Joi.object().max(0),
        admin: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        editor: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        dealer: Joi.object().keys({
            formsPermission: Joi.object().keys(getCommercialFormsSchema())
        }),
    });
    result[domains.MARKETING] = Joi.object().keys({
        powerUser: Joi.object().max(0),
        admin: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        editor: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),

        dealer: Joi.object().keys({
            formsPermission: Joi.object().keys(getMarketingFormsSchema())
        }),
    });
    return result;
}

function getDistributionFormsSchema() {
    const result = {};
    const types = getFormTypes(domains.DISTRIBUTION);
    types.forEach(type => result[type] = Joi.boolean().required());
    return result;
}

function getCommercialFormsSchema() {
    const result = {};
    const types = getFormTypes(domains.COMMERCIAL);
    types.forEach(type => result[type] = Joi.boolean());
    return result;
}

function getMarketingFormsSchema() {
    const result = {};
    const types = getFormTypes(domains.MARKETING);
    types.forEach(type => result[type] = Joi.boolean().required());
    return result;
}

function getCountryGroupsSchema() {
    const result = {};
    Object.keys(countryGroups).forEach(countryGroup => result[countryGroups[countryGroup]] = Joi.boolean().required());
    return result;
}

export function getSettingsSchema() {
    return {
        language: Joi.string(),
        locale: Joi.string(),
    };
}
