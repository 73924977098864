import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    editSubjectSettings, removeSubjectSettings, updateSubjectSettings
} from '../../../actions/admin/actionAdminSubjectSettings';
import {IconCancel, IconDelete, IconEdit, IconSave, PrimaryIcon} from '../../common/Button';
import {MessageCell, StyledSmall} from '../../common/StyledComponents';
import ConfirmationDialog from '../../common/modal/ConfirmationDialog';

class SubjectSettingsRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueDefault: this.props.data.languages.default,
            valueLocal: this.props.data.languages.local,
            vinObligation: this.props.data.vinObligation,
            refNoObligation: this.props.data.refNoObligation,
            isOpenRemoveDialog: false
        }
    }

    onClickPencil = e => {
        const {adminSubjectSettings, index, data, updateSubjectSettings, editSubjectSettings, subjectKey, domain} = this.props;
        const {valueDefault, valueLocal, vinObligation, refNoObligation} = this.state;

        e.preventDefault();
        if ((adminSubjectSettings.edit === index) &&
            ((valueDefault !== data.languages.default) || (valueLocal !== data.languages.local) || (vinObligation !== data.vinObligation) || (refNoObligation !== data.refNoObligation))) {
            const subject = {
                group: adminSubjectSettings.selectedCountryGroup,
                uuid: data.uuid,
                languages: {default: valueDefault, local: valueLocal || ''},
                subjectKey: subjectKey
            };
            if(['commercial', 'distribution'].includes(subjectKey)){
                subject['vinObligation'] = vinObligation;
            };
            if (['marketingQuestion', 'priceVerification', 'nameVerification'].includes(subjectKey)){
                subject['refNoObligation'] = refNoObligation;
            };
            updateSubjectSettings(domain, subject);
        } else if (adminSubjectSettings.edit === index) {
            editSubjectSettings(-1);
        } else {
            this.setState({
                vinObligation: data.vinObligation,
                refNoObligation: data.refNoObligation,
                valueDefault: data.languages.default,
                valueLocal: data.languages.local
            });
            editSubjectSettings(index);
        }
    };

    onCancel = e => {
        e.preventDefault();
        this.setState({
            valueDefault: this.props.data.languages.default,
            valueLocal: this.props.data.languages.local,
            vinObligation: this.props.data.vinObligation,
            refNoObligation: this.props.data.refNoObligation,
        });
        this.props.editSubjectSettings(-1);
    };

    onClickDelete = () => {
        const subject = {
            group: this.props.adminSubjectSettings.selectedCountryGroup,
            uuid: this.props.data.uuid,
            subjectKey: this.props.subjectKey,
        };
        this.props.removeSubjectSettings(this.props.domain, subject);
    };

    onChangeHandlerDefault = e => {
        e.preventDefault();
        this.setState({valueDefault: e.target.value});
    };

    onChangeHandlerLocal = e => {
        e.preventDefault();
        this.setState({valueLocal: e.target.value});
    };

    onClickVinObligation = e => {
        this.setState({vinObligation: e.target.checked});
    };

    onClickRefNoObligation = e => {
        this.setState({refNoObligation: e.target.checked});
    };

    showRemoveModal = () => {
        this.setState({isOpenRemoveDialog: true});
    };

    closeModal = e => {
        e.preventDefault();
        this.setState({isOpenRemoveDialog: false});
    };

    saveModal = e => {
        e.preventDefault();
        this.setState({isOpenRemoveDialog: false});
        this.onClickDelete();
    };

    renderHandler(provided) {
        return (
            <td className="col-1"
                {...provided.dragHandleProps}>
                {
                    (this.props.adminSubjectSettings.edit === -1) &&
                    <FontAwesomeIcon icon="arrows-alt-v"/>
                }
            </td>
        )
    }

    renderSubjectDefault(index) {
        if (this.props.adminSubjectSettings.edit === index) {
            return (
                <StyledSmall
                    className="form-control"
                    value={this.state.valueDefault}
                    placeholder="Enter your text in english..."
                    onChange={this.onChangeHandlerDefault}/>
            );
        } else {
            return this.props.data.languages.default;
        }
    }

    renderSubjectLocal(index) {
        if (this.props.adminSubjectSettings.edit === index) {
            return (
                <StyledSmall
                    className="form-control"
                    value={this.state.valueLocal}
                    placeholder="Enter text in local language..."
                    onChange={this.onChangeHandlerLocal}/>
            );
        } else {
            return this.props.data.languages.local;
        }
    }

    renderVin(index) {
        return (
            <input
                type="checkbox"
                checked={this.props.adminSubjectSettings.edit === index ? this.state.vinObligation : this.props.data.vinObligation}
                onClick={this.onClickVinObligation}
                disabled={this.props.adminSubjectSettings.edit !== index}
            />
        );
    }

    renderRefNo(index) {
        return (
            <input
                type="checkbox"
                checked={this.props.adminSubjectSettings.edit === index ? this.state.refNoObligation : this.props.data.refNoObligation}
                onClick={this.onClickRefNoObligation}
                disabled={this.props.adminSubjectSettings.edit !== index}
            />
        );
    }

    renderTools(index) {
        const {valueDefault} = this.state;
        let buttons;

        if (this.props.adminSubjectSettings.edit === index) {
            buttons =
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group" role="group" aria-label="First group">
                        <PrimaryIcon className="btn btn-sm m-sm-1"
                                     onClick={this.onClickPencil}
                                     title="Save"
                                     disabled={!valueDefault || (!!valueDefault && valueDefault.trim().length === 0)}
                                     type="button">
                            <IconSave/>
                        </PrimaryIcon>
                    </div>
                    <div className="btn-group" role="group" aria-label="Second group">
                        <PrimaryIcon className="btn btn-sm m-sm-1"
                                     onClick={this.onCancel}
                                     title="Cancel"
                                     type="button">
                            <IconCancel/>
                        </PrimaryIcon>
                    </div>
                </div>
        } else {
            buttons =
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group" role="group" aria-label="First group">
                        <PrimaryIcon className="btn btn-sm m-sm-1"
                                     onClick={this.onClickPencil}
                                     title="Edit"
                                     type="button">
                            <IconEdit/>
                        </PrimaryIcon>
                    </div>
                    <div className="btn-group" role="group" aria-label="Second group">
                        <PrimaryIcon className="btn btn-sm m-sm-1"
                                     onClick={this.showRemoveModal}
                                     title="Delete"
                                     type="button">
                            <IconDelete/>
                        </PrimaryIcon>
                    </div>
                </div>
        }
        return buttons;
    }

    render() {
        const {provided, index, subjectKey} = this.props;
        return (
            <tr className="d-flex" ref={provided.innerRef} {...provided.draggableProps}>
                {this.renderHandler(provided)}
                <MessageCell className="col-4">
                    {this.renderSubjectDefault(index)}</MessageCell>
                <MessageCell className="col">
                    {this.renderSubjectLocal(index)}</MessageCell>
                {['commercial', 'distribution'].includes(subjectKey) &&
                    <td className="col-2 col-md-1">
                        {this.renderVin(index)}</td>
                }
                {['marketingQuestion', 'priceVerification', 'nameVerification'].includes(subjectKey) &&
                    <td className="col-2 col-md-1">
                        {this.renderRefNo(index)}</td>
                }
                <td className="col-2">
                    {this.renderTools(index)}</td>
                <ConfirmationDialog show={this.state.isOpenRemoveDialog}
                                    closeModal={this.closeModal}
                                    saveModal={this.saveModal}
                                    actionIdentifier="remove the subject"/>
            </tr>
        )
    }
}

SubjectSettingsRow.propTypes = {
    languages: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    provided: PropTypes.object.isRequired,
    adminSubjectSettings: PropTypes.object.isRequired,
    removeSubjectSettings: PropTypes.func.isRequired,
    editSubjectSettings: PropTypes.func.isRequired,
    updateSubjectSettings: PropTypes.func.isRequired,
    subjectKey: PropTypes.any,
};

const mapStateToProps = state => ({
    adminSubjectSettings: state.adminSubjectSettings,
});

export default connect(mapStateToProps, {
    removeSubjectSettings,
    editSubjectSettings,
    updateSubjectSettings
})(injectIntl(SubjectSettingsRow))
