import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {MARKETING_TICKET_RESET} from '../../actions/marketing/actionMarketingTicket';
import {
    MARKETING_ATTACHMENTS_UPLOADING,
    MARKETING_ATTACHMENTS_UPLOADING_ERROR
} from '../../actions/marketing/actionMarketingAttachment';

export const marketingAttachment = (state = {
    correlationId: null,
    isTicketAttachmentUploading: false,
    attachmentsForUpload: [],
}, action) => {
    switch (action.type) {
        case MARKETING_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        case marketingWebsocketCommands.MARKETING_GENERATED_PRESIGNED_URLS: {
            const {attachments} = action.payload;
            return Object.assign({}, state, {
                attachmentsForUpload: Array.isArray(attachments) ? attachments : [],
            });
        }
        case MARKETING_ATTACHMENTS_UPLOADING: {
            return Object.assign({}, state, {isTicketAttachmentUploading: true});
        }
        //att done + error
        case marketingWebsocketCommands.MARKETING_ATTACHMENTS_SAVED_ERROR:
        case MARKETING_ATTACHMENTS_UPLOADING_ERROR: {
            return Object.assign({}, state, {
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        case marketingWebsocketCommands.MARKETING_ATTACHMENTS_SAVED: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        default:
            return state;
    }
};
