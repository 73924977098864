import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const DISTRIBUTION_HISTORY_FETCH = 'DISTRIBUTION_HISTORY_FETCH';

export const fetchDistributionHistory = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: DISTRIBUTION_HISTORY_FETCH,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_HISTORY_GET,
        payload: {
            pageSize: pageSize,
            sorted: sorted,
            cursor: cursor,
            isNextFromPivot: isNextFromPivot,
            filtered: filtered
        }
    });
};
