import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../../Loader';
import { FORM_BUTTON_EDIT, BUSINESS_UNIT_FORM } from '../../../../constants/formConstants';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH,
} from '../../../../routes/paths';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    fetchBusinessUnit,
    resetBusinessUnit,
    updateBusinessUnit
} from '../../../../actions/admin/actionBusinessUnit';
import { initLoadedBusinessUnit } from '../../../../utils/businessUnitUtils';
import BusinessUnitForm from './forms/BusinessUnitForm';
import menuMessages from '../../../../intl/common/menuMessages';

class ViewBusinessUnitPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToNew: false,
            redirectToEdit: false,
            businessUnitSubmitted: false,
            hasToBeUpdated: false,
            storedBusinessUnitData: {},
            newInitData: {},
        };
    }

    componentDidMount() {
        this.props.clearAlerts();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchBusinessUnit(this.props.domain, this.props.match.params.businessUnitUuid, this.props.userDetail.ipn);
        }
    }

    componentWillUnmount() {
        this.props.resetBusinessUnit();
    }

    handleSubmit = (values) => {
        const { submitBy } = values;

        switch (submitBy) {
            case FORM_BUTTON_EDIT: {
                this.setState({
                    businessUnitSubmitted: true,
                    redirectToEdit: true,
                });
                break;
            }
            default: {
            }
        }
    };

    render() {
        const {
            businessUnitData,
            domain,
            intl: {formatMessage},
        } = this.props;

        let formType = false;
        if (get(businessUnitData, 'businessUnit.name', { default: false })) {
            formType = BUSINESS_UNIT_FORM;
        }


        if (this.state.businessUnitSubmitted && this.state.redirectToEdit) {
            return <Redirect to={{
                pathname: `${ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH}/edit/${businessUnitData.businessUnit.id}`,
            }}/>;
        } else if (this.state.businessUnitSubmitted) {
            this.props.updateBusinessUnit(domain, this.state.storedBusinessUnitData);
            this.setState({ businessUnitSubmitted: false, storedBusinessUnitData: {}, hasToBeUpdated: false });
        }

        const initializationValue = initLoadedBusinessUnit(businessUnitData, formatMessage);
        if (
            businessUnitData.isBusinessUnitLoading
            || businessUnitData.isBusinessUnitCreating
            || !formType
        ) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.businessUnit.title"
                                      defaultMessage="Business Unit Management - {domain} - Business Unit"
                                      values={{ domain: <FormattedMessage {...menuMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <BusinessUnitForm
                            form={formType}
                            initialValues={initializationValue}
                            enableReinitialize={true}
                            reqFields={[]}
                            domain={domain}
                            readOnly
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    businessUnitData: state.adminBusinessUnit,
    userDetail: state.profile.userDetail,
});

ViewBusinessUnitPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    businessUnitData: PropTypes.object.isRequired,
    fetchBusinessUnit: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    updateBusinessUnit: PropTypes.func.isRequired,
    resetBusinessUnit: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchBusinessUnit,
    clearAlerts,
    resetBusinessUnit,
    updateBusinessUnit,
})(injectIntl(ViewBusinessUnitPage))), ['DB_ADMIN']);

