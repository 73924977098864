import {
    CONNECT_ADMIN_WEBSOCKET_ERROR,
    CONNECT_ADMIN_WEBSOCKET_PENDING,
    CONNECT_ADMIN_WEBSOCKET_SUCCESS,
    CONNECT_DISTRIBUTION_WEBSOCKET_ERROR,
    CONNECT_DISTRIBUTION_WEBSOCKET_PENDING,
    CONNECT_DISTRIBUTION_WEBSOCKET_SUCCESS,
    CONNECT_COMMERCIAL_WEBSOCKET_ERROR,
    CONNECT_COMMERCIAL_WEBSOCKET_PENDING,
    CONNECT_COMMERCIAL_WEBSOCKET_SUCCESS,
    CONNECT_MARKETING_WEBSOCKET_ERROR,
    CONNECT_MARKETING_WEBSOCKET_SUCCESS,
    CONNECT_MARKETING_WEBSOCKET_PENDING,
    CONNECT_APIGEE_WEBSOCKET_ERROR,
    CONNECT_APIGEE_WEBSOCKET_PENDING,
    CONNECT_APIGEE_WEBSOCKET_SUCCESS,
} from '../actions/actionWebsocket';
import {cloneDeep} from 'lodash';
import {modules} from '../constants/Utils';

export const websocket = (state = {
    isConnected: {},
}, action) => {
    switch (action.type) {
        case CONNECT_ADMIN_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ADMINISTRATION] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_ADMIN_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ADMINISTRATION] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_ADMIN_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ADMINISTRATION] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_DISTRIBUTION_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.DISTRIBUTION] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_DISTRIBUTION_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.DISTRIBUTION] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_DISTRIBUTION_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.DISTRIBUTION] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_COMMERCIAL_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.COMMERCIAL] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_COMMERCIAL_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.COMMERCIAL] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_COMMERCIAL_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.COMMERCIAL] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_MARKETING_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.MARKETING] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_MARKETING_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.MARKETING] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_MARKETING_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.MARKETING] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_APIGEE_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.APIGEE] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_APIGEE_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.APIGEE] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_APIGEE_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.APIGEE] = false;
            return Object.assign({}, state, {isConnected});
        }
        default:
            return state;
    }
};
