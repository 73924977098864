import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Async from 'react-select/async';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    FIELD_ASSIGNED_TO,
    TICKET_SECTION
} from '../../../../../../constants/formConstants';
import {Label} from '../../../../../common/StyledComponents';
import formMessages from '../../../../../../intl/modules/formMessages';
import {DEFAULT_DEBOUNCE_TIMEOUT} from '../../../../../../constants/validationConstants';
import {debounce} from 'throttle-debounce';
import {handleEditorSearch} from '../../../../../../utils/editorUtils';
import {handleFetchUsersError} from '../../../../../../actions/actionRole';
import {domains} from '../../../../../../constants/Utils';
import {change, formValueSelector, getFormInitialValues, getFormValues} from 'redux-form';


const domain = domains.DISTRIBUTION;

class AssigneeSelect extends Component {

    loadOptions = (input, callBack) => {
        const {handleFetchUsersError, group} = this.props;
        handleEditorSearch(input, callBack, handleFetchUsersError, domain, group);
    };

    selectUser = user => {
        const {change} = this.props;
        change(`${TICKET_SECTION}.${FIELD_ASSIGNED_TO}`, {value: user.ipn, label: user.label});


    };

    render() {

        const {initialValues, handleUserSelection, handleUserSearch, intl: {formatMessage}, readOnly, selectedEditor = '', group} = this.props;

        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                background: '#fff',
                borderColor: '#ced4da',
                minHeight: '30px',
                height: '30px',
                boxShadow: state.isFocused ? null : null,
                margin:0,
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: 'Black'
            }),

            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
        };

        const customDisabledStyles = {
            control: (provided, state) => ({
                ...provided,
                background: '#e9ecef',
                borderColor: '#ced4da',
                minHeight: '30px',
                height: '30px',
                boxShadow: state.isFocused ? null : null,
                margin:0,
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px',
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: 'Black'
            }),

            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
        };


        return (
            <div className="container-fluid" style={{paddingLeft:'0px'}}>
                <div className="row">
                    <Label style={{marginTop:'5px', paddingLeft:'15px'}}>
                        <FormattedMessage {...formMessages.ASSIGNED_TO}/>
                    </Label>
                </div>
                <div className="form-row">
                    <div className="col">
                        <Async
                            field={FIELD_ASSIGNED_TO}
                            value={selectedEditor}
                            loadOptions={this.loadOptions}
                            onChange={this.selectUser}
                            isDisabled={readOnly}
                            styles={readOnly ? customDisabledStyles : customStyles}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

AssigneeSelect.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleUserSelection: PropTypes.func.isRequired,
    handleUserSearch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
};

const mapStateToProps = (state, initialProps) => ({
    selectedEditor: formValueSelector(initialProps.form)(state, `${TICKET_SECTION}.${FIELD_ASSIGNED_TO}`),
    group: formValueSelector(initialProps.form)(state, `${TICKET_SECTION}.group`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
    handleFetchUsersError,
});

export default connect(mapStateToProps, mapDispatchToProps)((injectIntl(AssigneeSelect)))
