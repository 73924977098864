import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    ALL: {
        id: 'common.all',
        defaultMessage: 'All'
    },
    DEALER: {
        id: 'common.role.dealer',
        defaultMessage: 'Dealer'
    },
    EDITOR: {
        id: 'common.role.editor',
        defaultMessage: 'Editor'
    },
    ADMIN: {
        id: 'common.role.admin',
        defaultMessage: 'Admin'
    },
    DROP_ZONE_OVER_FILE: {
        id: 'common.dropZone.overFile',
        defaultMessage: 'Drop files here. (max 100 MB)'
    },
    DROP_ZONE: {
        id: 'common.dropZone',
        defaultMessage: 'Click or drag and drop a file here. (max 100 MB)'
    },
    PAGE_DOES_NOT_EXIST: {
        id: 'common.invalid.page',
        defaultMessage: 'This page does not exist. If it should exist please contact support.'
    },
    UNAUTHORIZED: {
        id: 'common.unauthorized',
        defaultMessage: 'You don\'t have a permission to access this page.'
    },
    CONTACT_ADMIN: {
        id: 'common.contactAdministrator',
        defaultMessage: 'Please contact your administrator to assign you appropriate rights.'
    },
    SEARCHING: {
        id: 'common.searching',
        defaultMessage: 'Searching...'
    }
});

export default messages;
