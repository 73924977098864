import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import workingHoursMessages from '../../../intl/admin/workingHoursMessages';
import {connect} from 'react-redux';
import {updateBankHolidays,} from '../../../actions/admin/actionAdminWorkingHours';
import DatePickerCommon from '../../common/datePicker/DatePickerCommon';
import Select from 'react-select';
import * as moment from 'moment';

const createOption = (label) => ({
    label,
    value: label,
});

const components = {
    DropdownIndicator: null,
};

class BankHolidays extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: (this.props.bankValues || []).map(e => ({
                value: e.date,
                label: e.date
            })),
            selectedDates: (this.props.bankValues || []).map(e => new Date(e.date)),
        };
    }

    handleChange = (date) => {
        const {options, selectedDates} = this.state;
        const parseDate = moment(date).format('YYYY-MM-DD')
        if (options.find(e => e.value === parseDate)) {
            const index = options.findIndex(e => e.value === parseDate);
            options.splice(index, 1)
            this.setState({
                selectedDates: (options || []).map(e => new Date(e.value)),
                options: [...options],
            })
        } else {
            this.setState({
                selectedDates: [...selectedDates, date],
                options: [...options, createOption(parseDate)],
            });
            const updatedValues = (options || []).map(e => ({date: e.value}))
            updatedValues.push({date: parseDate});
            this.props.updateBankHolidays({days: updatedValues});
        }
    };

    handleSelectorChange = (value) => {
        this.setState({
            options: (value || []),
            selectedDates: (value || []).map(e => new Date(e.value)),
        });
        this.props.updateBankHolidays({days: (value || []).map(e => ({date: e.value}))});
    };

    render() {
        const newHighlightDatesFormat = [{'react-datepicker__day--highlighted-custom-3': this.state.selectedDates}]
        return (
            <div>
                <label className="col-form-label p-0 m-0 mt-2 font-weight-bold">
                    <FormattedMessage {...workingHoursMessages.BANK_HOLIDAYS_TITLE}/>
                </label>
                <hr/>
                <DatePickerCommon
                    isClearable={false}
                    selected={null}
                    onChange={this.handleChange}
                    className={'form-control form-control-sm'}
                    highlightDates={newHighlightDatesFormat}
                    onBlur={this.handleOnBlur}
                    inline/>
                <Select
                    inputValue={''}
                    isClearable
                    components={components}
                    isMulti
                    menuIsOpen={false}
                    placeholder=''
                    value={this.state.options}
                    onChange={this.handleSelectorChange}/>
            </div>
        );
    }
}

BankHolidays.propTypes = {
    group: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    bankValues: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    data: state.adminWorkingHours.data,
});

export default connect(mapStateToProps, {
    updateBankHolidays
})(BankHolidays);
