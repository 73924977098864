import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {addLocaleData} from 'react-intl';
import thunk from 'redux-thunk';
import csLocaleData from 'react-intl/locale-data/cs';
import skLocaleData from 'react-intl/locale-data/sk';
import enLocaleData from 'react-intl/locale-data/en';
import deLocaleData from 'react-intl/locale-data/de';
import frLocaleData from 'react-intl/locale-data/fr';
import daLocaleData from 'react-intl/locale-data/da';
import esLocaleData from 'react-intl/locale-data/es';
import hrLocaleData from 'react-intl/locale-data/hr';
import huLocaleData from 'react-intl/locale-data/hu';
import itLocaleData from 'react-intl/locale-data/it';
import nlLocaleData from 'react-intl/locale-data/nl';
import plLocaleData from 'react-intl/locale-data/pl';
import ptLocaleData from 'react-intl/locale-data/pt';
import roLocaleData from 'react-intl/locale-data/ro';
import slLocaleData from 'react-intl/locale-data/sl';
import srLocaleData from 'react-intl/locale-data/sr';
import svLocaleData from 'react-intl/locale-data/sv';
import initialState from './initialState';
import history from '../history.js';
import {routerMiddleware} from 'react-router-redux';
import metisMenuReducer from 'react-metismenu/lib/reducers';
import createSagaMiddleware from 'redux-saga'
import {
    createAdminSocketMiddleware,
    createApigeeSocketMiddleware,
    createCommercialSocketMiddleware,
    createDistributionSocketMiddleware,
    createMarketingSocketMiddleware,
} from './socketMiddleware';
import {intl} from '../reducers/intlReducer';
import {menu} from '../reducers/menuReducer';
import {profile} from '../reducers/roleReducer';
import {adminRightsManagement} from '../reducers/admin/adminRightsManagementReducer';
import {alerts} from '../reducers/alertReducer';
import {websocket} from '../reducers/websocketReducer';
import {supportModal} from '../reducers/supportReducer';
import {reducer as form} from 'redux-form';
import {
    distributionListOrderLockTicketCheck,
    distributionList
} from '../reducers/distribution/distributionListReducer';
import {distributionFullTextSearch} from '../reducers/distribution/distributionFullTextSearchReducer';
import {distributionPreferences} from '../reducers/distribution/distributionPreferencesReducer';
import {adminTicketSettings} from '../reducers/admin/adminTicketSettingsReducer';
import {home} from '../reducers/home/homeReducer';
import {distributionDealer} from '../reducers/distribution/distributionDealersReducer';
import {distributionHistory} from '../reducers/distribution/distributionHistoryReducer';
import {distributionTicket, distributionLockTicketCheck} from '../reducers/distribution/distributionTicketReducer';
import {distributionAttachment} from '../reducers/distribution/distributionAttachmentReducer';
import rightsManagementSaga from '../sagas/rightsManagementSaga';
import {adminNotification} from '../reducers/admin/adminNotificationReducer';
import {adminSubjectSettings} from '../reducers/admin/adminSubjectSettingsReducer';
import {distributionExportTickets} from '../reducers/distribution/distributionExportReducer';
import distributionTicketSaga from '../sagas/distributionTicketSaga';
import {distributionStatistics} from '../reducers/distribution/distributionStatisticsReducer';
import {dhsData} from '../reducers/dhsReducer';
import {adminFrequentQuestion} from '../reducers/admin/adminFrequentQuestionReducer';
import {adminPresetResponse} from '../reducers/admin/adminPresetResponseReducer';
import {mainFiles} from '../reducers/fileReducer';
import frequentQuestionFileSaga from '../sagas/frequentQuestionFileSaga';
import supportModalSaga from '../sagas/supportModalSaga';
import marketingTicketSaga from '../sagas/marketingTicketSaga';
import {marketingExportTickets} from '../reducers/marketing/marketingExportReducer';
import {marketingListOrderLockTicketCheck, marketingList} from '../reducers/marketing/marketingListReducer';
import {marketingFullTextSearch} from '../reducers/marketing/marketingFullTextSearchReducer';
import {marketingPreferences} from '../reducers/marketing/marketingPreferencesReducer';
import {marketingDealer} from '../reducers/marketing/marketingDealersReducer';
import {marketingHistory} from '../reducers/marketing/marketingHistoryReducer';
import {marketingLockTicketCheck, marketingTicket} from '../reducers/marketing/marketingTicketReducer';
import {marketingAttachment} from '../reducers/marketing/marketingAttachmentReducer';
import {marketingStatistics} from '../reducers/marketing/marketingStatisticsReducer';
import {adminWorkingHours} from '../reducers/admin/adminWorkingHoursReducer';
import {commercialStatistics} from '../reducers/commercial/commercialStatisticsReducer';
import {commercialLockTicketCheck, commercialTicket} from '../reducers/commercial/commercialTicketReducer';
import {commercialAttachment} from '../reducers/commercial/commercialAttachmentReducer';
import {commercialHistory} from '../reducers/commercial/commercialHistoryReducer';
import {commercialDealer} from '../reducers/commercial/commercialDealersReducer';
import {commercialPreferences} from '../reducers/commercial/commercialPreferencesReducer';
import {commercialList, commercialListOrderLockTicketCheck} from '../reducers/commercial/commercialListReducer';
import commercialTicketSaga from '../sagas/commercialTicketSaga';
import {commercialFullTextSearch} from '../reducers/commercial/commercialFullTextSearchReducer';
import {asdData} from '../reducers/asdReducer';
import {commercialExportTickets} from '../reducers/commercial/commercialExportReducer';
import {adminBusinessUnit} from '../reducers/admin/adminBusinessUnitReducer';
import {adminBusinessUnitList} from '../reducers/admin/adminBusinessUnitListReducer';
import {adminBUAssignment} from '../reducers/admin/adminBUAssignmentReducer';

/**
 * Configuration of store, reducers, middlewares, enhancers management.
 */
const configureStore = (initialStoreContent = {}) => {
    const persistedState = Object.assign(initialState, initialStoreContent);
    const middlewares = process.env.NODE_ENV !== 'production' ?
        [require('redux-immutable-state-invariant').default(), thunk] :
        [thunk];
    const enhancers = [];
    const sagaMiddleware = createSagaMiddleware();

    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);

    const appReducers = combineReducers({
        adminTicketSettings,
        adminNotification,
        adminRightsManagement,
        adminSubjectSettings,
        adminFrequentQuestion,
        adminPresetResponse,
        adminWorkingHours,
        adminBusinessUnit,
        adminBusinessUnitList,
        adminBUAssignment,
        distributionExportTickets,
        distributionList,
        distributionFullTextSearch,
        distributionListOrderLockTicketCheck,
        distributionPreferences,
        distributionDealer,
        distributionHistory,
        distributionTicket,
        distributionAttachment,
        distributionLockTicketCheck,
        distributionStatistics,
        commercialExportTickets,
        commercialList,
        commercialFullTextSearch,
        commercialListOrderLockTicketCheck,
        commercialPreferences,
        commercialDealer,
        commercialHistory,
        commercialTicket,
        commercialAttachment,
        commercialLockTicketCheck,
        commercialStatistics,
        marketingExportTickets,
        marketingList,
        marketingFullTextSearch,
        marketingListOrderLockTicketCheck,
        marketingPreferences,
        marketingDealer,
        marketingHistory,
        marketingTicket,
        marketingAttachment,
        marketingLockTicketCheck,
        marketingStatistics,
        mainFiles,
        dhsData,
        asdData,
        alerts,
        home,
        form,
        intl,
        menu,
        profile,
        supportModal,
        websocket,
        metisMenuStore: metisMenuReducer,
    });

    const store = createStore(
        appReducers,
        persistedState,
        compose(
            applyMiddleware(
                ...middlewares,
                createAdminSocketMiddleware(),
                createDistributionSocketMiddleware(),
                createCommercialSocketMiddleware(),
                createMarketingSocketMiddleware(),
                createApigeeSocketMiddleware(),
                routerMiddleware(history),
                sagaMiddleware
            ),
            ...enhancers
        )
    );

    addLocaleData(enLocaleData);
    addLocaleData(csLocaleData);
    addLocaleData(daLocaleData);
    addLocaleData(deLocaleData);
    addLocaleData(esLocaleData);
    addLocaleData(frLocaleData);
    addLocaleData(hrLocaleData);
    addLocaleData(huLocaleData);
    addLocaleData(itLocaleData);
    addLocaleData(nlLocaleData);
    addLocaleData(plLocaleData);
    addLocaleData(ptLocaleData);
    addLocaleData(roLocaleData);
    addLocaleData(skLocaleData);
    addLocaleData(slLocaleData);
    addLocaleData(srLocaleData);
    addLocaleData(svLocaleData);

    sagaMiddleware.run(rightsManagementSaga);
    sagaMiddleware.run(distributionTicketSaga);
    sagaMiddleware.run(commercialTicketSaga);
    sagaMiddleware.run(marketingTicketSaga);
    sagaMiddleware.run(frequentQuestionFileSaga);
    sagaMiddleware.run(supportModalSaga);

    return store;
};

export default configureStore;
