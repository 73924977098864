import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader, ModalWindow} from './modal/Modal';
import {StyledForm} from './StyledComponents';
import {FIELD_MESSAGE} from '../../constants/formConstants';
import formMessages from '../../intl/modules/formMessages';
import {TextareaFieldWithValidation} from './fields/TextareaFieldWithValidation';
import buttonMessages from '../../intl/common/buttonMessages';
import styled from 'styled-components';
import {BoldPrimaryButton, SecondaryButton} from './Button';

const Button = styled(SecondaryButton)`
  margin-right: 10px;
`;

class ReopenMessageModal extends Component {
    save = data => {
        this.props.saveModal(data);
    };

    close = () => {
        this.props.reset();
        this.props.closeModal();
    };

    render() {
        const {show, dirty, invalid, handleSubmit} = this.props;

        return (
            <ModalWindow
                isOpen={show}
                onRequestClose={this.close}
                shouldCloseOnOverlayClick={false}>
                <StyledForm onSubmit={handleSubmit(this.save)}>
                    <ModalHeader>
                        <FormattedMessage {...formMessages.REOPEN_MESSAGE}/>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-row">
                            <div className="col-12">
                                <TextareaFieldWithValidation label={formMessages.MESSAGE}
                                                             field={FIELD_MESSAGE}
                                                             rows={4}/>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row">
                            <div className="col">
                                <Button type="button" className="btn"
                                        onClick={this.close}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </Button>
                                <BoldPrimaryButton type="submit" className="btn"
                                                   disabled={!dirty || invalid}>
                                    <FormattedMessage {...buttonMessages.REOPEN}/>
                                </BoldPrimaryButton>
                            </div>
                        </div>
                    </ModalFooter>
                </StyledForm>
            </ModalWindow>
        )
    }

}

ReopenMessageModal.propTypes = {
    show: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    saveModal: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default reduxForm({
    form: 'ReopenMessageModalForm',
    enableReinitialize: true,
})(injectIntl(connect(mapStateToProps, {})(ReopenMessageModal)));
