import {distributionWebsocketCommands} from '../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../constants/marketingWebsocketCommands';
import {domains} from '../constants/Utils';

export const FILES_UPLOAD_PENDING = 'FILES_UPLOAD_PENDING';
export const FILES_DOWNLOAD_PENDING = 'FILES_DOWNLOAD_PENDING';
export const FILE_UPLOAD_STATUS = 'FILE_UPLOAD_STATUS';

export const downloadFile = (domain, {uuid, name}, questionId) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch
            ({
                type: FILES_DOWNLOAD_PENDING,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_FREQUENT_QUESTION_FILE_DOWNLOAD,
                payload: {attachment: {uuid, name, questionId}}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch
            ({
                type: FILES_DOWNLOAD_PENDING,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_FREQUENT_QUESTION_FILE_DOWNLOAD,
                payload: {attachment: {uuid, name, questionId}}
            });
        }
        case domains.MARKETING: {
            return dispatch
            ({
                type: FILES_DOWNLOAD_PENDING,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_FREQUENT_QUESTION_FILE_DOWNLOAD,
                payload: {attachment: {uuid, name, questionId}}
            });
        }
    }
};

export const uploadFile = (domain, uuid, questionId, contentType, localFileUrl) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            dispatch({
                type: FILE_UPLOAD_STATUS,
                payload: {uuid}
            });
            return dispatch({
                type: FILES_UPLOAD_PENDING,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_FREQUENT_QUESTION_FILE_UPLOAD,
                payload: {attachment: {uuid, questionId, contentType, localFileUrl}}
            });
        }
        case domains.COMMERCIAL: {
            dispatch({
                type: FILE_UPLOAD_STATUS,
                payload: {uuid}
            });
            return dispatch({
                type: FILES_UPLOAD_PENDING,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_FREQUENT_QUESTION_FILE_UPLOAD,
                payload: {attachment: {uuid, questionId, contentType, localFileUrl}}
            });
        }
        case domains.MARKETING: {
            dispatch({
                type: FILE_UPLOAD_STATUS,
                payload: {uuid}
            });
            return dispatch({
                type: FILES_UPLOAD_PENDING,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_FREQUENT_QUESTION_FILE_UPLOAD,
                payload: {attachment: {uuid, questionId, contentType, localFileUrl}}
            });
        }
    }
};

