import {defineMessages} from 'react-intl';

const messages = defineMessages({
    SELECT_USER: {
        id: 'admin.authorizationManagement.selectUser',
        defaultMessage: 'Select User'
    },
    SELECT_USER_PLACEHOLDER: {
        id: 'admin.authorizationManagement.selectUser.placeholder',
        defaultMessage: 'Select a User'
    },
    SELECT_USER_SEARCH: {
        id: 'admin.authorizationManagement.selectUser.search',
        defaultMessage: 'Start searching by name or IPN...'
    },
    SELECT_COUNTRIES_PLACEHOLDER: {
        id: 'admin.authorizationManagement.selectCountries.placeholder',
        defaultMessage: 'Select countries'
    },
    SELECT_FORM_TYPES: {
        id: 'admin.authorizationManagement.selectFormTypes',
        defaultMessage: 'Select Form Types'
    },
    SELECT_FORM_TYPES_PLACEHOLDER: {
        id: 'admin.authorizationManagement.selectFormTypes.placeholder',
        defaultMessage: 'Select Form Types'
    },
});

export default messages;
