import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getFormInitialValues, change} from 'redux-form';
import get from 'get-value';
import {
    BUTTON_LABEL_DHS_LOAD,
    BUTTON_LABEL_FREQUENT_QUESTION,
    FIELD_BRAND,
    FIELD_MODEL,
    FIELD_OPTION,
    FIELD_ORDER_NUMBER,
    FIELD_SUBJECT,
    FIELD_VERSION,
    FIELD_VIN,
    TICKET_SECTION,
    FIELD_DISTRIBUTION_ACCOUNT,
    FIELD_DISTRIBUTION_ACCOUNT_NAME,
    FIELD_MODEL_SCR,
    FIELD_VERSION_SCR,
    FIELD_OPTION_SCR,
    FIELD_CONCEDES,
    FIELD_CONCEDES_NAME,
    FIELD_CDE_CLI,
    FIELD_PRIORITY,
    FIELD_CONTACT,
    FIELD_STATUS
} from '../../../../../../constants/formConstants';
import {
    dhsDataResetActionCreator,
    dhsDataSetActionCreator,
    fetchDhsInformationActionCreator
} from '../../../../../../actions/actionDhsData';
import {InputFieldWithValidation} from '../../../../../common/fields/InputFieldWithValidation';
import {validVinLength} from '../../../../../common/validationCommons';
import {TextareaFieldWithValidation} from '../../../../../common/fields/TextareaFieldWithValidation';
import {brandOptions, modelValues, domains, getPriority, getContact} from '../../../../../../constants/Utils';
import {PrimaryButton} from '../../../../../common/Button';
import {Label, StyledDiv} from '../../../../../common/StyledComponents';
import FrequentQuestionModal from '../../../../../common/frequentQuestion/FrequentQuestionModal';
import InputSelectFieldWithValidation from '../../../../../common/fields/InputSelectFieldWithValidation';
import formValueSelector from 'redux-form/lib/formValueSelector';
import Loader from '../../../../../Loader';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import brandMessages from '../../../../../../intl/modules/brandMessages';
import formMessages from '../../../../../../intl/modules/formMessages';
import formTicketValuesMessages from '../../../../../../intl/common/formTicketValuesMessages';
import contactMessages from '../../../../../../intl/modules/contactMessages';
import {moduleRoles} from '../../../../../../utils/roles';
import AssigneeSelect from './AssigneeSelect';

const domain = domains.DISTRIBUTION;

class TicketSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenFrequentQuestionModal: false
        }
    }

    componentWillUnmount() {
        this.props.dhsDataResetActionCreator();
    }

    handleDhsLoad = (e) => {
        e.preventDefault();
        this.props.fetchDhsInformationActionCreator(this.props.vin, this.props.group);
    };

    showModal = () =>
        this.setState({
            isOpenFrequentQuestionModal: true,
        });
    closeModal = () =>
        this.setState({
            isOpenFrequentQuestionModal: false
        });

    fillVinDetailFromDhs = () => {
        const {dhsData, change} = this.props;
        change(`${TICKET_SECTION}.${FIELD_BRAND}`, get(dhsData, 'dhsData.brand', ''));
        change(`${TICKET_SECTION}.${FIELD_MODEL}`, get(dhsData, 'dhsData.model', ''));
        change(`${TICKET_SECTION}.${FIELD_VERSION}`, get(dhsData, 'dhsData.version', ''));
        change(`${TICKET_SECTION}.${FIELD_ORDER_NUMBER}`, get(dhsData, 'dhsData.orderNumber', ''));
        change(`${TICKET_SECTION}.${FIELD_OPTION}`, get(dhsData, 'dhsData.options', ''));
        change(`${TICKET_SECTION}.${FIELD_DISTRIBUTION_ACCOUNT}`, get(dhsData, 'dhsData.distrAccount', ''));
        change(`${TICKET_SECTION}.${FIELD_DISTRIBUTION_ACCOUNT_NAME}`, get(dhsData, 'dhsData.distrAccountName', ''));
        change(`${TICKET_SECTION}.${FIELD_VERSION_SCR}`, get(dhsData, 'dhsData.versionScr', ''));
        change(`${TICKET_SECTION}.${FIELD_MODEL_SCR}`, get(dhsData, 'dhsData.modelScr', ''));
        change(`${TICKET_SECTION}.${FIELD_OPTION_SCR}`, get(dhsData, 'dhsData.optionsScr', ''));
        change(`${TICKET_SECTION}.${FIELD_CONCEDES}`, get(dhsData, 'dhsData.concedes', ''));
        change(`${TICKET_SECTION}.${FIELD_CONCEDES_NAME}`, get(dhsData, 'dhsData.concedesName', ''));
        change(`${TICKET_SECTION}.${FIELD_CDE_CLI}`, get(dhsData, 'dhsData.cdeCli', ''));
        this.props.dhsDataSetActionCreator();
    };

    render() {
        const {readOnly, userRights, reqFields, subjects, subjectField, dhsData, group, initialValues, brand, isSubstituted, substitution, roles, editMode} = this.props;
        const {isOpenFrequentQuestionModal} = this.state;
        const requiredField = (subjects && !editMode) ? get(get(subjects, `distribution.${group}`, []).find(item => item.uuid === subjectField), 'vinObligation', false) : false;

        const isEditor = moduleRoles.isEditorDistribution(roles)

        if (dhsData.dhsSet) {
            this.fillVinDetailFromDhs();
        }

        return (
            <>
                <h3 className="text-left ml-4">
                    <FormattedMessage {...formMessages.TICKET_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white px-4 py-2">
                        <div className="form-row">
                            {userRights.canShowAssignee &&
                            <div className="col-md-12 col-lg-4 pb-2">
                                <AssigneeSelect form={this.props.form} readOnly={readOnly}/>
                            </div>
                            }
                            {isEditor && (isSubstituted || substitution) &&
                            <div className="col-md-12 col-lg-4 col-xl-3 pb-2">
                                <InputSelectFieldWithValidation
                                    label={formMessages.CONTACT}
                                    field={FIELD_CONTACT}
                                    options={getContact}
                                    intlMessages={contactMessages}
                                    isDisabled={!userRights.canEditContact || readOnly}
                                    isRequired={requiredField}/>
                            </div>
                            }
                        </div>
                    <div className="form-row">
                        <div className="col-12 pb-2">
                            <div className="form-row align-items-end">
                                <div className="col-md-12 col-lg-8 pb-2">
                                    <InputSelectFieldWithValidation
                                        label={formMessages.SUBJECT}
                                        field={FIELD_SUBJECT}
                                        customOptions={get(subjects, `distribution.${group}`, [])}
                                        isDisabled={!userRights.canEditSubject || readOnly}
                                        isRequired={reqFields.includes(FIELD_SUBJECT) && !readOnly}/>
                                </div>
                                {userRights.canOpenFrequentQuestion && !readOnly &&
                                <>
                                    <div className="pl-2 pb-2">
                                        <PrimaryButton type="button" className="btn btn-sm"
                                                       onClick={this.showModal}>
                                            <ValidFormattedMessage message={BUTTON_LABEL_FREQUENT_QUESTION}
                                                                   intlMessages={buttonMessages}/>
                                        </PrimaryButton>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 pb-2">
                            <div className="form-row align-items-end">
                                <div className="col-md-12 col-lg-6 col-xl-4">
                                    <InputFieldWithValidation
                                        label={formMessages.VIN}
                                        field={FIELD_VIN}
                                        maxLength={17}
                                        isDisabled={!userRights.canEditVin || readOnly || dhsData.dhsLoaded}
                                        validations={[validVinLength]}
                                        isRequired={requiredField}
                                        isSmall/>
                                </div>
                                {userRights.canEditDhs && !readOnly &&
                                <>
                                    <div className="pl-2 pt-1">
                                        <PrimaryButton type="button" className="btn btn-sm"
                                                       onClick={this.handleDhsLoad}
                                                       disabled={dhsData.dhsLoaded}>
                                            <ValidFormattedMessage message={BUTTON_LABEL_DHS_LOAD}
                                                                   intlMessages={buttonMessages}/>
                                        </PrimaryButton>
                                    </div>
                                    <div className="pl-2 py-auto">
                                        {dhsData.isDhsLoading &&
                                        <Loader isSmall/>
                                        }
                                    </div>
                                </>
                                }
                                <div className="col-md-12 col-lg-12 col-xl-4 offset-xl-2">
                                    <InputSelectFieldWithValidation
                                        label={formMessages.PRIORITY}
                                        field={FIELD_PRIORITY}
                                        notSortedOptions={getPriority}
                                        intlMessages={formTicketValuesMessages}
                                        isDisabled={!userRights.canEditPriority || readOnly}
                                        isRequired={reqFields.includes(FIELD_PRIORITY) && !readOnly}
                                        noEmpty/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 col-lg-4 col-xl-3 pb-2">
                            <InputSelectFieldWithValidation
                                label={formMessages.BRAND}
                                field={FIELD_BRAND}
                                options={get(brandOptions,
                                    `${get(initialValues, 'ticketSection.group', {default: 'X'})}`,
                                    {default: brandOptions.DEFAULT})}
                                intlMessages={brandMessages}
                                isDisabled={!userRights.canEditBrand || readOnly}
                                isRequired={requiredField}/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-5 pb-2">
                            <InputSelectFieldWithValidation
                                label={formMessages.MODEL}
                                field={FIELD_MODEL}
                                options={get(modelValues,
                                    `${get(initialValues, 'ticketSection.group', {default: 'X'})}.${brand}`,
                                    {default: []})}
                                isDisabled={!userRights.canEditModel || readOnly}
                                isRequired={requiredField}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 col-lg-6 col-xl-4 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.VERSION}
                                field={FIELD_VERSION}
                                maxLength={12}
                                isDisabled={!userRights.canEditVersion || readOnly}
                                isSmall/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-4 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.ORDER_NUMBER}
                                field={FIELD_ORDER_NUMBER}
                                maxLength={15}
                                isDisabled={!userRights.canEditOrderNumber || readOnly}
                                isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 col-lg-12 col-xl-8 pb-2">
                            <TextareaFieldWithValidation label={formMessages.OPTION}
                                                         field={FIELD_OPTION}
                                                         isDisabled={!userRights.canEditOption || readOnly}
                                                         allowBrackets={true}
                                                         rows={2}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.DISTRIBUTION_ACCOUNT}
                                field={FIELD_DISTRIBUTION_ACCOUNT}
                                isDisabled
                                isSmall/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-5 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.DISTRIBUTION_ACCOUNT_NAME}
                                field={FIELD_DISTRIBUTION_ACCOUNT_NAME}
                                isDisabled
                                isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 col-lg-6 col-xl-3 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.CONCEDES}
                                field={FIELD_CONCEDES}
                                isDisabled
                                isSmall/>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-5 pb-2">
                            <InputFieldWithValidation
                                label={formMessages.CONCEDES_NAME}
                                field={FIELD_CONCEDES_NAME}
                                isDisabled
                                isSmall/>
                        </div>
                    </div>
                </StyledDiv>
                {isOpenFrequentQuestionModal &&
                <FrequentQuestionModal show={isOpenFrequentQuestionModal}
                                       domain={domain}
                                       closeModal={this.closeModal}
                                       group={group}/>
                }
            </>
        )
    }
}

const mapStateToProps = (state, props) => ({
    roles: state.profile.userDetail.roles,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
    subjectField: formValueSelector(props.form)(state, `${TICKET_SECTION}.${FIELD_SUBJECT}`),
    dhsData: state.dhsData,
    initialValues: getFormInitialValues(props.form)(state),
    vin: formValueSelector(props.form)(state, `${TICKET_SECTION}.${FIELD_VIN}`),
    brand: formValueSelector(props.form)(state, `${TICKET_SECTION}.${FIELD_BRAND}`),
    group: formValueSelector(props.form)(state, `${TICKET_SECTION}.group`),
    isSubstituted: state.distributionTicket.substitutionUser,
    substitution: formValueSelector(props.form)(state, `${TICKET_SECTION}.substitution`),
    status: formValueSelector(props.form)(state, `${TICKET_SECTION}.${FIELD_STATUS}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            dhsDataSetActionCreator,
            fetchDhsInformationActionCreator,
            dhsDataResetActionCreator
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketSection);
