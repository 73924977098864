import {
    FIELD_CURRENT_SPARE_PART_NAME,
    FIELD_MESSAGE, FIELD_REFERENCE_NUMBER, FIELD_SEGMENT,
    FIELD_SUBJECT, FIELD_SUGGESTED_SPARE_PART_NAME,
    GENERAL_QUESTION_FORM,
    SPARE_PART_NAME_VERIFICATION_FORM,
    SPARE_PART_PRICE_VERIFICATION_FORM,
} from './formConstants';

export const createReqFields = {
    [GENERAL_QUESTION_FORM]: [
        FIELD_SUBJECT,
        FIELD_MESSAGE
    ],
    [SPARE_PART_PRICE_VERIFICATION_FORM]: [
        FIELD_SUBJECT,
        FIELD_MESSAGE
    ],
    [SPARE_PART_NAME_VERIFICATION_FORM]: [
        FIELD_SUBJECT,
        FIELD_MESSAGE,
        FIELD_SUGGESTED_SPARE_PART_NAME
    ],
};

export const editReqFields = {
    'DEALER': {
        [GENERAL_QUESTION_FORM]: [
            FIELD_MESSAGE,
        ],
        [SPARE_PART_PRICE_VERIFICATION_FORM]: [
            FIELD_MESSAGE,
        ],
        [SPARE_PART_NAME_VERIFICATION_FORM]: [
            FIELD_MESSAGE,
        ],
    },
    'EDITOR': {
        [GENERAL_QUESTION_FORM]: [
            FIELD_MESSAGE,
        ],
        [SPARE_PART_PRICE_VERIFICATION_FORM]: [
            FIELD_MESSAGE,
        ],
        [SPARE_PART_NAME_VERIFICATION_FORM]: [
            FIELD_MESSAGE,
        ],
    }
};
