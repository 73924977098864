import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {storeSelectedCountryGroup} from '../../../actions/admin/actionAdminPresetResponse';
import {StyledForm, Label} from '../../common/StyledComponents';
import {moduleRoles} from '../../../utils/roles';
import SelectCountryGroup from '../../common/country/SelectCountryGroup';
import PresetResponseContainer from './PresetResponseContainer';
import checkRoles from '../../common/roleChecker/RoleChecker';
import PageHeader from '../../common/PageHeader';
import presetResponseMessages from '../../../intl/admin/presetResponseMessages';
import adminMessages from '../../../intl/admin/adminMessages';

class PresetResponsePage extends Component {
    componentDidMount() {
        const {domain, roles, selectedCountryGroup} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        if (selectedCountryGroup === '' || !groups.map(group => group.group).includes(selectedCountryGroup)) {
            this.props.storeSelectedCountryGroup(get(groups, '0.group', ''));
        }
    }

    handleCountryGroupSelection = countryGroup => this.props.storeSelectedCountryGroup(countryGroup.value);

    render() {
        const {roles, domain, selectedCountryGroup} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        return (
            <div>
                <PageHeader title={<FormattedMessage {...presetResponseMessages.TITLE}/>}/>
                <StyledForm className="form-group">
                    <div className="form-row mb-sm-3">
                        <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6">
                            <Label>
                                <FormattedMessage {...adminMessages.SELECT_COUNTRY}/>
                            </Label>
                            <SelectCountryGroup groups={groups}
                                                handleCountryGroupSelection={this.handleCountryGroupSelection}
                                                selectedCountryGroup={selectedCountryGroup}/>
                        </div>
                    </div>
                </StyledForm>
                <div>
                    {groups.map((group, index) =>
                        (selectedCountryGroup === group.group &&
                            <div key={index}>
                                <PresetResponseContainer domain={domain}
                                                         selectedGroup={selectedCountryGroup}/>
                            </div>
                        )
                    )}
                </div>
            </div>
        );
    }
}

PresetResponsePage.propTypes = {
    domain: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    selectedCountryGroup: state.adminPresetResponse.selectedCountryGroup
});

export default checkRoles(connect(mapStateToProps, {
    storeSelectedCountryGroup
})(PresetResponsePage), ['DB_ADMIN', 'CC_ADMIN', 'MA_ADMIN']);
