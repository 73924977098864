import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.title',
        defaultMessage: 'Administration Module'
    },
    SELECT_COUNTRY: {
        id: 'admin.selectCountry',
        defaultMessage: 'Select Country'
    },
    SELECT_COUNTRIES: {
        id: 'admin.selectCountries',
        defaultMessage: 'Select Countries'
    },
    ACTION: {
        id: 'admin.action',
        defaultMessage: 'Action'
    },
});

export default messages;
