import {modules} from '../constants/Utils';

export const CONNECT_ADMIN_WEBSOCKET_PENDING = 'CONNECT_ADMIN_WEBSOCKET_PENDING';
export const CONNECT_ADMIN_WEBSOCKET_SUCCESS = 'CONNECT_ADMIN_WEBSOCKET_SUCCESS';
export const CONNECT_ADMIN_WEBSOCKET_ERROR = 'CONNECT_ADMIN_WEBSOCKET_ERROR';

export const CONNECT_DISTRIBUTION_WEBSOCKET_PENDING = 'CONNECT_DISTRIBUTION_WEBSOCKET_PENDING';
export const CONNECT_DISTRIBUTION_WEBSOCKET_SUCCESS = 'CONNECT_DISTRIBUTION_WEBSOCKET_SUCCESS';
export const CONNECT_DISTRIBUTION_WEBSOCKET_ERROR = 'CONNECT_DISTRIBUTION_WEBSOCKET_ERROR';

export const CONNECT_COMMERCIAL_WEBSOCKET_PENDING = 'CONNECT_COMMERCIAL_WEBSOCKET_PENDING';
export const CONNECT_COMMERCIAL_WEBSOCKET_SUCCESS = 'CONNECT_COMMERCIAL_WEBSOCKET_SUCCESS';
export const CONNECT_COMMERCIAL_WEBSOCKET_ERROR = 'CONNECT_COMMERCIAL_WEBSOCKET_ERROR';

export const CONNECT_MARKETING_WEBSOCKET_PENDING = 'CONNECT_MARKETING_WEBSOCKET_PENDING';
export const CONNECT_MARKETING_WEBSOCKET_SUCCESS = 'CONNECT_MARKETING_WEBSOCKET_SUCCESS';
export const CONNECT_MARKETING_WEBSOCKET_ERROR = 'CONNECT_MARKETING_WEBSOCKET_ERROR';

export const CONNECT_APIGEE_WEBSOCKET_PENDING = 'CONNECT_APIGEE_WEBSOCKET_PENDING';
export const CONNECT_APIGEE_WEBSOCKET_SUCCESS = 'CONNECT_APIGEE_WEBSOCKET_SUCCESS';
export const CONNECT_APIGEE_WEBSOCKET_ERROR = 'CONNECT_APIGEE_WEBSOCKET_ERROR';

export const PING_WEBSOCKET = 'ping';

export const connectWebsockets = (token) => {
    return dispatch => {
        dispatch({type: CONNECT_ADMIN_WEBSOCKET_PENDING, payload: {token}});
        dispatch({type: CONNECT_DISTRIBUTION_WEBSOCKET_PENDING, payload: {token}});
        dispatch({type: CONNECT_COMMERCIAL_WEBSOCKET_PENDING, payload: {token}});
        dispatch({type: CONNECT_MARKETING_WEBSOCKET_PENDING, payload: {token}});
        dispatch({type: CONNECT_APIGEE_WEBSOCKET_PENDING, payload: {token}});
    };
};

export const pingWebsocket = (module) => {
    let moduleSocket = '';

    switch (module) {
        case modules.ADMINISTRATION: {
            moduleSocket = 'adminWebsocket';
            break;
        }
        case modules.DISTRIBUTION: {
            moduleSocket = 'distributionWebsocket';
            break;
        }
        case modules.COMMERCIAL: {
            moduleSocket = 'commercialWebsocket';
            break;
        }
        case modules.MARKETING: {
            moduleSocket = 'marketingWebsocket';
            break;
        }
        case modules.APIGEE: {
            moduleSocket = 'apigeeWebsocket';
            break;
        }
        default: {
            moduleSocket = 'default';
            break;
        }
    }

    return dispatch => {
        dispatch({
            type: PING_WEBSOCKET,
            command: PING_WEBSOCKET,
            [moduleSocket]: true
        });
    };
};
