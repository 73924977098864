import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    DHS_VIN_WARN001: {
        id: 'warnings.dhs.dataNotFound',
        defaultMessage: 'No data found in DHS for the VIN {vinNumber}'
    },
    ASD_WARN001: {
        id: 'warnings.asd.dataNotFound',
        defaultMessage: 'No data found in ASD for the Reference Number {refNumber}'
    },
    ATTACHMENTS_SIZE: {
        id: 'warnings.bigAttachmentsSize',
        defaultMessage: 'Attachments size exceeded {filesSize}MB, please take a notice that larger files can take a while to upload'
    },
});

export default messages;
