import React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import SelectUser from './SelectUser';
import {required, validInput} from '../../../../../common/validationCommons';
import {DEFAULT_FIELD_MAX_LENGTH} from '../../../../../../constants/validationConstants';
import {RequiredSpan} from '../../../../../common/StyledComponents';

export const InputSelectUserFieldWithValidation = (props) => {
    const {
        label, field, placeholder, isDisabled, isRequired, isSmall, validations, maxLength, style, handleUserSelection, handleUserSearch
    } = props;
    let newValidations = validations;

    if (!newValidations) {
        newValidations = [validInput];
    } else {
        newValidations.push(validInput);
    }
    if (isRequired) {
        newValidations.push(required);
    }

    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>}
            <Field
                name={field} component={SelectUser} type="text"
                placeholder={placeholder}
                maxLength={maxLength ? maxLength : DEFAULT_FIELD_MAX_LENGTH}
                isSmall={isSmall}
                validate={newValidations}
                isDisabled={isDisabled}
                style={style}
                handleUserSelection={handleUserSelection}
                handleUserSearch={handleUserSearch}
            />
        </div>
    )
};
