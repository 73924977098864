import {put, takeEvery, select} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {MARKETING_TICKET_RESET} from '../actions/marketing/actionMarketingTicket';
import get from 'get-value';
import {marketingWebsocketCommands} from '../constants/marketingWebsocketCommands';

export function* handleTicketError(action) {
    const stateCorrelationId = get(yield select(state => state.marketingTicket), `correlationId`, {default: undefined});
    const {correlationId} = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('/marketing'));
    yield put({type: MARKETING_TICKET_RESET});
}

function* marketingTicketSaga() {
    yield takeEvery(marketingWebsocketCommands.MARKETING_TICKET_UPDATE_FATAL, handleTicketError);
    yield takeEvery(marketingWebsocketCommands.MARKETING_TICKET_CREATE_FATAL, handleTicketError);
    yield takeEvery(marketingWebsocketCommands.MARKETING_TICKET_SEND_ERROR, handleTicketError);
}

export default marketingTicketSaga;
