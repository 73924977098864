import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const fetchMarketingDealers = () => (dispatch) => {
    dispatch({
        type: marketingWebsocketCommands.MARKETING_DEALERS_GET,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_DEALERS_GET,
        payload: {}
    });
};
