import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {fetchTicketSettings, updateTicketSettings} from '../../../actions/admin/actionAdminTicketSettings';
import {Wrapper} from '../../common/StyledComponents';
import TicketSettingsForm from './TicketSettingsForm';
import Loader from '../../Loader';

class TicketSettingsContainer extends Component {
    componentDidMount() {
        this.props.fetchTicketSettings(this.props.domain, this.props.group);
    }

    handleSubmit = (values) => {
        const {group, domain} = this.props;
        const settings = {
            group: group,
            daysToHistory: parseInt(values.daysToHistory),
            daysToClose: parseInt(values.daysToClose),
            blockingTime: parseInt(values.blockingTime)
        };
        this.props.updateTicketSettings(domain, settings);
    };

    render() {
        const {isLoading, group, data, domain} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        return (
            <Wrapper classname="container-fluid">
                <TicketSettingsForm onSubmit={this.handleSubmit}
                                    initialValues={data}
                                    enableReinitialize={true}
                                    form={`ticketSettingsForm${domain}_${group}`}/>
            </Wrapper>
        )
    }
}

TicketSettingsContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    updateTicketSettings: PropTypes.func.isRequired,
    fetchTicketSettings: PropTypes.func.isRequired,
    group: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminTicketSettings.isLoading,
    data: state.adminTicketSettings.ticketSettings,
});

export default connect(mapStateToProps, {
    fetchTicketSettings,
    updateTicketSettings,
})(TicketSettingsContainer);
