import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    TOKEN_EXPIRED: {
        id: 'alert.token.expired',
        defaultMessage: 'Authorization token expired. Refresh?'
    },
    ADMINISTRATION_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.administration',
        defaultMessage: 'Cannot reach Administration backend'
    },
    DISTRIBUTION_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.distribution',
        defaultMessage: 'Cannot reach Distribution backend'
    },
    COMMERCIAL_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.commercial',
        defaultMessage: 'Cannot reach Commercial backend'
    },
    MARKETING_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.marketing',
        defaultMessage: 'Cannot reach Marketing backend'
    },
    APIGEE_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.apigee',
        defaultMessage: 'Cannot reach APIGEE backend'
    },
    ADMINISTRATION_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.administration',
        defaultMessage: 'Command structure error for Administration'
    },
    DISTRIBUTION_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.distribution',
        defaultMessage: 'Command structure error for Distribution'
    },
    COMMERCIAL_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.commercial',
        defaultMessage: 'Command structure error for Commercial'
    },
    MARKETING_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.marketing',
        defaultMessage: 'Command structure error for Marketing'
    },
    APIGEE_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.apigee',
        defaultMessage: 'Command structure error for APIGEE'
    },
    FILE_UPLOAD_ERROR: {
        id: 'alert.error.file.upload',
        defaultMessage: 'Cannot upload file'
    },
    RIGHTS_ADMIN_FETCH_ERROR: {
        id: 'alert.error.rights.admin.fetch',
        defaultMessage: 'Cannot fetch Rights Management admin data'
    },
    RIGHTS_ADMIN_RETRIEVED_ERROR: {
        id: 'alert.error.rights.admin.retrieved',
        defaultMessage: 'Retrieved invalid admin rights from backend'
    },
    RIGHTS_ADMIN_SEND_ERROR: {
        id: 'alert.error.rights.admin.send',
        defaultMessage: 'Cannot send invalid admin rights to backend'
    },
    RIGHTS_DEALER_FETCH_ERROR: {
        id: 'alert.error.rights.dealer.fetch',
        defaultMessage: 'Cannot fetch Rights Management dealer data'
    },
    RIGHTS_DEALER_RETRIEVED_ERROR: {
        id: 'alert.error.rights.dealer.retrieved',
        defaultMessage: 'Retrieved invalid dealer rights from backend'
    },
    RIGHTS_DEALER_SEND_ERROR: {
        id: 'alert.error.rights.dealer.send',
        defaultMessage: 'Cannot send invalid dealer rights to backend'
    },
    RIGHTS_EDITOR_FETCH_ERROR: {
        id: 'alert.error.rights.editor.fetch',
        defaultMessage: 'Cannot fetch Rights Management editor data'
    },
    RIGHTS_EDITOR_RETRIEVED_ERROR: {
        id: 'alert.error.rights.editor.retrieved',
        defaultMessage: 'Retrieved invalid editor rights from backend'
    },
    RIGHTS_EDITOR_SEND_ERROR: {
        id: 'alert.error.rights.editor.send',
        defaultMessage: 'Cannot send invalid editor rights to backend'
    },
    RIGHTS_EXTERNAL_EDITOR_FETCH_ERROR: {
        id: 'alert.error.rights.externalEditor.fetch',
        defaultMessage: 'Cannot fetch Rights Management external editor data'
    },
    RIGHTS_EXTERNAL_EDITOR_RETRIEVED_ERROR: {
        id: 'alert.error.rights.externalEditor.retrieved',
        defaultMessage: 'Retrieved invalid external editor rights from backend'
    },
    RIGHTS_EXTERNAL_EDITOR_SEND_ERROR: {
        id: 'alert.error.rights.externalEditor.send',
        defaultMessage: 'Cannot send invalid external editor rights to backend'
    },
    USERS_DETAILS_FETCH_ERROR: {
        id: 'alert.error.users.details.fetch',
        defaultMessage: 'Cannot fetch user\'s detail'
    },
    USERS_FETCH_ERROR: {
        id: 'alert.error.users.fetch',
        defaultMessage: 'Cannot fetch users'
    },
    USERS_RETRIEVED_ERROR: {
        id: 'alert.error.users.retrieved',
        defaultMessage: 'Retrieved invalid user from backend'
    },
    USERS_SAVE_ERROR: {
        id: 'alert.error.users.save',
        defaultMessage: 'Cannot save a user'
    },
    DETAILS_FOR_SUPPORT: {
        id: 'alert.link.detailsForSupport',
        defaultMessage: 'Details for Support'
    },
    FILE_DOWNLOAD_ERROR: {
        id: 'alert.error.file.download',
        defaultMessage: 'Cannot download file'
    },
    BUSINESS_UNIT_NEW_SEND_ERROR: {
        id: 'alert.error.businessUnit.new.send',
        defaultMessage: 'Cannot send invalid new business unit to backend'
    },
    BUSINESS_UNIT_UPDATE_SEND_ERROR: {
        id: 'alert.error.businessUnit.update.send',
        defaultMessage: 'Cannot send invalid updated business unit to backend'
    },
});

export default messages;
