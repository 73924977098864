import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {withAlert} from 'react-alert';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {ADMINISTRATION_PATH, COMMERCIAL_PATH, DISTRIBUTION_PATH, HOME_PATH, MARKETING_PATH,} from '../routes/paths';
import NotFoundPage from './NotFoundPage';
import HomePage from './home/HomePage';
import TopBar from './menu/topMenu/TopBar';
import SideBar from './menu/sideMenu/SideBar';
import Administration from './administration/Administration';
import Distribution from './distribution/Distribution';
import Marketing from './marketing/Marketing';
import Commercial from './commercial/Commercial';
import Header from './Header';

const Main = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
`;

const ContentWrapper = styled.div`
    flex: 1;
    padding: 6px 20px 6px 20px;
    transition: all 0.5s ease;
    overflow-y: auto;
    overflow-x: hidden;
`;

const AppWrapper = styled.div`
    height: ${({isHomePage = false}) => isHomePage ? '' : '100vh'};
    display: flex;
    flex-direction: column;
`;

class AppContent extends Component {
    componentDidMount() {
        this.props.alert.show();
    }

    render() {
        const {store, location: {pathname}} = this.props;
        return (
            <AppWrapper isHomePage={pathname === HOME_PATH}>
                <div>
                    <Header/>
                    <TopBar/>
                </div>
                <Main>
                    <SideBar store={store}/>
                    <ContentWrapper id="contentWrapper">
                        <Switch>
                            <Route exact path={HOME_PATH} component={HomePage}/>
                            <Route path={ADMINISTRATION_PATH} component={Administration}/>
                            <Route path={DISTRIBUTION_PATH} component={Distribution}/>
                            <Route path={COMMERCIAL_PATH} component={Commercial}/>
                            <Route path={MARKETING_PATH} component={Marketing}/>
                            <Route component={NotFoundPage}/>
                        </Switch>
                    </ContentWrapper>
                </Main>
            </AppWrapper>
        );
    }
}

AppContent.propTypes = {
    store: PropTypes.any.isRequired,
    alert: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withRouter(withAlert(AppContent));
