import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {
    DISTRIBUTION_ATTACHMENTS_UPLOADING,
    DISTRIBUTION_ATTACHMENTS_UPLOADING_ERROR
} from '../../actions/distribution/actionDistributionAttachment';
import {DISTRIBUTION_TICKET_RESET} from '../../actions/distribution/actionDistributionTicket';

export const distributionAttachment = (state = {
    correlationId: null,
    isTicketAttachmentUploading: false,
    attachmentsForUpload: [],
}, action) => {
    switch (action.type) {
        case DISTRIBUTION_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_GENERATED_PRESIGNED_URLS: {
            const {attachments} = action.payload;
            return Object.assign({}, state, {
                attachmentsForUpload: Array.isArray(attachments) ? attachments : [],
            });
        }
        case DISTRIBUTION_ATTACHMENTS_UPLOADING: {
            return Object.assign({}, state, {isTicketAttachmentUploading: true});
        }
        //att done + error
        case distributionWebsocketCommands.DISTRIBUTION_ATTACHMENTS_SAVED_ERROR:
        case DISTRIBUTION_ATTACHMENTS_UPLOADING_ERROR: {
            return Object.assign({}, state, {
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_ATTACHMENTS_SAVED: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        default:
            return state;
    }
};
