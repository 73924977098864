import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../Loader';
import {
    initNewBusinessUnit,
    initNewBusinessUnitAfterFail,
    transformNewBusinessUnitForSave
} from '../../../../utils/businessUnitUtils';
import { createReqFields } from '../../../../constants/businessUnitRequiredFields';
import PageHeader from '../../../common/PageHeader';
import get from 'get-value';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    redirectReset,
    resetBusinessUnit,
    saveBusinessUnit
} from '../../../../actions/admin/actionBusinessUnit';
import BusinessUnitForm from './forms/BusinessUnitForm';
import {
    ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH,
} from '../../../../routes/paths';
import { BUSINESS_UNIT_FORM } from '../../../../constants/formConstants';
import {FormattedMessage, injectIntl} from 'react-intl';
import menuMessages from '../../../../intl/common/menuMessages';
class NewBusinessUnitPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetBusinessUnit();
    }
    componentWillUnmount() {
        this.props.redirectReset(this.props.domain);
    }

    handleSubmit = (values) => {
        const dataForSend = transformNewBusinessUnitForSave(values.newValues);
        this.setState(dataForSend);
        this.props.saveBusinessUnit(this.props.domain, dataForSend);
    };

    render() {
        const {
            buData,
            domain,
            intl: {formatMessage},
        } = this.props;

        if (buData.businessUnitSubmittedAndShouldRedirect && !buData.isBusinessUnitCreating && !buData.isBusinessUnitLoading && get(buData, 'businessUnit.id', { default: false })) {
            return <Redirect to={{
                pathname: `${ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH}/view/${buData.businessUnit.id}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (buData.isBusinessUnitCreating
            || buData.isBusinessUnitLoading
            || buData.businessUnitSubmittedAndShouldRedirect
        ) {
            return <Loader/>;
        }

        let initializationValue
        if(!buData.isBusinessUnitCreating && !buData.isBusinessUnitLoading && !buData.businessUnitSubmittedAndShouldRedirect && buData.initWithOldData && this.state){
            initializationValue = initNewBusinessUnitAfterFail(this.state, formatMessage);
        } else {
            initializationValue = initNewBusinessUnit();
        }

        const requiredFields = createReqFields['DEFAULT'][BUSINESS_UNIT_FORM];
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.businessUnit.title"
                                      defaultMessage="Business Unit Management - {domain} - Business Unit"
                                      values={{ domain: <FormattedMessage {...menuMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <BusinessUnitForm
                            form={BUSINESS_UNIT_FORM}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            domain={domain}
                            creating={true}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

NewBusinessUnitPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    buData: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any,
    resetBusinessUnit: PropTypes.func.isRequired,
    saveBusinessUnit: PropTypes.func.isRequired,
    isLoadingProfile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    clearAlerts: PropTypes.func.isRequired,
    buData: state.adminBusinessUnit,
    userDetail: state.profile.userDetail,
    isLoadingProfile: state.profile.isLoading,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    saveBusinessUnit,
    resetBusinessUnit,
    redirectReset,
})(injectIntl(NewBusinessUnitPage))), ['DB_ADMIN']);

