import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    fetchWorkingHours,
    resetWorkingHours,
    saveWorkingHours, updateBankHolidays,
    updateWorkingHours
} from '../../../actions/admin/actionAdminWorkingHours';
import {WorkingHoursBox} from './WorkingHours';
import Loader from '../../Loader';
import {Wrapper} from '../../common/StyledComponents';

class WorkingHoursContainer extends Component {
    constructor(props) {
        super(props);
        this.onSliderChange = this.onSliderChange.bind(this);
    }

    componentDidMount() {
        this.props.fetchWorkingHours(this.props.domain, this.props.group);
    }

    onSave = () => {
        const {domain, data, group} = this.props;
        const workingHoursSetting = {
            group: group,
            workingHours: data.workingHours,
            bankHolidays: data.bankHolidays
        };
        this.props.saveWorkingHours(domain, workingHoursSetting);
    };

    onCancel = () => {
        this.props.resetWorkingHours();
    };

    onSliderChange(day, values) {
        this.props.updateWorkingHours(day, values);
    }

    onBankHolidaysChange = (value) => {
        this.props.updateBankHolidays(value);
    }

    render() {
        const {isLoading, isDirty, data, group} = this.props;
        if (isLoading || !data) {
            return <Loader/>;
        }
        return (
            <Wrapper classname="container-fluid">
                <div className="tab-content" id="myTabContent">
                    <WorkingHoursBox disabled={!isDirty}
                                     countryWorkingHours={data.workingHours.days}
                                     countryBankHolidays={data.bankHolidays.days}
                                     onSliderChange={this.onSliderChange}
                                     onBankHolidaysChange={this.onBankHolidaysChange}
                                     onSave={this.onSave}
                                     onCancel={this.onCancel}
                                     group={group}/>
                </div>
            </Wrapper>
        );
    }
}

WorkingHoursContainer.propTypes = {
    domain: PropTypes.string.isRequired,
    loggedUser: PropTypes.object.isRequired,
    group: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    isDirty: PropTypes.bool,
    fetchWorkingHours: PropTypes.func.isRequired,
    resetWorkingHours: PropTypes.func.isRequired,
    saveWorkingHours: PropTypes.func.isRequired,
    updateWorkingHours: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminWorkingHours.isLoading,
    isDirty: state.adminWorkingHours.isDirty,
    data: state.adminWorkingHours.data,
    loggedUser: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, {
    fetchWorkingHours,
    resetWorkingHours,
    saveWorkingHours,
    updateWorkingHours,
    updateBankHolidays
})(WorkingHoursContainer);
