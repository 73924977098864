export const DEFAULT_DEBOUNCE_TIMEOUT = 500;
export const DEFAULT_FIELD_MAX_LENGTH = 50;
export const VIN_FIELD_LENGTH = 17;
export const REFERENCE_NUMBER_FIELD_LENGTH = 10;
export const FILE_MAX_SIZE = 100000000;
export const FILES_SIZE_BYTE = 40000000;
export const FILES_SIZE_MEGABYTE = 40;
export const DEFAULT_NOTE_FIELD_MAX_LENGTH = 3000;
export const DIGIT_STRING_VALIDATION_REGEX = /^[0-9]*$/;
export const COMMON_INPUT_VALIDATION_REGEX = /^[^}{\][]*$/;
export const COMMON_INPUT_VALIDATION_WITH_BRACKETS_REGEX = /^[^}{]*$/;
export const EMAIL_FIELD = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const BANK_HOLIDAYS_INPUT = /^(20[0-4][0-9]|2050)[-](0?[1-9]|1[0-2])[-](0?[1-9]|[12][0-9]|3[01])$/;
