import React from 'react';
import { connect } from 'react-redux';
import {change, formValueSelector} from 'redux-form';
import { InputFieldWithValidation } from '../../../../../../common/fields/InputFieldWithValidation';
import buMessages from '../../../../../../../intl/admin/businessUnitMessages';
import {
    FIELD_BU_NAME,
    LABEL_BU_NAME
} from '../../../../../../../constants/formConstants';
import BorderRadius from '../../../../../../common/styled/BorderRadius';


const InfoSection = (props) => {
    const {
        readOnly, reqFields,
    } = props;

    return (
        <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
            <div className="form-row">
                <div className="col pb-2">
                    <InputFieldWithValidation
                        label={buMessages[LABEL_BU_NAME]}
                        field={FIELD_BU_NAME}
                        isDisabled={readOnly}
                        isRequired={reqFields.includes(FIELD_BU_NAME) && !readOnly}
                        isSmall/>
                </div>
            </div>
        </BorderRadius>
    )

};

const mapStateToProps = (state, props) => ({

});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoSection);
