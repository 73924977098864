import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {domains} from '../../constants/Utils';

export const fetchBusinessUnitListPageData = (domain, pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            dispatch({
                type: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_LIST_FETCH_PENDING,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_LIST,
                payload: {pageSize: pageSize, sorted: sorted, cursor: cursor, isNextFromPivot: isNextFromPivot, filtered: filtered}
            });
            break;
        }
    }
};
