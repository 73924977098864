import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    INFORMATION: {
        id: 'support.header.information',
        defaultMessage: 'Information'
    },
    USER_CUSTOMIZATION: {
        id: 'support.header.userCustomization',
        defaultMessage: 'User Customization'
    },
    MULTI_SOURCING_COUNTRIES: {
        id: 'support.header.multiSourcingCountries',
        defaultMessage: 'Multisourcing Countries'
    },
    VERSION: {
        id: 'support.version',
        defaultMessage: 'Version'
    },
    CALENDAR_VERSION: {
        id: 'support.calendarVersion',
        defaultMessage: 'Calendar Version'
    },
    RESP_HELP_DESK: {
        id: 'support.responsibleHelpDesk',
        defaultMessage: 'Responsible HELP-DESK'
    },
    PHONE: {
        id: 'support.phone',
        defaultMessage: 'Phone'
    },
    EMAIL: {
        id: 'support.email',
        defaultMessage: 'E-mail'
    },
    LANGUAGE: {
        id: 'support.language',
        defaultMessage: 'Language'
    },
    LOCATION: {
        id: 'support.location',
        defaultMessage: 'Location'
    },
    TIMEZONE: {
        id: 'support.timezone',
        defaultMessage: 'Time Zone'
    },
});

export default messages;
