import {defineMessages} from 'react-intl';

const messages = defineMessages({
    PHONE: {
        id: 'contact.phone',
        defaultMessage: 'Phone'
    },
    EMAIL: {
        id: 'contact.email',
        defaultMessage: 'Email'
    },
    OTHER: {
        id: 'contact.other',
        defaultMessage: 'Other'
    },
});

export default messages;
