import Joi from 'joi';

export function validateBusinessUnitCreateEvent(businessUnitCreateEvent) {
    return Joi.validate(
        businessUnitCreateEvent,
        Joi.object().required().keys({
            id: Joi.string().required().guid({version: ['uuidv4']}),
            name: Joi.string().required(),
            groups: Joi.array().required(),
        })
    );
}

export function validateBusinessUnitUpdateEvent(businessUnitUpdateEvent) {
    return Joi.validate(
        businessUnitUpdateEvent,
        Joi.object().required().keys({
            id: Joi.string().required().guid({version: ['uuidv4']}),
            name: Joi.string(),
            groups: Joi.array().required(),
        })
    );
}
