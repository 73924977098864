import {
    DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_DEALER,
    DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    DISTRIBUTION_TICKET_STATUS_NEW,
    DISTRIBUTION_TICKET_STATUS_REOPEN, DISTRIBUTION_TICKET_STATUS_WORK_IN_PROGRESS
} from './formConstants';

export const editDistributionListButtonVisibility = {
    DEALER: [
        DISTRIBUTION_TICKET_STATUS_NEW,
        DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_DEALER,
        DISTRIBUTION_TICKET_STATUS_WORK_IN_PROGRESS,
    ],
    EDITOR: [
        DISTRIBUTION_TICKET_STATUS_NEW,
        DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
        DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_DEALER,
        DISTRIBUTION_TICKET_STATUS_WORK_IN_PROGRESS,
        DISTRIBUTION_TICKET_STATUS_REOPEN
    ]
};

export const createRights = {
    // substitution section
    canShowSubstitutionSection: true,
    // dealer section
    canShowDealerSection: false,
    // ticket section
    canShowAssignee: false,
    canEditContact: true,
    canEditSubject: true,
    canEditPriority: true,
    canEditBrand: true,
    canEditModel: true,
    canEditVersion: true,
    canEditVin: true,
    canEditOrderNumber: true,
    canEditOption: true,
    canEditDhs: true,
    canOpenFrequentQuestion: true,
    // message section
    canShowAutomaticResponse: false,
    canAddMessage: true,
    canAddSendCopyTo: true,
    // internal notes section
    canAddInternalNotes: false,
    canShowInternalNotes: false,
    // attachment section
    canAddAttach: true,
    // buttons
    canSave: true,
    canClose: false,
    canForwardToDealer: false,
    canForwardToNPDC: false,
    canWorkInProgress: false,
    canReopen: false,
    canSkipModalReopen: false,
};

export const editRights = {
    DEALER: {
        NEW: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canShowAssignee: false,
            canEditContact: false,
            canEditSubject: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: false,
            canAddSendCopyTo: false,
            // internal notes section
            canAddInternalNotes: false,
            canShowInternalNotes: false,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: true,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        },
        CLOSED: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canShowAssignee: false,
            canEditContact: false,
            canEditSubject: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: false,
            canAddSendCopyTo: false,
            // internal notes section
            canAddInternalNotes: false,
            canShowInternalNotes: false,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: true,
            canSkipModalReopen: false,
        },
        WAIT_SUBSIDIARY: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canShowAssignee: false,
            canEditContact: false,
            canEditSubject: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: false,
            canAddSendCopyTo: false,
            // internal notes section
            canAddInternalNotes: false,
            canShowInternalNotes: false,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        },
        WAIT_DEALER: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section field
            canShowAssignee: false,
            canEditContact: false,
            canEditSubject: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // internal notes section
            canAddInternalNotes: false,
            canShowInternalNotes: false,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: true,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        },
        WAIT_WORK_IN_PROGRESS: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section field
            canShowAssignee: false,
            canEditContact: false,
            canEditSubject: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // internal notes section
            canAddInternalNotes: false,
            canShowInternalNotes: false,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: true,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        },
        REOPEN: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canShowAssignee: false,
            canEditContact: false,
            canEditSubject: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: false,
            canAddSendCopyTo: false,
            // internal notes section
            canAddInternalNotes: false,
            canShowInternalNotes: false,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        }
    },
    EDITOR: {
        NEW: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canShowAssignee: true,
            canEditContact: false,
            canEditSubject: true,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: true,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // internal notes section
            canAddInternalNotes: true,
            canShowInternalNotes: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: true,
            canClose: true,
            canForwardToDealer: true,
            canForwardToNPDC: false,
            canWorkInProgress: true,
            canReopen: false,
            canSkipModalReopen: false,
        },
        CLOSED: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canShowAssignee: true,
            canEditContact: false,
            canEditSubject: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: false,
            canAddSendCopyTo: false,
            // internal notes section
            canAddInternalNotes: false,
            canShowInternalNotes: true,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: true,
        },
        WAIT_SUBSIDIARY: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canShowAssignee: true,
            canEditContact: false,
            canEditSubject: true,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: true,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // internal notes section
            canAddInternalNotes: true,
            canShowInternalNotes: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: false,
            canClose: true,
            canForwardToDealer: true,
            canForwardToNPDC: false,
            canWorkInProgress: true,
            canReopen: false,
            canSkipModalReopen: false,
        },
        WAIT_DEALER: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canShowAssignee: true,
            canEditContact: false,
            canEditSubject: true,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: true,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // internal notes section
            canAddInternalNotes: true,
            canShowInternalNotes: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: true,
            canClose: true,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: true,
            canReopen: false,
            canSkipModalReopen: false,
        },
        WAIT_WORK_IN_PROGRESS: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canShowAssignee: true,
            canEditContact: false,
            canEditSubject: true,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: true,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // internal notes section
            canAddInternalNotes: true,
            canShowInternalNotes: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: true,
            canClose: true,
            canForwardToDealer: true,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        },
        REOPEN: {
            // substitution section
            canShowSubstitutionSection: false,
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canShowAssignee: true,
            canEditContact: false,
            canEditSubject: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditVersion: false,
            canEditVin: false,
            canEditOrderNumber: false,
            canEditOption: false,
            canEditDhs: false,
            canOpenFrequentQuestion: false,
            // message section
            canShowAutomaticResponse: true,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // internal notes section
            canAddInternalNotes: true,
            canShowInternalNotes: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: false,
            canClose: true,
            canForwardToDealer: true,
            canForwardToNPDC: false,
            canWorkInProgress: true,
            canReopen: false,
            canSkipModalReopen: false,
        }
    }
};
