import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const fetchDistributionDealers = () => (dispatch) => {
    dispatch({
        type: distributionWebsocketCommands.DISTRIBUTION_DEALERS_GET,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_DEALERS_GET,
        payload: {}
    });
};
