import React from 'react';
import {
    COMMERCIAL_EXPORT_PATH,
    COMMERCIAL_FULL_TEXT_SEARCH_PATH,
    COMMERCIAL_HISTORY_PATH,
    COMMERCIAL_LIST_PATH, COMMERCIAL_NEW_OFFER_FOR_EMPLOYEES_PATH,
    COMMERCIAL_NEW_PATH, COMMERCIAL_NEW_TICKET_PATH,
    COMMERCIAL_NOTIFICATION_PATH,
} from '../../../../routes/paths';
import {FormattedMessage} from 'react-intl';
import menuMessages from '../../../../intl/common/menuMessages';
import {moduleRoles} from '../../../../utils/roles';
import {domains} from '../../../../constants/Utils';
import formTypeMessages from '../../../../intl/modules/formTypeMessages';
import {COMMERCIAL_FORM, GENERAL_QUESTION_FORM, OFFER_FOR_EMPLOYEES_FORM} from '../../../../constants/formConstants';

export const CommercialMenuEditor = [
    {
        label: <FormattedMessage {...menuMessages.LIST}/>,
        to: COMMERCIAL_LIST_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.HISTORY}/>,
        to: COMMERCIAL_HISTORY_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.FULL_TEXT_SEARCH}/>,
        to: COMMERCIAL_FULL_TEXT_SEARCH_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.NOTIFICATION}/>,
        to: COMMERCIAL_NOTIFICATION_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.EXPORT}/>,
        to: COMMERCIAL_EXPORT_PATH
    }
];

export const getCommercialDealerMenu = (roles) => {
    const commercialNewFormSubmenu = buildCommercialNewFormSubmenu(roles);
    const result = [
        {
            label: <FormattedMessage {...menuMessages.LIST}/>,
            to: COMMERCIAL_LIST_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.HISTORY}/>,
            to: COMMERCIAL_HISTORY_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.FULL_TEXT_SEARCH}/>,
            to: COMMERCIAL_FULL_TEXT_SEARCH_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.NOTIFICATION}/>,
            to: COMMERCIAL_NOTIFICATION_PATH
        },
    ];

    if (commercialNewFormSubmenu.length > 0) {
        result.unshift(
            {
                label: <FormattedMessage {...menuMessages.NEW}/>,
                content: commercialNewFormSubmenu
            }
        );
    }

    return result;
};

const buildCommercialNewFormSubmenu = roles => {
    const result = [];

    const formTypes = moduleRoles.getDealerForms(domains.COMMERCIAL, roles).map(formType => formType.group);

    if (formTypes.includes(COMMERCIAL_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[COMMERCIAL_FORM]}/>,
                to: COMMERCIAL_NEW_TICKET_PATH
            }
        );
    }
    if (formTypes.includes(OFFER_FOR_EMPLOYEES_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[OFFER_FOR_EMPLOYEES_FORM]}/>,
                to: COMMERCIAL_NEW_OFFER_FOR_EMPLOYEES_PATH
            }
        );
    }

    return result;
};
