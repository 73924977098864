import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {domains} from '../../constants/Utils';

export const ADMIN_NOTIFICATION_SELECT_GROUP = 'ADMIN_NOTIFICATION_SELECT_GROUP';
export const ADMIN_NOTIFICATION_FETCH = 'ADMIN_NOTIFICATION_FETCH';
export const ADMIN_NOTIFICATION_UPDATE = 'ADMIN_NOTIFICATION_UPDATE';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_NOTIFICATION_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchNotification = (domain, group) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_NOTIFICATION_FETCH,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_NOTIFICATIONS_GET,
                payload: {group}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_NOTIFICATION_FETCH,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_NOTIFICATIONS_GET,
                payload: {group}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_NOTIFICATION_FETCH,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_NOTIFICATIONS_GET,
                payload: {group}
            });
        }
    }
};

export const updateNotification = (domain, notification) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_NOTIFICATION_UPDATE,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_NOTIFICATIONS_SET,
                payload: {notification}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_NOTIFICATION_UPDATE,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_NOTIFICATIONS_SET,
                payload: {notification}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_NOTIFICATION_UPDATE,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_NOTIFICATIONS_SET,
                payload: {notification}
            });
        }
    }
};

