import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Field} from 'redux-form';

const RadioFields = (props) => {
    const {
        label, field, style, intlMessages, options,
    } = props;

    return (
        <div>
            {!!label &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label} />
            </label>}
            {options.map((item, index) => (
                <div key={index}>
                    <Field
                        name={field}
                        component="input"
                        type="radio"
                        value={item}
                        style={style}
                    />
                    <FormattedMessage {...intlMessages[item]} />
                </div>
            ))}
        </div>
    )
};
export default RadioFields;



