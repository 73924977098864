import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import { Redirect, withRouter } from 'react-router-dom';
import { clearAlerts } from '../../../../actions/alertsActions';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH} from '../../../../routes/paths';
import ListBusinessUnitTable from './ListBusinessUnitTable';
import menuMessages from '../../../../intl/common/menuMessages';
import businessUnitMessages from '../../../../intl/admin/businessUnitMessages';
import countryGroupMessages from '../../../../intl/common/countryGroupMessages';

class ListBusinessUnitPage extends Component {
    constructor(props) {
        super(props);
        const { intl, userGroups } = props;

        let adminCountries = {};
        for (const [key, value] of Object.entries(userGroups)) {
            if(value){
                adminCountries[key] = value;
            }
        }

        this.state = {
            ratchet: false,
            businessUnit: '',
            columnLibrary: [
                {
                    id: 'group',
                    accessor: row => this.formatCountryLabel(row.groups, intl),
                    message: businessUnitMessages.TABLE_COUNTRIES,
                    Filter: ({ filter, onChange }) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%', border: '1px solid #999999' }}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(countryGroupMessages.ALL)}</option>
                            {Object.keys(adminCountries).map((key, index) =>
                                <option key={index} value={key}>
                                    {countryGroupMessages[key] ? intl.formatMessage(countryGroupMessages[key]) : key}
                                </option>)}
                        </select>
                    ),
                },
            ],
        };
    }

    formatCountryLabel = (value, intl) => {
        let result = '';
        if (value) {
            value.map(country => result += intl.formatMessage(countryGroupMessages[country]) + ', ')
        }
        return result.replace(/,\s$/, '');
    };

    handleEditClick = ({ id }) => {
        this.setState({ ratchet: true, businessUnit: id });
    };

    render() {
        const { filter, domain } = this.props;
        const { columnLibrary, businessUnit } = this.state;

        if (this.state.ratchet) {
            return <Redirect to={`${ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH}/edit/${businessUnit}`}/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.businessUnit.listTitle"
                                      defaultMessage="Business Unit Management - {domain} - Business Unit List"
                                      values={{ domain: <FormattedMessage {...menuMessages[domain]} /> }} />
                } />
                <ListBusinessUnitTable
                    columns={columnLibrary}
                    handleEditClick={this.handleEditClick} filter={filter} domain={domain}/>
            </div>
        );
    }
}

ListBusinessUnitPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
};

const mapStateToProps = (state, initialProps) => ({
    userGroups: state.profile.userDetail.roles.DISTRIBUTION.admin.groupPermission,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
})(injectIntl(ListBusinessUnitPage))), ['DB_ADMIN']);
