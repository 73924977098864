import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'mainPage.title',
        defaultMessage: 'Welcome'
    },
    STATISTICS: {
        id: 'mainPage.statistics',
        defaultMessage: 'Statistics'
    },
});

export default messages;
