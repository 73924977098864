import React from 'react';
import {required, validInput, validInputwithBrackets} from '../validationCommons';
import {Field} from 'redux-form';
import {
    DEFAULT_NOTE_FIELD_MAX_LENGTH,
} from '../../../constants/validationConstants';
import {TextareaWithValidation} from './TextareaWithValidation';
import {RequiredSpan} from '../StyledComponents';
import {FormattedMessage} from 'react-intl';

export const TextareaFieldWithValidation = (props) => {
    const {label, field, rows, isRequired, maxLength, isDisabled, placeholder, allowBrackets} = props;
    let validations;

    if(allowBrackets){
        validations = [validInputwithBrackets];
    } else {
        validations = [validInput];
    }

    if (isRequired) {
        validations.push(required);
    }
    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>}
            <Field
                name={field} component={TextareaWithValidation} type="text"
                placeholder={placeholder}
                maxLength={maxLength ? maxLength : DEFAULT_NOTE_FIELD_MAX_LENGTH}
                validate={validations}
                rows={rows}
                isDisabled={isDisabled}
            />
        </div>
    )
};
