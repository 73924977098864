import React from 'react'
import {Field, reduxForm} from 'redux-form'
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import buttonMessages from '../../../../../intl/common/buttonMessages';

const Button = styled.button`
    &&{
        background-color: ${props => (props.isPristine ? '' : '#ffcc33')};
        color: black;
    };
`;

const InputFullTextForm = props => {
    const {handleSubmit, pristine, submitting} = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
                <Field
                    name="fulltext"
                    component="input"
                    type="text"
                    placeholder="Empty"
                    onInput={(e) => {
                        let value = e.target.value

                        value = value.replace(/[^A-Za-z0-9 \-,.'&]/ig, '')

                        e.target.value = value;
                    }}
                    className="form-control"/>
                <div className="input-group-append">
                    <Button
                        type="submit"
                        className="btn btn-outline-secondary"
                        disabled={pristine || submitting}
                        isPristine={!!pristine}>
                        <FormattedMessage {...buttonMessages.SEARCH}/>
                    </Button>
                </div>
            </div>
        </form>
    )
};

InputFullTextForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool
};

export default reduxForm({})(InputFullTextForm)
