import 'font-awesome/css/font-awesome.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import MetisMenu from 'react-metismenu';
import RouterLink from 'react-metismenu-router-link'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {PRIMARY_DARK_GREY, PRIMARY_WHITE} from '../../../theme/colors';
import {getAdministrationMenuAdmin} from './structure/AdministrationMenu';
import {getDistributionDealerMenu, DistributionMenuEditor} from './structure/DistributionMenu';
import {CommercialMenuEditor, getCommercialDealerMenu} from './structure/CommercialMenu';
import {getMarketingDealerMenu, MarketingMenuEditor} from './structure/MarketingMenu';
import {moduleRoles} from '../../../utils/roles';
import 'font-awesome/css/font-awesome.css';

const Menu = styled.div`
    padding-top: 15px;
    width: 283px;
    transition: all 0.5s ease;
    direction: rtl;
`;
Menu.displayName = 'Menu';

const ScrollBarLeft = styled.div`
    direction: ltr;
    transition: all 0.5s ease;
`;
ScrollBarLeft.displayName = 'ScrollBarLeft';

const Wrapper = styled.div`
    ${props => props.sideBarShow ? 'margin-left: -280px' : 'margin-left: 0px'};
    width: 308px;
    height: 100%;
    display: flex;
    transition: all 0.5s ease;
    padding-left: 3px;
`;
Wrapper.displayName = 'Wrapper';

const ChevronCol = styled.div`
    border-right: 1px solid ${PRIMARY_DARK_GREY};
    width: 28px;
    padding-top: 50px;
    margin-left: 3px;
`;

const ChevronButton = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 25px;
    cursor: pointer;
    height: 50px;
    background-color: ${PRIMARY_WHITE};
    border: 1px solid ${PRIMARY_DARK_GREY};
    border-right: none;
    border-radius: 0.3em 0 0 0.3em;
    margin-left: 3px;
`;
ChevronCol.displayName = 'ChevronCol';

const ChevronIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    margin-left: 5px;
`;
ChevronIcon.displayName = 'ChevronIcon';

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {sideBarShow: false, sideBarContent: []};
    }

    componentDidMount() {
        this.createSideBarContent();
    }

    componentDidUpdate() {
        this.createSideBarContent();
    }

    handleChevronClick = e => {
        e.preventDefault();
        this.setState({sideBarShow: !this.state.sideBarShow});
    };

    createSideBarContent = () => {
        const {administration = false, distribution = false, commercial = false, marketing = false, roles} = this.props;
        let sideBarContent = [];
        if (administration && (moduleRoles.isAdmin(roles) || moduleRoles.isPowerUser(roles))) {
            sideBarContent = getAdministrationMenuAdmin(roles);
        } else if (distribution && moduleRoles.isDealerDistribution(roles)) {
            sideBarContent = getDistributionDealerMenu(roles);
        } else if (distribution && !moduleRoles.isDealerDistribution(roles) && moduleRoles.isEditorDistribution(roles)) {
            sideBarContent = [...DistributionMenuEditor,];
        } else if (commercial && moduleRoles.isDealerCommercial(roles)) {
            sideBarContent = getCommercialDealerMenu(roles);
        } else if (commercial && !moduleRoles.isDealerCommercial(roles) && moduleRoles.isEditorCommercial(roles)) {
            sideBarContent = [...CommercialMenuEditor,];
        } else if (marketing && moduleRoles.isDealerMarketing(roles)) {
            sideBarContent = getMarketingDealerMenu(roles);
        } else if (marketing && !moduleRoles.isDealerMarketing(roles) && moduleRoles.isEditorMarketing(roles)) {
            sideBarContent = [...MarketingMenuEditor,];
        }
        if (JSON.stringify(this.state.sideBarContent) !== JSON.stringify(sideBarContent)) {
            this.setState({sideBarContent});
        }
    };

    render() {
        return (
            <Wrapper sideBarShow={this.state.sideBarShow}>
                <Menu>
                    <ScrollBarLeft>
                        <MetisMenu
                            content={this.state.sideBarContent}
                            LinkComponent={RouterLink}
                            useExternalReduxStore={this.props.store}
                        />
                    </ScrollBarLeft>
                </Menu>
                <ChevronCol>
                    <ChevronButton sideBarShow={this.state.sideBarShow} onClick={this.handleChevronClick}>
                        <ChevronIcon icon={this.state.sideBarShow ? 'chevron-right' : 'chevron-left'}/>
                    </ChevronButton>
                </ChevronCol>
            </Wrapper>
        );
    }
}

SideMenu.propTypes = {
    store: PropTypes.object.isRequired,
    administration: PropTypes.bool,
    distribution: PropTypes.bool,
    commercial: PropTypes.bool,
    marketing: PropTypes.bool,
    roles: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, {})(SideMenu);
