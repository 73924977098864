import 'react-picky/dist/picky.css';
import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import get from 'get-value';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import {
    fetchMarketingPreferences,
    updateMarketingUserHistoryPreference
} from '../../../actions/marketing/actionMarketingPreferences';
import {MarketingColumnLibrary} from '../../common/table/MarketingColumnLibrary';
import {fetchSubjects} from '../../../actions/admin/actionAdminSubjectSettings';
import {clearAlerts} from '../../../actions/alertsActions';
import {StyledPicky} from '../../common/StyledComponents';
import {domains} from '../../../constants/Utils';
import checkRoles from '../../common/roleChecker/RoleChecker';
import HistoryMarketingTable from './HistoryMarketingTable';
import DatePickerTableComponent from '../../common/datePicker/DatePickerTableComponent';
import PageHeader from '../../common/PageHeader';
import Loader from '../../Loader';
import historyMessages from '../../../intl/modules/historyMessages';
import tableMessages from '../../../intl/common/tableMessages';

const domain = domains.MARKETING;

class HistoryMarketingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratchet: false,
        }
    }

    componentDidMount() {
        this.props.fetchMarketingPreferences();
    }

    render() {
        const {intl, marketingPreferences, isLoadingSubjects} = this.props;
        const columnLibrary = [
            ...MarketingColumnLibrary(this.props, domain),
            {
                id: 'CLOSING_DATE',
                accessor: 'closed',
                message: tableMessages.CLOSING_DATE,
                Filter: ({filter, onChange}) => {
                    let value = filter && filter.value;
                    if (typeof value === 'string') {
                        value = moment(value).toDate();
                    }
                    return <DatePickerTableComponent
                        value={value || undefined}
                        onChange={onChange}
                        filter={filter}/>;
                },
                Cell: e => <FormattedDate value={new Date(e.value)}/>,
            },
        ];
        const selectMultipleOption = (value) => {
            this.props.updateMarketingUserHistoryPreference(
                columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
        };

        if (marketingPreferences.isLoading
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...historyMessages.TITLE}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold">
                            <FormattedMessage {...tableMessages.VISIBLE_COLUMNS}/>
                            {':'}
                        </span>
                        <StyledPicky
                            value={columnLibrary.filter(item => !get(
                                marketingPreferences,
                                'menuHistory',
                                {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <HistoryMarketingTable
                    columns={
                        columnLibrary.filter(item =>
                            !get(marketingPreferences, 'menuHistory', {default: []}).includes(item.id))
                    }
                />
            </div>
        );
    }
}

HistoryMarketingPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    loggedUser: PropTypes.object.isRequired,
    marketingPreferences: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    updateMarketingUserHistoryPreference: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    marketingPreferences: state.marketingPreferences,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    userDetail: state.profile.userDetail,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateMarketingUserHistoryPreference,
    fetchMarketingPreferences,
    fetchSubjects,
})(injectIntl(HistoryMarketingPage))), ['MA_DEALER', 'MA_EDITOR']);
