import React from 'react';
import {reduxForm} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import * as PropTypes from 'prop-types';
import buttonMessages from '../../../intl/common/buttonMessages';
import {withRouter} from 'react-router-dom';
import {BoldPrimaryButton, SecondaryButton} from '../Button';

const ButtonSection = (props) => {
    const {pristine, submitting, onSubmit, onCancel} = props;

    return (
        <div className="d-flex justify-content-end mt-4">
            <form className="form-group">
                <BoldPrimaryButton type="submit"
                                   disabled={pristine || submitting}
                                   className="btn float-right"
                                   onClick={onSubmit}>
                    <FormattedMessage {...buttonMessages.SAVE}/>
                </BoldPrimaryButton>
                <SecondaryButton type="button"
                                 disabled={pristine || submitting}
                                 className="btn float-right mr-2"
                                 onClick={onCancel}>
                    <FormattedMessage {...buttonMessages.CANCEL}/>
                </SecondaryButton>
            </form>
        </div>
    )
};

ButtonSection.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
};

export default reduxForm({})(withRouter(ButtonSection));
