import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {
    MANAGED_USER,
    SELECTED_ADMIN_COUNTRY_OPTIONS,
    ADMIN_COUNTRIES_CHANGED,
    ADMIN_RIGHTS_FETCH,
    ADMIN_RIGHTS_SAVE,
    ADMIN_RIGHTS_CLEAR,
    ADMIN_RIGHTS_FETCH_SUCCESS,
    SELECTED_EDITOR_COUNTRY_OPTIONS,
    EDITOR_COUNTRIES_CHANGED,
    EDITOR_RIGHTS_FETCH,
    EDITOR_RIGHTS_SAVE,
    EDITOR_RIGHTS_CLEAR,
    EDITOR_RIGHTS_FETCH_SUCCESS,
    SELECTED_DEALER_FORM_TYPE_OPTIONS,
    DEALER_FORM_TYPES_CHANGED,
    DEALER_RIGHTS_FETCH,
    DEALER_RIGHTS_SAVE,
    DEALER_RIGHTS_CLEAR,
    DEALER_RIGHTS_FETCH_SUCCESS,
    RIGHTS_ERROR,
    INITIAL_ADMIN_COUNTRY_OPTIONS,
    INITIAL_DEALER_FORM_TYPE_OPTIONS,
    INITIAL_EDITOR_COUNTRY_OPTIONS,
    INITIAL_EXTERNAL_EDITOR_COUNTRY_OPTIONS,
    SELECTED_EXTERNAL_EDITOR_COUNTRY_OPTIONS,
    EXTERNAL_EDITOR_COUNTRIES_CHANGED,
    EXTERNAL_EDITOR_RIGHTS_FETCH,
    EXTERNAL_EDITOR_RIGHTS_SAVE,
    EXTERNAL_EDITOR_RIGHTS_CLEAR, EXTERNAL_EDITOR_RIGHTS_FETCH_SUCCESS,
} from '../../actions/admin/actionAdminRightsManagement';

export const adminRightsManagement = (state = {
    isLoading: false,
    managedUser: {},
    adminData: {},
    initialAdminOptions: [],
    selectedAdminOptions: null,
    adminCountriesChanged: false,
    editorData: {},
    initialEditorOptions: [],
    selectedEditorOptions: null,
    editorCountriesChanged: false,
    externalEditorData: {},
    initialExternalEditorOptions: [],
    selectedExternalEditorOptions: null,
    externalEditorCountriesChanged: false,
    dealerData: {},
    initialDealerOptions: [],
    selectedDealerOptions: null,
    dealerFormTypesChanged: false,
}, action) => {
    switch (action.type) {
        case MANAGED_USER: {
            return Object.assign({}, state, {
                adminCountriesChanged: false,
                editorCountriesChanged: false,
                dealerFormTypesChanged: false,
                managedUser: action.payload
            });
        }
        case INITIAL_ADMIN_COUNTRY_OPTIONS: {
            return Object.assign({}, state, {initialAdminOptions: action.payload,})
        }
        case SELECTED_ADMIN_COUNTRY_OPTIONS: {
            return Object.assign({}, state, {selectedAdminOptions: action.payload})
        }
        case ADMIN_COUNTRIES_CHANGED: {
            return Object.assign({}, state, {adminCountriesChanged: action.payload})
        }
        case ADMIN_RIGHTS_FETCH: {
            return Object.assign({}, state, {isLoading: true});
        }
        case ADMIN_RIGHTS_SAVE: {
            return Object.assign({}, state, {isLoading: true});
        }
        case ADMIN_RIGHTS_CLEAR: {
            return Object.assign({}, state, {adminData: {}});
        }
        case ADMIN_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                adminData: action.payload.finalResult,
                correlationId: action.payload.correlationId
            });
        }

        case INITIAL_EDITOR_COUNTRY_OPTIONS: {
            return Object.assign({}, state, {initialEditorOptions: action.payload})
        }
        case SELECTED_EDITOR_COUNTRY_OPTIONS: {
            return Object.assign({}, state, {selectedEditorOptions: action.payload})
        }
        case EDITOR_COUNTRIES_CHANGED: {
            return Object.assign({}, state, {editorCountriesChanged: action.payload})
        }
        case EDITOR_RIGHTS_FETCH: {
            return Object.assign({}, state, {isLoading: true});
        }
        case EDITOR_RIGHTS_SAVE: {
            return Object.assign({}, state, {isLoading: true});
        }
        case EDITOR_RIGHTS_CLEAR: {
            return Object.assign({}, state, {editorData: {}});
        }
        case EDITOR_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                editorData: action.payload.finalResult,
                editorCorrelationId: action.payload.correlationId
            });
        }

        case INITIAL_EXTERNAL_EDITOR_COUNTRY_OPTIONS: {
            return Object.assign({}, state, {initialExternalEditorOptions: action.payload})
        }
        case SELECTED_EXTERNAL_EDITOR_COUNTRY_OPTIONS: {
            return Object.assign({}, state, {selectedExternalEditorOptions: action.payload})
        }
        case EXTERNAL_EDITOR_COUNTRIES_CHANGED: {
            return Object.assign({}, state, {externalEditorCountriesChanged: action.payload})
        }
        case EXTERNAL_EDITOR_RIGHTS_FETCH: {
            return Object.assign({}, state, {isLoading: true});
        }
        case EXTERNAL_EDITOR_RIGHTS_SAVE: {
            return Object.assign({}, state, {isLoading: true});
        }
        case EXTERNAL_EDITOR_RIGHTS_CLEAR: {
            return Object.assign({}, state, {externalEditorData: {}});
        }
        case EXTERNAL_EDITOR_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                externalEditorData: action.payload.finalResult,
                externalEditorCorrelationId: action.payload.correlationId
            });
        }

        case INITIAL_DEALER_FORM_TYPE_OPTIONS: {
            return Object.assign({}, state, {initialDealerOptions: action.payload})
        }
        case SELECTED_DEALER_FORM_TYPE_OPTIONS: {
            return Object.assign({}, state, {selectedDealerOptions: action.payload})
        }
        case DEALER_FORM_TYPES_CHANGED: {
            return Object.assign({}, state, {dealerFormTypesChanged: action.payload})
        }
        case DEALER_RIGHTS_FETCH: {
            return Object.assign({}, state, {isLoading: true});
        }
        case DEALER_RIGHTS_SAVE: {
            return Object.assign({}, state, {isLoading: true});
        }
        case DEALER_RIGHTS_CLEAR: {
            return Object.assign({}, state, {dealerData: {}});
        }
        case DEALER_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                dealerData: action.payload.finalResult,
                dealerCorrelationId: action.payload.correlationId
            });
        }

        case RIGHTS_ERROR: {
            const {correlationId} = action.payload;
            return Object.assign({}, state, {isLoading: false, correlationId});
        }

        case distributionWebsocketCommands.DISTRIBUTION_ADMIN_ROLE_SEND_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_ADMIN_ROLE_SEND_ERROR:
        case marketingWebsocketCommands.MARKETING_ADMIN_ROLE_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId});
        }
        case distributionWebsocketCommands.DISTRIBUTION_EDITOR_ROLE_SEND_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_EDITOR_ROLE_SEND_ERROR:
        case marketingWebsocketCommands.MARKETING_EDITOR_ROLE_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.editorCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, editorCorrelationId: correlationId});
        }
        case distributionWebsocketCommands.DISTRIBUTION_DEALER_ROLE_SEND_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_DEALER_ROLE_SEND_ERROR:
        case marketingWebsocketCommands.MARKETING_DEALER_ROLE_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.dealerCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, dealerCorrelationId: correlationId});
        }
        case distributionWebsocketCommands.DISTRIBUTION_ROLES_SEND_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_ROLES_SEND_ERROR:
        case marketingWebsocketCommands.MARKETING_ROLES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId});
        }

        default: {
            return state;
        }
    }
};
