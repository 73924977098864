import React from 'react';
import {
    DISTRIBUTION_EXPORT_PATH,
    DISTRIBUTION_FULL_TEXT_SEARCH_PATH,
    DISTRIBUTION_HISTORY_PATH,
    DISTRIBUTION_LIST_PATH,
    DISTRIBUTION_NEW_PATH,
    DISTRIBUTION_NOTIFICATION_PATH,
} from '../../../../routes/paths';
import {FormattedMessage} from 'react-intl';
import menuMessages from '../../../../intl/common/menuMessages';

export const DistributionMenuEditor = [
    {
        label: <FormattedMessage {...menuMessages.NEW}/>,
        to: DISTRIBUTION_NEW_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.LIST}/>,
        to: DISTRIBUTION_LIST_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.HISTORY}/>,
        to: DISTRIBUTION_HISTORY_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.FULL_TEXT_SEARCH}/>,
        to: DISTRIBUTION_FULL_TEXT_SEARCH_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.NOTIFICATION}/>,
        to: DISTRIBUTION_NOTIFICATION_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.EXPORT}/>,
        to: DISTRIBUTION_EXPORT_PATH
    }
];

export const getDistributionDealerMenu = () => {
    return [
        {
            label: <FormattedMessage {...menuMessages.NEW}/>,
            to: DISTRIBUTION_NEW_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.LIST}/>,
            to: DISTRIBUTION_LIST_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.HISTORY}/>,
            to: DISTRIBUTION_HISTORY_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.FULL_TEXT_SEARCH}/>,
            to: DISTRIBUTION_FULL_TEXT_SEARCH_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.NOTIFICATION}/>,
            to: DISTRIBUTION_NOTIFICATION_PATH
        },
    ];
};
