import React from 'react';
import {FormattedMessage} from 'react-intl';
import AsyncCreatableSelect from 'react-select/async-creatable';
import ValidFormattedMessage from '../ValidFormattedMessage';
import validationMessages from '../../../intl/common/validationMessages';
import styled from 'styled-components';
import formMessages from '../../../intl/modules/formMessages';
import commonMessages from '../../../intl/common/commonMessages';
import tableMessages from '../../../intl/common/tableMessages';
import {DEFAULT_DEBOUNCE_TIMEOUT, EMAIL_FIELD} from '../../../constants/validationConstants';
import {debounce} from 'throttle-debounce';
import buttonMessages from '../../../intl/common/buttonMessages';

const ErrorDiv = styled.label`
    display: inherit;
`;

export const EmailSelector = props => {
    const {input: { onChange }, label, meta: {touched, error, invalid}, handleSearch} = props;
    let newStyles;
    let newTheme;
    if (touched && invalid) {
        newTheme = theme => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary: '#efa4ab',
                neutral30: '#efa4ab',
            },
        })
        newStyles = {
            control: styles => ({
                ...styles,
                borderColor: '#dc3545',
            }),
        };
    }
    const components = {
        DropdownIndicator: null,
    };

    return (
        <div>
            <div className="row mt-2">
                <div className="col">
                    <label className="col-form-label pl-0 font-weight-bold">
                        <FormattedMessage {...label}/>
                    </label>
                </div>
            </div>
            <AsyncCreatableSelect
                isMulti
                onChange={onChange}
                placeholder={<FormattedMessage {...formMessages.SEND_COPY_TO_PLACEHOLDER}/>}
                loadingMessage={() => <FormattedMessage {...commonMessages.SEARCHING}/>}
                noOptionsMessage={() => <FormattedMessage {...tableMessages.NO_DATA}/>}
                formatCreateLabel={(value) => <><FormattedMessage {...buttonMessages.ADD}/>  {value}</>}
                styles={newStyles}
                theme={newTheme}
                components={components}
                isClearable
                cacheOptions
                loadOptions={debounce(DEFAULT_DEBOUNCE_TIMEOUT, handleSearch)}
                isValidNewOption={(mail) => EMAIL_FIELD.test(mail)}
            />
            {touched && error && <ErrorDiv className="invalid-feedback">
                <ValidFormattedMessage message={error} intlMessages={validationMessages}/></ErrorDiv>
            }
        </div>
    )
};
