import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';

export const COMMERCIAL_RESET_REDIRECT = 'COMMERCIAL_RESET_REDIRECT';
export const COMMERCIAL_SET_LOCK_TICKET = 'COMMERCIAL_SET_LOCK_TICKET';

export const lockCommercialTicket = uuid => dispatch =>
    dispatch({
        type: COMMERCIAL_SET_LOCK_TICKET,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_SET_LOCK,
        payload: {uuid}
    });

export const unlockCommercialTicket = uuid => dispatch =>
    dispatch({
        type: commercialWebsocketCommands.COMMERCIAL_SET_UNLOCK,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_SET_UNLOCK,
        payload: {uuid}
    });

export const resetRedirectAfterLockCommercialTicket = () => dispatch => dispatch({type: COMMERCIAL_RESET_REDIRECT});
