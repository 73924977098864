import {
    DISTRIBUTION_FETCH_PREFERENCES,
    DISTRIBUTION_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
    DISTRIBUTION_UPDATE_USER_HISTORY_PREFERENCE,
    DISTRIBUTION_UPDATE_USER_LIST_PREFERENCE,
    DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_FETCH,
    DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_UPDATE
} from '../../actions/distribution/actionDistributionPreferences';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import get from 'get-value';

export const distributionPreferences = (state = {
    isLoading: true,
    menuList: [],
    menuHistory: [],
    menuFullTextSearch: [],
    subjects: [],
    statuses: []
}, action) => {
    switch (action.type) {
        case DISTRIBUTION_UPDATE_USER_LIST_PREFERENCE: {
            return Object.assign({}, state, {menuList: action.payload.preferences.hiddenColumns});
        }
        case DISTRIBUTION_UPDATE_USER_HISTORY_PREFERENCE: {
            return Object.assign({}, state, {menuHistory: action.payload.preferences.hiddenColumns});
        }
        case DISTRIBUTION_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE: {
            return Object.assign({}, state, {menuFullTextSearch: action.payload.preferences.hiddenColumns});
        }
        case distributionWebsocketCommands.DISTRIBUTION_PREFERENCES_SEND: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                ...action.payload.preferences,
                isLoading: false,
                correlationId: action.payload.correlationId
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_PREFERENCES_SEND_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case distributionWebsocketCommands.DISTRIBUTION_PREFERENCES_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case DISTRIBUTION_FETCH_PREFERENCES: {
            return Object.assign({}, state, {isLoading: true});
        }

        case DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_FETCH: {
            return Object.assign({}, state, {isLoading: true, subjects: [], statuses: [],});
        }
        case DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_UPDATE: {
            return Object.assign({}, state, {isLoading: true, subjects: [], statuses: [],})
        }
        case distributionWebsocketCommands.DISTRIBUTION_NOTIFICATIONS_PREFERENCES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                subjects: get(action, 'payload.subjects', {default: []}),
                statuses: get(action, 'payload.statuses', {default: []}),
                correlationId
            })
        }
        case distributionWebsocketCommands.DISTRIBUTION_NOTIFICATIONS_PREFERENCES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, subjects: [], statuses: [], correlationId});
        }
        default:
            return state;
    }
};
