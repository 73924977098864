import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {PRIMARY_YELLOW, SECONDARY_RED} from '../../theme/colors';

export const PrimaryIcon = styled.button`
  background-color: ${PRIMARY_YELLOW};
`;

export const PrimaryButton = styled(PrimaryIcon)`
  border-radius: 0.3em;
`;

export const BoldPrimaryButton = styled(PrimaryButton)`
  font-weight: bold;
`;

export const SecondaryButton = styled.button`
  border-radius: 0.3em;
  border-color: ${PRIMARY_YELLOW};
`;

export const DestructiveIcon = styled.button`
  background-color: ${SECONDARY_RED};
`;

export const IconAdd = () => <FontAwesomeIcon icon="plus"/>;

export const IconEdit = () => <FontAwesomeIcon icon="pencil-alt"/>;

export const IconDelete = () => <FontAwesomeIcon icon="trash-alt"/>;

export const IconCancel = () => <FontAwesomeIcon icon="times"/>;

export const IconSave = () => <FontAwesomeIcon icon="check"/>;

export const IconCopy = () => <FontAwesomeIcon icon="copy"/>;
