import { distributionWebsocketCommands } from '../../constants/distributionWebsocketCommands';
import { errorAlert } from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';
import { validateBusinessUnitCreateEvent, validateBusinessUnitUpdateEvent } from '../../api/validation/businessUnit';
import {domains} from '../../constants/Utils';


export const updateBusinessUnit = (domain, data) => dispatch => {
    const { error } = validateBusinessUnitUpdateEvent(data);
    if (error) {
        switch (domain) {
            case domains.DISTRIBUTION: {
                dispatch(errorAlert(alertMessages.BUSINESS_UNIT_UPDATE_SEND_ERROR, [error.toString()]));
                dispatch({type: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_SAVE_UPDATE_ERROR});
                break;
            }
        }
    } else {
        switch (domain) {
            case domains.DISTRIBUTION: {
                return dispatch({
                    type: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_UPDATE_PENDING,
                    distributionWebsocket: true,
                    command: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_UPDATE,
                    payload: {
                        payload: {
                            ...data
                        }
                    }
                });
            }
        }
    }
};

export const saveBusinessUnit = (domain, data) => dispatch => {
    const { error } = validateBusinessUnitCreateEvent(data);
    if (error) {
        switch (domain) {
            case domains.DISTRIBUTION: {
                dispatch(errorAlert(alertMessages.BUSINESS_UNIT_NEW_SEND_ERROR, [error.toString()]));
                dispatch({ type: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_SAVE_NEW_ERROR });
                break;
            }
        }
    } else {
        switch (domain) {
            case domains.DISTRIBUTION: {
                return dispatch({
                    type: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_CREATE_PENDING,
                    distributionWebsocket: true,
                    command: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_CREATE,
                    payload: {
                        payload: {
                            ...data,
                        }
                    }
                });
            }
        }
    }
};

export const fetchBusinessUnit = (domain, id, ipn) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_DETAIL_FETCH_PENDING,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_DETAIL,
                payload: {
                    businessUnit: {
                        id,
                    },
                }
            });
        }
    }
};

export const resetBusinessUnit = (domain) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_RESET,
            });
        }
    }
};

export const redirectReset = (domain) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: distributionWebsocketCommands.DISTRIBUTION_BUSINESS_UNITS_REDIRECT_RESET,
            });
        }
    }
};
