import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.subjectSetting.title',
        defaultMessage: 'Subject Settings'
    },
    EMPTY: {
        id: 'admin.subjectSetting.empty',
        defaultMessage: 'No Subjects'
    },
    SUBJECT_DEFAULT: {
        id: 'admin.subjectSetting.subjectDefault',
        defaultMessage: 'Default Language'
    },
    SUBJECT_LOCAL: {
        id: 'admin.subjectSetting.subjectLocal',
        defaultMessage: 'Local Language'
    },
    VIN: {
        id: 'admin.subjectSetting.vin',
        defaultMessage: 'VIN'
    },
    VIN_OBLIGATION: {
        id: 'admin.subjectSetting.vinObligation',
        defaultMessage: 'VIN Obligation'
    },
    REFERENCE_NUMBER: {
        id: 'admin.subjectSetting.refNo',
        defaultMessage: 'Ref No.'
    },
    REFERENCE_NUMBER_OBLIGATION: {
        id: 'admin.subjectSetting.refNoObligation',
        defaultMessage: 'Reference Number Obligation'
    },
    MODAL_TITLE: {
        id: 'admin.subjectSetting.modal.title',
        defaultMessage: 'Add New Subject'
    },
    SUBJECT_DEFAULT_PLACEHOLDER: {
        id: 'admin.subjectSetting.subjectDefault.placeholder',
        defaultMessage: 'Add Subject in English'
    },
    SUBJECT_LOCAL_PLACEHOLDER: {
        id: 'admin.subjectSetting.subjectLocal.placeholder',
        defaultMessage: 'Add Subject in Local Language'
    }
});

export default messages;
