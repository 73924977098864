import v4 from 'uuid/v4';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const DISTRIBUTION_STATISTICS_FETCH = 'DISTRIBUTION_STATISTICS_FETCH';

export const fetchDistributionStatistics = ({dealerNumber, countryGroups}) => dispatch => {
    return dispatch({
        type: DISTRIBUTION_STATISTICS_FETCH,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_GET_STATISTICS,
        payload: {dealerNumber, countryGroups, correlationId: v4()}
    });
};
