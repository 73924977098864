import React from 'react';
import styled from 'styled-components';
import {FormSection, formValueSelector, reduxForm} from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import { scrollContentWrapperToTop } from '../../../../../utils/utils';
import {
    BUSINESS_UNIT_FORM, FORM_BUSINESS_UNIT_COUNTRY_SECTION, FORM_BUSINESS_UNIT_INFO_SECTION,
} from '../../../../../constants/formConstants';
import RenderSyncErrors from '../../../../common/RenderSyncErrors'
// import {intlSolutionGroupFieldMapping} from '../../../../../constants/solutionGroupGieldLabelMapping';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import InfoSection from './sections/info/InfoSection';
import CountrySection from './sections/country/CountrySection';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const BusinessUnitForm = (props) => {
    const { form, reqFields, readOnly, handleSubmit, domain, creating, editing, roles } = props;

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            {/*<RenderSyncErrors form={form}*/}
            {/*                  intlMessages={formMessages}*/}
            {/*                  mapping={intlSolutionGroupFieldMapping}/>*/}
            <FormSection name={FORM_BUSINESS_UNIT_INFO_SECTION}>
                <InfoSection form={form}
                             domain={domain}
                             reqFields={reqFields}
                             creating={creating}
                             readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_BUSINESS_UNIT_COUNTRY_SECTION}>
                <CountrySection form={form}
                                reqFields={reqFields}
                                creating={creating}
                                readOnly={readOnly || editing}/>
            </FormSection>
            <SubmitButtons form={form}
                           reqFields={reqFields}
                           readOnly={readOnly}
                           onSubmit={handleSubmit}
                           onSubmitFail={scrollContentWrapperToTop}/>
        </StyledDiv>
    )
};

const mapStateToProps = (state, props) => ({
    roles: state.profile.userDetail.roles,
});

const mapDispatchToProps = (dispatch, props) => ({

});

export default reduxForm({
    form: BUSINESS_UNIT_FORM,
    // asyncBlurFields: [`${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_NAME}`]
})(injectIntl(connect(mapStateToProps, mapDispatchToProps)(BusinessUnitForm)));

