import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    REQUIRED: {
        id: 'validations.required',
        defaultMessage: 'Required'
    },
    MUST_BE_10_CHARS: {
        id: 'validations.mustBe10Characters',
        defaultMessage: 'Must be 10 characters'
    },
    MUST_BE_4_CHARS: {
        id: 'validations.mustBe4Characters',
        defaultMessage: 'Must be 4 characters'
    },
    MUST_BE_5_CHARS: {
        id: 'validations.mustBe5Characters',
        defaultMessage: 'Must be 5 characters'
    },
    MUST_BE_6_CHARS: {
        id: 'validations.mustBe6Characters',
        defaultMessage: 'Must be 6 characters'
    },
    MUST_BE_17_CHARS: {
        id: 'validations.mustBe17Characters',
        defaultMessage: 'Must be 17 characters'
    },
    INVALID_LENGTH: {
        id: 'validations.invalidLength',
        defaultMessage: 'Invalid length'
    },
    POSITIVE_NUMBER: {
        id: 'validations.positiveNumber',
        defaultMessage: 'Must be a positive number'
    },
    INVALID_IPN: {
        id: 'validations.invalidIpn',
        defaultMessage: 'Invalid IPN'
    },
    INVALID_INPUT: {
        id: 'validations.invalidInput',
        defaultMessage: 'Invalid input'
    },
    INVALID_DATE: {
        id: 'validations.invalidDate',
        defaultMessage: 'Invalid date dd.mm.yyyy'
    },
    DIGITS_ONLY: {
        id: 'validations.digitsOnly',
        defaultMessage: 'Digits only'
    },
    MAXIMUM_FILES: {
        id: 'validations.maximumFiles',
        defaultMessage: 'Maximum files exceeded'
    },
    MAXIMUM_FILE_SIZE: {
        id: 'validations.maximumFileSize',
        defaultMessage: 'Maximum file size exceeded'
    },
    INVALID_EMAIL: {
        id: 'validations.invalidEmail',
        defaultMessage: 'Invalid e-mail address'
    },
    MAXIMUM_EMAILS: {
        id: 'validations.maximumEmails',
        defaultMessage: 'Maximum emails exceeded'
    },
    DUPLICITY_EMAIL: {
        id: 'validations.duplicityEmail',
        defaultMessage: 'This e-mail address has been already added'
    },
    DUPLICITY_BANK_HOLIDAYS: {
        id: 'validations.duplicityBankHolidays',
        defaultMessage: 'This date has been already added'
    },
    INVALID_BANK_HOLIDAYS: {
        id: 'validations.invalidBankHolidays',
        defaultMessage: 'Invalid format, use YYYY-MM-DD'
    },
});

export default messages;
