import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import {DISTRIBUTION_FORM, FORM_BUTTON_TAKE_BACK} from '../../../constants/formConstants';
import {
    fetchDistributionTicket, resetDistributionTicket, updateDistributionTicket
} from '../../../actions/distribution/actionDistributionTicket';
import {
    lockDistributionTicket, resetRedirectAfterLockDistributionTicket, unlockDistributionTicket
} from '../../../actions/distribution/actionDistributionLockTicket';
import {initLoadedDistributionTicket, transformUpdatedDistributionTicketForSave} from '../Utils';
import {formatTicketNumber} from '../../../utils/utils';
import {clearAlerts} from '../../../actions/alertsActions';
import {editRights} from '../../../constants/distributionTicketRights';
import {editReqFields} from '../../../constants/distributionRequiredFields';
import {moduleRoles} from '../../../utils/roles';
import DistributionTicketMasterForm from './forms/DistributionTicketMasterForm';
import UploadingDistributionAttachment from './UploadingDistributionAttachment';
import checkRoles from '../../common/roleChecker/RoleChecker';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import formMessages from '../../../intl/modules/formMessages';

class EditDistributionTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentWillUnmount() {
        const {uuid, redirect} = this.props.lockTicket;
        if (!redirect) {
            this.props.unlockDistributionTicket(uuid);
        }
        this.props.resetRedirectAfterLockDistributionTicket();
    }

    componentDidMount() {
        this.props.resetDistributionTicket();
        this.props.lockDistributionTicket(this.props.match.params.ticketUuid);
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchDistributionTicket(this.props.match.params.ticketUuid);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.ticketSubmittedAndShouldRedirect && !this.state.ticketSubmittedAndShouldRedirect
            && prevProps.ticketData.isTicketCreating && !this.props.ticketData.isTicketCreating
            && this.props.ticketData.isTicketLoading) {
            this.props.lockDistributionTicket(this.props.match.params.ticketUuid);
        }
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const dataForSend = transformUpdatedDistributionTicketForSave(values);
        this.props.updateDistributionTicket(dataForSend);
        if (!([FORM_BUTTON_TAKE_BACK].includes(submitBy))) {
            this.setState({ticketSubmittedAndShouldRedirect: true});
        }
    };

    render() {
        const {ticketData, attachmentData, lockTicket, loggedUser, isLoadingSubjects} = this.props;
        const {ticketSubmittedAndShouldRedirect} = this.state;
        const initializationValue = initLoadedDistributionTicket(ticketData);

        if (lockTicket.redirect) {
            return <Redirect to={`/distribution/view/${lockTicket.uuid}`}/>;
        }

        let type = false;
        if (get(ticketData, 'ticket.type', {default: false})) {
            type = ticketData.ticket.type;
        }

        if (Array.isArray(attachmentData.attachmentsForUpload) && (attachmentData.attachmentsForUpload.length > 0)) {
            return <UploadingDistributionAttachment attachments={attachmentData.attachmentsForUpload}
                                                    attachmentsUrl={ticketData.newAttachments}
                                                    correlationId={ticketData.correlationId}
                                                    isAttachmentUploading={attachmentData.isTicketAttachmentUploading}
                                                    formType={type}/>
        }

        if (ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading &&
            get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/distribution/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !type
            || lockTicket.isTicketLocking
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        const requiredFields = editReqFields[moduleRoles.isEditorDistribution(loggedUser) ? 'EDITOR' : 'DEALER'][type];

        return (
            <div className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={type} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {type === DISTRIBUTION_FORM &&
                    <DistributionTicketMasterForm form={type}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[moduleRoles.isEditorDistribution(loggedUser) ?
                                                      'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  enableReinitialize={true}
                                                  keepDirtyOnReinitialize={true}
                                                  isEditor={moduleRoles.isEditorDistribution(loggedUser)}
                                                  handleSubmit={this.handleSubmit}
                                                  reqFields={requiredFields}
                                                  editMode
                    />
                    }
                </div>
            </div>
        );
    }
}

EditDistributionTicketPage.propTypes = {
    resetRedirectAfterLockDistributionTicket: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    resetDistributionTicket: PropTypes.func.isRequired,
    fetchDistributionTicket: PropTypes.func.isRequired,
    lockDistributionTicket: PropTypes.func.isRequired,
    updateDistributionTicket: PropTypes.func.isRequired,
    unlockDistributionTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.distributionTicket,
    lockTicket: state.distributionLockTicketCheck,
    attachmentData: state.distributionAttachment,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchDistributionTicket,
    updateDistributionTicket,
    resetDistributionTicket,
    lockDistributionTicket,
    resetRedirectAfterLockDistributionTicket,
    unlockDistributionTicket,
})(injectIntl(EditDistributionTicketPage))), ['DB_DEALER', 'DB_EDITOR']);
