import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const createDistributionExport = (exportFilter) => (dispatch) => {
    dispatch({
        type: distributionWebsocketCommands.DISTRIBUTION_EXPORT_CREATE,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_EXPORT_CREATE,
        payload: {exportFilter},
    });
};

export const fetchDistributionExport = () => (dispatch) => {
    dispatch({
        type: distributionWebsocketCommands.DISTRIBUTION_EXPORT_GET,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_EXPORT_GET,
        payload: {},
    });
};

export const downloadDistributionExport = (exportRecord) => (dispatch) => {
    dispatch({
        type: distributionWebsocketCommands.DISTRIBUTION_EXPORT_DOWNLOAD_URL_GET,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_EXPORT_DOWNLOAD_URL_GET,
        payload: {exportRecord},
    });
};
