import React from 'react';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import history from '../history.js';
import PreLoadApp from './PreLoadApp';

const PiwikReactRouter = require('piwik-react-router');

const piwik = PiwikReactRouter({
    url: 'https://piwik.renault-digital.com/',
    siteId: process.env.REACT_APP_PIWIK_SITE_ID
});

const Root = ({store}) => (
    <Provider store={store}>
        <Router history={piwik.connectToHistory(history)}>
            <PreLoadApp store={store}/>
        </Router>
    </Provider>
);

export default Root;
