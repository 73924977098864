import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';

export const COMMERCIAL_FULL_TEXT_SEARCH_FETCH = 'COMMERCIAL_FULL_TEXT_SEARCH_FETCH';

export const fetchCommercialFullTextSearch = (pageSize, sorted, cursor, isNextFromPivot, filtered) => (dispatch) => {
    dispatch({
        type: COMMERCIAL_FULL_TEXT_SEARCH_FETCH,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_FULL_TEXT_SEARCH_GET,
        payload: {
            pageSize: pageSize,
            sorted: sorted,
            cursor: cursor,
            isNextFromPivot: isNextFromPivot,
            filtered: filtered
        },
    });
};
