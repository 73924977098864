import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {DISTRIBUTION_STATISTICS_FETCH} from '../../actions/distribution/actionDistributionStatistics';

export const distributionStatistics = (state = {
    correlationId: '',
    isLoading: false,
    data: [],
}, action) => {
    switch (action.type) {
        case DISTRIBUTION_STATISTICS_FETCH: {
            const { correlationId } = action.payload;
            return Object.assign({}, state, {
                isLoading: true, correlationId
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_STATISTICS_SEND: {
            const { correlationId, result: { data } } = action.payload;
            if (correlationId !== state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isLoading: false, correlationId, data
                });
            }
        }
        case distributionWebsocketCommands.DISTRIBUTION_STATISTICS_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId !== state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isLoading: false, correlationId
                });
            }
        }
        default: {
            return state;
        }
    }
};
