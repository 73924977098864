import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const DISTRIBUTION_LIST_FETCH = 'DISTRIBUTION_LIST_FETCH';

export const fetchDistributionList = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: DISTRIBUTION_LIST_FETCH,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_LIST_GET,
        payload: {
            pageSize: pageSize,
            sorted: sorted,
            cursor: cursor,
            isNextFromPivot: isNextFromPivot,
            filtered: filtered
        }
    });
};
