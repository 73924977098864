import 'react-picky/dist/picky.css';
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import {
    lockMarketingTicket,
    resetRedirectAfterLockMarketingTicket
} from '../../../actions/marketing/actionMarketingLockTicket';
import {
    fetchMarketingPreferences,
    updateMarketingUserListPreference
} from '../../../actions/marketing/actionMarketingPreferences';
import {clearAlerts} from '../../../actions/alertsActions';
import {MarketingColumnLibrary} from '../../common/table/MarketingColumnLibrary';
import {StyledPicky} from '../../common/StyledComponents';
import {domains} from '../../../constants/Utils';
import ListMarketingTable from './ListMarketingTable';
import checkRoles from '../../common/roleChecker/RoleChecker';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import listMessages from '../../../intl/modules/listMessages';
import tableMessages from '../../../intl/common/tableMessages';

const domain = domains.MARKETING;

class ListMarketingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratchet: false,
        }
    }

    componentWillUnmount() {
        this.props.resetRedirectAfterLockMarketingTicket();
    }

    componentDidMount() {
        this.props.fetchMarketingPreferences();
    }

    handleEditClick = ({id}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockMarketingTicket(id);
            this.setState({ratchet: true})
        }
    };

    render() {
        const {intl, marketingPreferences, lockTicket, location, isLoadingSubjects} = this.props;
        const columnLibrary = MarketingColumnLibrary(this.props, domain);
        const selectMultipleOption = (value) => {
            this.props.updateMarketingUserListPreference(
                columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
        };

        if (lockTicket.redirect && this.state.ratchet) {
            this.props.history.push(`/marketing/edit/${lockTicket.uuid}`);
        }
        if (marketingPreferences.isLoading
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...listMessages.TITLE}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold">
                            <FormattedMessage {...tableMessages.VISIBLE_COLUMNS}/>
                            {':'}
                        </span>
                        <StyledPicky
                            value={columnLibrary.filter(item => !get(
                                marketingPreferences,
                                'menuList',
                                {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <ListMarketingTable
                    columns={
                        columnLibrary.filter(item =>
                            !get(marketingPreferences, 'menuList', {default: []}).includes(item.id))
                    }
                    handleEditClick={this.handleEditClick}
                    location={location}
                />
            </div>
        );
    }
}

ListMarketingPage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    updateMarketingUserListPreference: PropTypes.func.isRequired,
    lockMarketingTicket: PropTypes.func.isRequired,
    resetRedirectAfterLockMarketingTicket: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
    marketingPreferences: state.marketingPreferences,
    lockTicket: state.marketingListOrderLockTicketCheck,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
    listData: state.marketingList.listData,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateMarketingUserListPreference,
    fetchMarketingPreferences,
    lockMarketingTicket,
    resetRedirectAfterLockMarketingTicket,
})(injectIntl(ListMarketingPage))), ['MA_DEALER', 'MA_EDITOR']);
