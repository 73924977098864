import {
    ADMIN_WORKING_HOURS_SAVE,
    ADMIN_WORKING_HOURS_FETCH,
    ADMIN_WORKING_HOURS_RESET,
    ADMIN_WORKING_HOURS_SELECT_GROUP,
    ADMIN_WORKING_HOURS_UPDATE, ADMIN_BANK_HOLIDAYS_UPDATE,
} from '../../actions/admin/actionAdminWorkingHours';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {cloneDeep} from 'lodash';
import get from 'get-value';

const defaultData = {
    workingHours: {
        days: {
            1: {
                from: 0,
                to: 0,
                diff: 0
            },
            2: {
                from: 0,
                to: 0,
                diff: 0
            },
            3: {
                from: 0,
                to: 0,
                diff: 0
            },
            4: {
                from: 0,
                to: 0,
                diff: 0
            },
            5: {
                from: 0,
                to: 0,
                diff: 0
            },
            6: {
                from: 0,
                to: 0,
                diff: 0
            },
            7: {
                from: 0,
                to: 0,
                diff: 0
            }
        }
    },
    bankHolidays: {days: []}
};

export const adminWorkingHours = (state = {
    isLoading: false,
    isDirty: false,
    data: defaultData,
    selectedCountryGroup: '',
}, action) => {
    switch (action.type) {
        case ADMIN_WORKING_HOURS_SELECT_GROUP:
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        case ADMIN_WORKING_HOURS_FETCH:
            return Object.assign({}, state, {isLoading: true, data: defaultData});
        case ADMIN_WORKING_HOURS_SAVE:
            return Object.assign({}, state, {isLoading: true});
        case ADMIN_WORKING_HOURS_RESET:
            return Object.assign({}, state, {isDirty: false, data: cloneDeep(state.originalData)});
        case ADMIN_WORKING_HOURS_UPDATE: {
            const {day, values} = action.payload;
            const days = state.data.workingHours.days;
            const diff = values[1] - values[0];
            const changedObject = {
                from: values[0],
                to: values[1],
                diff: diff,
            };
            const newState = {
                workingHours: {
                    days: Object.assign({}, days, {[day]: changedObject}),
                },
                bankHolidays: state.data.bankHolidays,
            };
            return Object.assign({}, state, {data: newState}, {isDirty: true});
        }
        case ADMIN_BANK_HOLIDAYS_UPDATE: {
            const {bankHolidays} = action.payload;
            const newState = {
                workingHours: state.data.workingHours,
                bankHolidays: bankHolidays,
            };
            return Object.assign({}, state, {data: newState}, {isDirty: true});
        }

        case distributionWebsocketCommands.DISTRIBUTION_WORKING_HOURS_SEND:
        case commercialWebsocketCommands.COMMERCIAL_WORKING_HOURS_SEND:
        case marketingWebsocketCommands.MARKETING_WORKING_HOURS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            if (!get(action, 'payload.workingHours', {default: false})) {
                return Object.assign({}, state, {
                    isLoading: false,
                    isDirty: false,
                    originalData: defaultData,
                    data: defaultData,
                    correlationId
                });
            }
            const {workingHours = {}, bankHolidays = []} = action.payload;
            const {days = {}} = workingHours;
            const convertedDays = Object.keys(days).map(day => {
                const {from, to, diff} = days[day];
                return {
                    day,
                    from: !from ? 0 : from,
                    to: !to ? 0 : to,
                    diff: !diff ? 0 : diff,
                }
            }).reduce((acc, curr) => ({
                ...acc,
                [curr.day]: {
                    from: curr.from,
                    to: curr.to,
                    diff: curr.diff,
                },
            }), {});
            const data = {
                workingHours: {
                    ...workingHours,
                    days: convertedDays,
                },
                bankHolidays
            };
            return Object.assign({}, state, {
                isLoading: false,
                isDirty: false,
                originalData: data,
                data,
                correlationId
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_WORKING_HOURS_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_WORKING_HOURS_ERROR:
        case marketingWebsocketCommands.MARKETING_WORKING_HOURS_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, data: cloneDeep(state.originalData), correlationId});
        }
        case distributionWebsocketCommands.DISTRIBUTION_WORKING_HOURS_SEND_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_WORKING_HOURS_SEND_ERROR:
        case marketingWebsocketCommands.MARKETING_WORKING_HOURS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                data: defaultData,
                originalData: defaultData,
                correlationId
            });
        }
        default:
            return state;
    }
}

