// DISTRIBUTION
// tickets
export const DISTRIBUTION_FORM = 'DISTRIBUTION_FORM';
// statuses
export const DISTRIBUTION_TICKET_STATUS_NEW = 'NEW';
export const DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY = 'WAIT_SUBSIDIARY';
export const DISTRIBUTION_TICKET_STATUS_IN_PROGRESS_DEALER = 'WAIT_DEALER';
export const DISTRIBUTION_TICKET_STATUS_WORK_IN_PROGRESS = 'WAIT_WORK_IN_PROGRESS'
export const DISTRIBUTION_TICKET_STATUS_REOPEN = 'REOPEN';
export const DISTRIBUTION_TICKET_STATUS_CLOSED = 'CLOSED';
// MARKETING
// tickets
export const GENERAL_QUESTION_FORM = 'GENERAL_QUESTION';
export const SPARE_PART_PRICE_VERIFICATION_FORM = 'SPARE_PART_PRICE_VERIFICATION';
export const SPARE_PART_NAME_VERIFICATION_FORM = 'SPARE_PART_NAME_VERIFICATION';
// statuses
export const MARKETING_TICKET_STATUS_NEW = 'NEW';
export const MARKETING_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY = 'WAIT_SUBSIDIARY';
export const MARKETING_TICKET_STATUS_IN_PROGRESS_DEALER = 'WAIT_DEALER';
export const MARKETING_TICKET_STATUS_WORK_IN_PROGRESS = 'WAIT_WORK_IN_PROGRESS';
export const MARKETING_TICKET_STATUS_REOPEN = 'REOPEN';
export const MARKETING_TICKET_STATUS_CLOSED = 'CLOSED';
// COMMERCIAL
// tickets
export const COMMERCIAL_FORM = 'COMMERCIAL_FORM';
export const OFFER_FOR_EMPLOYEES_FORM = 'OFFER_FOR_EMPLOYEES_FORM';
// statuses
export const COMMERCIAL_TICKET_STATUS_NEW = 'NEW';
export const COMMERCIAL_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY = 'WAIT_SUBSIDIARY';
export const COMMERCIAL_TICKET_STATUS_IN_PROGRESS_DEALER = 'WAIT_DEALER';
export const COMMERCIAL_TICKET_STATUS_WORK_IN_PROGRESS = 'WAIT_WORK_IN_PROGRESS';
export const COMMERCIAL_TICKET_STATUS_REOPEN = 'REOPEN';
export const COMMERCIAL_TICKET_STATUS_CLOSED = 'CLOSED';

// SECTIONS
export const SUBSTITUTION_SECTION = 'substitutionSection';
export const TICKET_SECTION = 'ticketSection';
export const MESSAGE_SECTION = 'messageSection';
export const ATTACHMENT_SECTION = 'attachmentSection';
export const STATUS_HISTORY_SECTION = 'statusHistory';
export const DEALER_SECTION = 'dealerSection';
export const INTERNAL_NOTE_SECTION = 'internalNoteSection';

// BUTTONS
export const FORM_BUTTON_SAVE = 'saveButton';
export const FORM_BUTTON_EDIT = 'editButton';
export const FORM_BUTTON_CLOSE = 'closeButton';
export const FORM_BUTTON_REOPEN = 'reopenButton';
export const FORM_BUTTON_FORWARD_TO_NPDC = 'forwardToNPDCButton';
export const FORM_BUTTON_FORWARD_TO_DEALER = 'forwardToDealerButton';
export const FORM_BUTTON_WORK_IN_PROGRESS = 'workInProgressButton';
export const FORM_BUTTON_TAKE_BACK = 'takeBackButton';
// buttons labels
export const BUTTON_LABEL_SAVE = 'SAVE';
export const BUTTON_LABEL_EDIT = 'EDIT';
export const BUTTON_LABEL_CLOSE = 'CLOSE';
export const BUTTON_LABEL_REOPEN = 'REOPEN';
export const BUTTON_LABEL_FORWARD_TO_NPDC = 'FORWARD_TO_NPDC';
export const BUTTON_LABEL_FORWARD_TO_DEALER = 'FORWARD_TO_DEALER';
export const BUTTON_LABEL_WORK_IN_PROGRESS = 'WORK_IN_PROGRESS';
export const BUTTON_LABEL_DHS_LOAD = 'DHS_LOAD';
export const BUTTON_LABEL_ASD_LOAD = 'ASD_LOAD';
export const BUTTON_LABEL_FREQUENT_QUESTION = 'FREQUENT_QUESTION';

// FIELDS
//Information section
export const FORM_FIELD_SUBSTITUTION = 'substitution';

//Fields
export const FORM_FIELD_SUBSTITUTION_USER = 'substitutionUser';

// //Labels
export const FORM_LABEL_SUBSTITUTION_USER = 'SUBSTITUTION_USER';

export const FIELD_CONTACT = 'contact';
export const FIELD_SUBJECT = 'subject';
export const FIELD_PRIORITY = 'priority';
export const FIELD_BRAND = 'brand';
export const FIELD_MODEL = 'model';
export const FIELD_MODEL_SCR = 'modelScr';
export const FIELD_DEALER_NAME = 'dealerName';
export const FIELD_DEALER_NUMBER = 'dealerNumber';
export const FIELD_CREATOR_NAME = 'creatorName';
export const FIELD_CREATION_DATE = 'creationDate';
export const FIELD_ASSIGNED_EDITOR = 'assignedEditor';

export const FIELD_TYPE_OF_PURCHASE = 'typeOfPurchase';
export const FIELD_EMPLOYEE_OF = 'employeeOf';
export const FIELD_NAME_AND_SURNAME_OF_THE_EMPLOYEE = 'nameAndSurnameOfTheEmployee';
export const FIELD_NO_OF_THE_EMPLOYEE_ELIGIBLE_FOR_A_DISCOUNT = 'noOfTheEmployeeEligibleForADiscount';
export const FIELD_NAME_AND_SURNAME_OF_THE_PERSON = 'nameAndSurnameOfThePerson';
export const FIELD_COLOUR = 'colour';
export const FIELD_YEAR_OF_PRODUCTION = 'yearOfProduction';

export const FIELD_ATTACHMENT = 'attachments';
export const FIELD_NEW_ATTACHMENT = 'newAttachments';
export const FIELD_MESSAGE = 'message';
export const FIELD_AUTOMATIC_RESPONSE = 'automaticResponse';
export const FIELD_SFX = 'sfxNumber';
export const FIELD_PFX = 'pfxNumber';
export const FIELD_DATE_FROM = 'dateFrom';
export const FIELD_DATE_TO = 'dateTo';
export const FIELD_ASSIGNED_TO = 'assignedTo';
export const FIELD_CUSTOM_DEADLINE = 'customDeadline';
export const FIELD_STATUS = 'status';
export const FIELD_EXPORT_TYPE = 'type';
export const FIELD_EXPORT_CHARSET = 'charset';

export const FORM_FIELD_INTERNAL_NOTE='internalNote'
// distribution
export const FIELD_VERSION = 'version';
export const FIELD_VERSION_SCR = 'versionScr';
export const FIELD_ORDER_NUMBER = 'orderNumber';
export const FIELD_VIN = 'vin';
export const FIELD_DISTRIBUTION_ACCOUNT = 'distrAccount';
export const FIELD_DISTRIBUTION_ACCOUNT_NAME = 'distrAccountName';
export const FIELD_OPTION = 'option';
export const FIELD_OPTION_SCR = 'optionScr';
export const FIELD_CONCEDES = 'concedes';
export const FIELD_CONCEDES_NAME = 'concedesName';
export const FIELD_CDE_CLI = 'cdeCli';
export const FIELD_SEND_COPY_TO = 'sendCopyTo';
// marketing
export const FIELD_REFERENCE_NUMBER = 'referenceNumber';
export const FIELD_SEGMENT = 'segment';
export const FIELD_PCL_PRICE = 'pclPrice';
export const FIELD_TECHNICAL_TYPE = 'technicalType';
export const FIELD_FAMILY = 'family';
export const FIELD_PRODUCTION_DATE = 'productionDate';
export const FIELD_CURRENT_SPARE_PART_NAME = 'currentSparePartName';
export const FIELD_SUGGESTED_SPARE_PART_NAME = 'suggestedSparePartName';
export const FIELD_SPARE_PART_LOCATION = 'sparePartLocation';
export const FIELD_LEVEL_EQUIPMENT = 'levelEquipment';

// LABELS
// pouzito jako nazev fieldu
export const LABEL_STATUS = 'STATUS';
export const LABEL_DEALER_NAME = 'DEALER_NAME';
export const LABEL_DEALER_NUMBER = 'DEALER_NUMBER';
export const LABEL_ASSIGNED_TO = 'ASSIGNED_TO';
export const LABEL_BUSINESS_UNIT = 'BUSINESS_UNIT';
export const LABEL_CREATOR_NAME = 'CREATOR_NAME';
export const LABEL_PRIORITY = 'PRIORITY';
export const LABEL_SUBJECT = 'SUBJECT';
export const LABEL_BRAND = 'BRAND';
export const LABEL_MODEL = 'MODEL';
export const LABEL_VIN = 'VIN';
export const LABEL_ORDER_NUMBER = 'ORDER_NUMBER';
export const LABEL_REFERENCE_NUMBER = 'REFERENCE_NUMBER';
export const LABEL_PCL_PRICE = 'PCL_PRICE';
export const LABEL_TECHNICAL_TYPE = 'TECHNICAL_TYPE';
export const LABEL_FAMILY = 'FAMILY';
export const LABEL_CURRENT_SPARE_PART_NAME = 'CURRENT_SPARE_PART_NAME';
export const LABEL_SUGGESTED_SPARE_PART_NAME = 'SUGGESTED_SPARE_PART_NAME';
export const LABEL_LEVEL_EQUIPMENT = 'LEVEL_EQUIPMENT';
export const LABEL_VERSION = 'VERSION';
export const LABEL_MESSAGE = 'MESSAGE';
export const LABEL_NEW_ATTACHMENT = 'NEW_ATTACHMENT';
export const LABEL_NAME_AND_SURNAME_OF_THE_EMPLOYEE = 'NAME_AND_SURNAME_OF_THE_EMPLOYEE';
export const LABEL_TYPE_OF_PURCHASE = 'TYPE_OF_PURCHASE';
export const LABEL_EMPLOYEE_OF = 'EMPLOYEE_OF';
export const LABEL_INTERNAL_NOTE = 'INTERNAL_NOTE'
export const LABEL_INTERNAL_NOTE_HISTORY = 'INTERNAL_NOTE_HISTORY'
// export const LABEL_NO_OF_THE_EMPLOYEE_ELIGIBLE_FOR_A_DISCOUNT = 'DISCOUNT_EMPLOYEE';

// Business Units
export const BUSINESS_UNIT_FORM = 'BUSINESS_UNIT_FORM';
// SECTIONS
export const FORM_BUSINESS_UNIT_INFO_SECTION = 'businessUnitSection';
export const FORM_BUSINESS_UNIT_COUNTRY_SECTION = 'countrySection';
// FIELDS
export const FIELD_BU = 'businessUnit';
export const FIELD_BU_NAME = 'name';
export const FIELD_BU_COUNTRIES = 'countries';
// LABELS
export const LABEL_BU_NAME = 'NAME';
export const LABEL_BU_COUNTRIES = 'COUNTRIES';

// muze se smazat
export const LABEL_TICKET_NUMBER = 'TICKET_NUMBER';
export const LABEL_DATE_FROM = 'DATE_FROM';
export const LABEL_DATE_TO = 'DATE_TO';
export const LABEL_EXPORT_TYPE = 'EXPORT_TYPE';
export const LABEL_EXPORT_CHARSET = 'EXPORT_CHARSET';
export const LABEL_SEGMENT = 'SEGMENT';
export const LABEL_PRODUCTION_DATE = 'PRODUCTION_DATE';
export const LABEL_SPARE_PART_LOCATION = 'SPARE_PART_LOCATION';
export const LABEL_CREATION_DATE = 'CREATION_DATE';
export const LABEL_AUTOMATIC_RESPONSE = 'AUTOMATIC_RESPONSE';
export const LABEL_DISTRIBUTION_ACCOUNT = 'DISTRIBUTION_ACCOUNT';
export const LABEL_OPTION = 'OPTION';
export const LABEL_SEND_COPY_TO = 'SEND_COPY_TO';
export const LABEL_ATTACHMENT = 'ATTACHMENT';
