import {fetchUsersPromise} from '../actions/actionRole';

export function handleUserSearch(inputValue, debouncedCallback, errorCallback) {
    fetchUsersPromise(inputValue).then(
        ({data}) => debouncedCallback(
            data.sort((user1, user2) => user1.name.localeCompare(user2.name))
                .map(mapUserToSelectionOption)
        ),
        error => errorCallback(error)
    );
}

export function mapUserToSelectionOption(user) {
    return Object.assign(user, {value: user.ipn, label: extractUserLabelForSelectionOption(user)});
}

export function extractUserLabelForSelectionOption(user) {
    return `${user.name} (${user.ipn})${user.group ? ' - ' + user.group : ''}`;
}
