export const marketingWebsocketCommands = {
    // route (commands)

    // FILE
    MARKETING_FREQUENT_QUESTION_FILE_DOWNLOAD: 'frequentQuestionAttachmentDownload',
    MARKETING_FREQUENT_QUESTION_FILE_UPLOAD: 'frequentQuestionAttachmentUpload',
    // FREQUENT QUESTION
    MARKETING_FREQUENT_QUESTION_GET: 'getFrequentQuestions',
    MARKETING_FREQUENT_QUESTION_CREATE: 'createFrequentQuestion',
    MARKETING_FREQUENT_QUESTION_MOVE: 'moveFrequentQuestion',
    MARKETING_FREQUENT_QUESTION_DELETE: 'deleteFrequentQuestion',
    MARKETING_FREQUENT_QUESTION_UPDATE: 'updateFrequentQuestion',
    // PRESET RESPONSE
    MARKETING_PRESET_RESPONSE_GET: 'getPresetResponses',
    MARKETING_PRESET_RESPONSE_CREATE: 'createPresetResponse',
    MARKETING_PRESET_RESPONSE_MOVE: 'movePresetResponse',
    MARKETING_PRESET_RESPONSE_DELETE: 'deletePresetResponse',
    MARKETING_PRESET_RESPONSE_UPDATE: 'updatePresetResponse',
    // NOTIFICATION
    MARKETING_NOTIFICATIONS_GET: 'getEmailNotifications',
    MARKETING_NOTIFICATIONS_SET: 'setEmailNotifications',
    // RIGHTS MANAGEMENT
    MARKETING_ADMIN_ROLE_GET: 'getAdminRole',
    MARKETING_ADMIN_ROLE_SET: 'setAdminRole',
    MARKETING_EDITOR_ROLE_GET: 'getEditorRole',
    MARKETING_EDITOR_ROLE_SET: 'setEditorRole',
    MARKETING_DEALER_ROLE_GET: 'getDealerRole',
    MARKETING_DEALER_ROLE_SET: 'setDealerRole',
    // SUBJECT SETTINGS
    MARKETING_SUBJECT_SETTINGS_GET: 'getSubjectSettings',
    MARKETING_SUBJECT_SETTINGS_CREATE: 'createSubjectSettings',
    MARKETING_SUBJECT_SETTINGS_UPDATE: 'updateSubjectSettings',
    MARKETING_SUBJECT_SETTINGS_MOVE: 'moveSubjectSettings',
    MARKETING_SUBJECT_SETTINGS_DELETE: 'deleteSubjectSettings',
    // TICKET SETTINGS
    MARKETING_TICKET_SETTINGS_GET: 'getTicketSettings',
    MARKETING_TICKET_SETTINGS_SET: 'setTicketSettings',
    // WORKING HOURS
    MARKETING_WORKING_HOURS_GET: 'getWorkingHours',
    MARKETING_WORKING_HOURS_SET: 'setWorkingHours',

    // ATTACHMENTS
    MARKETING_CREATE_ATTACHMENT: 'createAttachments',
    MARKETING_DOWNLOAD_PRESIGNED_URL: 'attachmentDownloadPreSignedUrl',
    // DEALERS
    MARKETING_DEALERS_GET: 'getDealers',
    // EXPORT
    MARKETING_EXPORT_GET: 'getExport',
    MARKETING_EXPORT_CREATE: 'createTicketExport',
    MARKETING_EXPORT_DOWNLOAD_URL_GET: 'exportDownloadPreSignedUrl',
    // FULL TEXT SEARCH
    MARKETING_FULL_TEXT_SEARCH_GET: 'getFullTextSearch',
    // HISTORY
    MARKETING_HISTORY_GET: 'getHistory',
    // LIST
    MARKETING_LIST_GET: 'getList',
    // LOCK TICKET
    MARKETING_SET_LOCK: 'setLockTicket',
    MARKETING_SET_UNLOCK: 'setUnlockTicket',
    // PREFERENCES
    MARKETING_PREFERENCES_GET: 'getPreferences',
    MARKETING_USER_LIST_PREFERENCE_SET: 'setUserListPreference',
    MARKETING_USER_HISTORY_PREFERENCE_SET: 'setUserHistoryPreference',
    MARKETING_USER_FULL_TEXT_SEARCH_PREFERENCE_SET: 'setUserFullTextSearchPreference',
    MARKETING_USER_NOTIFICATION_PREFERENCE_GET: 'getUserNotificationPreference',
    MARKETING_USER_NOTIFICATION_PREFERENCE_SET: 'setUserNotificationPreference',
    // STATISTICS
    MARKETING_GET_STATISTICS: 'getStatistics',
    // TICKET
    MARKETING_UPDATE_TICKET: 'updateMarketingTicket',
    MARKETING_CREATE_TICKET: 'createMarketingTicket',
    MARKETING_GET_TICKET: 'getTicket',

    // receive

    // FREQUENT QUESTION
    MARKETING_FREQUENT_QUESTION_ERROR: 'MARKETING_FREQUENT_QUESTIONS_ERROR',
    MARKETING_FREQUENT_QUESTION_SEND: 'MARKETING_FREQUENT_QUESTIONS_SEND',
    MARKETING_FREQUENT_QUESTION_SEND_ERROR: 'MARKETING_FREQUENT_QUESTIONS_SEND_ERROR',
    MARKETING_FREQUENT_QUESTION_ATTACHMENTS_DOWNLOAD: 'MARKETING_FREQUENT_QUESTIONS_ATTACHMENTS_DOWNLOAD',
    MARKETING_FREQUENT_QUESTION_ATTACHMENTS_UPLOAD: 'MARKETING_FREQUENT_QUESTIONS_ATTACHMENTS_UPLOAD',
    // PRESET RESPONSE
    MARKETING_PRESET_RESPONSES_ERROR: 'MARKETING_PRESET_RESPONSES_ERROR',
    MARKETING_PRESET_RESPONSES_SEND: 'MARKETING_PRESET_RESPONSES_SEND',
    MARKETING_PRESET_RESPONSES_SEND_ERROR: 'MARKETING_PRESET_RESPONSES_SEND_ERROR',
    // NOTIFICATION
    MARKETING_NOTIFICATIONS_ERROR: 'MARKETING_NOTIFICATIONS_ERROR',
    MARKETING_NOTIFICATIONS_SEND: 'MARKETING_NOTIFICATIONS_SEND',
    MARKETING_NOTIFICATIONS_SEND_ERROR: 'MARKETING_NOTIFICATIONS_SEND_ERROR',
    // ADMIN RIGHTS MANAGEMENT
    MARKETING_ROLES_SEND_ERROR: 'MARKETING_ROLES_SEND_ERROR',
    MARKETING_ADMIN_ROLE_SEND: 'MARKETING_ADMIN_ROLE_SEND',
    MARKETING_ADMIN_ROLE_SEND_ERROR: 'MARKETING_ADMIN_ROLE_SEND_ERROR',
    MARKETING_EDITOR_ROLE_SEND: 'MARKETING_EDITOR_ROLE_SEND',
    MARKETING_EDITOR_ROLE_SEND_ERROR: 'MARKETING_EDITOR_ROLE_SEND_ERROR',
    MARKETING_DEALER_ROLE_SEND: 'MARKETING_DEALER_ROLE_SEND',
    MARKETING_DEALER_ROLE_SEND_ERROR: 'MARKETING_DEALER_ROLE_SEND_ERROR',
    // SUBJECT SETTINGS
    MARKETING_SUBJECT_SETTINGS_ERROR: 'MARKETING_SUBJECT_SETTINGS_ERROR',
    MARKETING_SUBJECT_SETTINGS_SEND: 'MARKETING_SUBJECT_SETTINGS_SEND',
    MARKETING_SUBJECT_SETTINGS_SEND_ERROR: 'MARKETING_SUBJECT_SETTINGS_SEND_ERROR',
    // TICKET SETTINGS
    MARKETING_TICKET_SETTINGS_ERROR: 'MARKETING_TICKET_SETTINGS_ERROR',
    MARKETING_TICKET_SETTINGS_SEND: 'MARKETING_TICKET_SETTINGS_SEND',
    MARKETING_TICKET_SETTINGS_SEND_ERROR: 'MARKETING_TICKET_SETTINGS_SEND_ERROR',
    // WORKING HOURS
    MARKETING_WORKING_HOURS_ACCEPTED: 'MARKETING_WORKING_HOURS_ACCEPTED',
    MARKETING_WORKING_HOURS_ERROR: 'MARKETING_WORKING_HOURS_ERROR',
    MARKETING_WORKING_HOURS_SEND: 'MARKETING_WORKING_HOURS_SEND',
    MARKETING_WORKING_HOURS_SEND_ERROR: 'MARKETING_WORKING_HOURS_SEND_ERROR',

    // ATTACHMENTS
    MARKETING_ATTACHMENTS_SAVED: 'MARKETING_TICKET_ATTACHMENTS_SAVED',
    MARKETING_ATTACHMENTS_SAVED_ERROR: 'MARKETING_ATTACHMENTS_SAVED_ERROR',
    MARKETING_GENERATED_PRESIGNED_URLS: 'MARKETING_GENERATED_PRESIGNED_URLS',
    MARKETING_ATTACHMENTS_DOWNLOAD: 'MARKETING_TICKET_ATTACHMENTS_DOWNLOAD',
    // DEALERS
    MARKETING_DEALERS_SEND: 'MARKETING_DEALERS_FETCH_SUCCESS',
    MARKETING_DEALERS_SEND_ERROR: 'MARKETING_DEALERS_FETCH_ERROR',
    // EXPORT
    MARKETING_EXPORT_SEND: 'MARKETING_EXPORT_FETCH_SUCCESS',
    MARKETING_EXPORT_SEND_ERROR: 'MARKETING_EXPORT_FETCH_ERROR',
    MARKETING_EXPORT_STATUS: 'MARKETING_EXPORT_STATUS',
    MARKETING_EXPORT_ERROR: 'MARKETING_EXPORT_ERROR',
    MARKETING_EXPORT_DOWNLOAD: 'MARKETING_EXPORT_DOWNLOAD',
    // FULL TEXT SEARCH
    MARKETING_FULL_TEXT_SEARCH_FETCH_SUCCESS: 'MARKETING_FULL_TEXT_SEARCH_FETCH_SUCCESS',
    MARKETING_FULL_TEXT_SEARCH_SEND_ERROR: 'MARKETING_TICKET_FULL_TEXT_SEARCH_SEND_ERROR',
    // HISTORY
    MARKETING_HISTORY_SEND: 'MARKETING_TICKET_HISTORY_SEND',
    MARKETING_HISTORY_SEND_ERROR: 'MARKETING_TICKET_HISTORY_SEND_ERROR',
    // LIST
    MARKETING_LIST_SEND: 'MARKETING_TICKET_LIST_SEND',
    MARKETING_LIST_SEND_ERROR: 'MARKETING_TICKET_LIST_SEND_ERROR',
    // LOCK TICKET
    MARKETING_TICKET_LOCKED: 'MARKETING_TICKET_LOCKED',
    MARKETING_TICKET_LOCKED_ERROR: 'MARKETING_TICKET_LOCKED_ERROR',
    MARKETING_TICKET_LOCKED_FATAL: 'MARKETING_TICKET_LOCKED_FATAL',
    // PREFERENCES
    MARKETING_PREFERENCES_ERROR: 'MARKETING_PREFERENCES_ERROR',
    MARKETING_PREFERENCES_SEND: 'MARKETING_PREFERENCES_SEND',
    MARKETING_PREFERENCES_SEND_ERROR: 'MARKETING_PREFERENCES_SEND_ERROR',
    MARKETING_PREFERENCES_ACCEPTED: 'MARKETING_PREFERENCES_ACCEPTED',
    MARKETING_NOTIFICATIONS_PREFERENCES_SEND: 'MARKETING_NOTIFICATIONS_PREFERENCES_SEND',
    MARKETING_NOTIFICATIONS_PREFERENCES_SEND_ERROR: 'MARKETING_NOTIFICATIONS_PREFERENCES_SEND_ERROR',
    // STATISTICS
    MARKETING_STATISTICS_SEND: 'MARKETING_STATISTICS_SEND',
    MARKETING_STATISTICS_SEND_ERROR: 'MARKETING_STATISTICS_SEND_ERROR',
    // TICKET
    MARKETING_TICKET_SEND: 'MARKETING_TICKET_SEND',
    MARKETING_TICKET_CREATED: 'MARKETING_TICKET_CREATED',
    MARKETING_TICKET_UPDATED: 'MARKETING_TICKET_UPDATED',
    MARKETING_TICKET_UPDATE_FATAL: 'MARKETING_TICKET_UPDATE_FATAL',
    MARKETING_TICKET_CREATE_FATAL: 'MARKETING_TICKET_CREATE_FATAL',
    MARKETING_TICKET_SEND_ERROR: 'MARKETING_TICKET_SEND_ERROR',
};
