import React from 'react';
import {FormSection, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {MESSAGE_SECTION, ATTACHMENT_SECTION, TICKET_SECTION, DEALER_SECTION} from '../../../../constants/formConstants';
import {scrollContentWrapperToTop} from '../../../../utils/utils';
import {marketingFieldLabelMapping} from '../../../../constants/marketingFieldLabelMapping';
import {StyledDiv} from '../../../common/StyledComponents';
import SparePartNameSection from './sections/ticket/SparePartNameSection';
import AttachmentSection from './sections/attachment/AttachmentSection';
import MessageSection from './sections/message/MessageSection';
import RenderSyncErrors from '../../../common/RenderSyncErrors';
import SubmitButtons from './sections/SubmitButtons';
import TicketStatusHistory from '../../../common/TicketStatusHistory';
import DealerSection from './sections/ticket/DealerSection';
import formMessages from '../../../../intl/modules/formMessages';

const GeneralQuestionMasterForm = (props) => {
    const {form, userRights, readOnly, handleSubmit, reqFields} = props;

    return (
        <StyledDiv className="border border-dark px-4 py-2 bg-light">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={marketingFieldLabelMapping}/>
            <FormSection name={DEALER_SECTION}>
                <DealerSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={TICKET_SECTION}>
                <SparePartNameSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={MESSAGE_SECTION}>
                <MessageSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={ATTACHMENT_SECTION}>
                <AttachmentSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <TicketStatusHistory form={form}/>
            <SubmitButtons form={form}
                           onSubmit={handleSubmit}
                           onSubmitFail={scrollContentWrapperToTop}
                           userRights={userRights}
                           readOnly={readOnly}
            />
        </StyledDiv>
    )
};

GeneralQuestionMasterForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.object.isRequired,
};

export default reduxForm({})(GeneralQuestionMasterForm);
