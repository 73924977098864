import {defineMessages} from 'react-intl';

const messages = defineMessages({
    NEW_ATTACHMENT: {
        id: 'attachment.new',
        defaultMessage: 'New attachment'
    },
    FILE_SELECT: {
        id: 'file.select',
        defaultMessage: 'Choose a file or drag it here'
    },
    FILE_DROP: {
        id: 'file.drop',
        defaultMessage: 'Drop the file'
    },
});

export default messages;
