import React, {Component} from 'react';
import { connect } from 'react-redux';
import {change, formValueSelector, getFormInitialValues, getFormValues} from 'redux-form';
import messages from '../../../../../../../intl/common/countryGroupMessages';
import { countryGroups } from '../../../../../../../constants/Utils';
import Select from 'react-select';
import {
    FIELD_BU_COUNTRIES,
    FORM_BUSINESS_UNIT_COUNTRY_SECTION,
    LABEL_BU_COUNTRIES,
} from '../../../../../../../constants/formConstants';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Label, RequiredSpan} from '../../../../../../common/StyledComponents';
import BorderRadius from '../../../../../../common/styled/BorderRadius';
import buMessages from '../../../../../../../intl/admin/businessUnitMessages'


class CountrySection extends Component {

    handleCountrySelectionChange = newOptions => {
        const { change, intl: {formatMessage} } = this.props;

        const newOptionsToSave = (newOptions ? newOptions : []).map(element => {
            return {
                value: element.value,
                label: element.label
            };
        });

        const uniqueOptionsToSave = Array.from(new Set(newOptionsToSave.map(x => x.value)))
            .map(value => {
                return {
                    value: value,
                    label: newOptionsToSave.find(y => y.value === value).label
                }
            });

        if(uniqueOptionsToSave.length > 0) {
            change(`${FORM_BUSINESS_UNIT_COUNTRY_SECTION}.${FIELD_BU_COUNTRIES}`, uniqueOptionsToSave);
        } else {
            change(`${FORM_BUSINESS_UNIT_COUNTRY_SECTION}.${FIELD_BU_COUNTRIES}`, null);
        }
    };

    render() {
        const { userGroups, readOnly, groups, intl: {formatMessage}, reqFields, isDisabled } = this.props;

        let newObj = {};

        for (const [key, value] of Object.entries(userGroups)) {
            if(value){
                newObj[key] = value;
            }
        }

        const countryOptions = Object.keys(newObj)
                .map(country => ({
                    label: messages[country] ? formatMessage(messages[country]) : country,
                    value: country
                }));

        return (
            <>
                <hr/>
                <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
                        <div className="row mb-sm-2">
                            <Label>
                                <FormattedMessage {...buMessages[LABEL_BU_COUNTRIES]} />
                                {!isDisabled && reqFields.includes(FIELD_BU_COUNTRIES) && !readOnly ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
                            </Label>
                        </div>
                        <div className="row mb-sm-3">
                            <div className="col">
                                <Select
                                    isDisabled={readOnly}
                                    isRequired={reqFields.includes(FIELD_BU_COUNTRIES) && !readOnly}
                                    field={FIELD_BU_COUNTRIES}
                                    value={groups ? groups : ''}
                                    isMulti
                                    options={countryOptions}
                                    onChange={this.handleCountrySelectionChange}
                                />
                            </div>
                        </div>
                </BorderRadius>
            </>
        )
    }
}

const mapStateToProps = (state, initialProps) => ({
    formValues: getFormValues(initialProps.form)(state),
    initialValues: getFormInitialValues(initialProps.form)(state, `countrySection`),
    userDetail: state.profile.userDetail,
    userGroups: state.profile.userDetail.roles.DISTRIBUTION.admin.groupPermission,
    groups: formValueSelector(initialProps.form)(state, `${FORM_BUSINESS_UNIT_COUNTRY_SECTION}.${FIELD_BU_COUNTRIES}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CountrySection));

