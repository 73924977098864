import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.workingHours.title',
        defaultMessage: 'Working Hours Setting'
    },
    WORKING_HOURS_TITLE: {
        id: 'admin.workingHours.workingHours',
        defaultMessage: 'Working Hours'
    },
    BANK_HOLIDAYS_TITLE: {
        id: 'admin.workingHours.bankHoliday',
        defaultMessage: 'Bank Holidays'
    },
});

export default messages;
