import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import Get from 'get-value';
import PropTypes from 'prop-types';
import {storeSelectedCountryGroup} from '../../../../actions/admin/actionBUAssignment'
import {moduleRoles} from '../../../../utils/roles';
import buMessages from '../../../../intl/admin/businessUnitMessages';
import {domains} from '../../../../constants/Utils';
import PageHeader from '../../../common/PageHeader';
import SelectCountryGroup from '../../../common/country/SelectCountryGroup';
import BUAssignmentContainer from './BUAssignmentContainer';

const Label = styled.label`
    font-weight: bold;
`;

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

class BUAssignmentPage extends Component {
    componentDidMount() {
        if (this.props.selectedCountryGroup === '') {
            this.props.storeSelectedCountryGroup(
                Get(this.getDBAdminGroups(), '0.group') || ''
            );
        }
    }

    handleCountryGroupSelection = countryGroup => this.props.storeSelectedCountryGroup(countryGroup.value);

    getDBAdminGroups = () => {
        const {roles} = this.props;
        return moduleRoles.getAdminGroups(domains.DISTRIBUTION, roles).map(group => ({
            ...group,
            domain: domains.DISTRIBUTION
        })).reduce((uniqueGroups, group) => {
                return uniqueGroups.some(item => item.group === group.group) ? uniqueGroups : [...uniqueGroups, group]
            }, [])
            .sort((groupA, groupB) => groupA.group >= groupB.group ? 1 : -1);
    };

    render() {
        const {selectedCountryGroup} = this.props;
        const groups = this.getDBAdminGroups();
        return (
            <div>
                <PageHeader title={<FormattedMessage {...buMessages.BU_ASSIGNMENT_TITLE}/>}/>
                <StyledForm className="form-group">
                    <div className="form-row mb-sm-3">
                        <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6">
                            <Label>
                                <FormattedMessage {...buMessages.SELECT_COUNTRY}/>
                            </Label>
                            <SelectCountryGroup groups={groups}
                                                handleCountryGroupSelection={this.handleCountryGroupSelection}
                                                selectedCountryGroup={selectedCountryGroup}/>
                        </div>
                    </div>
                </StyledForm>
                {groups.map((group, index) =>
                    (selectedCountryGroup === group.group &&
                        <div key={index}>
                            <BUAssignmentContainer selectedGroup={group.group}/>
                        </div>
                    )
                )}
                {/*{selectedCountryGroup && selectedCountryGroup === 'IMP' && <ImportersTable/>}*/}
            </div>
        );
    }
}

BUAssignmentPage.propTypes = {
    roles: PropTypes.object.isRequired,
    storeSelectedCountryGroup: PropTypes.func.isRequired,
    selectedCountryGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    selectedCountryGroup: state.adminBUAssignment.selectedCountryGroup
});

export default connect(mapStateToProps, {
    storeSelectedCountryGroup
})(BUAssignmentPage);
