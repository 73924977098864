import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import get from 'get-value';
import {SubmissionError} from 'redux-form';
import * as moment from 'moment';
import ExportMarketingForm from './ExportMarketingForm';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import exportMessages from '../../../intl/modules/exportMessages';
import {fetchMarketingDealers} from '../../../actions/marketing/actionMarketingDealers';
import {
    createMarketingExport,
    downloadMarketingExport,
    fetchMarketingExport
} from '../../../actions/marketing/actionMarketingExport';
import {
    FIELD_PFX,
    FIELD_SFX,
    FIELD_EXPORT_TYPE,
    FIELD_EXPORT_CHARSET
} from '../../../constants/formConstants';
import {XLS} from '../../../constants/exportConstant';
import {fetchSubjects} from '../../../actions/admin/actionAdminSubjectSettings';

class ExportMarketingPage extends Component {
    componentDidMount() {
        this.props.fetchMarketingDealers();
        this.props.fetchMarketingExport();
    }

    handleSubmit = (values) => {
        const pfx = get(values, 'pfxNumber', '').length;
        const sfx = get(values, 'sfxNumber', '').length;

        if (pfx > 0 && sfx === 0) {
            throw new SubmissionError({
                [FIELD_SFX]: 'Fill complete ticket number'
            })
        }

        if (pfx === 0 && sfx > 0) {
            throw new SubmissionError({
                [FIELD_PFX]: 'Fill complete ticket number'
            })
        }

        const temp = JSON.parse(JSON.stringify((({assigned, dealer = {}, status, priority}) => {
            const {dealerNumber, dealerName} = dealer;
            return ({
                lastEditor: assigned ? assigned.trim() : undefined,
                dealerNumber: dealerNumber ? dealerNumber.trim() : undefined,
                dealerName: dealerName ? dealerName.trim() : undefined,
                status,
                priority
            });
        })(values)));

        if (pfx || sfx) {
            temp.ticketNumber = `${values.pfxNumber}-${values.sfxNumber}`;
        }

        const exportFilter = {};
        exportFilter.filter = [];
        for (let key in temp) {
            if (temp.hasOwnProperty(key)) {
                if (key === 'lastEditor') {
                    temp[key].split(' ').forEach(element => exportFilter.filter.push({id: key, value: element}));
                } else {
                    exportFilter.filter.push({id: key, value: temp[key]})
                }
            }
        }
        if (values.from) {
            exportFilter.from = moment(values.from).add(12, 'hours').toISOString().substr(0, 10);
        }
        if (values.to) {
            exportFilter.to = moment(values.to).add(12, 'hours').toISOString().substr(0, 10);
        }
        if (values.customDeadline) {
            exportFilter.customDeadline = Number(values.customDeadline);
        }
        exportFilter.type = values.type;
        if (!exportFilter.type) {
            exportFilter.type = XLS;
        }
        exportFilter.charset = values.charset;
        this.props.createMarketingExport(exportFilter);
    };

    downloadExport = () => {
        this.props.downloadMarketingExport(this.props.exportRecord);
    };

    render() {
        const {isLoadingDealer, isLoadingExport, charSets} = this.props;
        if (isLoadingDealer || isLoadingExport
        ) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...exportMessages.TITLE}/>}/>
                <ExportMarketingForm onSubmit={this.handleSubmit}
                                     form={'marketingExportForm'}
                                     downloadExport={this.downloadExport}
                                     initialValues={{
                                         [FIELD_EXPORT_TYPE]: XLS,
                                         [FIELD_EXPORT_CHARSET]: charSets[0]
                                     }}/>
            </div>
        )
    }
}

ExportMarketingPage.propTypes = {
    fetchMarketingDealers: PropTypes.func.isRequired,
    fetchMarketingExport: PropTypes.func.isRequired,
    createMarketingExport: PropTypes.func.isRequired,
    downloadMarketingExport: PropTypes.func.isRequired,
    exportRecord: PropTypes.object.isRequired,
    isLoadingDealer: PropTypes.bool.isRequired,
    isLoadingExport: PropTypes.bool.isRequired,
    charSets: PropTypes.array.isRequired,
};

const mapStateToProp = state => ({
    exportRecord: state.marketingExportTickets.exportRecord,
    isLoadingDealer: state.marketingDealer.isLoadingDealer,
    isLoadingExport: state.marketingExportTickets.isLoadingExport,
    charSets: state.profile.userDetail.charSets,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
});

export default checkRoles(connect(mapStateToProp, {
    fetchMarketingDealers,
    fetchMarketingExport,
    createMarketingExport,
    downloadMarketingExport,
    fetchSubjects,
})(injectIntl(ExportMarketingPage)), ['MA_EDITOR'])
