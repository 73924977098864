import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Select from 'react-select';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {BoldPrimaryButton, PrimaryButton, SecondaryButton} from '../../common/Button';
import {Label} from '../../common/StyledComponents';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import authManagementMessages from '../../../intl/admin/rightsManagementMessages';
import buttonMessages from '../../../intl/common/buttonMessages';

const SelectDealerFormTypes = (props) => {
    const {
        dealerData, intl: {formatMessage}, selectedOptions, formTypesChanged, handleFormTypeSelectionChange,
        handleAllFormTypesClick, handleSaveClick, handleCancelClick
    } = props;
    const options = Object.keys(dealerData || {})
        .map(type => ({
            value: type,
            label: formTypeMessages[type] ? formatMessage(formTypeMessages[type]) : type
        }));

    const formattedSelectedOptions = (selectedOptions || []).map(type => ({
        value: type.value,
        label: formTypeMessages[type.value] ? formatMessage(formTypeMessages[type.value]) : type.value
    })).sort((option1, option2) => option1.value.localeCompare(option2.value));

    return (
        <div className="container-fluid">
            <div className="row mb-sm-2">
                <div className="col-sm-12">
                    <Label>
                        <FormattedMessage {...authManagementMessages.SELECT_FORM_TYPES}/>
                    </Label>
                    <PrimaryButton type="button" className="btn btn-sm ml-sm-3" onClick={handleAllFormTypesClick}>
                        <FormattedMessage {...buttonMessages.ALL}/>
                    </PrimaryButton>
                </div>
            </div>
            <div className="row mb-sm-3">
                <div className="col">
                    <Select isMulti isSearchable value={formattedSelectedOptions} options={options}
                            placeholder={formatMessage(authManagementMessages.SELECT_FORM_TYPES_PLACEHOLDER)}
                            onChange={handleFormTypeSelectionChange}/>
                </div>
            </div>
            <div className="row mb-sm-3">
                <div className="col-sm-6">
                    <SecondaryButton type="button" className="btn float-right"
                                     onClick={handleCancelClick}
                                     disabled={!formTypesChanged}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </SecondaryButton>
                </div>
                <div className="col-sm-6">
                    <BoldPrimaryButton type="button" className="btn float-left"
                                       disabled={!formTypesChanged}
                                       onClick={handleSaveClick}>
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                </div>
            </div>
        </div>
    );
};

SelectDealerFormTypes.propTypes = {
    dealerData: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    selectedOptions: PropTypes.array,
    formTypesChanged: PropTypes.bool.isRequired,
    handleFormTypeSelectionChange: PropTypes.func.isRequired,
    handleAllFormTypesClick: PropTypes.func.isRequired,
    handleSaveClick: PropTypes.func.isRequired,
    handleCancelClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    dealerData: state.adminRightsManagement.dealerData,
    selectedOptions: state.adminRightsManagement.selectedDealerOptions,
    formTypesChanged: state.adminRightsManagement.dealerFormTypesChanged,
});

export default connect(mapStateToProps, {})(injectIntl(SelectDealerFormTypes))
