import React from 'react';
import {injectIntl} from 'react-intl';
import {Field} from 'redux-form';
import {FormattedMessage} from 'react-intl';
import {required} from '../validationCommons';
import {RequiredSpan} from '../StyledComponents';
import {FieldWithValidation} from './FormField';
import SelectFieldWithValidation from './FormField';
import {countryLanguageMapping} from '../../../constants/Utils';
import {connect} from 'react-redux';
import get from 'get-value';

const InputSelectFieldWithValidation = (props) => {
    const {style, label, field, options, notSortedOptions, isDisabled, isRequired, intlMessages, intl, handleChange, noEmpty, customOptions, group, groupLang, language, customOptionsFts, customOptionsMsg} = props;
    let validations = [];
    if (isRequired) {
        validations.push(required);
    }
    const handleFormat = (value) => {
        let format;
        if (customOptions) {
            format = get(customOptions
                .map((option) => ({
                    id: option.uuid,
                    translation: countryLanguageMapping[group ? group : groupLang] === language ? [option.languages.local === null ? option.languages.default : option.languages.local ] : option.languages.default
                })));

            return get(format.find(option => option.id === value), 'translation', value)
        } else if (customOptionsFts) {
            format = get(customOptionsFts
                .map((option) => ({
                    id: option.id,
                    translation: option.translation
                })));
            return get(format.find(option => option.id === value), 'translation', value)
        } else if (customOptionsMsg) {
            format = get(customOptionsMsg
                .map((option) => ({
                    id: option.uuid,
                    translation: option.presetName
                })));
            return get(format.find(option => option.id === value), 'translation', value)
        } else if (intlMessages) {
            return intlMessages[value] ? intl.formatMessage(intlMessages[value]) : value
        } else {
            return value
        }
    };

    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>
            }
            <Field name={field}
                   component={isDisabled ? FieldWithValidation : SelectFieldWithValidation}
                   type="text"
                   validate={validations}
                   isDisabled={isDisabled}
                   style={style}
                   intlMessages={intlMessages}
                   options={options}
                   notSortedOptions={notSortedOptions}
                   customOptions={customOptions}
                   format={isDisabled ? ((value) => value ? handleFormat(value) : null) : null}
                   onChange={handleChange ? handleChange : undefined}
                   isRequired
                   label={label}
                   isSmall
                   placeholder="---"
                   noEmpty={noEmpty}
                   customOptionsFts={customOptionsFts}
                   customOptionsMsg={customOptionsMsg}/>
        </div>
    )
};

const mapStateToProps = (state) => ({
    group: state.distributionTicket.ticket.group,
    language: state.intl.language,
});
export default connect(mapStateToProps, {})(injectIntl(InputSelectFieldWithValidation));
