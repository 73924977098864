import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const DISTRIBUTION_TICKET_CREATE = 'DISTRIBUTION_TICKET_CREATE';
export const DISTRIBUTION_TICKET_UPDATE = 'DISTRIBUTION_TICKET_UPDATE';
export const DISTRIBUTION_TICKET_RESET = 'DISTRIBUTION_TICKET_RESET';
export const DISTRIBUTION_SUBSTITUTION_USER = 'DISTRIBUTION_SUBSTITUTION_USER';

export const fetchDistributionTicket = (uuid) => dispatch => {
    return dispatch({
        type: distributionWebsocketCommands.DISTRIBUTION_GET_TICKET,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_GET_TICKET,
        payload: {
            ticket: {
                uuid: uuid,
            }
        }
    });
};

export const createDistributionTicket = (data) => dispatch => {
    return dispatch({
        type: DISTRIBUTION_TICKET_CREATE,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_CREATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const updateDistributionTicket = (data) => dispatch => {
    return dispatch({
        type: DISTRIBUTION_TICKET_UPDATE,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_UPDATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const dealerMessageReadDistributionTicket = uuid => dispatch => {
    return dispatch({
        type: distributionWebsocketCommands.DISTRIBUTION_SET_DEALER_MESSAGE_READ,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_SET_DEALER_MESSAGE_READ,
        payload: {uuid}
    });
};

export const resetDistributionTicket = () => dispatch => {
    return dispatch({
        type: DISTRIBUTION_TICKET_RESET,
    });
};


export const setSubstitutionUser = user => ({
    type: DISTRIBUTION_SUBSTITUTION_USER,
    payload: user
});
