import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {domains, getFormStates} from '../../../constants/Utils';
import {
    fetchCommercialUserNotificationPreferences,
    updateCommercialUserNotificationPreference
} from '../../../actions/commercial/actionCommercialPreferences';
import PageHeader from '../../common/PageHeader';
import Loader from '../../Loader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import NotificationCommercialForm from './NotificationCommercialForm';
import formStatesMessages from '../../../intl/modules/formStatesMessages';
import notificationMessages from '../../../intl/modules/notificationMessages';

const domain = domains.COMMERCIAL;

class NotificationCommercialPage extends Component {
    componentDidMount() {
        this.props.fetchCommercialUserNotificationPreferences();
    }

    handleSubmit = (values) => {
        const preferences = {
            statuses: get(values, `statuses`, []),
            subjects: get(values, `subjects`, []),
        };
        this.props.updateCommercialUserNotificationPreference(preferences);
    };

    compare = (arr1, arr2) => {
        const newArray = [];
        arr1.forEach((e1) => arr2.forEach((e2) => {
            if (e1 !== e2) {
                newArray.push(e1)
            }
        }));
        return newArray
    };

    render() {
        const {commercialSubjects, isLoadingSubjects, intl: {formatMessage}, isLoadingPreferences, subjects, statuses} = this.props;

        if (isLoadingPreferences
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        const statusOptions = (getFormStates(domain) || []).map(status => ({
            value: status,
            label: formStatesMessages[status] ? formatMessage(formStatesMessages[status]) : status
        }));
        const subjectOptions = Array.prototype.concat(...Object.values(get(commercialSubjects, 'commercial', []))).map(subject => ({
            value: get(subject, `uuid`, ''),
            label: get(subject, `languages.default`, '')
        }));

        const initialValues = {
            statuses,
            subjects: (subjects || []).filter(filterSubject => Array.prototype
                .concat(...Object.values((commercialSubjects.commercial || [])))
                .find(e => e.uuid === filterSubject)),
        };

        return (
            <div>
                <PageHeader title={<FormattedMessage {...notificationMessages.TITLE}/>}/>
                {isLoadingSubjects && isLoadingPreferences && <Loader/>}
                {!isLoadingSubjects && !isLoadingPreferences && <>
                    <div className="container-fluid">
                        <NotificationCommercialForm initialValues={initialValues}
                                                    enableReinitialize={true}
                                                    onSubmit={this.handleSubmit}
                                                    statusOptions={statusOptions}
                                                    subjectOptions={subjectOptions}
                                                    form={'NotificationCommercialForm'}/>
                    </div>
                </>}
            </div>
        )
    }
}

NotificationCommercialPage.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    userDetail: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    commercialSubjects: state.adminSubjectSettings.subjectSettings.subjects,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    isLoadingPreferences: state.commercialPreferences.isLoading,
    subjects: state.commercialPreferences.subjects,
    statuses: state.commercialPreferences.statuses,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchCommercialUserNotificationPreferences,
    updateCommercialUserNotificationPreference
})(injectIntl(NotificationCommercialPage))), ['CC_DEALER', 'CC_EDITOR']);
