import {ASD_DATA_RESET, ASD_DATA_SET} from '../actions/actionAsdData';
import {apigeeWebsocketCommands} from '../constants/apigeeWebsocketCommands';

export const asdData = (state = {
    isAsdLoading: false,
    asdData: {},
    asdLoaded: false,
    asdSet: false,
}, action) => {
    switch (action.type) {
        case apigeeWebsocketCommands.ASD_DATA_GET: {
            return Object.assign({}, state, {isAsdLoading: true, asdLoaded: false, asdSet: false});
        }
        case apigeeWebsocketCommands.ASD_DATA_SEND: {
            const {asdData} = action.payload;
            return Object.assign({}, state, {asdData, isAsdLoading: false, asdLoaded: true, asdSet: true});
        }
        case ASD_DATA_SET: {
            return Object.assign({}, state, {asdData: {}, asdSet: false});
        }
        case ASD_DATA_RESET:
        case apigeeWebsocketCommands.ASD_DATA_SEND_WARN:
        case apigeeWebsocketCommands.ASD_DATA_SEND_ERROR: {
            return Object.assign({}, state, {asdData: {}, isAsdLoading: false, asdLoaded: false, asdSet: false});
        }
        default:
            return state;
    }
};
