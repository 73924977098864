import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TICKET_SECTION: {
        id: 'ticket.header.ticketSection',
        defaultMessage: 'Ticket'
    },
    ATTACHMENT_SECTION: {
        id: 'ticket.header.attachment',
        defaultMessage: 'Attachment'
    },
    MESSAGE_SECTION: {
        id: 'ticket.header.message',
        defaultMessage: 'Messages'
    },
    INTERNAL_NOTE_SECTION: {
        id: 'ticket.header.internalNotes',
        defaultMessage: 'Internal Notes'
    },
    TICKET_NUMBER: {
        id: 'form.ticketNumber',
        defaultMessage: 'Ticket Number'
    },
    STATUS: {
        id: 'form.status',
        defaultMessage: 'Status'
    },
    DEALER_NAME: {
        id: 'form.dealerName',
        defaultMessage: 'Dealer Name'
    },
    DEALER_NUMBER: {
        id: 'form.dealerNumber',
        defaultMessage: 'Dealer Number'
    },
    DATE_FROM: {
        id: 'form.from',
        defaultMessage: 'Date from'
    },
    DATE_TO: {
        id: 'form.to',
        defaultMessage: 'Date to'
    },
    ASSIGNED_TO: {
        id: 'form.assignedTo',
        defaultMessage: 'Assigned to'
    },
    BUSINESS_UNIT: {
        id: 'form.businessUnit',
        defaultMessage: 'Business Unit'
    },
    CREATOR_NAME: {
        id: 'form.creatorName',
        defaultMessage: 'Creator Name'
    },
    SUBJECT: {
        id: 'form.subject',
        defaultMessage: 'Subject'
    },
    PRIORITY: {
        id: 'form.priority',
        defaultMessage: 'Priority'
    },
    BRAND: {
        id: 'form.brand',
        defaultMessage: 'Brand'
    },
    MODEL: {
        id: 'form.model',
        defaultMessage: 'Model'
    },
    VERSION: {
        id: 'form.version',
        defaultMessage: 'Version'
    },
    VIN: {
        id: 'form.vin',
        defaultMessage: 'VIN'
    },
    ORDER_NUMBER: {
        id: 'form.orderNumber',
        defaultMessage: 'Order Number'
    },
    REFERENCE_NUMBER: {
        id: 'form.referenceNumber',
        defaultMessage: 'Reference Number'
    },
    SEGMENT: {
        id: 'form.segment',
        defaultMessage: 'Segment'
    },
    PCL_PRICE: {
        id: 'form.pclPrice',
        defaultMessage: 'PCL Price'
    },
    TECHNICAL_TYPE: {
        id: 'form.technicalType',
        defaultMessage: 'Technical Type'
    },
    FAMILY: {
        id: 'form.family',
        defaultMessage: 'Family'
    },
    PRODUCTION_DATE: {
        id: 'form.productionDate',
        defaultMessage: 'Production Date'
    },
    CURRENT_SPARE_PART_NAME: {
        id: 'form.currentSparePartName',
        defaultMessage: 'Current Spare Part Name'
    },
    SUGGESTED_SPARE_PART_NAME: {
        id: 'form.suggestedSparePartName',
        defaultMessage: 'Suggested Spare Part Name'
    },
    SPARE_PART_LOCATION: {
        id: 'form.sparePartLocation',
        defaultMessage: 'Spare Part Location'
    },
    LEVEL_EQUIPMENT: {
        id: 'form.levelEquipment',
        defaultMessage: 'Level Equipment'
    },
    CREATION_DATE: {
        id: 'form.creationDate',
        defaultMessage: 'Creation Date'
    },
    LAST_UPDATE: {
        id: 'form.lastUpdate',
        defaultMessage: 'Last Contact'
    },
    LAST_CHANGE_EDITOR: {
        id: 'form.lastChangeEditor',
        defaultMessage: 'Last Editor Change'
    },
    LAST_CHANGE_ASSIGNEE: {
        id: 'form.lastChangeAssignee',
        defaultMessage: 'Last Assignee Change'
    },
    MESSAGE: {
        id: 'ticket.message',
        defaultMessage: 'Message'
    },
    REOPEN_MESSAGE: {
        id: 'ticket.reopenMessage',
        defaultMessage: 'Reason for reopening the ticket '
    },
    AUTOMATIC_RESPONSE: {
        id: 'ticket.automaticResponse',
        defaultMessage: 'Automatic response'
    },
    UPLOADING_ATTACHMENT: {
        id: 'ticket.attachment.uploadingAttachment',
        defaultMessage: 'Ticket was saved, uploading attachment...'
    },
    INTERNAL_NOTE: {
        id: 'ticket.internalNote',
        defaultMessage: 'Internal Note'
    },
    TICKET: {
        id: 'ticket.ticket',
        defaultMessage: 'Ticket'
    },
    DISTRIBUTION_ACCOUNT: {
        id: 'ticket.distrAccount',
        defaultMessage: 'Distribution Account'
    },
    DISTRIBUTION_ACCOUNT_NAME: {
        id: 'ticket.distrAccountName',
        defaultMessage: 'Distribution Name'
    },
    CONCEDES: {
        id: 'ticket.concedes',
        defaultMessage: 'Destination Account'
    },
    CONCEDES_NAME: {
        id: 'ticket.concedesName',
        defaultMessage: 'Destination Name'
    },
    OPTION: {
        id: 'ticket.option',
        defaultMessage: 'Option'
    },
    SEND_COPY_TO: {
        id: 'ticket.sendCopyTo',
        defaultMessage: 'Send Copy To'
    },
    DEALER_ATTACHMENT: {
        id: 'ticket.dealerAttachment',
        defaultMessage: 'Dealer Attachment'
    },
    EDITOR_ATTACHMENT: {
        id: 'ticket.editorAttachment',
        defaultMessage: 'Editor Attachment'
    },
    NEW_ATTACHMENT: {
        id: 'ticket.newAttachment',
        defaultMessage: 'Attachment'
    },
    MESSAGE_HISTORY: {
        id: 'ticket.messageHistory',
        defaultMessage: 'Message History'
    },
    INTERNAL_NOTE_HISTORY: {
        id: 'ticket.internalNoteHistory',
        defaultMessage: 'Internal Note History'
    },
    IS_MANDATORY: {
        id: 'ticket.isMandatory',
        defaultMessage: 'is mandatory'
    },
    SEND_COPY_TO_PLACEHOLDER: {
        id: 'ticket.sendCopyToPlaceholder',
        defaultMessage: 'Add e-mail address and press ENTER'
    },
    TYPE_OF_PURCHASE: {
        id: 'form.typeOfPurchase',
        defaultMessage: 'Type Of Purchase'
    },
    EMPLOYEE_OF: {
        id: 'form.employeeOf',
        defaultMessage: 'Employee Of'
    },
    NAME_AND_SURNAME_OF_THE_EMPLOYEE: {
        id: 'form.nameAndSurnameOfTheEmployee',
        defaultMessage: 'Name And Surname Of The Employee Eligible To The Discount'
    },
    NO_OF_THE_EMPLOYEE_ELIGIBLE_FOR_A_DISCOUNT: {
        id: 'form.noOfTheEmployeeEligibleForADiscount',
        defaultMessage: 'No: AL / IL / DL Of The Employee Eligible For A Discount'
    },
    NAME_AND_SURNAME_OF_THE_PERSON: {
        id: 'form.nameAndSurnameOfThePerson',
        defaultMessage: 'Name And Surname Of The Person Using The Offer In The Case Of Transfering The Discount'
    },
    COLOUR: {
        id: 'form.colour',
        defaultMessage: 'Colour'
    },
    YEAR_OF_PRODUCTION: {
        id: 'form.yearOfProduction',
        defaultMessage: 'Year Of Production'
    },
    SUBSTITUTION_DEALER: {
        id: 'form.substitution.dealer',
        defaultMessage: 'Substitution as dealer'
    },
    CONTACT: {
        id: 'form.contact',
        defaultMessage: 'Contact'
    },
    TABLE_LAST_EDITOR: {
        id: 'table.lastEditor',
        defaultMessage: 'Last Editor'
    },
});

export default messages;
