import {defineMessages} from 'react-intl';

const messages = defineMessages({
    AR: {
        id: 'country.ar',
        defaultMessage: 'Argentina'
    },
    AT: {
        id: 'country.at',
        defaultMessage: 'Austria'
    },
    BA: {
        id: 'country.ba',
        defaultMessage: 'Bosnia and Herzegovina'
    },
    BE: {
        id: 'country.be',
        defaultMessage: 'Belgium'
    },
    CH: {
        id: 'country.ch',
        defaultMessage: 'Switzerland'
    },
    CZ: {
        id: 'country.cz',
        defaultMessage: 'Czechia'
    },
    DE: {
        id: 'country.de',
        defaultMessage: 'Germany'
    },
    DK: {
        id: 'country.dk',
        defaultMessage: 'Denmark'
    },
    EE: {
        id: 'country.ee',
        defaultMessage: 'Estonia'
    },
    ES: {
        id: 'country.es',
        defaultMessage: 'Spain'
    },
    FI: {
        id: 'country.fi',
        defaultMessage: 'Finland'
    },
    FR: {
        id: 'country.fr',
        defaultMessage: 'France'
    },
    HR: {
        id: 'country.hr',
        defaultMessage: 'Croatia'
    },
    HU: {
        id: 'country.hu',
        defaultMessage: 'Hungary'
    },
    IE: {
        id: 'country.ie',
        defaultMessage: 'Ireland'
    },
    IT: {
        id: 'country.it',
        defaultMessage: 'Italy'
    },
    LT: {
        id: 'country.lt',
        defaultMessage: 'Lithuania'
    },
    LU: {
        id: 'country.lu',
        defaultMessage: 'Luxembourg'
    },
    LV: {
        id: 'country.lv',
        defaultMessage: 'Latvia'
    },
    MA: {
        id: 'country.ma',
        defaultMessage: 'Morocco'
    },
    ME: {
        id: 'country.me',
        defaultMessage: 'Montenegro'
    },
    MK: {
        id: 'country.mk',
        defaultMessage: 'North Macedonia'
    },
    NL: {
        id: 'country.nl',
        defaultMessage: 'Netherlands'
    },
    NO: {
        id: 'country.no',
        defaultMessage: 'Norway'
    },
    PL: {
        id: 'country.pl',
        defaultMessage: 'Poland'
    },
    PT: {
        id: 'country.pt',
        defaultMessage: 'Portugal'
    },
    RO: {
        id: 'country.ro',
        defaultMessage: 'Romania'
    },
    RS: {
        id: 'country.rs',
        defaultMessage: 'Serbia'
    },
    SE: {
        id: 'country.se',
        defaultMessage: 'Sweden'
    },
    SI: {
        id: 'country.si',
        defaultMessage: 'Slovenia'
    },
    SK: {
        id: 'country.sk',
        defaultMessage: 'Slovakia'
    },
    GB: {
        id: 'country.gb',
        defaultMessage: 'United Kingdom'
    },
    XK: {
        id: 'country.xk',
        defaultMessage: 'Kosovo'
    },
    IMP: {
        id: 'country.imp',
        defaultMessage: 'Importer Countries'
    },
    CM: {
        id: 'country.cm',
        defaultMessage: 'Cameroon'
    },
    CV: {
        id: 'country.cv',
        defaultMessage: 'Cabo Verde'
    },
    LK: {
        id: 'country.lk',
        defaultMessage: 'Sri Lanka'
    },
    TD: {
        id: 'country.td',
        defaultMessage: 'Chad'
    },
    TW: {
        id: 'country.tw',
        defaultMessage: 'Taiwan'
    },
    YT: {
        id: 'country.yt',
        defaultMessage: 'Mayotte'
    },
    CG: {
        id: 'country.cg',
        defaultMessage: 'Congo'
    },
    CY: {
        id: 'country.cy',
        defaultMessage: 'Cyprus'
    },
    BJ: {
        id: 'country.bj',
        defaultMessage: 'Benin'
    },
    GF: {
        id: 'country.gf',
        defaultMessage: 'French Guiana'
    },
    DJ: {
        id: 'country.dj',
        defaultMessage: 'Djibouti'
    },
    GA: {
        id: 'country.ga',
        defaultMessage: 'Gabon'
    },
    GE: {
        id: 'country.ge',
        defaultMessage: 'Georgia'
    },
    PS: {
        id: 'country.ps',
        defaultMessage: 'Palestine'
    },
    GH: {
        id: 'country.gh',
        defaultMessage: 'Ghana'
    },
    GP: {
        id: 'country.gp',
        defaultMessage: 'Guadeloupe'
    },
    HK: {
        id: 'country.hk',
        defaultMessage: 'Hong Kong'
    },
    ID: {
        id: 'country.id',
        defaultMessage: 'Indonesia'
    },
    IR: {
        id: 'country.ir',
        defaultMessage: 'Iran'
    },
    IQ: {
        id: 'country.iq',
        defaultMessage: 'Iraq'
    },
    IL: {
        id: 'country.il',
        defaultMessage: 'Israel'
    },
    CI: {
        id: 'country.ci',
        defaultMessage: 'Ivory Coast'
    },
    JO: {
        id: 'country.jo',
        defaultMessage: 'Jordan'
    },
    KE: {
        id: 'country.ke',
        defaultMessage: 'Kenya'
    },
    KW: {
        id: 'country.kw',
        defaultMessage: 'Kuwait'
    },
    LB: {
        id: 'country.lb',
        defaultMessage: 'Lebanon'
    },
    LR: {
        id: 'country.lr',
        defaultMessage: 'Liberia'
    },
    LY: {
        id: 'country.ly',
        defaultMessage: 'Libya'
    },
    MG: {
        id: 'country.mg',
        defaultMessage: 'Madagascar'
    },
    MW: {
        id: 'country.mw',
        defaultMessage: 'Malawi'
    },
    MY: {
        id: 'country.my',
        defaultMessage: 'Malaysia'
    },
    ML: {
        id: 'country.ml',
        defaultMessage: 'Mali'
    },
    MT: {
        id: 'country.mt',
        defaultMessage: 'Malta'
    },
    MQ: {
        id: 'country.mq',
        defaultMessage: 'Martinique'
    },
    MU: {
        id: 'country.mu',
        defaultMessage: 'Mauritius'
    },
    MZ: {
        id: 'country.mz',
        defaultMessage: 'Mozambique'
    },
    OM: {
        id: 'country.om',
        defaultMessage: 'Oman'
    },
    NC: {
        id: 'country.nc',
        defaultMessage: 'New Caledonia'
    },
    NZ: {
        id: 'country.nz',
        defaultMessage: 'New Zealand'
    },
    NE: {
        id: 'country.ne',
        defaultMessage: 'Niger'
    },
    NG: {
        id: 'country.ng',
        defaultMessage: 'Nigeria'
    },
    QA: {
        id: 'country.qa',
        defaultMessage: 'Qatar'
    },
    RE: {
        id: 'country.re',
        defaultMessage: 'Réunion'
    },
    PM: {
        id: 'country.pm',
        defaultMessage: 'Saint Pierre and Miquelon'
    },
    SA: {
        id: 'country.sa',
        defaultMessage: 'Saudi Arabia'
    },
    SN: {
        id: 'country.sn',
        defaultMessage: 'Senegal'
    },
    SC: {
        id: 'country.sc',
        defaultMessage: 'Seychelles'
    },
    SG: {
        id: 'country.sg',
        defaultMessage: 'Singapore'
    },
    VN: {
        id: 'country.vn',
        defaultMessage: 'Vietnam'
    },
    ZW: {
        id: 'country.zw',
        defaultMessage: 'Zimbabwe'
    },
    SD: {
        id: 'country.sd',
        defaultMessage: 'Sudan'
    },
    TH: {
        id: 'country.th',
        defaultMessage: 'Thailand'
    },
    TG: {
        id: 'country.tg',
        defaultMessage: 'Togo'
    },
    AE: {
        id: 'country.ae',
        defaultMessage: 'United Arab Emirates'
    },
    UG: {
        id: 'country.ug',
        defaultMessage: 'Uganda'
    },
    EG: {
        id: 'country.eg',
        defaultMessage: 'Egypt'
    },
    TZ: {
        id: 'country.tz',
        defaultMessage: 'Tanzania'
    },
    BF: {
        id: 'country.bf',
        defaultMessage: 'Burkina Faso'
    },
    ZM: {
        id: 'country.zm',
        defaultMessage: 'Zambia'
    },
    PF: {
        id: 'country.pf',
        defaultMessage: 'French Polynesia'
    },
    AO: {
        id: 'country.ao',
        defaultMessage: 'Angola'
    },
    AU: {
        id: 'country.au',
        defaultMessage: 'Australia'
    },
    BH: {
        id: 'country.bh',
        defaultMessage: 'Bahrain'
    },
    BN: {
        id: 'country.bn',
        defaultMessage: 'Brunei'
    },
    ZA: {
        id: 'country.za',
        defaultMessage: 'South Africa'
    },
    PH: {
        id: 'country.ph',
        defaultMessage: 'Philippines'
    },
    MR: {
        id: 'country.mr',
        defaultMessage: 'Mauritania'
    },
    SY: {
        id: 'country.sy',
        defaultMessage: 'Syria'
    },
    TN: {
        id: 'country.tn',
        defaultMessage: 'Tunisia'
    },
    GR: {
        id: 'country.gr',
        defaultMessage: 'Greece'
    },
    IS: {
        id: 'country.is',
        defaultMessage: 'Iceland'
    },
    ET: {
        id: 'country.et',
        defaultMessage: 'Ethiopia'
    },

    ALL: {
        id: 'country.all',
        defaultMessage: 'All Countries'
    },
});

export default messages;
