import {fetchDealersPromise} from '../actions/actionRole';

export function handleDealerSearch(inputValue, debouncedCallback, errorCallback, domain, formType, editorGroups) {
    fetchDealersPromise(inputValue, domain, formType).then(
        ({data}) => debouncedCallback(
            data.sort((user1, user2) => user1.name.localeCompare(user2.name)).filter(user => editorGroups.includes(user.group))
                .map(mapDealerToSelectionOption)
        ),
        error => errorCallback(error)
    );
}

export function mapDealerToSelectionOption(user) {
    return Object.assign(user, {value: user.ipn, label: extractDealerLabelForSelectionOption(user)});
}

export function extractDealerLabelForSelectionOption(user) {
    return `${user.name} (${user.ipn})${user.group ? ' - ' + user.group : ''}`;
}
