import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';

export const fetchCommercialDealers = () => (dispatch) => {
    dispatch({
        type: commercialWebsocketCommands.COMMERCIAL_DEALERS_GET,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_DEALERS_GET,
        payload: {}
    });
};
