import React, {Component} from 'react';
import {FormattedMessage, FormattedDate, FormattedTime, injectIntl} from 'react-intl';
import {getFormInitialValues, formValueSelector, change, Field} from 'redux-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {
    FIELD_SEND_COPY_TO,
    FIELD_MESSAGE,
    MESSAGE_SECTION,
    TICKET_SECTION
} from '../../../../../../constants/formConstants';
import {TextareaFieldWithValidation} from '../../../../../common/fields/TextareaFieldWithValidation';
import {EmailSelector} from '../../../../../common/fields/EmailSelector';
import {domains} from '../../../../../../constants/Utils';
import {StyledDiv} from '../../../../../common/StyledComponents';
import InputSelectFieldWithValidation from '../../../../../common/fields/InputSelectFieldWithValidation';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../intl/modules/formMessages';
import commonMessages from '../../../../../../intl/common/commonMessages';
import {bindActionCreators} from 'redux';
import {fetchPresetResponse} from '../../../../../../actions/admin/actionAdminPresetResponse';
import {handleEmailSearch} from '../../../../../../utils/utils';

class MessageSection extends Component {
    constructor(props) {
        super(props);
    };

    componentDidMount() {
        this.props.fetchPresetResponse(domains.DISTRIBUTION, this.props.group);
    }

    render() {
        const {userRights, readOnly, reqFields, change, messages, presetResponses} = this.props;
        const handleChange = (e) => {
            if (e.target.value !== '' && e.target.value !== undefined && e.target.value !== null) {
                change(`${MESSAGE_SECTION}.${FIELD_MESSAGE}`, get(presetResponses.find(i => i.uuid === e.target.value), 'presetMessage', ''));
            }
        };
        return (
            <>
                {((userRights.canAddMessage && !readOnly) || (messages && (messages.length > 0))) &&
                <>
                    <h3 className="text-left mt-4 ml-4">
                        <FormattedMessage {...formMessages.MESSAGE_SECTION}/>
                    </h3>
                    <StyledDiv className="border border-secondary bg-white">
                        {(messages && (messages.length > 0)) &&
                        <div>
                            <div className="border-bottom border-bottom-secondary"
                                 style={{backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0'}}>
                                <label className="col-form-label pl-0 font-weight-bold ml-3">
                                    <FormattedMessage {...formMessages.MESSAGE_HISTORY}/>
                                </label>
                            </div>
                            {messages.map((message, index) =>
                                <div key={index} className="row m-0 align-items-stretch">
                                    <div style={{backgroundColor: '#f8f9fa'}}
                                         className="col-md-12 col-lg-4 flex-column border-bottom">
                                        <div>
                                            <FormattedDate value={new Date(message.created)}/>
                                            {' '}
                                            <FormattedTime value={new Date(message.created)}/>
                                            <span className="font-weight-bold">
                                                {' '}
                                                <ValidFormattedMessage
                                                    message={message.isDealer ? 'DEALER' : 'EDITOR'}
                                                    intlMessages={commonMessages}/>
                                            </span>
                                        </div>
                                        <div>
                                            {message.creatorName + ' (' + message.creatorIpn + ')'}
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-8 flex-column border-bottom">
                                        <div style={{whiteSpace: "pre-wrap"}}>
                                            {message.message}
                                        </div>
                                        {message.sendCopyTo &&
                                        <div className="font-italic">
                                            {message.sendCopyTo.join(', ')}
                                        </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                        }
                        {userRights.canAddMessage && !readOnly &&
                        <div className="col-sm-12 px-4 py-2">
                            {userRights.canShowAutomaticResponse &&
                            <div className="col-xl-4 col-lg-6 col-md-12 px-0 pb-2">
                                <InputSelectFieldWithValidation label={formMessages.AUTOMATIC_RESPONSE}
                                                                field={'test'}
                                                                customOptionsMsg={presetResponses}
                                                                handleChange={handleChange}/>
                            </div>
                            }
                            <div className="form-row">
                                <div className="col-12">
                                    <TextareaFieldWithValidation label={formMessages.MESSAGE}
                                                                 field={FIELD_MESSAGE}
                                                                 isRequired={reqFields.includes(FIELD_MESSAGE)}
                                                                 rows={4}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col py-2">
                                    <Field name={FIELD_SEND_COPY_TO}
                                           label={formMessages.SEND_COPY_TO}
                                           parse={(emails) => emails ? emails.map(mail => mail.value) : emails}
                                           handleSearch={(value, callback) => handleEmailSearch(value, domains.DISTRIBUTION, callback)}
                                           component={EmailSelector}
                                           validate={(emails = []) => (emails && emails.length >= 20)  ? 'MAXIMUM_EMAILS' : undefined}
                                    />
                                </div>
                            </div>
                        </div>
                        }
                    </StyledDiv>
                </>
                }
            </>
        )
    }
}

MessageSection.propTypes = {
    intl: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
};
const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
    messages: formValueSelector(props.form)(state, 'messages'),
    presetResponses: state.adminPresetResponse.presetResponses,
    group: formValueSelector(props.form)(state, `${TICKET_SECTION}.group`),
});
const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            fetchPresetResponse
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MessageSection));
