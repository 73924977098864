import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    fetchBusinessUnit,
    resetBusinessUnit,
    updateBusinessUnit,
    redirectReset
} from '../../../../actions/admin/actionBusinessUnit';
import Loader from '../../../Loader';
import { BUSINESS_UNIT_FORM, } from '../../../../constants/formConstants';
import BusinessUnitForm from './forms/BusinessUnitForm';
import {
    initLoadedBusinessUnit,
    transformUpdateBusinessUnitForSave
} from '../../../../utils/businessUnitUtils';
import { editReqFields } from '../../../../constants/businessUnitRequiredFields';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH,
} from '../../../../routes/paths';
import menuMessages from '../../../../intl/common/menuMessages';


class EditBusinessUnitPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetBusinessUnit();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchBusinessUnit(this.props.domain, this.props.match.params.businessUnitUuid, this.props.loggedUser.ipn);
        }
    }

    handleSubmit = (values) => {
        const dataForSend = transformUpdateBusinessUnitForSave(values, this.props.businessUnitData);
        this.props.updateBusinessUnit(this.props.domain, dataForSend);
    };

    componentWillUnmount() {
        this.props.redirectReset(this.props.domain);
    }

    render() {
        const {
            businessUnitData,
            domain,
            intl: {formatMessage},
        } = this.props;

        let formType = false;
        if (get(businessUnitData, 'businessUnit.name', { default: false })) {
            formType = BUSINESS_UNIT_FORM;
        }

        if (businessUnitData.businessUnitSubmittedAndShouldRedirect && !businessUnitData.isBusinessUnitCreating && !businessUnitData.isBusinessUnitLoading && get(businessUnitData, 'businessUnit.id', { default: false })) {
            return <Redirect to={{
                pathname: `${ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_PATH}/view/${businessUnitData.businessUnit.id}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (
            businessUnitData.isBusinessUnitLoading
            || businessUnitData.isBusinessUnitCreating
            || !formType
        ) {
            return <Loader/>;
        }

        let initializationValue;
        initializationValue = initLoadedBusinessUnit(businessUnitData, formatMessage);

        const requiredFields = editReqFields['DEFAULT'][formType];
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.businessUnit.title"
                                      defaultMessage="Business Unit Management - {domain} - Business Unit"
                                      values={{ domain: <FormattedMessage {...menuMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <BusinessUnitForm
                            form={formType}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            domain={domain}
                            editing={true}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

EditBusinessUnitPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    resetBusinessUnit: PropTypes.func.isRequired,
    fetchBusinessUnit: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateBusinessUnit: PropTypes.func.isRequired,
    intl: PropTypes.object,
    businessUnitData: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    location: PropTypes.object,
};


const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    businessUnitData: state.adminBusinessUnit,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchBusinessUnit,
    updateBusinessUnit,
    resetBusinessUnit,
    redirectReset,
})(injectIntl(EditBusinessUnitPage))), ['DB_ADMIN']);
