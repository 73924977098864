import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    DISTRIBUTION: {
        id: 'formType.distribution',
        defaultMessage: 'Distribution'
    },
    DISTRIBUTION_FORM: {
        id: 'formType.distribution.distributionTicket',
        defaultMessage: 'Distribution Ticket'
    },
    COMMERCIAL: {
        id: 'formType.commercial',
        defaultMessage: 'Commercial'
    },
    COMMERCIAL_FORM: {
        id: 'formType.commercial.commercialTicket',
        defaultMessage: 'Commercial Ticket'
    },
    OFFER_FOR_EMPLOYEES: {
        id: 'formType.offerForEmployeesForm',
        defaultMessage: 'Offer for Employees'
    },
    OFFER_FOR_EMPLOYEES_FORM: {
        id: 'formType.commercial.offerForEmployeesForm',
        defaultMessage: 'Offer for Employees'
    },
    MARKETING: {
        id: 'formType.marketing',
        defaultMessage: 'Marketing Spare Parts'
    },
    GENERAL_QUESTION: {
        id: 'formType.marketing.generalQuestion',
        defaultMessage: 'General Question'
    },
    SPARE_PART_PRICE_VERIFICATION: {
        id: 'formType.marketing.sparePartPriceVerification',
        defaultMessage: 'Spare Part Price Verification'
    },
    SPARE_PART_NAME_VERIFICATION: {
        id: 'formType.marketing.sparePartNameVerification',
        defaultMessage: 'Spare Part Name Verification'
    },
});

export default messages;
