import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {domains} from '../../constants/Utils';

export const ADMIN_TICKET_SETTINGS_SELECT_GROUP = 'ADMIN_TICKET_SETTINGS_SELECT_GROUP';
export const ADMIN_TICKET_SETTINGS_FETCH = 'ADMIN_TICKET_SETTINGS_FETCH';
export const ADMIN_TICKET_SETTINGS_UPDATE = 'ADMIN_TICKET_SETTINGS_UPDATE';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_TICKET_SETTINGS_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchTicketSettings = (domain, group) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_FETCH,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_TICKET_SETTINGS_GET,
                payload: {group}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_FETCH,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_TICKET_SETTINGS_GET,
                payload: {group}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_FETCH,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_TICKET_SETTINGS_GET,
                payload: {group}
            });
        }
    }
};

export const updateTicketSettings = (domain, settings) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_UPDATE,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_TICKET_SETTINGS_SET,
                payload: {settings}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_UPDATE,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_TICKET_SETTINGS_SET,
                payload: {settings}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_UPDATE,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_TICKET_SETTINGS_SET,
                payload: {settings}
            });
        }
    }
};
