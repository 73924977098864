import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';

export const COMMERCIAL_TICKET_CREATE = 'COMMERCIAL_TICKET_CREATE';
export const COMMERCIAL_TICKET_UPDATE = 'COMMERCIAL_TICKET_UPDATE';
export const COMMERCIAL_TICKET_RESET = 'COMMERCIAL_TICKET_RESET';

export const fetchCommercialTicket = (uuid) => dispatch => {
    return dispatch({
        type: commercialWebsocketCommands.COMMERCIAL_GET_TICKET,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_GET_TICKET,
        payload: {
            ticket: {
                uuid: uuid,
            }
        }
    });
};

export const createCommercialTicket = (data) => dispatch => {
    return dispatch({
        type: COMMERCIAL_TICKET_CREATE,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_CREATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const updateCommercialTicket = (data) => dispatch => {
    return dispatch({
        type: COMMERCIAL_TICKET_UPDATE,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_UPDATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const resetCommercialTicket = () => dispatch => {
    return dispatch({
        type: COMMERCIAL_TICKET_RESET,
    });
};
