import {domains} from '../../constants/Utils';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const ADMIN_WORKING_HOURS_SELECT_GROUP = 'ADMIN_WORKING_HOURS_SELECT_GROUP';
export const ADMIN_WORKING_HOURS_FETCH = 'ADMIN_WORKING_HOURS_FETCH_PENDING';
export const ADMIN_WORKING_HOURS_SAVE = 'ADMIN_WORKING_HOURS_SAVE_PENDING';
export const ADMIN_WORKING_HOURS_UPDATE = 'ADMIN_WORKING_HOURS_UPDATE';
export const ADMIN_BANK_HOLIDAYS_UPDATE = 'ADMIN_BANK_HOLIDAYS_UPDATE';
export const ADMIN_WORKING_HOURS_RESET = 'ADMIN_WORKING_HOURS_RESET';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_WORKING_HOURS_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchWorkingHours = (domain, group) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_FETCH,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_WORKING_HOURS_GET,
                payload: {group}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_FETCH,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_WORKING_HOURS_GET,
                payload: {group}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_FETCH,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_WORKING_HOURS_GET,
                payload: {group}
            });
        }
    }
};

export const saveWorkingHours = (domain, workingHours) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_SAVE,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_WORKING_HOURS_SET,
                payload: workingHours
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_SAVE,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_WORKING_HOURS_SET,
                payload: workingHours
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_SAVE,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_WORKING_HOURS_SET,
                payload: workingHours
            });
        }
    }
};

export const updateWorkingHours = (day, values) => ({
    type: ADMIN_WORKING_HOURS_UPDATE,
    payload: {day, values},
});

export const updateBankHolidays = bankHolidays => ({
    type: ADMIN_BANK_HOLIDAYS_UPDATE,
    payload: {bankHolidays},
});

export const resetWorkingHours = () => ({
    type: ADMIN_WORKING_HOURS_RESET,
});
