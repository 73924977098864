import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import '../../../css/formControl.css';
import validationMessages from '../../../intl/common/validationMessages';
import ValidFormattedMessage from '../ValidFormattedMessage';
import {connect} from 'react-redux';
import {countryLanguageMapping} from '../../../constants/Utils';

const ErrorDiv = styled.label`
    display: inherit;
`;

const StyledInput = styled.input`
    color: black;
`;

const StyledSelect = styled.select`
    color: black;
`;

export class FieldWithValidation extends Component {
    render() {
        const {
            input, id, isDisabled, isSmall, type = 'text', style, placeholder, maxLength, handleNameChange, autoFocus,
            setRef = () => {
            }, meta: {touched, error, invalid}
        } = this.props;
        return (
            <div>
                <StyledInput {...input} type={type} placeholder={placeholder} maxLength={maxLength}
                             disabled={isDisabled}
                             className={'form-control ' + (isSmall ? ' form-control-sm' : '') + (touched && invalid ? ' is-invalid' : '')}
                             onKeyUp={(e) => handleNameChange && handleNameChange(e.target.value, id)}
                             autoFocus={autoFocus}
                             style={style}
                             ref={setRef}
                />
                {touched && error && <ErrorDiv className="invalid-feedback">
                    <ValidFormattedMessage message={error} intlMessages={validationMessages}/></ErrorDiv>
                }
            </div>
        );
    }
}

FieldWithValidation.propTypes = {
    input: PropTypes.object.isRequired,
    id: PropTypes.any,
    isDisabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    handleNameChange: PropTypes.func,
    autoFocus: PropTypes.bool
};

const SelectFieldWithValidation = props => {

    const {
        input, isDisabled, isSmall, options, notSortedOptions, customOptions, customOptionsFts, customOptionsMsg, intlMessages, intl,
        noEmpty, userDetail, language, meta: {touched, error, invalid}
    } = props;

    let sortedOptions;
    if (customOptions) {
        sortedOptions = customOptions
            .filter(option => option && (typeof option === 'object' || option instanceof Object) && option.active === true)
            .map((option) => ({
                id: option.uuid,
                translation: countryLanguageMapping[userDetail.group] === language ? [option.languages.local === null ? option.languages.default : option.languages.local ] : option.languages.default
            }));
    } else if (customOptionsFts) {
        sortedOptions = customOptionsFts
            .filter(option => option && (typeof option === 'object' || option instanceof Object))
            .map((option) => ({
                id: option.id,
                translation: option.translation
            }));
    } else if (customOptionsMsg) {
        sortedOptions = customOptionsMsg
            .filter(option => option && (typeof option === 'object' || option instanceof Object))
            .map((option) => ({
                id: option.uuid,
                translation: option.presetName
            }));
    } else if (options) {
        sortedOptions = options.filter(option => option && (typeof option === 'string' || option instanceof String)).map((option) => ({
            id: option,
            translation: intlMessages ? (intlMessages[option] ? intl.formatMessage(intlMessages[option]) : option) : option
        })).sort((option1, option2) => option1.translation.localeCompare(option2.translation));
    } else if (notSortedOptions) {
        sortedOptions = notSortedOptions.filter(option => option && (typeof option === 'string' || option instanceof String)).map((option) => ({
            id: option,
            translation: intlMessages ? (intlMessages[option] ? intl.formatMessage(intlMessages[option]) : option) : option
        }))
    } else {
        sortedOptions = [];
    }

    return (
        <div>
            <StyledSelect {...input}
                          disabled={isDisabled}
                          className={'form-control ' + (isSmall ? ' form-control-sm' : '') + (touched && invalid ? ' is-invalid' : '')}>
                {!noEmpty && <option/>}

                {sortedOptions.length && sortedOptions.map((option, index) =>
                    <option key={index} value={option.id}>
                        {option.translation}
                    </option>)}

                {!sortedOptions.some(option => option.id === input.value) && input.value !== ''
                && input.value !== undefined && input.value !== null &&
                <option disabled key={options.length} value={input.value}>{input.value}</option>}
            </StyledSelect>
            {touched && error && <ErrorDiv className="invalid-feedback">
                <ValidFormattedMessage message={error} intlMessages={validationMessages}/></ErrorDiv>}
        </div>
    );
};
SelectFieldWithValidation.propTypes = {
    input: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    options: PropTypes.array,
    notSortedOptions: PropTypes.array,
    intlMessages: PropTypes.any,
    intl: PropTypes.any,
};
const mapStateToProps = (state) => ({
    userDetail: state.profile.userDetail,
    language: state.intl.language,
});
export default connect(mapStateToProps, {})(injectIntl(SelectFieldWithValidation));
