import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {storeSelectedCountryGroup} from '../../../actions/admin/actionAdminWorkingHours';
import {moduleRoles} from '../../../utils/roles';
import get from 'get-value';
import PageHeader from '../../common/PageHeader';
import {FormattedMessage} from 'react-intl';
import {Label, StyledForm} from '../../common/StyledComponents';
import SelectCountryGroup from '../../common/country/SelectCountryGroup';
import workingHoursMessages from '../../../intl/admin/workingHoursMessages';
import WorkingHoursContainer from './WorkingHoursContainer';
import adminMessages from '../../../intl/admin/adminMessages';

class WorkingHoursPage extends Component {
    componentDidMount() {
        const {domain, roles, selectedCountryGroup} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        if (selectedCountryGroup === '' || !groups.map(group => group.group).includes(selectedCountryGroup)) {
            this.props.storeSelectedCountryGroup(get(groups, '0.group', ''))
        }
    }

    handleCountryGroupSelection = countryGroup => this.props.storeSelectedCountryGroup(countryGroup.value);

    render() {
        const {domain, roles, selectedCountryGroup} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        return (
            <div>
                <PageHeader title={<FormattedMessage {...workingHoursMessages.TITLE}/>}/>
                <StyledForm className="form-group">
                    <div className="form-row mb-sm-3">
                        <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6">
                            <Label>
                                <FormattedMessage {...adminMessages.SELECT_COUNTRY}/>
                            </Label>
                            <SelectCountryGroup groups={groups}
                                                handleCountryGroupSelection={this.handleCountryGroupSelection}
                                                selectedCountryGroup={selectedCountryGroup}/>
                        </div>
                    </div>
                </StyledForm>
                {groups.map((group, index) =>
                    (selectedCountryGroup === group.group &&
                        <div key={index}>
                            <WorkingHoursContainer domain={domain} group={group.group}/>
                        </div>
                    )
                )}
            </div>
        )
    }
}

WorkingHoursPage.propTypes = {
    domain: PropTypes.string.isRequired,
    roles: PropTypes.object.isRequired,
    storeSelectedCountryGroup: PropTypes.func.isRequired,
    selectedCountryGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    selectedCountryGroup: state.adminWorkingHours.selectedCountryGroup
});

export default connect(mapStateToProps, {
    storeSelectedCountryGroup
})(WorkingHoursPage);
