import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import get from 'get-value';
import {
    lockCommercialTicket,
    resetRedirectAfterLockCommercialTicket
} from '../../../actions/commercial/actionCommercialLockTicket';
import {clearAlerts} from '../../../actions/alertsActions';
import {
    fetchCommercialPreferences,
    updateCommercialUserFullTextSearchPreference
} from '../../../actions/commercial/actionCommercialPreferences';
import {fetchCommercialFullTextSearch} from '../../../actions/commercial/actionCommercialFullTextSearch';
import {fetchSubjects} from '../../../actions/admin/actionAdminSubjectSettings';
import {StyledPicky} from '../../common/StyledComponents';
import checkRoles from '../../common/roleChecker/RoleChecker';
import FullTextSearchCommercialForm from './FullTextSearchCommercialForm';
import FullTextSearchCommercialTable from './FullTextSearchCommercialTable';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import fullTextSearchMessages from '../../../intl/modules/fullTextSearchMessages';
import tableMessages from '../../../intl/common/tableMessages';
import formStatesMessages from '../../../intl/modules/formStatesMessages';
import formMessages from '../../../intl/modules/formMessages';
import formTicketValuesMessages from '../../../intl/common/formTicketValuesMessages';

class FullTextSearchCommercialPage extends Component {
    constructor(props) {
        super(props);
        const {intl} = props;
        this.state = {
            ratchet: true,
            searchDefaultFiltersCC: [],
            columnLibrary: [
                {
                    id: 'CREATION_DATE',
                    accessor: 'created',
                    filterable: false,
                    message: formMessages.CREATION_DATE,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'BRAND',
                    accessor: 'brand',
                    filterable: false,
                    message: formMessages.BRAND,
                },
                {
                    id: 'DEALER_NAME',
                    accessor: 'dealerName',
                    filterable: false,
                    message: formMessages.DEALER_NAME,
                },
                {
                    id: 'CREATOR_NAME',
                    accessor: 'creatorName',
                    filterable: false,
                    message: formMessages.CREATOR_NAME,
                },
                {
                    id: 'PRIORITY',
                    accessor: 'priority',
                    filterable: false,
                    message: formMessages.PRIORITY,
                    Cell: e =>
                        <div>{formTicketValuesMessages[e.value] ? intl.formatMessage(formTicketValuesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'SUBJECT',
                    accessor: 'subject',
                    filterable: false,
                    message: formMessages.SUBJECT,
                },
                {
                    id: 'ASSIGNED_TO',
                    accessor: 'lastEditor',
                    filterable: false,
                    message: formMessages.ASSIGNED_TO,
                },
                {
                    id: 'STATUS',
                    accessor: 'status',
                    filterable: false,
                    message: formMessages.STATUS,
                    Cell: e =>
                        <div>
                            {formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}
                        </div>,
                },
                {
                    id: 'CLOSING_DATE',
                    accessor: 'closed',
                    filterable: false,
                    message: tableMessages.CLOSING_DATE,
                },
                {
                    id: 'VIN',
                    accessor: 'vin',
                    filterable: false,
                    message: formMessages.VIN,
                },
            ],
        }
    }

    componentWillMount() {
        this.props.resetRedirectAfterLockCommercialTicket();
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.fetchCommercialPreferences();
    }

    handleEditClick = ({id}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockCommercialTicket(id);
            this.setState({ratchet: false});
        }
    };

    selectMultipleOption = (value) => {
        this.props.updateCommercialUserFullTextSearchPreference(
            this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    handleSubmit = (values) => {
        let newId;
        const filter = Object.entries(values)
            .filter(e => e[1])
            .map(e => {
                if (e[0] === 'pfxNumber') {
                    newId = 'PFX_NUMBER';
                } else if (e[0] === 'sfxNumber') {
                    newId = 'SFX_NUMBER';
                }
                return {
                    id: (e[0] === 'pfxNumber' || e[0] === 'sfxNumber') ? newId : e[0],
                    value: e[1]
                }
            });
        this.setState({searchDefaultFiltersCC: filter});
    };

    render() {
        const {intl, commercialPreferences, isLoadingSubjects, lockTicket, subjects, fullTextSearchData, managedGroups, userDetail} = this.props;

        if (lockTicket.redirect && !this.state.ratchet) {
            this.props.history.push(`/commercial/edit/${lockTicket.uuid}`);
        }

        if (commercialPreferences.isLoading
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...fullTextSearchMessages.TITLE}/>}/>
                <FullTextSearchCommercialForm
                    form={'commercialFullTextSearchForm'}
                    subjects={subjects}
                    fullTextSearchData={fullTextSearchData}
                    onSubmit={this.handleSubmit}
                    managedGroups={managedGroups}
                    language={get(userDetail, 'settings.language', 'DEFAULT')}/>
                <div className="d-flex justify-content-end my-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold">
                            <FormattedMessage {...tableMessages.VISIBLE_COLUMNS}/>
                            {':'}
                        </span>
                        <StyledPicky
                            value={this.state.columnLibrary.filter(item =>
                                !get(commercialPreferences, 'menuFullTextSearch', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={this.state.columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <FullTextSearchCommercialTable
                    columns={this.state.columnLibrary.filter(item =>
                        !get(commercialPreferences, 'menuFullTextSearch', {default: []}).includes(item.id))}
                    searchDefaultFiltersCC={this.state.searchDefaultFiltersCC}
                    handleEditClick={this.handleEditClick}
                />
            </div>
        )
    }
}

FullTextSearchCommercialPage.propTypes = {
    intl: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    resetRedirectAfterLockCommercialTicket: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    fetchCommercialPreferences: PropTypes.func.isRequired,
    fetchCommercialFullTextSearch: PropTypes.func.isRequired,
    lockCommercialTicket: PropTypes.func.isRequired,
    updateCommercialUserFullTextSearchPreference: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    commercialPreferences: state.commercialPreferences,
    lockTicket: state.commercialListOrderLockTicketCheck,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
    userDetail: state.profile.userDetail,
    fullTextSearchData: state.commercialFullTextSearch.fullTextData,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    resetRedirectAfterLockCommercialTicket,
    clearAlerts,
    fetchCommercialPreferences,
    fetchCommercialFullTextSearch,
    lockCommercialTicket,
    updateCommercialUserFullTextSearchPreference,
    fetchSubjects,
})(injectIntl(FullTextSearchCommercialPage))), ['CC_DEALER', 'CC_EDITOR'])
