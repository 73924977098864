import {DHS_DATA_RESET, DHS_DATA_SET} from '../actions/actionDhsData';
import {apigeeWebsocketCommands} from '../constants/apigeeWebsocketCommands';

export const dhsData = (state = {
    isDhsLoading: false,
    dhsData: {},
    dhsLoaded: false,
    dhsSet: false,
}, action) => {
    switch (action.type) {
        case apigeeWebsocketCommands.DHS_DATA_GET: {
            return Object.assign({}, state, {isDhsLoading: true, dhsLoaded: false, dhsSet: false});
        }
        case apigeeWebsocketCommands.DHS_DATA_SEND: {
            const {dhsData} = action.payload;
            return Object.assign({}, state, {dhsData, isDhsLoading: false, dhsLoaded: true, dhsSet: true});
        }
        case DHS_DATA_SET: {
            return Object.assign({}, state, {dhsData: {}, dhsSet: false});
        }
        case DHS_DATA_RESET:
        case apigeeWebsocketCommands.DHS_DATA_SEND_WARN:
        case apigeeWebsocketCommands.DHS_DATA_SEND_ERROR: {
            return Object.assign({}, state, {dhsData: {}, isDhsLoading: false, dhsLoaded: false, dhsSet: false});
        }
        default:
            return state;
    }
};
