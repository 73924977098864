import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';

export const COMMERCIAL_LIST_FETCH = 'COMMERCIAL_LIST_FETCH';

export const fetchCommercialList = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: COMMERCIAL_LIST_FETCH,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_LIST_GET,
        payload: {
            pageSize: pageSize,
            sorted: sorted,
            cursor: cursor,
            isNextFromPivot: isNextFromPivot,
            filtered: filtered
        }
    });
};
