import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';

export const DISTRIBUTION_FULL_TEXT_SEARCH_FETCH = 'DISTRIBUTION_FULL_TEXT_SEARCH_FETCH';

export const fetchDistributionFullTextSearch = (pageSize, sorted, cursor, isNextFromPivot, filtered) => (dispatch) => {
    dispatch({
        type: DISTRIBUTION_FULL_TEXT_SEARCH_FETCH,
        distributionWebsocket: true,
        command: distributionWebsocketCommands.DISTRIBUTION_FULL_TEXT_SEARCH_GET,
        payload: {
            pageSize: pageSize,
            sorted: sorted,
            cursor: cursor,
            isNextFromPivot: isNextFromPivot,
            filtered: filtered
        },
    });
};
