import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import {
    GENERAL_QUESTION_FORM, SPARE_PART_NAME_VERIFICATION_FORM, SPARE_PART_PRICE_VERIFICATION_FORM, FORM_BUTTON_TAKE_BACK
} from '../../../constants/formConstants';
import {
    fetchMarketingTicket, resetMarketingTicket, updateMarketingTicket
} from '../../../actions/marketing/actionMarketingTicket';
import {
    lockMarketingTicket, resetRedirectAfterLockMarketingTicket, unlockMarketingTicket
} from '../../../actions/marketing/actionMarketingLockTicket';
import {initLoadedMarketingTicket, transformUpdatedMarketingTicketForSave} from '../Utils';
import {formatTicketNumber} from '../../../utils/utils';
import {clearAlerts} from '../../../actions/alertsActions';
import {editRights} from '../../../constants/marketingTicketRights';
import {editReqFields} from '../../../constants/marketingRequiredFields';
import {moduleRoles} from '../../../utils/roles';
import UploadingMarketingAttachment from './UploadingMarketingAttachment';
import GeneralQuestionMasterForm from './forms/GeneralQuestionMasterForm';
import SparePartPriceVerificationMasterForm from './forms/SparePartPriceVerificationMasterForm';
import SparePartNameVerificationMasterForm from './forms/SparePartNameVerificationMasterForm';
import checkRoles from '../../common/roleChecker/RoleChecker';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import formMessages from '../../../intl/modules/formMessages';

class EditMarketingTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentWillUnmount() {
        const {uuid, redirect} = this.props.lockTicket;
        if (!redirect) {
            this.props.unlockMarketingTicket(uuid);
        }
        this.props.resetRedirectAfterLockMarketingTicket();
    }

    componentDidMount() {
        this.props.resetMarketingTicket();
        this.props.lockMarketingTicket(this.props.match.params.ticketUuid);
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchMarketingTicket(this.props.match.params.ticketUuid);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.ticketSubmittedAndShouldRedirect && !this.state.ticketSubmittedAndShouldRedirect
            && prevProps.ticketData.isTicketCreating && !this.props.ticketData.isTicketCreating
            && this.props.ticketData.isTicketLoading) {
            this.props.lockMarketingTicket(this.props.match.params.ticketUuid);
        }
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const dataForSend = transformUpdatedMarketingTicketForSave(values);
        this.props.updateMarketingTicket(dataForSend);
        if (!([FORM_BUTTON_TAKE_BACK].includes(submitBy))) {
            this.setState({ticketSubmittedAndShouldRedirect: true});
        }
    };

    render() {
        const {ticketData, attachmentData, lockTicket, loggedUser, isLoadingSubjects} = this.props;
        const {ticketSubmittedAndShouldRedirect} = this.state;
        const initializationValue = initLoadedMarketingTicket(ticketData);

        if (lockTicket.redirect) {
            return <Redirect to={`/marketing/view/${lockTicket.uuid}`}/>;
        }

        let type = false;
        if (get(ticketData, 'ticket.type', {default: false})) {
            type = ticketData.ticket.type;
        }

        if (Array.isArray(attachmentData.attachmentsForUpload) && (attachmentData.attachmentsForUpload.length > 0)) {
            return <UploadingMarketingAttachment attachments={attachmentData.attachmentsForUpload}
                                                 attachmentsUrl={ticketData.newAttachments}
                                                 correlationId={ticketData.correlationId}
                                                 isAttachmentUploading={attachmentData.isTicketAttachmentUploading}
                                                 formType={type}/>
        }

        if (ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading &&
            get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/marketing/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !type
            || lockTicket.isTicketLocking
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        const requiredFields = editReqFields[moduleRoles.isEditorMarketing(loggedUser) ? 'EDITOR' : 'DEALER'][type];

        return (
            <div className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={type} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {type === GENERAL_QUESTION_FORM &&
                    <GeneralQuestionMasterForm form={type}
                                               initialValues={initializationValue}
                                               userRights={editRights[moduleRoles.isEditorMarketing(loggedUser) ?
                                                   'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                               enableReinitialize={true}
                                               keepDirtyOnReinitialize={true}
                                               handleSubmit={this.handleSubmit}
                                               reqFields={requiredFields}/>
                    }
                    {type === SPARE_PART_PRICE_VERIFICATION_FORM &&
                    <SparePartPriceVerificationMasterForm form={type}
                                                          initialValues={initializationValue}
                                                          userRights={editRights[moduleRoles.isEditorMarketing(loggedUser) ?
                                                              'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                          enableReinitialize={true}
                                                          keepDirtyOnReinitialize={true}
                                                          handleSubmit={this.handleSubmit}
                                                          reqFields={requiredFields}/>
                    }
                    {type === SPARE_PART_NAME_VERIFICATION_FORM &&
                    <SparePartNameVerificationMasterForm form={type}
                                                         initialValues={initializationValue}
                                                         userRights={editRights[moduleRoles.isEditorMarketing(loggedUser) ?
                                                             'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                         enableReinitialize={true}
                                                         keepDirtyOnReinitialize={true}
                                                         handleSubmit={this.handleSubmit}
                                                         reqFields={requiredFields}/>
                    }
                </div>
            </div>
        );
    }
}

EditMarketingTicketPage.propTypes = {
    resetRedirectAfterLockMarketingTicket: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    resetMarketingTicket: PropTypes.func.isRequired,
    fetchMarketingTicket: PropTypes.func.isRequired,
    lockMarketingTicket: PropTypes.func.isRequired,
    updateMarketingTicket: PropTypes.func.isRequired,
    unlockMarketingTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.marketingTicket,
    lockTicket: state.marketingLockTicketCheck,
    attachmentData: state.marketingAttachment,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchMarketingTicket,
    updateMarketingTicket,
    resetMarketingTicket,
    lockMarketingTicket,
    resetRedirectAfterLockMarketingTicket,
    unlockMarketingTicket,
})(injectIntl(EditMarketingTicketPage))), ['MA_DEALER', 'MA_EDITOR']);

