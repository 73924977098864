import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Field, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import get from 'get-value';
import {
    FIELD_PFX,
    FIELD_SFX,
    LABEL_ASSIGNED_TO,
    LABEL_BRAND,
    LABEL_CREATOR_NAME,
    LABEL_DEALER_NAME,
    LABEL_DEALER_NUMBER,
    LABEL_MESSAGE,
    LABEL_MODEL,
    LABEL_NAME_AND_SURNAME_OF_THE_EMPLOYEE,
    LABEL_ORDER_NUMBER, LABEL_PRIORITY,
    LABEL_STATUS,
    LABEL_SUBJECT,
    LABEL_VERSION,
    LABEL_VIN,
} from '../../../constants/formConstants';
import {brandValues, domains, getFormStates, getPriority, languageCountryMapping} from '../../../constants/Utils';
import {PrimaryButton} from '../../common/Button';
import {StyledDiv} from '../../common/StyledComponents';
import {InputFieldTicketNumber} from '../../common/fields/InputFieldTicketNumber';
import {InputFieldWithValidation} from '../../common/fields/InputFieldWithValidation';
import {TextareaFieldWithValidation} from '../../common/fields/TextareaFieldWithValidation';
import InputSelectFieldWithValidation from '../../common/fields/InputSelectFieldWithValidation';
import DatePickerComponent from '../../common/datePicker/DatePickerComponent';
import buttonMessages from '../../../intl/common/buttonMessages';
import fullTextSearchMessages from '../../../intl/modules/fullTextSearchMessages';
import statusMessages from '../../../intl/modules/formStatesMessages';
import formMessages from '../../../intl/modules/formMessages';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import tableMessages from '../../../intl/common/tableMessages';
import formTicketValuesMessages from '../../../intl/common/formTicketValuesMessages';

const FullTextSearchCommercialForm = (props) => {
    const {handleSubmit, error, subjects, managedGroups, language} = props;
    const statusOptions = (() => {
        return getFormStates(domains.COMMERCIAL);
    })();
    const subjectOptions = subjects ? [].concat.apply([], Array.prototype.concat(...Object.values(subjects))
            .map(groupSubjects => Array.prototype.concat(...Object.values(
                Object.keys(groupSubjects)
                    .map((key) => groupSubjects[key]
                        .map(e => ({
                            id: get(e, `uuid`, ''),
                            translation: key === languageCountryMapping[language]
                                ? [get(e, `languages.local`, '') === null ?
                                    get(e, `languages.default`, '') :
                                    get(e, `languages.local`, '')]
                                : get(e, `languages.default`, '')
                        }))
                    ))
            )))
        : []

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            {error && <strong>{error}</strong>}
            <div className="row justify-content-center">
                <div className="col-12">
                    <form className="form-group">
                        <div className="col-12 pb-2">
                            <h3 className="text-left ml-4">
                                <FormattedMessage {...fullTextSearchMessages.GENERAL_SECTION}/>
                            </h3>
                            <StyledDiv className="border border-secondary p-4 bg-white">
                                <div className="form-row">
                                    <div className="col-xl-4 col-lg-7 col-md-7 pb-2">
                                        <InputFieldTicketNumber label={formMessages.TICKET_NUMBER}
                                                                fields={[FIELD_PFX, FIELD_SFX]}/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputSelectFieldWithValidation label={formMessages.STATUS}
                                                                        field={LABEL_STATUS}
                                                                        options={statusOptions}
                                                                        intlMessages={statusMessages}
                                                                        isSmall/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputSelectFieldWithValidation
                                            label={formMessages.PRIORITY}
                                            field={LABEL_PRIORITY}
                                            notSortedOptions={getPriority}
                                            intlMessages={formTicketValuesMessages}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputFieldWithValidation label={formMessages.DEALER_NAME}
                                                                  field={LABEL_DEALER_NAME}
                                                                  isSmall/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputFieldWithValidation label={formMessages.DEALER_NUMBER}
                                                                  field={LABEL_DEALER_NUMBER}
                                                                  isSmall/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputFieldWithValidation label={formMessages.CREATOR_NAME}
                                                                  field={LABEL_CREATOR_NAME}
                                                                  isSmall/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                                        <Field component={DatePickerComponent}
                                               selectsEnd
                                               isClearable
                                               label={formMessages.DATE_FROM}
                                               name={'FROM'}
                                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                                        <Field component={DatePickerComponent}
                                               selectsEnd
                                               isClearable
                                               label={formMessages.DATE_TO}
                                               name={'TO'}
                                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                                        <InputSelectFieldWithValidation label={tableMessages.GROUP}
                                                                        field={'GROUP'}
                                                                        options={managedGroups}
                                                                        intlMessages={countryGroupMessages}
                                                                        isSmall/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-xl-3 col-lg-6 col-md-6 pb-2">
                                        <InputFieldWithValidation label={formMessages.ASSIGNED_TO}
                                                                  field={LABEL_ASSIGNED_TO}
                                                                  isSmall/>
                                    </div>
                                </div>
                            </StyledDiv>

                            <h3 className="text-left ml-4 mt-4">
                                <FormattedMessage {...fullTextSearchMessages.TICKET_SECTION}/>
                            </h3>
                            <StyledDiv className="border border-secondary p-4 bg-white">
                                <div className="form-row">
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputSelectFieldWithValidation label={formMessages.SUBJECT}
                                                                        field={LABEL_SUBJECT}
                                                                        customOptionsFts={subjectOptions}/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputSelectFieldWithValidation
                                            label={formMessages.BRAND}
                                            field={LABEL_BRAND}
                                            options={get(brandValues)}
                                            isSmall/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputFieldWithValidation
                                            label={formMessages.MODEL}
                                            field={LABEL_MODEL}
                                            isSmall/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputFieldWithValidation
                                            label={formMessages.VERSION}
                                            field={LABEL_VERSION}
                                            maxLength={12}
                                            isSmall/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputFieldWithValidation
                                            label={formMessages.VIN}
                                            field={LABEL_VIN}
                                            maxLength={17}
                                            isSmall/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputFieldWithValidation
                                            label={formMessages.ORDER_NUMBER}
                                            field={LABEL_ORDER_NUMBER}
                                            maxLength={15}
                                            isSmall/>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col-xl-8 col-lg-12 col-md-12 pb-2">
                                        <InputFieldWithValidation
                                            label={formMessages.NAME_AND_SURNAME_OF_THE_EMPLOYEE}
                                            field={LABEL_NAME_AND_SURNAME_OF_THE_EMPLOYEE}
                                            isSmall/>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col-xl-8 col-lg-12 pb-2">
                                        <TextareaFieldWithValidation label={formMessages.MESSAGE}
                                                                     field={LABEL_MESSAGE}
                                                                     rows={2}/>
                                    </div>
                                </div>
                            </StyledDiv>

                            <PrimaryButton type="button"
                                           className="btn mr-2 mt-3 float-right"
                                           onClick={handleSubmit}>
                                <FormattedMessage {...buttonMessages.SEARCH}/>
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
            </div>
        </StyledDiv>
    )
};

FullTextSearchCommercialForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({})(FullTextSearchCommercialForm);
