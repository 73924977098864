import get from 'get-value';
import v4 from 'uuid/v4';
import messages from '../intl/common/countryGroupMessages';

export const initLoadedBusinessUnit = (businessUnitData, formatMessage) => {
    let countriesWithLabels = [];
    if(businessUnitData.businessUnit.groups){
        countriesWithLabels = businessUnitData.businessUnit.groups.map(country => {return {label: messages[country] ? formatMessage(messages[country]) : country, value: country}})
    }

    return {
        businessUnitSection: {
            id: businessUnitData.businessUnit.id,
            name: businessUnitData.businessUnit.name,
        },
        countrySection: {
            countries: countriesWithLabels,
        },
    };
};

export const initNewBusinessUnit = () => {
    return {
        businessUnitSection: {
            name: '',
        },
        countrySection: {

        },
    };
};

export const initNewBusinessUnitAfterFail = (data, formatMessage) => {
    let countriesWithLabels = [];
    if(data.groups){
        countriesWithLabels = data.groups.map(country => {return {label: messages[country] ? formatMessage(messages[country]) : country, value: country}})
    }

    return {
        businessUnitSection: {
            name: data.name,
        },
        countrySection: {
            countries: countriesWithLabels,
        },
    };
};

export const transformNewBusinessUnitForSave = (values) => {
    const data = {
        id: v4(),
        name: values.businessUnitSection.name,
    };

    data.groups = values.countrySection.countries.map(country => {return country.value});

    return data;
};

export const transformUpdateBusinessUnitForSave = (values) => {
    const { oldValues, newValues } = values;

    const data = {
        id: oldValues.businessUnitSection.id,
        name: newValues.businessUnitSection.name,
    };

    data.groups = oldValues.countrySection.countries.map(country => {return country.value});

    return data;
};
