import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import renaultCar from '../../images/car.jpg';
import adminMessages from '../../intl/admin/adminMessages';

const WelcomePageWrapper = styled.div`
  text-align: center;
  padding-top: 10px;
`;

const ImgWrapper = styled.div`
  text-align: center;
`;

const Img = styled.img`
    height: 35%;
    width: 35%;
`;

class AdministrationMainPage extends Component {
    render() {
        return (
            <WelcomePageWrapper>
                <h1>
                    <FormattedMessage {...adminMessages.TITLE} />
                </h1>
                <ImgWrapper>
                    <Img src={renaultCar}/>
                </ImgWrapper>
            </WelcomePageWrapper>
        );
    }
}

export default AdministrationMainPage;
