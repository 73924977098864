import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {domains} from '../../constants/Utils';

export const ADMIN_SUBJECT_SETTINGS_SELECT_GROUP = 'ADMIN_SUBJECT_SETTINGS_SELECT_GROUP';
export const ADMIN_SUBJECT_SETTINGS_FETCH = 'ADMIN_SUBJECT_SETTINGS_FETCH';
export const ADMIN_SUBJECT_SETTINGS_ADD = 'ADMIN_SUBJECT_SETTINGS_ADD';
export const ADMIN_SUBJECT_SETTINGS_EDIT = 'ADMIN_SUBJECT_SETTINGS_EDIT';
export const ADMIN_SUBJECT_SETTINGS_UPDATE = 'ADMIN_SUBJECT_SETTINGS_UPDATE';
export const ADMIN_SUBJECT_SETTINGS_MOVE = 'ADMIN_SUBJECT_SETTINGS_MOVE';
export const ADMIN_SUBJECT_SETTINGS_SWITCH = 'ADMIN_SUBJECT_SETTINGS_SWITCH';
export const ADMIN_SUBJECT_SETTINGS_REMOVE = 'ADMIN_SUBJECT_SETTINGS_REMOVE';
export const ADMIN_SUBJECTS_FETCH = 'ADMIN_SUBJECTS_FETCH';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_SUBJECT_SETTINGS_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchSubjectSettings = (domain, group, subjectKey) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_FETCH,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_SUBJECT_SETTINGS_GET,
                payload: {groups: [group], subjectKeys: [subjectKey]}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_FETCH,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_SUBJECT_SETTINGS_GET,
                payload: {groups: [group], subjectKeys: [subjectKey]}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_FETCH,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_SUBJECT_SETTINGS_GET,
                payload: {groups: [group], subjectKeys: [subjectKey]}
            });
        }
    }
};

export const addSubjectSettings = (domain, subject) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_ADD,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_SUBJECT_SETTINGS_CREATE,
                payload: {subject}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_ADD,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_SUBJECT_SETTINGS_CREATE,
                payload: {subject}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_ADD,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_SUBJECT_SETTINGS_CREATE,
                payload: {subject}
            });
        }
    }
};

export const editSubjectSettings = index => dispatch => {
    dispatch({
        type: ADMIN_SUBJECT_SETTINGS_EDIT,
        payload: {index}
    });
};

export const updateSubjectSettings = (domain, subject) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_UPDATE,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_SUBJECT_SETTINGS_UPDATE,
                payload: {subject}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_UPDATE,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_SUBJECT_SETTINGS_UPDATE,
                payload: {subject}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_UPDATE,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_SUBJECT_SETTINGS_UPDATE,
                payload: {subject}
            });
        }
    }
};

export const moveSubjectSettings = (domain, oldIndex, subject) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            dispatch({
                type: ADMIN_SUBJECT_SETTINGS_SWITCH,
                payload: {oldIndex, newIndex: subject.position, subject}
            });
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_MOVE,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_SUBJECT_SETTINGS_MOVE,
                payload: {subject}
            });
        }
        case domains.COMMERCIAL: {
            dispatch({
                type: ADMIN_SUBJECT_SETTINGS_SWITCH,
                payload: {oldIndex, newIndex: subject.position, subject}
            });
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_MOVE,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_SUBJECT_SETTINGS_MOVE,
                payload: {subject}
            });
        }
        case domains.MARKETING: {
            dispatch({
                type: ADMIN_SUBJECT_SETTINGS_SWITCH,
                payload: {oldIndex, newIndex: subject.position, subject}
            });
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_MOVE,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_SUBJECT_SETTINGS_MOVE,
                payload: {subject}
            });
        }
    }
};

export const removeSubjectSettings = (domain, subject) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_REMOVE,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_SUBJECT_SETTINGS_DELETE,
                payload: {subject}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_REMOVE,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_SUBJECT_SETTINGS_DELETE,
                payload: {subject}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_SUBJECT_SETTINGS_REMOVE,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_SUBJECT_SETTINGS_DELETE,
                payload: {subject}
            });
        }
    }
};

export const fetchSubjects = (domain, group, subjectKey) => dispatch => {
    switch (domain) {
        case domains.DISTRIBUTION: {
            return dispatch({
                type: ADMIN_SUBJECTS_FETCH,
                distributionWebsocket: true,
                command: distributionWebsocketCommands.DISTRIBUTION_SUBJECT_SETTINGS_GET,
                payload: {groups: group, subjectKeys: [subjectKey]}
            });
        }
        case domains.COMMERCIAL: {
            return dispatch({
                type: ADMIN_SUBJECTS_FETCH,
                commercialWebsocket: true,
                command: commercialWebsocketCommands.COMMERCIAL_SUBJECT_SETTINGS_GET,
                payload: {groups: group, subjectKeys: subjectKey}
            });
        }
        case domains.MARKETING: {
            return dispatch({
                type: ADMIN_SUBJECTS_FETCH,
                marketingWebsocket: true,
                command: marketingWebsocketCommands.MARKETING_SUBJECT_SETTINGS_GET,
                payload: {groups: group, subjectKeys: subjectKey}
            });
        }
    }
};
