export const distributionWebsocketCommands = {
    // route (commands)

    // FILE
    DISTRIBUTION_FREQUENT_QUESTION_FILE_DOWNLOAD: 'frequentQuestionAttachmentDownload',
    DISTRIBUTION_FREQUENT_QUESTION_FILE_UPLOAD: 'frequentQuestionAttachmentUpload',
    // FREQUENT QUESTION
    DISTRIBUTION_FREQUENT_QUESTION_GET: 'getFrequentQuestions',
    DISTRIBUTION_FREQUENT_QUESTION_CREATE: 'createFrequentQuestion',
    DISTRIBUTION_FREQUENT_QUESTION_MOVE: 'moveFrequentQuestion',
    DISTRIBUTION_FREQUENT_QUESTION_DELETE: 'deleteFrequentQuestion',
    DISTRIBUTION_FREQUENT_QUESTION_UPDATE: 'updateFrequentQuestion',
    // PRESET RESPONSE
    DISTRIBUTION_PRESET_RESPONSE_GET: 'getPresetResponses',
    DISTRIBUTION_PRESET_RESPONSE_CREATE: 'createPresetResponse',
    DISTRIBUTION_PRESET_RESPONSE_MOVE: 'movePresetResponse',
    DISTRIBUTION_PRESET_RESPONSE_DELETE: 'deletePresetResponse',
    DISTRIBUTION_PRESET_RESPONSE_UPDATE: 'updatePresetResponse',
    // NOTIFICATION
    DISTRIBUTION_NOTIFICATIONS_GET: 'getEmailNotifications',
    DISTRIBUTION_NOTIFICATIONS_SET: 'setEmailNotifications',
    // RIGHTS MANAGEMENT
    DISTRIBUTION_ADMIN_ROLE_GET: 'getAdminRole',
    DISTRIBUTION_ADMIN_ROLE_SET: 'setAdminRole',
    DISTRIBUTION_EDITOR_ROLE_GET: 'getEditorRole',
    DISTRIBUTION_EDITOR_ROLE_SET: 'setEditorRole',
    DISTRIBUTION_EXTERNAL_EDITOR_ROLE_GET: 'getExternalEditorRole',
    DISTRIBUTION_EXTERNAL_EDITOR_ROLE_SET: 'setExternalEditorRole',
    DISTRIBUTION_DEALER_ROLE_GET: 'getDealerRole',
    DISTRIBUTION_DEALER_ROLE_SET: 'setDealerRole',
    //BUSINESS UNITS
    //Get List Business Units
    DISTRIBUTION_BUSINESS_UNITS_LIST: 'getBusinessUnits',
    DISTRIBUTION_BUSINESS_UNITS_LIST_FETCH_PENDING: 'DISTRIBUTION_BUSINESS_UNITS_LIST_FETCH_PENDING',
    DISTRIBUTION_BUSINESS_UNITS_LIST_SEND: 'DISTRIBUTION_BUSINESS_UNITS_LIST_SEND',
    DISTRIBUTION_BUSINESS_UNITS_LIST_SEND_ERROR: 'DISTRIBUTION_BUSINESS_UNITS_LIST_SEND_ERROR',
    //Get country business units
    DISTRIBUTION_BUSINESS_UNITS_SHORT_LIST:  'getCountryBusinessUnits',
    DISTRIBUTION_BUSINESS_UNITS_SHORT_LIST_SEND: 'DISTRIBUTION_BUSINESS_UNITS_SHORT_LIST_SEND',
    //Get Detail Business unit
    DISTRIBUTION_BUSINESS_UNITS_DETAIL: 'getBusinessUnit',
    DISTRIBUTION_BUSINESS_UNITS_DETAIL_FETCH_PENDING: 'DISTRIBUTION_BUSINESS_UNITS_DETAIL_FETCH_PENDING',
    //Create Solution Group
    DISTRIBUTION_BUSINESS_UNITS_CREATE: 'createBusinessUnit',
    DISTRIBUTION_BUSINESS_UNITS_CREATE_PENDING: 'DISTRIBUTION_BUSINESS_UNITS_CREATE_PENDING',
    DISTRIBUTION_BUSINESS_UNITS_ACCEPTED: 'DISTRIBUTION_BUSINESS_UNITS_ACCEPTED',
    DISTRIBUTION_BUSINESS_UNITS_ERROR: 'DISTRIBUTION_BUSINESS_UNITS_ERROR',
    //Update Solution Group
    DISTRIBUTION_BUSINESS_UNITS_UPDATE: 'updateBusinessUnit',
    DISTRIBUTION_BUSINESS_UNITS_UPDATE_PENDING: 'DISTRIBUTION_BUSINESS_UNITS_UPDATE_PENDING',
    //Update Solution Group - NAME, USERS and RESET
    DISTRIBUTION_BUSINESS_UNITS_RESET: 'DISTRIBUTION_BUSINESS_UNITS_RESET',
    DISTRIBUTION_BUSINESS_UNITS_REDIRECT_RESET: 'DISTRIBUTION_BUSINESS_UNITS_REDIRECT_RESET',
    DISTRIBUTION_BUSINESS_UNITS_SEND: 'DISTRIBUTION_BUSINESS_UNITS_SEND',
    DISTRIBUTION_BUSINESS_UNITS_SEND_ERROR: 'DISTRIBUTION_BUSINESS_UNITS_SEND_ERROR',
    DISTRIBUTION_BUSINESS_UNITS_SAVE_NEW_ERROR: 'DISTRIBUTION_BUSINESS_UNITS_SAVE_NEW_ERROR',
    DISTRIBUTION_BUSINESS_UNITS_SAVE_UPDATE_ERROR: 'DISTRIBUTION_BUSINESS_UNITS_SAVE_UPDATE_ERROR',
    //BU BIRS
    DISTRIBUTION_BIRS_FETCH: 'getBirs',
    DISTRIBUTION_BIR_UPDATE_BU: 'setBusinessUnit',
    // SUBJECT SETTINGS
    DISTRIBUTION_SUBJECT_SETTINGS_GET: 'getSubjectSettings',
    DISTRIBUTION_SUBJECT_SETTINGS_CREATE: 'createSubjectSettings',
    DISTRIBUTION_SUBJECT_SETTINGS_UPDATE: 'updateSubjectSettings',
    DISTRIBUTION_SUBJECT_SETTINGS_MOVE: 'moveSubjectSettings',
    DISTRIBUTION_SUBJECT_SETTINGS_DELETE: 'deleteSubjectSettings',
    // TICKET SETTINGS
    DISTRIBUTION_TICKET_SETTINGS_GET: 'getTicketSettings',
    DISTRIBUTION_TICKET_SETTINGS_SET: 'setTicketSettings',
    // WORKING HOURS
    DISTRIBUTION_WORKING_HOURS_GET: 'getWorkingHours',
    DISTRIBUTION_WORKING_HOURS_SET: 'setWorkingHours',

    // ATTACHMENTS
    DISTRIBUTION_CREATE_ATTACHMENT: 'createAttachments',
    DISTRIBUTION_DOWNLOAD_PRESIGNED_URL: 'attachmentDownloadPreSignedUrl',
    // DEALERS
    DISTRIBUTION_DEALERS_GET: 'getDealers',
    // EXPORT
    DISTRIBUTION_EXPORT_GET: 'getExport',
    DISTRIBUTION_EXPORT_CREATE: 'createTicketExport',
    DISTRIBUTION_EXPORT_DOWNLOAD_URL_GET: 'exportDownloadPreSignedUrl',
    // FULL TEXT SEARCH
    DISTRIBUTION_FULL_TEXT_SEARCH_GET: 'getFullTextSearch',
    // HISTORY
    DISTRIBUTION_HISTORY_GET: 'getHistory',
    // LIST
    DISTRIBUTION_LIST_GET: 'getList',
    // LOCK TICKET
    DISTRIBUTION_SET_LOCK: 'setLockTicket',
    DISTRIBUTION_SET_UNLOCK: 'setUnlockTicket',

    DISTRIBUTION_SET_DEALER_MESSAGE_READ: 'setDealerMessageRead',
    // PREFERENCES
    DISTRIBUTION_PREFERENCES_GET: 'getPreferences',
    DISTRIBUTION_USER_LIST_PREFERENCE_SET: 'setUserListPreference',
    DISTRIBUTION_USER_HISTORY_PREFERENCE_SET: 'setUserHistoryPreference',
    DISTRIBUTION_USER_FULL_TEXT_SEARCH_PREFERENCE_SET: 'setUserFullTextSearchPreference',
    DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_GET: 'getUserNotificationPreference',
    DISTRIBUTION_USER_NOTIFICATION_PREFERENCE_SET: 'setUserNotificationPreference',
    // STATISTICS
    DISTRIBUTION_GET_STATISTICS: 'getStatistics',
    // TICKET
    DISTRIBUTION_UPDATE_TICKET: 'updateDistributionTicket',
    DISTRIBUTION_CREATE_TICKET: 'createDistributionTicket',
    DISTRIBUTION_GET_TICKET: 'getTicket',

    // receive

    // FREQUENT QUESTION
    DISTRIBUTION_FREQUENT_QUESTION_ERROR: 'DISTRIBUTION_FREQUENT_QUESTIONS_ERROR',
    DISTRIBUTION_FREQUENT_QUESTION_SEND: 'DISTRIBUTION_FREQUENT_QUESTIONS_SEND',
    DISTRIBUTION_FREQUENT_QUESTION_SEND_ERROR: 'DISTRIBUTION_FREQUENT_QUESTIONS_SEND_ERROR',
    DISTRIBUTION_FREQUENT_QUESTION_ATTACHMENTS_DOWNLOAD: 'DISTRIBUTION_FREQUENT_QUESTIONS_ATTACHMENTS_DOWNLOAD',
    DISTRIBUTION_FREQUENT_QUESTION_ATTACHMENTS_UPLOAD: 'DISTRIBUTION_FREQUENT_QUESTIONS_ATTACHMENTS_UPLOAD',
    // PRESET RESPONSE
    DISTRIBUTION_PRESET_RESPONSES_ERROR: 'DISTRIBUTION_PRESET_RESPONSES_ERROR',
    DISTRIBUTION_PRESET_RESPONSES_SEND: 'DISTRIBUTION_PRESET_RESPONSES_SEND',
    DISTRIBUTION_PRESET_RESPONSES_SEND_ERROR: 'DISTRIBUTION_PRESET_RESPONSES_SEND_ERROR',
    // NOTIFICATION
    DISTRIBUTION_NOTIFICATIONS_ERROR: 'DISTRIBUTION_NOTIFICATIONS_ERROR',
    DISTRIBUTION_NOTIFICATIONS_SEND: 'DISTRIBUTION_NOTIFICATIONS_SEND',
    DISTRIBUTION_NOTIFICATIONS_SEND_ERROR: 'DISTRIBUTION_NOTIFICATIONS_SEND_ERROR',
    // ADMIN RIGHTS MANAGEMENT
    DISTRIBUTION_ROLES_SEND_ERROR: 'DISTRIBUTION_ROLES_SEND_ERROR',
    DISTRIBUTION_ADMIN_ROLE_SEND: 'DISTRIBUTION_ADMIN_ROLE_SEND',
    DISTRIBUTION_ADMIN_ROLE_SEND_ERROR: 'DISTRIBUTION_ADMIN_ROLE_SEND_ERROR',
    DISTRIBUTION_EDITOR_ROLE_SEND: 'DISTRIBUTION_EDITOR_ROLE_SEND',
    DISTRIBUTION_EDITOR_ROLE_SEND_ERROR: 'DISTRIBUTION_EDITOR_ROLE_SEND_ERROR',
    DISTRIBUTION_EXTERNAL_EDITOR_ROLE_SEND: 'DISTRIBUTION_EXTERNAL_EDITOR_ROLE_SEND',
    DISTRIBUTION_EXTERNAL_EDITOR_ROLE_SEND_ERROR: 'DISTRIBUTION_EXTERNAL_EDITOR_ROLE_SEND_ERROR',
    DISTRIBUTION_DEALER_ROLE_SEND: 'DISTRIBUTION_DEALER_ROLE_SEND',
    DISTRIBUTION_DEALER_ROLE_SEND_ERROR: 'DISTRIBUTION_DEALER_ROLE_SEND_ERROR',
    // SUBJECT SETTINGS
    DISTRIBUTION_SUBJECT_SETTINGS_ERROR: 'DISTRIBUTION_SUBJECT_SETTINGS_ERROR',
    DISTRIBUTION_SUBJECT_SETTINGS_SEND: 'DISTRIBUTION_SUBJECT_SETTINGS_SEND',
    DISTRIBUTION_SUBJECT_SETTINGS_SEND_ERROR: 'DISTRIBUTION_SUBJECT_SETTINGS_SEND_ERROR',
    // TICKET SETTINGS
    DISTRIBUTION_TICKET_SETTINGS_ERROR: 'DISTRIBUTION_TICKET_SETTINGS_ERROR',
    DISTRIBUTION_TICKET_SETTINGS_SEND: 'DISTRIBUTION_TICKET_SETTINGS_SEND',
    DISTRIBUTION_TICKET_SETTINGS_SEND_ERROR: 'DISTRIBUTION_TICKET_SETTINGS_SEND_ERROR',
    // WORKING HOURS
    DISTRIBUTION_WORKING_HOURS_ACCEPTED: 'DISTRIBUTION_WORKING_HOURS_ACCEPTED',
    DISTRIBUTION_WORKING_HOURS_ERROR: 'DISTRIBUTION_WORKING_HOURS_ERROR',
    DISTRIBUTION_WORKING_HOURS_SEND: 'DISTRIBUTION_WORKING_HOURS_SEND',
    DISTRIBUTION_WORKING_HOURS_SEND_ERROR: 'DISTRIBUTION_WORKING_HOURS_SEND_ERROR',

    // ATTACHMENTS
    DISTRIBUTION_ATTACHMENTS_SAVED: 'DISTRIBUTION_TICKET_ATTACHMENTS_SAVED',
    DISTRIBUTION_ATTACHMENTS_SAVED_ERROR: 'DISTRIBUTION_ATTACHMENTS_SAVED_ERROR',
    DISTRIBUTION_GENERATED_PRESIGNED_URLS: 'DISTRIBUTION_GENERATED_PRESIGNED_URLS',
    DISTRIBUTION_ATTACHMENTS_DOWNLOAD: 'DISTRIBUTION_TICKET_ATTACHMENTS_DOWNLOAD',
    // DEALERS
    DISTRIBUTION_DEALERS_SEND: 'DISTRIBUTION_DEALERS_FETCH_SUCCESS',
    DISTRIBUTION_DEALERS_SEND_ERROR: 'DISTRIBUTION_DEALERS_FETCH_ERROR',
    // EXPORT
    DISTRIBUTION_EXPORT_SEND: 'DISTRIBUTION_EXPORT_FETCH_SUCCESS',
    DISTRIBUTION_EXPORT_SEND_ERROR: 'DISTRIBUTION_EXPORT_FETCH_ERROR',
    DISTRIBUTION_EXPORT_STATUS: 'DISTRIBUTION_EXPORT_STATUS',
    DISTRIBUTION_EXPORT_ERROR: 'DISTRIBUTION_EXPORT_ERROR',
    DISTRIBUTION_EXPORT_DOWNLOAD: 'DISTRIBUTION_EXPORT_DOWNLOAD',
    // FULL TEXT SEARCH
    DISTRIBUTION_FULL_TEXT_SEARCH_FETCH_SUCCESS: 'DISTRIBUTION_FULL_TEXT_SEARCH_FETCH_SUCCESS',
    DISTRIBUTION_FULL_TEXT_SEARCH_SEND_ERROR: 'DISTRIBUTION_TICKET_FULL_TEXT_SEARCH_SEND_ERROR',
    // HISTORY
    DISTRIBUTION_HISTORY_SEND: 'DISTRIBUTION_TICKET_HISTORY_SEND',
    DISTRIBUTION_HISTORY_SEND_ERROR: 'DISTRIBUTION_TICKET_HISTORY_SEND_ERROR',
    // LIST
    DISTRIBUTION_LIST_SEND: 'DISTRIBUTION_TICKET_LIST_SEND',
    DISTRIBUTION_LIST_SEND_ERROR: 'DISTRIBUTION_TICKET_LIST_SEND_ERROR',
    // LOCK TICKET
    DISTRIBUTION_TICKET_LOCKED: 'DISTRIBUTION_TICKET_LOCKED',
    DISTRIBUTION_TICKET_LOCKED_ERROR: 'DISTRIBUTION_TICKET_LOCKED_ERROR',
    DISTRIBUTION_TICKET_LOCKED_FATAL: 'DISTRIBUTION_TICKET_LOCKED_FATAL',
    // PREFERENCES
    DISTRIBUTION_PREFERENCES_ACCEPTED: 'DISTRIBUTION_PREFERENCES_ACCEPTED',
    DISTRIBUTION_PREFERENCES_ERROR: 'DISTRIBUTION_PREFERENCES_ERROR',
    DISTRIBUTION_PREFERENCES_SEND: 'DISTRIBUTION_PREFERENCES_SEND',
    DISTRIBUTION_PREFERENCES_SEND_ERROR: 'DISTRIBUTION_PREFERENCES_SEND_ERROR',
    DISTRIBUTION_NOTIFICATIONS_PREFERENCES_SEND: 'DISTRIBUTION_NOTIFICATIONS_PREFERENCES_SEND',
    DISTRIBUTION_NOTIFICATIONS_PREFERENCES_SEND_ERROR: 'DISTRIBUTION_NOTIFICATIONS_PREFERENCES_SEND_ERROR',
    // STATISTICS
    DISTRIBUTION_STATISTICS_SEND: 'DISTRIBUTION_STATISTICS_SEND',
    DISTRIBUTION_STATISTICS_SEND_ERROR: 'DISTRIBUTION_STATISTICS_SEND_ERROR',
    // TICKET
    DISTRIBUTION_TICKET_SEND: 'DISTRIBUTION_TICKET_SEND',
    DISTRIBUTION_TICKET_CREATED: 'DISTRIBUTION_TICKET_CREATED',
    DISTRIBUTION_TICKET_UPDATED: 'DISTRIBUTION_TICKET_UPDATED',
    DISTRIBUTION_TICKET_UPDATE_FATAL: 'DISTRIBUTION_TICKET_UPDATE_FATAL',
    DISTRIBUTION_TICKET_CREATE_FATAL: 'DISTRIBUTION_TICKET_CREATE_FATAL',
    DISTRIBUTION_TICKET_SEND_ERROR: 'DISTRIBUTION_TICKET_SEND_ERROR',
};
