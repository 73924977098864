import {
    ADMIN_TICKET_SETTINGS_SELECT_GROUP,
    ADMIN_TICKET_SETTINGS_FETCH,
    ADMIN_TICKET_SETTINGS_UPDATE,
} from '../../actions/admin/actionAdminTicketSettings';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import get from 'get-value';

export const adminTicketSettings = (state = {
    isLoading: true,
    ticketSettings: {},
    selectedCountryGroup: ''
}, action) => {
    switch (action.type) {
        case ADMIN_TICKET_SETTINGS_SELECT_GROUP:
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        case ADMIN_TICKET_SETTINGS_FETCH:
            return Object.assign({}, state, {isLoading: true, ticketSettings: {}});
        case ADMIN_TICKET_SETTINGS_UPDATE:
            return Object.assign({}, state, {isLoading: true, ticketSettings: {}});

        case distributionWebsocketCommands.DISTRIBUTION_TICKET_SETTINGS_SEND:
        case commercialWebsocketCommands.COMMERCIAL_TICKET_SETTINGS_SEND:
        case marketingWebsocketCommands.MARKETING_TICKET_SETTINGS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                ticketSettings: get(action, 'payload.settings', {default: {}}),
                correlationId
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_TICKET_SETTINGS_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_TICKET_SETTINGS_ERROR:
        case marketingWebsocketCommands.MARKETING_TICKET_SETTINGS_ERROR:
        case distributionWebsocketCommands.DISTRIBUTION_TICKET_SETTINGS_SEND_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_TICKET_SETTINGS_SEND_ERROR:
        case marketingWebsocketCommands.MARKETING_TICKET_SETTINGS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, ticketSettings: {}, correlationId});
        }
        default:
            return state;
    }
};

