import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {
    fetchSettingBUAssignmentData,
    setSorting,
    setTextSearch,
    setLinesPerPage,
    fetchCountryBU
} from '../../../../actions/admin/actionBUAssignment';
import {FormattedMessage} from 'react-intl';
import buMessages from '../../../../intl/admin/businessUnitMessages';
import Loader from '../../../Loader';
import Pagination from '../../../common/Pagination';
import Sorter from '../../../common/Sorter';
import BUAssignmentRecord from './BUAssignmentRecord';
import InputPerPageForm from './forms/InputPerPageForm';
import InputFullTextForm from './forms/InputFullTextForm';

const Table = styled.table`
  width: 100%;
  margin: 0 auto;
  table-layout: auto;
  border-bottom: solid 1px #eee;
  padding: 0 10px;
`;

const TBody = styled.tbody`
  border: 0;
`;

const THead = styled.thead`
  border: 0;
  border-bottom: none;
`;

class BUAssignmentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const {linesPerPage, selectedGroup} = this.props;
        this.props.fetchSettingBUAssignmentData(selectedGroup, 1, linesPerPage);
        this.props.fetchCountryBU([selectedGroup]);
    }

    loadPage = (page) => {
        const {linesPerPage, selectedGroup} = this.props;
        this.props.fetchSettingBUAssignmentData(selectedGroup, page, linesPerPage);
        this.props.fetchCountryBU([selectedGroup]);
    }

    handleOrderingSubmit = (column) => {
        const {currentPage, linesPerPage, selectedGroup} = this.props;
        this.props.setSorting(column);
        this.props.fetchSettingBUAssignmentData(selectedGroup, currentPage, linesPerPage);
        this.props.fetchCountryBU([selectedGroup]);
    }

    handleLinesPerPageSubmit = (values) => {
        const {selectedGroup} = this.props;
        this.props.setLinesPerPage(values.linesPerPage);
        this.props.fetchSettingBUAssignmentData(selectedGroup, 1, values.linesPerPage);
        this.props.fetchCountryBU([selectedGroup]);
    }

    handleFulltextSubmit = (values) => {
        const {linesPerPage, selectedGroup} = this.props;
        this.props.setTextSearch(values.fulltext);
        this.props.fetchSettingBUAssignmentData(selectedGroup, 1, linesPerPage);
        this.props.fetchCountryBU([selectedGroup]);
    }

    render() {
        const {totalCount, currentPage, tableData, sorting, linesPerPage, fulltext, isLoading, selectedGroup} = this.props;
        const totalPages = totalCount > 0 ? Math.ceil(totalCount / linesPerPage) : 1;

        if (isLoading) {
            return <Loader/>;
        }
        return (
            <div>
                <div className="d-flex flex-row pt-4 align-items-end justify-content-between">
                    <InputPerPageForm initialValues={{linesPerPage: linesPerPage}}
                                      enableReinitialize={true}
                                      onSubmit={this.handleLinesPerPageSubmit}
                                      form="InputPerPageForm"/>
                    <div className="d-flex flex-column align-items-center pb-3">
                        <span className="h-100 text-secondary">
                            <FormattedMessage {...buMessages.PAGE}/>
                            {currentPage &&
                            <span className="font-weight-bold">{' ' + currentPage + '/' + totalPages}</span>}
                        </span>
                        <div className="h-100 text-secondary">
                            <FormattedMessage {...buMessages.TOTAL_RESULTS}/>
                            <span className="font-weight-bold">
                                {': ' + totalCount}
                            </span>
                        </div>
                    </div>
                    <InputFullTextForm initialValues={{fulltext: fulltext,}}
                                       enableReinitialize={true}
                                       onSubmit={this.handleFulltextSubmit}
                                       form="InputFullTextForm"/>
                </div>
                <div className="card rounded">
                    <div className="table-responsive">
                        <Table className="table table-hover">
                            <THead className="thead-light">
                                <tr>
                                    <th>
                                        <FormattedMessage {...buMessages.BU_ASSIGNMENT_TABLE_DEALER_NUMBER}/>
                                        <Sorter column="id" sort={this.handleOrderingSubmit} sorting={sorting}/>
                                    </th>
                                    <th>
                                        <FormattedMessage {...buMessages.BU_ASSIGNMENT_TABLE_DEALER_NAME}/>
                                        <Sorter column="dealerName" sort={this.handleOrderingSubmit} sorting={sorting}/>
                                    </th>
                                    <th>
                                        <FormattedMessage {...buMessages.BU_ASSIGNMENT_TABLE_BUSINESS_UNIT}/>
                                        <Sorter column="businessUnit" sort={this.handleOrderingSubmit} sorting={sorting}/>
                                    </th>
                                    <th/>
                                </tr>
                            </THead>
                            <TBody>
                                {tableData && tableData.length > 0 ?
                                    tableData.map((bir, index) =>
                                        <BUAssignmentRecord
                                            key={index}
                                            index={index}
                                            data={bir}
                                            selectedGroup={selectedGroup}
                                        />)
                                    :
                                    <tr>
                                        <td className="text-center text-secondary" colSpan="7">
                                            <FormattedMessage {...buMessages.BU_ASSIGNMENT_TABLE_NO_RESULTS}/>
                                        </td>
                                    </tr>
                                }
                            </TBody>
                        </Table>
                    </div>
                </div>
                <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                    <Pagination
                        onSetPage={this.loadPage}
                        totalCount={totalCount}
                        page={currentPage}
                        linesPerPage={linesPerPage}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isLoading: state.adminBUAssignment.isLoading,
    totalCount: state.adminBUAssignment.totalCount,
    linesPerPage: state.adminBUAssignment.linesPerPage,
    currentPage: state.adminBUAssignment.page,
    tableData: state.adminBUAssignment.list,
    sorting: state.adminBUAssignment.sorting,
    fulltext: state.adminBUAssignment.fulltext,
});

export default connect(mapStateToProps, {
    fetchSettingBUAssignmentData,
    setSorting,
    setTextSearch,
    setLinesPerPage,
    fetchCountryBU,
})(BUAssignmentContainer);
