import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {
    MARKETING_TICKET_CREATE,
    MARKETING_TICKET_UPDATE,
    MARKETING_TICKET_RESET,
} from '../../actions/marketing/actionMarketingTicket';
import {MARKETING_ATTACHMENTS_UPLOADING_ERROR} from '../../actions/marketing/actionMarketingAttachment';
import {
    MARKETING_RESET_REDIRECT,
    MARKETING_SET_LOCK_TICKET
} from '../../actions/marketing/actionMarketingLockTicket';

export const marketingTicket = (state = {
    correlationId: null,
    isTicketLoading: false,
    isTicketCreating: false,
    created: false,
    ticket: {},
    logs: [],
    messages: {},
    attachments: [],
    newAttachments: [],
}, action) => {
    switch (action.type) {
        case MARKETING_TICKET_UPDATE:
        case MARKETING_TICKET_CREATE: {
            const {newAttachments} = action.payload;
            return Object.assign({}, state, {
                newAttachments,
                isTicketCreating: true,
                created: false,
                isTicketLoading: true,
                logs: [],
                attachments: [],
                messages: {},
            });
        }
        case MARKETING_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLoading: false,
                isTicketCreating: false,
                created: false,
                ticket: {},
                messages: {},
                logs: [],
                attachments: [],
                newAttachments: [],
            });
        }
        //command accepted
        case marketingWebsocketCommands.MARKETING_TICKET_CREATED:
        case marketingWebsocketCommands.MARKETING_TICKET_UPDATED:
        case marketingWebsocketCommands.MARKETING_GET_TICKET: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketCreating: false,
                created: true,
                isTicketLoading: true,
                correlationId
            });
        }
        case marketingWebsocketCommands.MARKETING_TICKET_SEND: {
            const {ticket, correlationId, messages, logs, attachments, error} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, {isTicketLoading: false, correlationId});
            }
            return Object.assign({}, state, {
                isTicketLoading: false,
                correlationId,
                ticket,
                logs,
                attachments,
                messages,
            });
        }
        //att done + error
        case MARKETING_ATTACHMENTS_UPLOADING_ERROR:
        case marketingWebsocketCommands.MARKETING_ATTACHMENTS_SAVED_ERROR: {
            return Object.assign({}, state, {
                newAttachments: [],
            });
        }
        case marketingWebsocketCommands.MARKETING_ATTACHMENTS_SAVED: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketLoading: true,
                correlationId,
                newAttachments: [],
            });
        }
        default:
            return state;
    }
};

export const marketingLockTicketCheck = (state = {
    correlationId: null,
    isTicketLocking: false,
    redirect: false,
}, action) => {
    switch (action.type) {
        case marketingWebsocketCommands.MARKETING_TICKET_LOCKED_ERROR:
        case marketingWebsocketCommands.MARKETING_TICKET_LOCKED_FATAL: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketLocking: false,
                redirect: true,
            });
        }
        case MARKETING_RESET_REDIRECT: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case marketingWebsocketCommands.MARKETING_TICKET_LOCKED: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case MARKETING_SET_LOCK_TICKET: {
            const {uuid} = action.payload;
            return Object.assign({}, state, {
                isTicketLocking: true,
                redirect: false,
                uuid,
            });
        }
        default:
            return state;
    }
};
