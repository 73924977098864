import React from 'react';
import PropTypes from 'prop-types';
import DatePickerInput from './DatePickerInput';
import {injectIntl} from 'react-intl';
import {Portal} from 'react-overlays';
import DatePickerCommon from './DatePickerCommon';

const CalendarContainer = ({children}) => {
    const el = document.getElementById('calendar-portal');

    return (
        <Portal container={el}>
            {children}
        </Portal>
    )
};

const DatePickerTableComponent = props => {
    const {
        value, onChange, filter
    } = props;
    return (
        <DatePickerCommon
            selected={value || undefined}
            onChange={onChange}
            customInput={<DatePickerInput filter={filter}/>}
            popperContainer={CalendarContainer}/>
    );
};
export default injectIntl(DatePickerTableComponent);

DatePickerTableComponent.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    filter: PropTypes.object,
};
