import {defineMessages} from 'react-intl';

const messages = defineMessages({
    RP: {
        id: 'employeeOf.RP',
        defaultMessage: 'RP'
    },
    DEALER_NETWORK: {
        id: 'employeeOf.dealerNetwork',
        defaultMessage: 'DEALER NETWORK'
    },
});

export default messages;
