import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {FormattedMessage} from 'react-intl';
import commonMessages from '../../../intl/common/commonMessages';

const checkRoles = (WrappedComponent, allowedRoles) => (props) => {
    const {authRoles} = props;
    if (authRoles.filter(value => allowedRoles.includes(value)).length) {
        return <WrappedComponent {...props}/>;
    } else {
        return <div className="card text-center">
            <div className="card-body py-5 my-5">
                <h5 className="card-title"><FormattedMessage {...commonMessages.UNAUTHORIZED}/></h5>
                <p className="card-text"><FormattedMessage {...commonMessages.CONTACT_ADMIN}/></p>
            </div>
        </div>
    }
};

const mapStateToProps = state => ({
    authRoles: state.profile.userRoles,
});

export default compose(connect(mapStateToProps, {}), checkRoles);
