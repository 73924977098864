import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import * as PropTypes from 'prop-types';
import {storeSelectedCountryGroup} from '../../../actions/admin/actionAdminSubjectSettings';
import {StyledForm, Label} from '../../common/StyledComponents';
import {moduleRoles} from '../../../utils/roles';
import SelectCountryGroup from '../../common/country/SelectCountryGroup';
import SubjectSettingsContainer from './SubjectSettingsContainer';
import checkRoles from '../../common/roleChecker/RoleChecker';
import PageHeader from '../../common/PageHeader';
import subjectSettingsMessages from '../../../intl/admin/subjectSettingsMessages';
import adminMessages from '../../../intl/admin/adminMessages';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import {getSubjectKey} from '../../../constants/Utils';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';

class SubjectSettingsPage extends Component {
    componentDidMount() {
        const {domain, roles, selectedCountryGroup} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        if (selectedCountryGroup === '' || !groups.map(group => group.group).includes(selectedCountryGroup)) {
            this.props.storeSelectedCountryGroup(get(groups, '0.group', ''))
        }
    };

    handleCountryGroupSelection = countryGroup => this.props.storeSelectedCountryGroup(countryGroup.value);

    render() {
        const {domain, roles, selectedCountryGroup, subjectKey} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        return (
            <div>
                <PageHeader title={<><FormattedMessage {...subjectSettingsMessages.TITLE}/> - <ValidFormattedMessage message={getSubjectKey[subjectKey]} intlMessages={formTypeMessages}/></>}/>
                <StyledForm className="form-group">
                    <div className="form-row mb-sm-3">
                        <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6">
                            <Label>
                                <FormattedMessage {...adminMessages.SELECT_COUNTRY}/>
                            </Label>
                            <SelectCountryGroup groups={groups}
                                                handleCountryGroupSelection={this.handleCountryGroupSelection}
                                                selectedCountryGroup={selectedCountryGroup}/>
                        </div>
                    </div>
                </StyledForm>
                <div>
                    {groups.map((group, index) =>
                        (selectedCountryGroup === group.group &&
                            <div key={index}>
                                <SubjectSettingsContainer group={selectedCountryGroup}
                                                          subjectKey={subjectKey}
                                                          domain={domain}/>
                            </div>
                        )
                    )}
                </div>
            </div>
        );
    }
}

SubjectSettingsPage.propTypes = {
    domain: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    selectedCountryGroup: state.adminSubjectSettings.selectedCountryGroup
});

export default checkRoles(connect(mapStateToProps, {
    storeSelectedCountryGroup
})(SubjectSettingsPage), ['DB_ADMIN', 'CC_ADMIN', 'MA_ADMIN']);
