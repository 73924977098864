import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    NO_DEALER_CODE: {
        id: 'error.noDealerCode',
        defaultMessage: 'You don\'t have a dealer code!'
    },
    DEALER_ROLE_SET: {
        id: 'error.dealerRoleSet',
        defaultMessage: 'Cannot grant editor access for this user! Reason: User has already dealer profile'
    },
    DEALER_ROLE_SET_TO_EXTERNAL_EDITOR: {
        id: 'error.dealerRoleSetToExternalEditor',
        defaultMessage: 'Cannot grant external editor access for this user! Reason: User has already dealer profile'
    },
    DEALER_ROLE_SET_ADMIN: {
        id: 'error.dealerRoleSetAdmin',
        defaultMessage: 'Cannot grant administrator access for this user! Reason: User has already dealer profile'
    },
    EDITOR_ROLE_SET: {
        id: 'error.editorRoleSet',
        defaultMessage: 'Cannot grant dealer access for this user! Reason: User has already editor profile'
    },
    EXTERNAL_EDITOR_ROLE_SET: {
        id: 'error.externalEditorRoleSet',
        defaultMessage: 'Cannot grant editor access for this user! Reason: User has already external editor profile'
    },
    EDITOR_ROLE_SET_TO_EXTERNAL_EDITOR: {
        id: 'error.editorRoleSetToExternalEditor',
        defaultMessage: 'Cannot grant external editor access for this user! Reason: User has already editor profile'
    },
    EXTERNAL_EDITOR_ROLE_SET_TO_DEALER: {
        id: 'error.externalEditorRoleSetToDealer',
        defaultMessage: 'Cannot grant dealer access for this user! Reason: User has already external editor profile'
    },
    DEALER_ROLE_NO_BIR: {
        id: 'error.dealerRoleNoBir',
        defaultMessage: 'Cannot grant dealer access for this user! Reason: User has no BIR number'
    },
    DISTRIBUTION_ERR001: {
        id: 'error.distribution.ticket.ticketLocked',
        defaultMessage: 'The ticket is locked for you for {lockMinutes} minutes and will be released at {lockTime}'
    },
    DISTRIBUTION_ERR002: {
        id: 'error.distribution.ticket.updateFailed',
        defaultMessage: 'Ticket update failed. Ticket was not locked or someone else locked it during the time'
    },
    DISTRIBUTION_ERR003: {
        id: 'error.distribution.ticket.ticketNotFound',
        defaultMessage: 'Ticket not found'
    },
    DISTRIBUTION_ERR004: {
        id: 'error.distribution.list.loadListFailed',
        defaultMessage: 'Failed to load a list of Distribution tickets'
    },
    DISTRIBUTION_ERR005: {
        id: 'error.distribution.history.loadHistoryFailed',
        defaultMessage: 'Failed to load a history of Distribution tickets'
    },
    DISTRIBUTION_ERR006: {
        id: 'error.distribution.frequentQuestion.loadFrequentQuestion',
        defaultMessage: 'Limit of 10 frequent questions for country {country} has been reached'
    },
    COMMERCIAL_ERR001: {
        id: 'error.commercial.ticket.ticketLocked',
        defaultMessage: 'The ticket is locked for you for {lockMinutes} minutes and will be released at {lockTime}'
    },
    COMMERCIAL_ERR002: {
        id: 'error.commercial.ticket.updateFailed',
        defaultMessage: 'Ticket update failed. Ticket was not locked or someone else locked it during the time'
    },
    COMMERCIAL_ERR003: {
        id: 'error.commercial.ticket.ticketNotFound',
        defaultMessage: 'Ticket not found'
    },
    COMMERCIAL_ERR004: {
        id: 'error.commercial.list.loadListFailed',
        defaultMessage: 'Failed to load a list of Commercial tickets'
    },
    COMMERCIAL_ERR005: {
        id: 'error.commercial.history.loadHistoryFailed',
        defaultMessage: 'Failed to load a history of Commercial tickets'
    },
    COMMERCIAL_ERR006: {
        id: 'error.commercial.frequentQuestion.loadFrequentQuestion',
        defaultMessage: 'Failed to load a frequent question of Commercial tickets'
    },
    MARKETING_ERR001: {
        id: 'error.marketing.ticket.ticketLocked',
        defaultMessage: 'The ticket is locked for you for {lockMinutes} minutes and will be released at {lockTime}'
    },
    MARKETING_ERR002: {
        id: 'error.marketing.ticket.updateFailed',
        defaultMessage: 'Ticket update failed. Ticket was not locked or someone else locked it during the time'
    },
    MARKETING_ERR003: {
        id: 'error.marketing.ticket.ticketNotFound',
        defaultMessage: 'Ticket not found'
    },
    MARKETING_ERR004: {
        id: 'error.marketing.list.loadListFailed',
        defaultMessage: 'Failed to load a list of Marketing tickets'
    },
    MARKETING_ERR005: {
        id: 'error.marketing.history.loadHistoryFailed',
        defaultMessage: 'Failed to load a history of Marketing tickets'
    },
    MARKETING_ERR006: {
        id: 'error.marketing.frequentQuestion.loadFrequentQuestion',
        defaultMessage: 'Failed to load a frequent question of Marketing tickets'
    },
    DHS_ERR001: {
        id: 'error.dhs.cannotLoadData',
        defaultMessage: 'Cannot load data from DHS'
    },
    ASD_ERR001: {
        id: 'error.asd.cannotLoadData',
        defaultMessage: 'Cannot load data from ASD'
    },
});

export default messages;
