import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {isEqual} from 'lodash';
import {fetchCommercialStatistics} from '../../actions/commercial/actionCommercialStatistics';
import {storeSelectedCountryGroup} from '../../actions/home/actionHome';
import {errorAlert} from '../../actions/alertsActions';
import {ALL_COUNTRIES_VALUE, domains} from '../../constants/Utils';
import {moduleRoles} from '../../utils/roles';
import {WrapperHome} from '../common/StyledComponents';
import SelectCountryGroup from '../common/country/SelectCountryGroup';
import StatisticsTable from './StatisticsTable';
import Loader from '../Loader';
import formTypeMessages from '../../intl/modules/formTypeMessages';
import mainPageMessages from '../../intl/common/mainPageMessages';

const DOMAIN = domains.COMMERCIAL;

class CommercialContent extends Component {
    constructor(props) {
        super(props);
        const {userDetail: {roles, group}, selectedCountryGroup, storeSelectedCountryGroup, errorAlert} = props;

        const editorCountryGroups = new Set();
        let isEditor = false;
        let isDealer = false;
        if (moduleRoles.isEditorCommercial(roles)) {
            moduleRoles.getEditorGroups(DOMAIN, roles)
                .forEach(({group}) => editorCountryGroups.add(group));
            isEditor = true;
        }
        if (!isEditor && moduleRoles.isDealerCommercial(roles)) {
            isDealer = true;
            storeSelectedCountryGroup(group, DOMAIN);
            if (!group) {
                errorAlert('User is configured as dealer but does not have any dealer country');
            }
        }
        if (isEditor) {
            if (selectedCountryGroup.length === 0) {
                storeSelectedCountryGroup(ALL_COUNTRIES_VALUE, DOMAIN);
            }
        }
        this.state = {isEditor, isDealer, editorCountryGroups};
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        const {
            selectedCountryGroup, fetchCommercialStatistics, userDetail: {roles, dealerNumber, group},
        } = this.props;
        if (this.state.isEditor && selectedCountryGroup) {
            let selectedCommercialCountryGroups = [selectedCountryGroup];
            if (selectedCountryGroup === ALL_COUNTRIES_VALUE) {
                selectedCommercialCountryGroups = Object.keys(get(roles, `${DOMAIN}.editor.groupPermission`, {default: []}))
                    .filter(countryGroup => roles[DOMAIN].editor.groupPermission[countryGroup] === true);
            }
            fetchCommercialStatistics({countryGroups: selectedCommercialCountryGroups});
        } else if (this.state.isDealer && group && dealerNumber) {
            fetchCommercialStatistics({dealerNumber});
        }
    }

    componentDidUpdate(prevProps) {
        const {selectedCountryGroup} = this.props;

        if (!isEqual(selectedCountryGroup, prevProps.selectedCountryGroup)) {
            this.loadData();
        }
    }

    handleCountryGroupSelection = countryGroup => {
        this.props.storeSelectedCountryGroup(countryGroup.value, DOMAIN);
    };

    render() {
        const {selectedCountryGroup, isLoadingStatistics, statisticData} = this.props;
        const {isEditor, isDealer, editorCountryGroups} = this.state;
        return <>
            <WrapperHome>
                {isEditor &&
                <div className="row mb-sm-3 justify-content-center">
                    <div className="col-12 col-md-6">
                        <SelectCountryGroup groups={[...editorCountryGroups]}
                                            handleCountryGroupSelection={this.handleCountryGroupSelection}
                                            selectedCountryGroup={selectedCountryGroup}
                                            includeSelectionForAll/>
                    </div>
                </div>
                }
                <div className="row mb-sm-3">
                    {(isDealer || isEditor) &&
                    <div className="col-sm-6">
                        <h4><FormattedMessage {...formTypeMessages[DOMAIN]} /></h4>
                    </div>
                    }
                </div>
                {selectedCountryGroup &&
                <>
                    <div className="row mb-sm-5">
                        {(isDealer || isEditor) &&
                        <div className="col-sm-12">
                            <h5>
                                <FormattedMessage {...mainPageMessages.STATISTICS} />
                            </h5>
                            {isLoadingStatistics && <Loader/>}
                            {!isLoadingStatistics &&
                            <StatisticsTable domain={DOMAIN} data={statisticData} group={selectedCountryGroup}/>}
                        </div>
                        }
                    </div>
                </>
                }
            </WrapperHome>
        </>;
    }
}

CommercialContent.propTypes = {
    userDetail: PropTypes.object.isRequired,
    selectedCountryGroup: PropTypes.string.isRequired,
    isLoadingStatistics: PropTypes.bool.isRequired,
    statisticData: PropTypes.array.isRequired,
    storeSelectedCountryGroup: PropTypes.func.isRequired,
    fetchCommercialStatistics: PropTypes.func.isRequired,
    errorAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
    selectedCountryGroup: get(state, `home.selectedCountryGroup.${DOMAIN}`, ''),
    isLoadingStatistics: get(state, `commercialStatistics.isLoading`),
    statisticData: get(state, `commercialStatistics.data`),
});

export default connect(mapStateToProps, {
    storeSelectedCountryGroup,
    fetchCommercialStatistics,
    errorAlert,
})(CommercialContent);
