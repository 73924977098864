import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {cloneDeep} from 'lodash';
import {
    setManagedUser,
    fetchEditorRightsManagement,
    clearEditorRightsManagement,
    fetchDealerRightsManagement,
    clearDealerRightsManagement,
    setExternalEditorCountriesChanged,
    setSelectedExternalEditorCountryOptions,
    saveExternalEditorRightsManagement,
    fetchExternalEditorRightsManagement,
    clearExternalEditorRightsManagement,
    setInitialExternalEditorCountryOptions,
} from '../../../actions/admin/actionAdminRightsManagement';
import {clearAlerts} from '../../../actions/alertsActions';
import {handleUserSearch} from '../../../utils/userUtils';
import {countryGroups} from '../../../constants/Utils';
import {Panel} from '../../common/StyledComponents';
import checkRoles from '../../common/roleChecker/RoleChecker';

import SelectUser from './SelectUser';
import SelectEditorCountries from './SelectEditorCountries';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import errorMessages from '../../../intl/common/errorMessages';
import {handleFetchUsersError} from '../../../actions/actionRole';
import SelectExternalEditorCountries from './SelectExternalEditorCountries';

class ExternalEditorRightsManagementPage extends Component {
    componentDidMount() {
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setExternalEditorCountriesChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleCountrySelectionChange = newOptions => {
        if (newOptions === null) {
            this.setSelectedOptionsChanged([]);
            this.props.setSelectedExternalEditorCountryOptions([]);
        } else {
            this.setSelectedOptionsChanged(newOptions);
            this.props.setSelectedExternalEditorCountryOptions(newOptions);
        }
    };

    handleAllCountriesClick = () => {
        const selectedCountries = {};

        Object.keys(this.props.externalEditorData || {}).forEach(
            countryIso => selectedCountries[countryIso] = true
        );

        const selectedOptions = this.setEditorDataAsCountryOptions(selectedCountries);
        this.setSelectedOptionsChanged(selectedOptions);
    };

    handleSaveClick = () => {
        const selectedCountries = {};
        Object.keys(countryGroups).forEach(
            groupKey => selectedCountries[countryGroups[groupKey]] = false
        );

        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({value}) => {
                    selectedCountries[value] = true;
                }
            )
        }

        this.props.saveExternalEditorRightsManagement(this.props.domain, this.props.managedUser, selectedCountries);
    };

    handleCancelClick = () => {
        this.props.setSelectedExternalEditorCountryOptions(cloneDeep(this.props.initialOptions));
        this.props.setExternalEditorCountriesChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearEditorRightsManagement();
        this.props.clearExternalEditorRightsManagement();
        this.props.clearDealerRightsManagement();
        this.props.fetchDealerRightsManagement(this.props.domain, user);
        this.props.fetchEditorRightsManagement(this.props.domain, user);
        this.props.fetchExternalEditorRightsManagement(this.props.domain, user);
    };

    setEditorDataAsCountryOptions = externalEditorData => {
        const result = Object.keys(externalEditorData || {}).map(countryIso => ({value: countryIso}));
        this.props.setSelectedExternalEditorCountryOptions(cloneDeep(result));

        return result;
    };

    setSelectedOptionsChanged = newOptions => this.props.setExternalEditorCountriesChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                .sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(
        newOptions
            .sort((option1, option2) => option1.value.localeCompare(option2.value))
        )
    );

    render() {
        const {isLoading, managedUser, externalEditorData, isDealer, isEditor, domain} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        return (
            <div>
                <PageHeader title={
                    <FormattedMessage id="admin.title.auth.mng.externalEditor"
                                      defaultMessage="Authorization Management - {domain} - External"
                                      values={{domain: <FormattedMessage {...formTypeMessages[domain]}/>}}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {isDealer &&
                    <Panel className="alert alert-warning">
                        <FormattedMessage {...errorMessages.DEALER_ROLE_SET_TO_EXTERNAL_EDITOR}/>
                    </Panel>
                }
                {isEditor &&
                    <Panel className="alert alert-warning">
                        <FormattedMessage {...errorMessages.EDITOR_ROLE_SET_TO_EXTERNAL_EDITOR}/>
                    </Panel>
                }
                {Object.keys(externalEditorData).length > 0 && managedUser && managedUser.ipn && !isDealer && !isEditor &&
                <SelectExternalEditorCountries handleCountrySelectionChange={this.handleCountrySelectionChange}
                                       handleAllCountriesClick={this.handleAllCountriesClick}
                                       handleSaveClick={this.handleSaveClick}
                                       handleCancelClick={this.handleCancelClick}/>
                }
            </div>
        );
    }
}

ExternalEditorRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    setSelectedExternalEditorCountryOptions: PropTypes.func.isRequired,
    externalEditorData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    initialOptions: PropTypes.array.isRequired,
    setExternalEditorCountriesChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearEditorRightsManagement: PropTypes.func.isRequired,
    fetchEditorRightsManagement: PropTypes.func.isRequired,
    clearDealerRightsManagement: PropTypes.func.isRequired,
    fetchDealerRightsManagement: PropTypes.func.isRequired,
    clearExternalEditorRightsManagement: PropTypes.func.isRequired,
    fetchExternalEditorRightsManagement: PropTypes.func.isRequired,
    saveExternalEditorRightsManagement: PropTypes.func.isRequired,
    setInitialExternalEditorCountryOptions: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDealer: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    externalEditorData: state.adminRightsManagement.externalEditorData,
    isEditor: Object.values(state.adminRightsManagement.editorData || {}).some(value => value),
    isDealer: Object.values(state.adminRightsManagement.dealerData || {}).some(value => value),
    loggedUser: state.profile.userDetail,
    initialOptions: state.adminRightsManagement.initialExternalEditorOptions,
    selectedOptions: state.adminRightsManagement.selectedExternalEditorOptions,
});

export default checkRoles(connect(mapStateToProps, {
    setManagedUser,
    clearAlerts,
    clearEditorRightsManagement,
    fetchEditorRightsManagement,
    clearDealerRightsManagement,
    fetchDealerRightsManagement,
    clearExternalEditorRightsManagement,
    fetchExternalEditorRightsManagement,
    setSelectedExternalEditorCountryOptions,
    setExternalEditorCountriesChanged,
    saveExternalEditorRightsManagement,
    setInitialExternalEditorCountryOptions,
    handleFetchUsersError,
})(injectIntl(ExternalEditorRightsManagementPage)), ['DB_ADMIN', 'CC_ADMIN', 'MA_ADMIN']);
