import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Select from 'react-select';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {BoldPrimaryButton, PrimaryButton, SecondaryButton} from '../../common/Button';
import {Label} from '../../common/StyledComponents';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import authManagementMessages from '../../../intl/admin/rightsManagementMessages';
import buttonMessages from '../../../intl/common/buttonMessages';
import adminMessages from '../../../intl/admin/adminMessages';

const SelectEditorCountries = props => {
    const {
        editorData, intl: {formatMessage}, selectedOptions, countriesChanged, handleCountrySelectionChange,
        handleAllCountriesClick, handleSaveClick, handleCancelClick
    } = props;
    const options = Object.keys(editorData || {})
        .map(countryIso => ({
            value: countryIso,
            label: countryGroupMessages[countryIso] ? formatMessage(countryGroupMessages[countryIso]) : countryIso
        }));

    const formattedSelectedOptions = (selectedOptions || []).map(countryIso => ({
        value: countryIso.value,
        label: countryGroupMessages[countryIso.value] ? formatMessage(countryGroupMessages[countryIso.value]) : countryIso.value
    })).sort((option1, option2) => option1.value.localeCompare(option2.value));

    return (
        <div className="container-fluid">
            <div className="row mb-sm-2">
                <div className="col-sm-12">
                    <Label>
                        <FormattedMessage {...adminMessages.SELECT_COUNTRIES}/>
                    </Label>
                    <PrimaryButton type="button" className="btn btn-sm ml-sm-3" onClick={handleAllCountriesClick}>
                        <FormattedMessage {...buttonMessages.ALL}/>
                    </PrimaryButton>
                </div>
            </div>
            <div className="row mb-sm-3">
                <div className="col">
                    <Select isMulti isSearchable value={formattedSelectedOptions} options={options}
                            placeholder={formatMessage(authManagementMessages.SELECT_COUNTRIES_PLACEHOLDER)}
                            onChange={handleCountrySelectionChange}/>
                </div>
            </div>
            <div className="row mb-sm-3">
                <div className="col-sm-6">
                    <SecondaryButton type="button" className="btn float-right" onClick={handleCancelClick}
                                     disabled={!countriesChanged}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </SecondaryButton>
                </div>
                <div className="col-sm-6">
                    <BoldPrimaryButton type="button" className="btn float-left" onClick={handleSaveClick}
                                       disabled={!countriesChanged}>
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                </div>
            </div>
        </div>
    );
};

SelectEditorCountries.propTypes = {
    editorData: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    countriesChanged: PropTypes.bool.isRequired,
    handleCountrySelectionChange: PropTypes.func.isRequired,
    handleAllCountriesClick: PropTypes.func.isRequired,
    handleSaveClick: PropTypes.func.isRequired,
    handleCancelClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    editorData: state.adminRightsManagement.editorData,
    selectedOptions: state.adminRightsManagement.selectedEditorOptions,
    countriesChanged: state.adminRightsManagement.editorCountriesChanged,
});

export default connect(mapStateToProps, {})(injectIntl(SelectEditorCountries))
