import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    SAVE: {
        id: 'button.save',
        defaultMessage: 'Save'
    },
    EDIT: {
        id: 'button.edit',
        defaultMessage: 'Edit'
    },
    CANCEL: {
        id: 'button.cancel',
        defaultMessage: 'Cancel'
    },
    RESET: {
        id: 'button.reset',
        defaultMessage: 'Reset'
    },
    PREVIOUS: {
        id: 'button.previous',
        defaultMessage: 'Previous'
    },
    NEXT: {
        id: 'button.next',
        defaultMessage: 'Next'
    },
    LAST: {
        id: 'button.last',
        defaultMessage: 'Last'
    },
    ALL: {
        id: 'button.all',
        defaultMessage: 'All'
    },
    NO: {
        id: 'button.no',
        defaultMessage: 'No'
    },
    YES: {
        id: 'button.yes',
        defaultMessage: 'Yes'
    },
    BACK: {
        id: 'button.back',
        defaultMessage: 'Back'
    },
    CLOSE: {
        id: 'button.close',
        defaultMessage: 'Close'
    },
    REOPEN: {
        id: 'button.reopen',
        defaultMessage: 'Reopen'
    },
    FORWARD_TO_NPDC: {
        id: 'button.forwardToNpdc',
        defaultMessage: 'Forward to NPDC'
    },
    FORWARD_TO_DEALER: {
        id: 'button.forwardToDealer',
        defaultMessage: 'Forward to Dealer'
    },
    WORK_IN_PROGRESS: {
        id: 'button.workInProgress',
        defaultMessage: 'Work in Progress'
    },
    ADD: {
        id: 'button.add',
        defaultMessage: 'Add'
    },
    SEARCH: {
        id: 'button.search',
        defaultMessage: 'Search'
    },
    FIRST: {
        id: 'button.first',
        defaultMessage: 'First'
    },
    CLEAR_ATTACHMENT: {
        id: 'button.clearAttachment',
        defaultMessage: 'Clear Attachment'
    },
    DOWNLOAD: {
        id: 'button.download',
        defaultMessage: 'Download'
    },
    EXPORT: {
        id: 'button.export',
        defaultMessage: 'Export'
    },
    DHS_LOAD: {
        id: 'button.dhsLoad',
        defaultMessage: 'Load Data'
    },
    ASD_LOAD: {
        id: 'button.asdLoad',
        defaultMessage: 'Load Data'
    },
    FREQUENT_QUESTION: {
        id: 'button.frequentQuestion',
        defaultMessage: 'Frequent Question'
    },
});

export default messages;
