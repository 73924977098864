import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import {ModalFooter, ModalWindow} from '../../common/modal/Modal';
import {localeToCodeMapping, supportProperties} from '../../../constants/support';
import {languages, locations} from '../../../constants/Utils';
import {PrimaryButton} from '../../common/Button';
import InputSelectFieldWithValidation from '../../common/fields/InputSelectFieldWithValidation';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import Loader from '../../Loader';
import buttonMessages from '../../../intl/common/buttonMessages';
import supportMessages from '../../../intl/common/supportMessages';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import localesMessages from '../../../intl/common/localesMessages';
import locationsMessages from '../../../intl/common/locationsMessages';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        minWidth: '600px',
        maxHeight: '90%',
        overflowY: 'auto',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
};

const customHeaderStyles = {
    fontWeight: '700',
    textAlign: 'center',
    border: 0,
    padding: '15px',
};

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

const StyledLoader = styled.div`
 display: inline-block;
`;

class SupportModal extends React.Component {
    getUserSupport = () => {
        if (!!localeToCodeMapping[this.props.locale] && supportProperties[localeToCodeMapping[this.props.locale]]) {
            return supportProperties[localeToCodeMapping[this.props.locale]]
        } else {
            return supportProperties.DEFAULT;
        }
    };

    save = (data) => {
        this.props.saveModal(data);
    };

    close = () => {
        this.props.reset();
        this.props.closeModal();
    };

    render() {
        const {show, handleSubmit, pristine, submitting, isLoading} = this.props;
        const timezone = moment.tz(moment.tz.guess()).format('Z');
        const countrySupport = this.getUserSupport();

        return (
            <ModalWindow isOpen={show}
                         onRequestClose={this.close}
                         customStyles={customStyles}>
                <StyledForm onSubmit={handleSubmit(this.save)}>
                    <div style={customHeaderStyles}>
                        <FormattedMessage {...supportMessages.INFORMATION} />
                    </div>
                    <table className='table table-striped table-sm'>
                        <tbody>
                        <tr>
                            <td className='font-weight-bold'>
                                <FormattedMessage {...supportMessages.VERSION} />{':'}
                            </td>
                            <td>{'1.0.0.0'}</td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold'>
                                <FormattedMessage {...supportMessages.CALENDAR_VERSION} />{':'}
                            </td>
                            <td>{'1.0.0.0'}</td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold'>
                                <FormattedMessage {...supportMessages.RESP_HELP_DESK} />{':'}
                            </td>
                            <td>{countrySupport.responsible}</td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold'><FormattedMessage {...supportMessages.PHONE} />{':'}
                            </td>
                            <td>{countrySupport.phone}</td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold'><FormattedMessage {...supportMessages.EMAIL} />{':'}
                            </td>
                            <td>{countrySupport.email}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div style={customHeaderStyles}>
                        <FormattedMessage {...supportMessages.USER_CUSTOMIZATION} />
                    </div>
                    <table className='table table-striped table-sm'>
                        <tbody>
                        <tr>
                            <td className='font-weight-bold'>
                                <FormattedMessage {...supportMessages.LANGUAGE} />{':'}
                            </td>
                            <td>
                                <InputSelectFieldWithValidation field='language'
                                                                options={languages}
                                                                noEmpty
                                                                intlMessages={localesMessages}/>
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold'>
                                <FormattedMessage {...supportMessages.LOCATION} />{':'}
                            </td>
                            <td>
                                <InputSelectFieldWithValidation field='locale'
                                                                options={locations}
                                                                noEmpty
                                                                intlMessages={locationsMessages}/>
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold'>
                                <FormattedMessage {...supportMessages.TIMEZONE} />{':'}
                            </td>
                            <td>{'UTC' + timezone}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div style={customHeaderStyles}>
                        <FormattedMessage {...supportMessages.MULTI_SOURCING_COUNTRIES} />
                    </div>
                    <div className="text-center">
                        {['PL', 'DE', 'HU', 'IT'].map((country, index) => <React.Fragment
                            key={index}>{index !== 0 ? ', ' : ''}<ValidFormattedMessage
                            message={country} intlMessages={countryGroupMessages}/></React.Fragment>)}
                    </div>
                    <ModalFooter>
                        <div className="row">
                            <div className="col">
                                <PrimaryButton type="submit" className="btn" disabled={pristine || submitting}>
                                    <FormattedMessage {...buttonMessages.SAVE} />
                                </PrimaryButton>
                                {isLoading &&
                                <StyledLoader className="pl-2 py-auto">
                                    <Loader isSmall/>
                                </StyledLoader>
                                }
                            </div>
                        </div>
                    </ModalFooter>
                </StyledForm>
            </ModalWindow>
        )
    }
}

SupportModal.propTypes = {
    show: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    userCountry: state.profile.userDetail.group,
    language: state.intl.language,
    locale: state.intl.locale,
    isLoading: state.supportModal.isLoading,
});


export default connect(mapStateToProps, {})(reduxForm({
    form: 'SupportModalForm',
    enableReinitialize: true,
})(SupportModal));
