import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.frequentQuestions.title',
        defaultMessage: 'Frequent Question Setting'
    },
    FREQUENT_QUESTION: {
        id: 'admin.frequentQuestions',
        defaultMessage: 'Frequent Question'
    },
    COUNTRY: {
        id: 'admin.frequentQuestions.country',
        defaultMessage: 'Country'
    },
    EMPTY: {
        id: 'admin.frequentQuestions.empty',
        defaultMessage: 'No Frequent Questions'
    },
    ATTACHMENT: {
        id: 'admin.frequentQuestions.attachment',
        defaultMessage: 'Attachment'
    },
    LAST_UPDATE: {
        id: 'admin.frequentQuestions.lastUpdate',
        defaultMessage: 'Last Update'
    },
    TEXT: {
        id: 'admin.frequentQuestions.text',
        defaultMessage: 'Text'
    },
    PLACEHOLDER: {
        id: 'admin.frequentQuestions.placeholder',
        defaultMessage: 'Text'
    },
    MODAL_TITLE: {
        id: 'admin.frequentQuestions.modal.title',
        defaultMessage: 'Add New Frequent Question'
    },
});

export default messages;
