import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const MARKETING_RESET_REDIRECT = 'MARKETING_RESET_REDIRECT';
export const MARKETING_SET_LOCK_TICKET = 'MARKETING_SET_LOCK_TICKET';

export const lockMarketingTicket = uuid => dispatch =>
    dispatch({
        type: MARKETING_SET_LOCK_TICKET,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_SET_LOCK,
        payload: {uuid}
    });

export const unlockMarketingTicket = uuid => dispatch =>
    dispatch({
        type: marketingWebsocketCommands.MARKETING_SET_UNLOCK,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_SET_UNLOCK,
        payload: {uuid}
    });

export const resetRedirectAfterLockMarketingTicket = () => dispatch => dispatch({type: MARKETING_RESET_REDIRECT});
