//DOC export
// export const DOC_EXPORT_FORM_FIELD_PFX_NUMBER = 'pfxNumber';
// export const DOC_EXPORT_FORM_FIELD_SFX_NUMBER = 'sfxNumber';
// export const EXPORT_FORM_FIELD_FROM = 'from';
// export const EXPORT_FORM_FIELD_TO = 'to';
// export const DOC_EXPORT_FORM_FIELD_ASSIGNED = 'assigned';
// export const DOC_EXPORT_FORM_FIELD_STATUS = 'status';
// export const EXPORT_FORM_FIELD_DEALER_NUMBER = 'dealerNumber';
// export const EXPORT_FORM_FIELD_DEALER_NAME = 'dealerName';
// export const EXPORT_FORM_FIELD_TYPE = 'type';
// export const EXPORT_FORM_FIELD_CHARSET = 'charset';
//
// export const DOC_EXPORT_FORM_LABEL_TICKET_NUMBER = 'TICKET_NUMBER';
// export const DOC_EXPORT_FORM_LABEL_FROM = 'DATE_FROM';
// export const DOC_EXPORT_FORM_LABEL_TO = 'DATE_TO';
// export const DOC_EXPORT_FORM_LABEL_ASSIGNED = 'ASSIGNED';
// export const DOC_EXPORT_FORM_LABEL_STATUS = 'STATUS';
// export const DOC_EXPORT_FORM_LABEL_DEALER_NUMBER = 'DEALER_NUMBER';
// export const DOC_EXPORT_FORM_LABEL_DEALER_NAME = 'DEALER_NAME';
// export const EXPORT_FORM_LABEL_TYPE = 'TYPE';
// export const DOC_EXPORT_FORM_LABEL_CHARSET = 'CHARSET';

//export
export const XLS = 'XLS';
export const CSV = 'CSV';

//charset
// export const WINDOWS_1252 = 'WINDOWS_1252';
// export const ISO8859_2 = 'ISO8859_2';
// export const UTF_8_BOM = 'UTF_8_BOM';
// export const UTF_8 = 'UTF_8';

export const ticketExportTypes = [
    XLS,
    CSV,
];

// export const ticketCharsetTypes = [
//     WINDOWS_1252,
//     ISO8859_2,
//     UTF_8,
//     UTF_8_BOM,
// ];
