import React, {Component} from 'react';
import {FormattedDate, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import v4 from 'uuid/v4';
import {
    addFrequentQuestion, editFrequentQuestion, removeFrequentQuestion, updateFrequentQuestion
} from '../../../actions/admin/actionAdminFrequentQuestion';
import {IconCancel, IconDelete, IconEdit, IconSave, PrimaryIcon} from '../../common/Button';
import {downloadFile, uploadFile} from '../../../actions/actionFile';
import {MessageCell, StyledSmall} from '../../common/StyledComponents';
import ConfirmationDialog from '../../common/modal/ConfirmationDialog';
import FileSelectionField from '../../common/fields/FileSelectionField';
import FileIconWithName from '../../common/fields/FileIconWithName';
import Loader from '../../Loader';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';

const FileCell = styled.td`
    text-align: center;
    cursor: ${props => props.cursorPointer ? 'pointer' : 'default'};
`;

class FrequentQuestionRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.data.message,
            attachment: this.props.data.attachment,
            isOpenRemoveDialog: false
        };
    }

    onClickPencil = e => {
        const {adminFrequentQuestion, index, data, domain, profile, uploadFile, updateFrequentQuestion, editFrequentQuestion} = this.props;
        const {value, attachment} = this.state;
        e.preventDefault();
        if ((adminFrequentQuestion.edit === index) && ((value !== data.message) || (attachment !== data.attachment))) {
            const date = new Date();
            const item = {
                groups: data.groups,
                uuid: data.uuid,
                message: value,
                updated: date.getTime(),
                userName: profile.name
            };
            if (attachment) {
                const newAttachmentId = v4();
                item.attachment = {
                    uuid: attachment.uuid ? attachment.uuid : newAttachmentId,
                    name: attachment.name,
                    type: attachment.type
                };
                if (!attachment.uuid) {
                    uploadFile(domain, newAttachmentId, data.uuid, attachment.type, window.URL.createObjectURL(this.state.attachment));
                }
            }
            updateFrequentQuestion(domain, item);
        } else if (adminFrequentQuestion.edit === index) {
            editFrequentQuestion(-1);
        } else {
            this.setState({value: data.message, attachment: data.attachment});
            editFrequentQuestion(index);
        }
    };

    onCancel = e => {
        e.preventDefault();
        this.setState({value: this.props.data.message, attachment: this.props.data.attachment});
        this.props.editFrequentQuestion(-1);
    };

    onClickDelete = () => {
        const item = {
            ...this.props.data
        };
        this.props.removeFrequentQuestion(this.props.domain, item);
    };

    onChangeHandler = e => {
        e.preventDefault();
        this.setState({value: e.target.value});
    };

    showRemoveModal = () => {
        this.setState({
            isOpenRemoveDialog: true
        });
    };

    closeModal = e => {
        e.preventDefault();
        this.setState({
            isOpenRemoveDialog: false
        });
    };

    saveModal = e => {
        e.preventDefault();
        this.setState({
            isOpenRemoveDialog: false
        });
        this.onClickDelete();
    };

    handleDownload = e => {
        e.preventDefault();
        this.props.downloadFile(this.props.domain, this.props.data.attachment, this.props.data.uuid);
    };

    handleAttachmentSelection = attachment => {
        this.setState({attachment});
    };

    renderHandler(provided) {
        return (
            <td className="col-1"
                {...provided.dragHandleProps}
            >
                {
                    (this.props.adminFrequentQuestion.edit === -1) &&
                    <FontAwesomeIcon icon="arrows-alt-v"/>
                }
            </td>
        );
    }

    renderMessage(index) {
        if (this.props.adminFrequentQuestion.edit === index) {
            return (
                <StyledSmall
                    className="form-control"
                    value={this.state.value}
                    placeholder="Enter your text here..."
                    onChange={this.onChangeHandler}
                />
            );
        } else {
            return this.props.data.message;
        }
    }

    renderAttachment = index => {
        const {adminFrequentQuestion, progressStatus, data} = this.props;
        const {attachment} = this.state;
        if (adminFrequentQuestion.edit === index) {
            return <FileSelectionField input={{value: attachment}}
                                       handleAttachmentSelection={this.handleAttachmentSelection} showIcon/>
        } else if (data.attachment && progressStatus.get(data.attachment.uuid)) {
            return <Loader isSmall/>;
        } else if (data.attachment) {
            return <FileIconWithName file={data.attachment} showIcon onClick={this.handleDownload}/>;
        }
    };

    renderTools(index) {
        let buttons;

        if (this.props.adminFrequentQuestion.edit === index) {
            buttons =
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group " role="group" aria-label="First group">
                        <PrimaryIcon className="btn btn-sm m-sm-1" onClick={this.onClickPencil}
                                     title="Save" type="button">
                            <IconSave/>
                        </PrimaryIcon>
                    </div>
                    <div className="btn-group " role="group" aria-label="Second group">
                        <PrimaryIcon className="btn btn-sm  m-sm-1" onClick={this.onCancel}
                                     title="Cancel" type="button">
                            <IconCancel/>
                        </PrimaryIcon>
                    </div>
                </div>
        } else {
            buttons =
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group " role="group" aria-label="First group">
                        <PrimaryIcon className="btn btn-sm m-sm-1" onClick={this.onClickPencil}
                                     title="Edit" type="button">
                            <IconEdit/>
                        </PrimaryIcon>
                    </div>
                    <div className="btn-group " role="group" aria-label="Second group">
                        <PrimaryIcon className="btn btn-sm m-sm-1" title="Delete" type="button"
                                     onClick={this.showRemoveModal}>
                            <IconDelete/>
                        </PrimaryIcon>
                    </div>
                </div>
        }

        return buttons;
    }

    render() {
        const {data, provided, index, intl: {formatMessage}} = this.props;

        return (
            <tr className="d-flex"
                ref={provided.innerRef}
                {...provided.draggableProps}>
                {this.renderHandler(provided)}
                <td className="col-2">
                    {data.groups.map(element => formatMessage(countryGroupMessages[element])).join(', ')}
                </td>
                <MessageCell className="col-3">{this.renderMessage(index)}</MessageCell>
                <FileCell cursorPointer={data.attachment} className="col-2">{this.renderAttachment(index)}</FileCell>
                <td className="col-2"><FormattedDate value={new Date(data.updated)}/></td>
                <td className="col-2">{this.renderTools(index)}</td>
                <ConfirmationDialog show={this.state.isOpenRemoveDialog} closeModal={this.closeModal}
                                    saveModal={this.saveModal} actionIdentifier="remove the frequent question"/>
            </tr>
        );
    }
}

FrequentQuestionRow.propTypes = {
    data: PropTypes.object.isRequired,
    provided: PropTypes.object.isRequired,
    removeFrequentQuestion: PropTypes.func.isRequired,
    addFrequentQuestion: PropTypes.func.isRequired,
    editFrequentQuestion: PropTypes.func.isRequired,
    updateFrequentQuestion: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    adminFrequentQuestion: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    progressStatus: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    adminFrequentQuestion: state.adminFrequentQuestion,
    profile: state.profile.userDetail,
    progressStatus: state.mainFiles.uploading
});

export default connect(mapStateToProps, {
    removeFrequentQuestion,
    addFrequentQuestion,
    editFrequentQuestion,
    updateFrequentQuestion,
    downloadFile,
    uploadFile
})(injectIntl(FrequentQuestionRow));
