import v4 from 'uuid/v4';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';

export const COMMERCIAL_STATISTICS_PENDING = 'COMMERCIAL_STATISTICS_PENDING';

export const fetchCommercialStatistics = ({dealerNumber, countryGroups}) => dispatch => {
    return dispatch({
        type: COMMERCIAL_STATISTICS_PENDING,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_GET_STATISTICS,
        payload: {dealerNumber, countryGroups, correlationId: v4()}
    });
};
