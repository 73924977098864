import {
    SUPPORT_MODAL_CLOSE,
    SUPPORT_MODAL_SHOW,
    USER_SETTINGS_SAVE_PENDING,
    ADMIN_USER_SETTINGS_SEND,
    ADMIN_USER_SETTINGS_SEND_ERROR,
} from '../actions/actionSupport';

export const supportModal = (state = {
                                 isModalOpen: false,
                             },
                             action) => {
        switch (action.type) {
            case SUPPORT_MODAL_SHOW:
                return Object.assign({}, state, {isModalOpen: true});
            case SUPPORT_MODAL_CLOSE: {
                return Object.assign({}, state, {isModalOpen: false});
            }
            case USER_SETTINGS_SAVE_PENDING: {
                return Object.assign({}, state, {isLoading: true});
            }
            case ADMIN_USER_SETTINGS_SEND_ERROR:
            case ADMIN_USER_SETTINGS_SEND: {
                return Object.assign({}, state, {isLoading: false});
            }
            default:
                return state;
        }
    }
;
