import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {DISTRIBUTION_FORM, FORM_BUTTON_REOPEN} from '../../../constants/formConstants';
import {
    fetchDistributionTicket, resetDistributionTicket, updateDistributionTicket
} from '../../../actions/distribution/actionDistributionTicket';
import {
    lockDistributionTicket, resetRedirectAfterLockDistributionTicket
} from '../../../actions/distribution/actionDistributionLockTicket';
import {initLoadedDistributionTicket, transformUpdatedDistributionTicketForSave} from '../Utils';
import {formatTicketNumber} from '../../../utils/utils';
import {clearAlerts} from '../../../actions/alertsActions';
import {editRights} from '../../../constants/distributionTicketRights';
import {moduleRoles} from '../../../utils/roles';
import checkRoles from '../../common/roleChecker/RoleChecker';
import DistributionTicketMasterForm from './forms/DistributionTicketMasterForm';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import formMessages from '../../../intl/modules/formMessages';

class ViewDistributionTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToEdit: false,
            ticketSubmitted: false,
            storedTicketData: {},
        };
    }

    componentDidMount() {
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchDistributionTicket(this.props.match.params.ticketUuid)
        }
    }

    componentWillUnmount() {
        this.props.resetDistributionTicket();
        this.props.resetRedirectAfterLockDistributionTicket();
    }

    handleSubmit = (values) => {
        const {submitBy} = values;

        this.props.lockDistributionTicket(this.props.match.params.ticketUuid);
        this.setState({ticketSubmitted: true, storedTicketData: transformUpdatedDistributionTicketForSave(values)});
        if (submitBy === FORM_BUTTON_REOPEN) {
            this.setState({redirectToEdit: true});
        }
    };

    render() {
        const {ticketData, loggedUser, lockTicket, isLoadingSubjects} = this.props;
        const {ticketSubmitted, redirectToEdit, storedTicketData} = this.state;

        let type = false;
        if (get(ticketData, 'ticket.type', {default: false})) {
            type = ticketData.ticket.type;
        }

        if (!lockTicket.isTicketLocking && !lockTicket.redirect && ticketSubmitted && redirectToEdit) {
            this.props.updateDistributionTicket(storedTicketData);
            return <Redirect to={{
                pathname: `/distribution/edit/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        } else if (!lockTicket.isTicketLocking && !lockTicket.redirect && ticketSubmitted) {
            this.props.updateDistributionTicket(storedTicketData);
            this.setState({ticketSubmitted: false, storedTicketData: {}});
        }

        const initializationValue = initLoadedDistributionTicket(ticketData);
        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !type
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={type} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {type === DISTRIBUTION_FORM &&
                    <DistributionTicketMasterForm form={type}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[moduleRoles.isEditorDistribution(loggedUser) ?
                                                      'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  enableReinitialize={true}
                                                  isEditor={moduleRoles.isEditorDistribution(loggedUser)}
                                                  handleSubmit={this.handleSubmit}
                                                  reqFields={[]}
                                                  readOnly/>
                    }
                </div>
            </div>
        );
    }
}

ViewDistributionTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    fetchDistributionTicket: PropTypes.func.isRequired,
    resetDistributionTicket: PropTypes.func.isRequired,
    resetRedirectAfterLockDistributionTicket: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.distributionTicket,
    lockTicket: state.distributionLockTicketCheck,
    userDetail: state.profile.userDetail,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchDistributionTicket,
    resetDistributionTicket,
    resetRedirectAfterLockDistributionTicket,
    lockDistributionTicket,
    updateDistributionTicket,
})(injectIntl(ViewDistributionTicketPage))), ['DB_DEALER', 'DB_EDITOR']);
