import React, {Component} from 'react';
import styled from 'styled-components';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import {FormattedMessage, injectIntl, FormattedDate, FormattedTime} from 'react-intl';
import {connect} from 'react-redux';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import {InputFieldTicketNumber} from '../../common/fields/InputFieldTicketNumber';
import {InputFieldWithValidation} from '../../common/fields/InputFieldWithValidation';
import InputSelectFieldWithValidation from '../../common/fields/InputSelectFieldWithValidation';
import RadioFields from '../../common/fields/RadioFields';
import {PrimaryButton} from '../../common/Button';
import DatePickerComponent from '../../common/datePicker/DatePickerComponent';
import {
    CSV,
    ticketExportTypes
} from '../../../constants/exportConstant';
import {
    FIELD_DEALER_NAME,
    FIELD_DEALER_NUMBER,
    FIELD_PFX,
    FIELD_SFX,
    FIELD_EXPORT_TYPE,
    FIELD_EXPORT_CHARSET,
    FIELD_STATUS, FIELD_DATE_FROM, FIELD_DATE_TO, FIELD_CUSTOM_DEADLINE, FIELD_PRIORITY,
} from '../../../constants/formConstants';
import charsetMessages from '../../../intl/common/charsetMessages';
import buttonMessages from '../../../intl/common/buttonMessages';
import exportMessages from '../../../intl/modules/exportMessages';
import {domains, getFormStates, getPriority} from '../../../constants/Utils';
import statusMessages from '../../../intl/modules/formStatesMessages';
import exportConstantMessages from '../../../intl/modules/exportConstantMessages';
import {onlyDigits, positiveNumber} from '../../common/validationCommons';
import formMessages from '../../../intl/modules/formMessages';
import formTicketValuesMessages from '../../../intl/common/formTicketValuesMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

class ExportMarketingForm extends Component {
    onChange = (event, newValue) => {
        event.preventDefault();
        const {change, dealers} = this.props;
        if (event.target.name === `dealer.${FIELD_DEALER_NAME}`) {
            const find = (dealers || []).filter(dealer => dealer.dealerName === newValue)[0];
            change('dealer', find ? find : {dealerNumber: '', dealerName: ''});
        }
        if (event.target.name === `dealer.${FIELD_DEALER_NUMBER}`) {
            const find = (dealers || []).filter(dealer => dealer.dealerNumber === newValue)[0];
            change('dealer', find ? find : {dealerNumber: '', dealerName: ''});
        }
    };

    render() {
        const {handleSubmit, exportType, exportRecord, downloadExport, charSets, dealers} = this.props;
        const statusOptions = (() => {
            return getFormStates(domains.MARKETING);
        })();

        return (
            <StyledDiv className="border border-dark p-4 bg-light">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="col-12 pb-2">
                            <StyledDiv className="border border-secondary p-4 bg-white">
                                <div className="form-row">
                                    <div className="col-xl-4 col-lg-7 col-md-7 pb-2">
                                        <InputFieldTicketNumber label={formMessages.TICKET_NUMBER}
                                                                fields={[FIELD_PFX, FIELD_SFX]}/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputSelectFieldWithValidation label={formMessages.STATUS}
                                                                        field={FIELD_STATUS}
                                                                        options={statusOptions}
                                                                        intlMessages={statusMessages}
                                                                        isSmall/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputFieldWithValidation label={formMessages.ASSIGNED_TO}
                                                                  field={'assigned'}
                                                                  isSmall/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputSelectFieldWithValidation
                                            label={formMessages.PRIORITY}
                                            field={FIELD_PRIORITY}
                                            notSortedOptions={getPriority}
                                            intlMessages={formTicketValuesMessages}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputSelectFieldWithValidation label={formMessages.DEALER_NAME}
                                                                        field={`dealer.${FIELD_DEALER_NAME}`}
                                                                        options={(dealers || []).map(dealer => dealer.dealerName)}
                                                                        handleChange={this.onChange}/>

                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputSelectFieldWithValidation label={formMessages.DEALER_NUMBER}
                                                                        field={`dealer.${FIELD_DEALER_NUMBER}`}
                                                                        options={(dealers || []).map(dealer => dealer.dealerNumber)}
                                                                        handleChange={this.onChange}/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <InputFieldWithValidation label={exportMessages.CUSTOM_DEADLINE}
                                                                  field={FIELD_CUSTOM_DEADLINE}
                                                                  validations={[positiveNumber]}
                                                                  normalize={onlyDigits}
                                                                  maxLength={3}
                                                                  isSmall/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-xl-4 col-lg-4 col-md-6 pb-2">
                                        <Field component={DatePickerComponent}
                                               selectsEnd
                                               isClearable
                                               label={formMessages.DATE_FROM}
                                               name={'from'}
                                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 pb-2">
                                        <Field component={DatePickerComponent}
                                               selectsEnd
                                               isClearable
                                               label={formMessages.DATE_TO}
                                               name={'to'}
                                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 pb-2">
                                        <RadioFields
                                            label={exportMessages.EXPORT_TYPE}
                                            field={FIELD_EXPORT_TYPE}
                                            options={ticketExportTypes}
                                            intlMessages={exportMessages}/>
                                        <InputSelectFieldWithValidation label={exportMessages.EXPORT_CHARSET}
                                                                        field={FIELD_EXPORT_CHARSET}
                                                                        options={charSets}
                                                                        intlMessages={charsetMessages}
                                                                        isDisabled={!exportType}
                                                                        noEmpty
                                                                        isSmall/>
                                    </div>
                                </div>
                            </StyledDiv>

                            <form className="form-group">
                                {(Object.keys(exportRecord).length > 0) &&
                                <div>
                                    <div>
                                    <span className="font-weight-bold">
                                        <FormattedMessage {...exportMessages.LAST_EXPORT}/>{' '}
                                    </span>
                                        <FormattedDate value={new Date(exportRecord.created)}/>
                                        {' '}
                                        <FormattedTime value={new Date(exportRecord.created)}/>
                                    </div>
                                    <div>
                                    <span className="font-weight-bold">
                                        <FormattedMessage {...exportMessages.LAST_EXPORT}/>{' '}
                                    </span>
                                        <FormattedMessage {...exportConstantMessages[exportRecord.status]}/>
                                    </div>
                                    {exportRecord.status === 'FINISH' &&
                                    <PrimaryButton type="button"
                                                   className="btn mr-2 float-left"
                                                   onClick={downloadExport}>
                                        <FormattedMessage {...buttonMessages.DOWNLOAD}/>
                                    </PrimaryButton>
                                    }
                                </div>
                                }
                                <PrimaryButton type="button"
                                               className="btn mr-2 mt-3 float-right"
                                               onClick={handleSubmit}>
                                    <FormattedMessage {...buttonMessages.EXPORT}/>
                                </PrimaryButton>
                            </form>
                        </div>
                    </div>
                </div>
            </StyledDiv>
        )
    }
}

ExportMarketingForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    exportType: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    dealers: PropTypes.array.isRequired,
    charSets: PropTypes.array.isRequired,
};

const selector = (form, ...other) => (formValueSelector(form))(...other);

const mapSateToProps = (state, initialProps) => ({
    startDate: selector(initialProps.form, state, FIELD_DATE_FROM),
    endDate: selector(initialProps.form, state, FIELD_DATE_TO),
    exportType: (selector(initialProps.form, state, FIELD_EXPORT_TYPE) === CSV),
    dealers: state.marketingDealer.dealers,
    exportRecord: state.marketingExportTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
});

export default reduxForm({
    enableReinitialize: true,
})(injectIntl(connect(mapSateToProps, {})(ExportMarketingForm)));
