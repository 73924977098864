import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'get-value';
import {
    COMMERCIAL_PATH,
    COMMERCIAL_NEW_PATH,
    COMMERCIAL_NEW_TICKET_PATH,
    COMMERCIAL_LIST_PATH,
    COMMERCIAL_HISTORY_PATH,
    COMMERCIAL_FULL_TEXT_SEARCH_PATH,
    COMMERCIAL_NOTIFICATION_PATH,
    COMMERCIAL_EXPORT_PATH,
    COMMERCIAL_NEW_OFFER_FOR_EMPLOYEES_PATH,
} from '../../routes/paths';
import {COMMERCIAL_FORM, OFFER_FOR_EMPLOYEES_FORM} from '../../constants/formConstants';
import {fetchSubjects} from '../../actions/admin/actionAdminSubjectSettings';
import {domains} from '../../constants/Utils';
import {moduleRoles} from '../../utils/roles';
import NotFoundPage from '../NotFoundPage';
import ListCommercialPage from './list/ListCommercialPage';
import HistoryCommercialPage from './history/HistoryCommercialPage';
import NewCommercialTicketPage from './ticket/NewCommercialTicketPage';
import FullTextSearchCommercialPage from './fullTextSearch/FullTextSearchCommercialPage';
import ViewCommercialTicketPage from './ticket/ViewCommercialTicketPage';
import EditCommercialTicketPage from './ticket/EditCommercialTicketPage';
import NotificationCommercialPage from './notification/NotificationCommercialPage';
import ExportCommercialPage from './export/ExportCommercialPage';

const domain = domains.COMMERCIAL;

class Commercial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managedGroups: get(this.props.loggedUser, `roles.${domain}.dealer`) ?
                [get(this.props.loggedUser, 'group', 'XX')] :
                moduleRoles.getEditorGroups(domain, get(this.props.loggedUser, `roles`, {})).map(({group}) => group),
            subjectKeys: ['commercial', 'offerForEmployees']
        }
    }

    componentDidMount() {
        this.props.fetchSubjects(domain, this.state.managedGroups, this.state.subjectKeys);
    }

    render() {
        return (
            <Switch>
                <Route exact path={COMMERCIAL_PATH}
                       render={() => <Redirect to={COMMERCIAL_LIST_PATH}/>}/>

                <Route exact path={COMMERCIAL_LIST_PATH}
                       render={() => <ListCommercialPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={COMMERCIAL_PATH + '/:view(view)/:ticketUuid(.*)'}
                       component={ViewCommercialTicketPage}/>
                <Route exact path={COMMERCIAL_PATH + '/:edit(edit)/:ticketUuid(.*)'}
                       component={EditCommercialTicketPage}/>
                <Route exact path={COMMERCIAL_HISTORY_PATH}
                       render={() => <HistoryCommercialPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={COMMERCIAL_FULL_TEXT_SEARCH_PATH}
                       render={() => <FullTextSearchCommercialPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={COMMERCIAL_EXPORT_PATH}
                       render={() => <ExportCommercialPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={COMMERCIAL_NOTIFICATION_PATH}
                       component={NotificationCommercialPage}/>

                <Route exact path={COMMERCIAL_NEW_PATH}
                       render={() => <Redirect to={COMMERCIAL_NEW_TICKET_PATH}/>}/>
                <Route exact path={COMMERCIAL_NEW_TICKET_PATH}
                       render={() => <NewCommercialTicketPage type={COMMERCIAL_FORM}/>}/>
                <Route exact path={COMMERCIAL_NEW_OFFER_FOR_EMPLOYEES_PATH}
                       render={() => <NewCommercialTicketPage type={OFFER_FOR_EMPLOYEES_FORM}/>}/>

                <Route component={NotFoundPage}/>
            </Switch>
        )
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail,
});
export default withRouter(connect(mapStateToProps, {fetchSubjects})(Commercial));
