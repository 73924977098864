import {defineMessages} from 'react-intl';

const messages = defineMessages({
    cs: {
        id: 'locale.cs',
        defaultMessage: 'Czech'
    },
    da: {
        id: 'locale.da',
        defaultMessage: 'Danish'
    },
    de: {
        id: 'locale.de',
        defaultMessage: 'German'
    },
    en: {
        id: 'locale.en',
        defaultMessage: 'English'
    },
    es: {
        id: 'locale.es',
        defaultMessage: 'Spanish'
    },
    fr: {
        id: 'locale.fr',
        defaultMessage: 'French'
    },
    hr: {
        id: 'locale.hr',
        defaultMessage: 'Croatian'
    },
    hu: {
        id: 'locale.hu',
        defaultMessage: 'Hungarian'
    },
    it: {
        id: 'locale.it',
        defaultMessage: 'Italian'
    },
    nl: {
        id: 'locale.nl',
        defaultMessage: 'Dutch'
    },
    pl: {
        id: 'locale.pl',
        defaultMessage: 'Polish'
    },
    pt: {
        id: 'locale.pt',
        defaultMessage: 'Portuguese'
    },
    ro: {
        id: 'locale.ro',
        defaultMessage: 'Romanian'
    },
    sk: {
        id: 'locale.sk',
        defaultMessage: 'Slovak'
    },
    sl: {
        id: 'locale.sl',
        defaultMessage: 'Slovenian'
    },
    sr: {
        id: 'locale.sr',
        defaultMessage: 'Serbian'
    },
    sv: {
        id: 'locale.sv',
        defaultMessage: 'Swedish'
    }
});

export default messages;
