import React from 'react';
import {FormattedDate} from 'react-intl';
import get from 'get-value';
import 'react-picky/dist/picky.css';
import * as moment from 'moment';
import tableMessages from '../../../intl/common/tableMessages';
import {
    brandValues,
    getFormStates, getPriority, languageCountryMapping
} from '../../../constants/Utils';
import DatePickerTableComponent from '../../common/datePicker/DatePickerTableComponent';
import commonMessages from '../../../intl/common/commonMessages';
import formStatesMessages from '../../../intl/modules/formStatesMessages';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import formMessages from '../../../intl/modules/formMessages';
import brandMessages from '../../../intl/modules/brandMessages';
import formTicketValuesMessages from '../../../intl/common/formTicketValuesMessages';
import {StyledPicky} from "../StyledComponents";

export const ColumnLibrary = (props, domain, isList = false) => {
    const {intl, subjects, managedGroups, userDetail, countryBU = []} = props;
    let subjectFilter = [];
    const language = get(userDetail, 'settings.language', 'DEFAULT')
    if (subjects) {
        subjectFilter = [].concat.apply([], Array.prototype.concat(...Object.values(subjects))
            .map(groupSubjects => Array.prototype.concat(...Object.values(
                Object.keys(groupSubjects)
                    .map((key) => groupSubjects[key]
                        .map(e => ({
                            id: get(e, `uuid`, ''),
                            translation: key === languageCountryMapping[language]
                                ? [get(e, `languages.local`, '') === null ?
                                        get(e, `languages.default`, '') :
                                        get(e, `languages.local`, '')]
                                : get(e, `languages.default`, '')
                        }))
                    ))
            )))
    }
    const result = [
        {
            id: 'CREATION_DATE',
            accessor: 'created',
            message: formMessages.CREATION_DATE,
            Filter: ({filter, onChange}) => {
                let value = filter && filter.value;
                if (typeof value === 'string') {
                    value = moment(value).toDate();
                }
                return <DatePickerTableComponent
                    value={value || undefined}
                    onChange={onChange}
                    filter={filter}/>;
            },
            Cell: e => <FormattedDate value={new Date(e.value)}/>,
        },
        {
            id: 'SUBJECT',
            accessor: 'subject',
            message: formMessages.SUBJECT,
            Filter: ({filter, onChange}) => (
                <StyledPicky
                    value={filter && filter.value && Array.isArray(filter.value) ?
                        subjectFilter.filter(item => filter.value.some(value => value === item.id)).map(column => ({
                        id: column.id,
                        message: column.translation
                    })) : []}
                    options={subjectFilter.map(col => {
                        return {id: col.id, message: col.translation}
                    })}
                    onChange={value => {
                        const formatted = !!value ? subjectFilter.filter(item => value.some(value => value.id === item.id)).map(column => column.id) : [];
                        return onChange(formatted.length > 0 ? formatted : '');
                    }
                    }
                    open={false}
                    valueKey="id"
                    labelKey="message"
                    multiple={true}
                    numberDisplayed={1}
                    includeFilter={false}
                    dropdownHeight={200}
                    placeholder={intl.formatMessage(commonMessages.ALL)}
                />
            )
        },
        {
            id: 'BRAND',
            accessor: 'brand',
            message: formMessages.BRAND,
            Filter: ({filter, onChange}) => (
                <select onChange={event => onChange(event.target.value)}
                        style={{width: '100%', border: '1px solid #999999'}}
                        value={filter ? filter.value : ''}>
                    <option value="">
                        {intl.formatMessage(commonMessages.ALL)}
                    </option>
                    {brandValues.length && brandValues.map((option) =>
                        <option value={option}>
                            {intl.formatMessage(brandMessages[option])}
                        </option>)}
                </select>
            )
        },
        {
            id: 'DEALER_NAME',
            accessor: 'dealerName',
            message: formMessages.DEALER_NAME,
        },
        {
            id: 'CREATOR_NAME',
            accessor: 'creatorName',
            message: formMessages.CREATOR_NAME,
        },
        {
            id: 'PRIORITY',
            accessor: 'priority',
            message: formMessages.PRIORITY,
            Filter: ({filter, onChange}) => (
                <select onChange={event => onChange(event.target.value)}
                        style={{width: '100%', border: '1px solid #999999'}}
                        value={filter ? filter.value : ''}>
                    <option value="">{intl.formatMessage(
                        commonMessages.ALL)}</option>
                    {getPriority.map((key, index) =>
                        <option key={index} value={key}>
                            {formTicketValuesMessages[key] ? intl.formatMessage(
                                formTicketValuesMessages[key]) : key}
                        </option>)}
                </select>
            ),
            Cell: e =>
                <div>{formTicketValuesMessages[e.value] ? intl.formatMessage(
                    formTicketValuesMessages[e.value]) : e.value}</div>,
        },
        {
            id: 'ASSIGNED_TO',
            accessor: 'lastEditor',
            message: formMessages.ASSIGNED_TO,
        },
        {
            id: 'BUSINESS_UNIT',
            accessor: 'businessUnit',
            message: formMessages.BUSINESS_UNIT,
            Filter: ({filter, onChange}) => (
                <StyledPicky
                    value={filter && filter.value && Array.isArray(filter.value) ?
                        countryBU.filter(item => filter.value.some(value => value === item.id)).map(column => ({
                            id: column.id,
                            message: column.name
                        })) : []}
                    options={(countryBU || []).map(col => {
                        return {id: col.id, message: col.name}
                    })}
                    onChange={value => {
                        const formatted = !!value ? countryBU.filter(item => value.some(value => value.id === item.id)).map(column => column.id) : [];
                        return onChange(formatted.length > 0 ? formatted : '');
                    }
                    }
                    open={false}
                    valueKey="id"
                    labelKey="message"
                    multiple={true}
                    numberDisplayed={1}
                    includeFilter={false}
                    dropdownHeight={200}
                    placeholder={intl.formatMessage(commonMessages.ALL)}
                />
            ),
            Cell: e =>
                <div>{e.value ? get(countryBU.find(bu => bu.id == e.value),
                    "name") : ""}</div>
        },
        {
            id: 'STATUS',
            accessor: 'status',
            message: formMessages.STATUS,
            Filter: ({filter, onChange}) => (
                <StyledPicky
                    value={filter && filter.value && Array.isArray(filter.value) ?
                        getFormStates(domain).filter(item => filter.value.some(value => value === item)).map(column => ({
                            id: column,
                            message: formStatesMessages[column] ? intl.formatMessage(formStatesMessages[column]) : column
                        })) : []}
                    options={getFormStates(domain).map((key, index) => {
                        return {id: key, message: formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                    })}
                    onChange={value => {
                        const formatted = !!value ? getFormStates(domain).filter(item => value.some(value => value.id === item)).map(column => column) : [];
                        return onChange(formatted.length > 0 ? formatted : '');
                    }
                    }
                    open={false}
                    valueKey="id"
                    labelKey="message"
                    multiple={true}
                    numberDisplayed={1}
                    includeFilter={false}
                    dropdownHeight={200}
                    placeholder={intl.formatMessage(commonMessages.ALL)}
                />
            ),
            Cell: e =>
                <div>
                    {formStatesMessages[e.value] ? intl.formatMessage(
                        formStatesMessages[e.value]) : e.value}
                </div>,
        },
        {
            id: 'GROUP',
            accessor: 'group',
            message: tableMessages.GROUP,
            Filter: ({filter, onChange}) => (
                <select onChange={event => onChange(event.target.value)}
                        style={{width: '100%', border: '1px solid #999999'}}
                        value={filter ? filter.value : ''}>
                    <option value="">
                        {intl.formatMessage(commonMessages.ALL)}
                    </option>
                    {managedGroups.length && managedGroups.map((key, index) =>
                        <option key={index} value={key}>
                            {countryGroupMessages[key] ? intl.formatMessage(
                                countryGroupMessages[key]) : key}
                        </option>)
                    }
                </select>
            ),
            Cell: e =>
                <div>
                    {countryGroupMessages[e.value] ? intl.formatMessage(
                        countryGroupMessages[e.value]) : e.value}
                </div>,
        },
        {
            id: 'VIN',
            accessor: 'vin',
            message: formMessages.VIN,
        },
    ];
    if (isList) {
        result.splice(1,0,{
            id: 'LAST_UPDATE',
            accessor: 'lastUpdate',
            message: formMessages.LAST_UPDATE,
            Filter: ({filter, onChange}) => {
                let value = filter && filter.value;
                if (typeof value === 'string') {
                    value = moment(value).toDate();
                }
                return <DatePickerTableComponent
                    value={value || undefined}
                    onChange={onChange}
                    filter={filter}/>;
            },
            Cell: e => e.value ? <FormattedDate value={new Date(e.value)}/> : ''
        },
        {
            id: 'LAST_CHANGE_EDITOR',
                accessor: 'lastChangeEditor',
            message: formMessages.LAST_CHANGE_EDITOR,
            Filter: ({filter, onChange}) => {
            let value = filter && filter.value;
            if (typeof value === 'string') {
                value = moment(value).toDate();
            }
            return <DatePickerTableComponent
                value={value || undefined}
                onChange={onChange}
                filter={filter}/>;
        },
            Cell: e => e.value ? <FormattedDate value={new Date(e.value)}/> : ''
        }, {
            id: 'LAST_EDITOR_CHANGED',
            accessor: 'lastEditorChanged',
            message: formMessages.TABLE_LAST_EDITOR,
        },)
        result.splice(9, 0, {
            id: 'LAST_CHANGE_ASSIGNEE',
            accessor: 'lastChangeAssignee',
            message: formMessages.LAST_CHANGE_ASSIGNEE,
            Filter: ({filter, onChange}) => {
                let value = filter && filter.value;
                if (typeof value === 'string') {
                    value = moment(value).toDate();
                }
                return <DatePickerTableComponent
                    value={value || undefined}
                    onChange={onChange}
                    filter={filter}/>;
            },
            Cell: e => e.value ? <FormattedDate value={new Date(e.value)}/> : ''
        });
    }
    return result;
};
