import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {editBURecordsState, addBIRtoBU} from '../../../../actions/admin/actionBUAssignment';
import {DestructiveIcon, IconAdd, IconCancel, IconDelete, IconEdit, PrimaryIcon} from '../../../common/Button'
import PropTypes from 'prop-types';
import EditBUAssignmentForm from './forms/EditBUAssignmentForm';
import RecordButtonForm from './forms/RecordButtonForm';
import {FIELD_BU} from '../../../../constants/formConstants';
import get from 'get-value';
import {change, formValueSelector, getFormInitialValues, getFormValues} from 'redux-form';

const Row = styled.tr`
    background-color: ${props => (props.isEmpty ? '#f8d7da' : '#fff')};
`;

const Cell = styled.td`
  box-sizing: border-box;
  white-space: pre;
  &&{padding: 10px;};
`;

const ToolCell = styled(Cell)`
  width: 125px;
`;

export const InputCell = styled(Cell)`
  width: 20%;
`;

const ButtonGroupDiv = styled.div`
  display: flex;
`;

class BUAssignmentRecord extends Component {
    handleClickPencil = (e) => {
        const {editBURecordsState, index} = this.props;
        e.preventDefault();
        editBURecordsState(index);
    };

    handleCancel = (e) => {
        const {editBURecordsState} = this.props;
        e.preventDefault();
        editBURecordsState(-1);
    };

    handleSubmit = (values) => {
        const {index, addBIRtoBU, data: {id}, selectedBU, countryBU} = this.props;
        const businessUnit = countryBU.map(bu => ({id: bu.id})).find(bu => bu.id === selectedBU);

        addBIRtoBU({
            id: id,
            businessUnit: businessUnit,
        });
    };

    renderTools() {
        const {editRecord, index, data} = this.props;
        return (
            <ButtonGroupDiv>
                {(editRecord !== index) ?
                    <React.Fragment>
                        {data.type !== 'NOT_USED' &&
                        <PrimaryIcon className="btn btn-sm"
                                     onMouseDown={this.handleClickPencil}
                                     title="Edit"
                                     type="button"
                                     disabled={editRecord === -1 ? false : true}>
                            <IconEdit/>
                        </PrimaryIcon>}

                    </React.Fragment>
                    :
                    <React.Fragment>
                        <DestructiveIcon className="btn btn-sm"
                                         onClick={this.handleCancel}
                                         type="button">
                            <IconCancel/>
                        </DestructiveIcon>
                        <RecordButtonForm onSubmit={this.handleSubmit} form={`BIRBURecordForm${index}`}/>
                    </React.Fragment>}
            </ButtonGroupDiv>
        );
    }

    renderFormCells() {
        const {editRecord, index, data, countryBU} = this.props;
        if (editRecord === index) {
             return (
                <EditBUAssignmentForm initialValues={{
                    businessUnit: get(data, "businessUnit.id", "")
                }} enableReinitialize={true} form={`BIRBURecordForm${index}`} countryBU={countryBU}/>
             );
        } else {
            const businessUnitLabel = get(countryBU.find(bu => bu.id === get(data, "businessUnit.id", "")), "name")

            return (
                <React.Fragment>
                    <InputCell>{businessUnitLabel}</InputCell>
                </React.Fragment>);
        }
    }

    render() {
        const {data} = this.props;
        const [bir, birNumber] = data.id.split('-')
        return (
            <Row isEmpty={(!data.id || !data.dealerName) && data.type !== 'NOT_USED'}>
                <Cell>{birNumber}</Cell>
                <Cell>{data.dealerName}</Cell>
                {this.renderFormCells()}
                <ToolCell>{this.renderTools()}</ToolCell>
            </Row>
        );
    }
}

const mapStateToProps = (state, props) => ({
    editRecord: state.adminBUAssignment.edit,
    countryBU: state.adminBUAssignment.countryBU,
    selectedBU: formValueSelector(`BIRBURecordForm${props.index}`)(state, `${FIELD_BU}`),
});

BUAssignmentRecord.propTypes = {
    data: PropTypes.object.isRequired,
    index: PropTypes.any.isRequired,
    addBIRtoBU: PropTypes.func.isRequired,
    editBURecordsState: PropTypes.func.isRequired,
    editRecord: PropTypes.any.isRequired,
    selectedGroup: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {
    editBURecordsState,
    addBIRtoBU,
})(BUAssignmentRecord);
