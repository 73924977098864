import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import buMessages from '../../intl/admin/businessUnitMessages';

const Button = styled.button`
    &&{
        background-color: ${props => (props.isActive ? "#ffcc33" : "")};

        :hover{
            background-color: #ffcc33;
            color: black;
        };
    };
`;

const Ul = styled.ul`
    &&{
        margin-bottom: 0;
    };
`;


const PaginationItem = (props) => (
    <li>
        <Button isActive={props.isActive} className="btn btn-outline-secondary btn-sm mx-1"
                onClick={props.onPageChange}>{props.display}</Button>
    </li>
);


const pageRange = (selected, numPages, num) => {
    const selectedPos = Math.ceil(num / 2);
    const start = (selected < selectedPos) ? 1 : (selected - selectedPos) + 1;
    const len = (numPages < (start + num) - 1) ? (numPages - start) + 1 : num;

    return Array(len)
        .fill(null)
        .map((u, i) => start + i);
};

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagesToDisplay: 3,
            showFirstLast: true,
            showPrevNext: true
        };
    }


    paginationItem = (className, page, text) => (
        <div key={text}>
            <PaginationItem
                onPageChange={() => this.props.onSetPage(page, this.props.linesPerPage)}
                display={text}
                key={text}
                isActive={this.props.page === page ? true : false}
            />
        </div>
    );


    render() {
        const currentPage = this.props.page;
        const {intl: {formatMessage}} = this.props;
        const total = Math.ceil(this.props.totalCount / this.props.linesPerPage);


        const pages = pageRange(currentPage, total, this.state.pagesToDisplay)
            .map((p) => {
                const isCurrentPage = currentPage === p;
                return this.paginationItem(isCurrentPage ? 'current' : '', p, '' + p);
            });

        const firstPage = this.state.showFirstLast && currentPage !== 1 && total > 1 ?
            this.paginationItem('first', 1, formatMessage(buMessages.SHOW_FIRST)) : null;

        const prevPage = this.state.showPrevNext && currentPage > 1 && total > 1 ?
            this.paginationItem('prev', currentPage - 1, formatMessage(buMessages.SHOW_PREV)) : null;

        const nextPage = this.state.showPrevNext && currentPage !== total && total > 1 ?
            this.paginationItem('next', currentPage + 1, formatMessage(buMessages.SHOW_NEXT)) : null;

        const lastPage = this.state.showFirstLast && currentPage !== total && total > 1 ?
            this.paginationItem('last', total, formatMessage(buMessages.SHOW_LAST)) : null;

        return (
            <div className="row mb-su-small">
                <div className="small-12 column">
                    <div className="pagination-centered pagination-padding align-items-center">
                        <Ul className="pagination">
                            {firstPage}
                            {prevPage}
                            {pages}
                            {nextPage}
                            {lastPage}
                        </Ul>
                    </div>
                </div>
            </div>
        );
    }
}

PaginationItem.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func.isRequired,
    display: PropTypes.string.isRequired,
};

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    linesPerPage: PropTypes.number.isRequired,
    onSetPage: PropTypes.func.isRequired,
    intl: PropTypes.any,
};

const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(injectIntl(Pagination));
