export const apigeeWebsocketCommands = {
    //route (commands)
    DHS_DATA_GET: 'loadDhsData',
    ASD_DATA_GET: 'loadAsdData',

    //receive
    DHS_DATA_SEND: 'DHS_DATA_SEND',
    DHS_DATA_SEND_ERROR: 'DHS_DATA_SEND_ERROR',
    DHS_DATA_SEND_WARN: 'DHS_DATA_SEND_WARN',

    ASD_DATA_SEND: 'ASD_DATA_SEND',
    ASD_DATA_SEND_ERROR: 'ASD_DATA_SEND_ERROR',
    ASD_DATA_SEND_WARN: 'ASD_DATA_SEND_WARN',
};
