import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    ADMINISTRATION: {
        id: 'menu.administration',
        defaultMessage: 'Administration'
    },
    DISTRIBUTION: {
        id: 'menu.distribution',
        defaultMessage: 'Distribution',
    },
    COMMERCIAL: {
        id: 'menu.commercial',
        defaultMessage: 'Commercial',
    },
    MARKETING: {
        id: 'menu.marketing',
        defaultMessage: 'Marketing Spare Part',
    },
    ADMIN_TICKET_SETTING: {
        id: 'admin.sideMenu.ticketSetting',
        defaultMessage: 'Tickets'
    },
    ADMIN_NOTIFICATION: {
        id: 'admin.sideMenu.adminNotification',
        defaultMessage: 'E-mail Notifications'
    },
    ADMIN_FREQUENT_QUESTION: {
        id: 'admin.sideMenu.frequentQuestions',
        defaultMessage: 'Frequent Questions'
    },
    ADMIN_PRESET_RESPONSE: {
        id: 'admin.sideMenu.presetResponse',
        defaultMessage: 'Automatic Responses'
    },
    ADMIN_WORKING_HOURS: {
        id: 'admin.sideMenu.workingHours',
        defaultMessage: 'Working Hours'
    },
    ADMIN_SUBJECT_SETTING: {
        id: 'admin.menu.subjectSetting',
        defaultMessage: 'Subjects'
    },
    ADMIN_SUBJECT_OFFER_FOR_EMPLOYEES_SETTING: {
        id: 'admin.menu.subjectOfferForEmployeesSetting',
        defaultMessage: 'Offer For Employees'
    },
    ADMIN_GENERAL_QUESTION: {
        id: 'admin.menu.subjectSetting.generalQuestion',
        defaultMessage: 'General Question'
    },
    ADMIN_SPARE_PART_PRICE_VERIFICATION: {
        id: 'admin.menu.subjectSetting.sparePartPriceVerification',
        defaultMessage: 'Spare Part Price Verification'
    },
    ADMIN_SPARE_PART_NAME_VERIFICATION: {
        id: 'admin.menu.subjectSetting.sparePartNameVerification',
        defaultMessage: 'Spare Part Name Verification'
    },
    ADMIN_AUTH_MANAGEMENT: {
        id: 'admin.menu.authorizationManagement',
        defaultMessage: 'Authorization Management'
    },
    ADMIN_AUTH_MANAGEMENT_ADMIN: {
        id: 'admin.menu.authorizationManagement.admin',
        defaultMessage: 'Admin'
    },
    ADMIN_AUTH_MANAGEMENT_EDITOR: {
        id: 'admin.menu.authorizationManagement.editor',
        defaultMessage: 'Editor'
    },
    ADMIN_AUTH_MANAGEMENT_EXTERNAL_EDITOR: {
        id: 'admin.menu.authorizationManagement.externalEditor',
        defaultMessage: 'External'
    },
    ADMIN_AUTH_MANAGEMENT_DEALER: {
        id: 'admin.menu.authorizationManagement.dealer',
        defaultMessage: 'Dealer'
    },
    ADMIN_BUSINESS_UNIT_MANAGEMENT: {
        id: 'admin.menu.businessUnitManagement',
        defaultMessage: 'Business Units Management'
    },
    ADMIN_BUSINESS_UNIT_NEW: {
        id: 'admin.menu.businessUnit.new',
        defaultMessage: 'New'
    },
    ADMIN_BUSINESS_UNIT_LIST: {
        id: 'admin.menu.businessUnit.list',
        defaultMessage: 'List'
    },
    ADMIN_BUSINESS_ASSIGNMENT: {
        id: 'admin.menu.businessUnit.buAssignment',
        defaultMessage: 'BU Assignment'
    },
    LIST: {
        id: 'menu.list',
        defaultMessage: 'List'
    },
    NEW: {
        id: 'menu.new',
        defaultMessage: 'New'
    },
    HISTORY: {
        id: 'menu.history',
        defaultMessage: 'History'
    },
    FULL_TEXT_SEARCH: {
        id: 'menu.fulltextSearch',
        defaultMessage: 'Fulltext Search'
    },
    EXPORT: {
        id: 'menu.export',
        defaultMessage: 'Export'
    },
    NOTIFICATION: {
        id: 'menu.notification',
        defaultMessage: 'E-mail Notification Setting'
    },
});

export default messages;
