import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.ticketSetting.title',
        defaultMessage: 'Ticket Setting'
    },
    TICKET_BLOCKING_DURATION: {
        id: 'admin.ticketSetting.blockingDuration',
        defaultMessage: 'Blocking Time Duration (min)'
    },
    DAYS_TO_HISTORY: {
        id: 'admin.ticketSetting.daysToHistory',
        defaultMessage: 'Days to Move in History'
    },
    DAYS_TO_CLOSE: {
        id: 'admin.ticketSetting.daysToClose',
        defaultMessage: 'Automatic Ticket Closure (days)'
    },
});

export default messages;
