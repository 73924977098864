import {
    ADMIN_FREQUENT_QUESTION_SELECT_GROUP,
    ADMIN_FREQUENT_QUESTION_FETCH,
    ADMIN_FREQUENT_QUESTION_ADD,
    ADMIN_FREQUENT_QUESTION_EDIT,
    ADMIN_FREQUENT_QUESTION_UPDATE,
    ADMIN_FREQUENT_QUESTION_SWITCH,
    ADMIN_FREQUENT_QUESTION_REMOVE,
} from '../../actions/admin/actionAdminFrequentQuestion';
import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {move} from '../../utils/utils';
import get from 'get-value';

export const adminFrequentQuestion = (state = {
    isLoading: false,
    frequentQuestions: [],
    edit: -1,
    selectedCountryGroup: '',
}, action) => {
    switch (action.type) {
        case ADMIN_FREQUENT_QUESTION_SELECT_GROUP:
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        case ADMIN_FREQUENT_QUESTION_FETCH:
            return Object.assign({}, state, {isLoading: true, frequentQuestions: []});
        case ADMIN_FREQUENT_QUESTION_ADD:
            return Object.assign({}, state, {isLoading: true});
        case ADMIN_FREQUENT_QUESTION_EDIT:
            return Object.assign({}, state, {edit: action.payload.index});
        case ADMIN_FREQUENT_QUESTION_UPDATE:
            return Object.assign({}, state, {isLoading: true, edit: -1});
        case ADMIN_FREQUENT_QUESTION_SWITCH: {
            let newArray = state.frequentQuestions.slice();
            move(newArray, action.payload.oldIndex, action.payload.newIndex);
            return Object.assign({}, state, {frequentQuestions: newArray});
        }
        case ADMIN_FREQUENT_QUESTION_REMOVE:
            return Object.assign({}, state, {isLoading: true});

        case distributionWebsocketCommands.DISTRIBUTION_FREQUENT_QUESTION_SEND:
        case commercialWebsocketCommands.COMMERCIAL_FREQUENT_QUESTION_SEND:
        case marketingWebsocketCommands.MARKETING_FREQUENT_QUESTION_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                frequentQuestions: get(action, 'payload.frequentQuestions', {default: []}),
                correlationId
            });
        }
        case distributionWebsocketCommands.DISTRIBUTION_FREQUENT_QUESTION_SEND_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_FREQUENT_QUESTION_SEND_ERROR:
        case marketingWebsocketCommands.MARKETING_FREQUENT_QUESTION_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, frequentQuestions: [], correlationId});
        }
        case distributionWebsocketCommands.DISTRIBUTION_FREQUENT_QUESTION_ERROR:
        case commercialWebsocketCommands.COMMERCIAL_FREQUENT_QUESTION_ERROR:
        case marketingWebsocketCommands.MARKETING_FREQUENT_QUESTION_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId});
        }
        default:
            return state;
    }
};
