import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';

export const createCommercialExport = (exportFilter) => (dispatch) => {
    dispatch({
        type: commercialWebsocketCommands.COMMERCIAL_EXPORT_CREATE,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_EXPORT_CREATE,
        payload: {
            exportFilter
        },
    });
};

export const fetchCommercialExport = () => (dispatch) => {
    dispatch({
        type: commercialWebsocketCommands.COMMERCIAL_EXPORT_GET,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_EXPORT_GET,
        payload: {},
    });
};

export const downloadCommercialExport = (exportRecord) => (dispatch) => {
    dispatch({
        type: commercialWebsocketCommands.COMMERCIAL_EXPORT_DOWNLOAD_URL_GET,
        commercialWebsocket: true,
        command: commercialWebsocketCommands.COMMERCIAL_EXPORT_DOWNLOAD_URL_GET,
        payload: {exportRecord},
    });
};
