import 'react-table/react-table.css';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {debounce} from 'throttle-debounce';
import * as moment from 'moment';
import get from 'get-value';
import {fetchMarketingHistory} from '../../../actions/marketing/actionMarketingHistory';
import {countryLanguageMapping, formNameFromSubjectKey} from '../../../constants/Utils';
import Table from '../../common/table/TicketTable';
import formMessages from '../../../intl/modules/formMessages';

class HistoryMarketingTable extends Component {
    constructor(props) {
        super(props);
        this.state = this.loadStateFromSessionStorage({
            historyDefaultPageSizeMA: 10,
            historyDefaultSortMA: [{id: 'TICKET_NUMBER', desc: true}],
            historyDefaultFiltersMA: [],
        });
        this.filtering = false;
        this.handleFetchDataDebounced = debounce(1000, this.handleFetchData);
    }

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^history.*MA/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage = () => {
        for (let key in this.state) {
            if (this.state.hasOwnProperty(key) && key.match(/^history.*MA/)) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = state => {
        this.props.fetchMarketingHistory(
            state.pageSize,
            state.sorted,
            state.cursor,
            state.isNextFromPivot,
            this.normalizeFiltered(state));
        this.filtering = false;
        this.setState({
            historyDefaultPageSizeMA: state.pageSize,
            historyDefaultSortMA: state.sorted,
            historyDefaultFiltersMA: state.filtered
        });
    };

    fetchStrategy = state => {
        this.filtering ? this.handleFetchDataDebounced(state) : this.handleFetchData(state);
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date || filterItem.id === 'CREATION_DATE') {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const {columns, isLoading, data, pages, isNextPage, isNextFromPivot, subjects, userDetail} = this.props;
        const language = get(userDetail, 'settings.language', 'DEFAULT')
        const translatedData = data.map(oldData => ({
            ...oldData,
            subject: (subjects !== undefined && subjects !== null) ?
                countryLanguageMapping[oldData.group] === language ?
                    [get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, []).find(subject => subject.uuid === oldData.subject), `languages.local`, oldData.subject) === null ?
                        get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                            .find(subject => subject.uuid === oldData.subject), `languages.default`, oldData.subject) :
                        get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                            .find(subject => subject.uuid === oldData.subject), `languages.local`, oldData.subject)]
                    : get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                        .find(subject => subject.uuid === oldData.subject), `languages.default`, oldData.subject)
                : oldData.subject,
        }));

        return (
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'TICKET_NUMBER',
                                    accessor: 'ticketNumber',
                                    message: formMessages.TICKET_NUMBER,
                                    Cell: row => <Link to={`view/${row.original.id}`}>{row.value}</Link>
                                },
                                ...columns]}
                            isLoading={isLoading}
                            data={translatedData}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.filtering = true;
                            }}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={this.state.historyDefaultFiltersMA}
                            defaultSorting={this.state.historyDefaultSortMA}
                            defaultPageSize={this.state.historyDefaultPageSizeMA}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

HistoryMarketingTable.propTypes = {
    columns: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number.isRequired,
    fetchMarketingHistory: PropTypes.func.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.marketingHistory.isTableLoading,
    data: state.marketingHistory.listData,
    pages: state.marketingHistory.pages,
    isNextPage: state.marketingHistory.isNextPage,
    isNextFromPivot: state.marketingHistory.isNextFromPivot,
    userDetail: state.profile.userDetail,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
});

export default connect(mapStateToProps, {
    fetchMarketingHistory
})(HistoryMarketingTable)
