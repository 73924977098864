import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {
    startUploadingMarketingAttachments, uploadMarketingAttachments
} from '../../../actions/marketing/actionMarketingAttachment';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import PageHeader from '../../common/PageHeader';
import Loader from '../../Loader';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import formMessages from '../../../intl/modules/formMessages';

const UploadingMarketingAttachment = (props) => {
    const {formType, isAttachmentUploading, correlationId, attachmentsUrl, attachments} = props;

    const uploadAttachments = () => {
        const merge = [];
        attachmentsUrl.forEach(attachment => {
            const data = attachments.find(att => att.uuid === attachment.uuid);
            merge.push({
                ...attachment,
                ...data,
            })
        });
        props.uploadMarketingAttachments(merge, correlationId);
    };

    if (!isAttachmentUploading) {
        props.startUploadingMarketingAttachments();
        uploadAttachments();
    }

    return (
        <div className="container-fluid">
            <PageHeader title={<ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>}/>
            <div className="container-fluid">
                <div className="col-12">
                    <FormattedMessage {...formMessages.UPLOADING_ATTACHMENT}/>
                </div>
                <Loader/>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, {
    startUploadingMarketingAttachments,
    uploadMarketingAttachments,
})(UploadingMarketingAttachment);

