import Scroll from 'react-scroll/modules';
import {SORT_ASC, SORT_DESC, SORT_NONE} from '../constants/Utils';
import {fetchEmailsPromise} from '../actions/actionUtils';

const computeOrder = (column, sorting) => {
    if (sorting.column === column) {
        if (!sorting.order) {
            return SORT_ASC;
        } else if (sorting.order === SORT_ASC) {
            return SORT_DESC;
        }
        return SORT_NONE;
    }
    return SORT_ASC;
};

export const fillSfxZeros = (number) => ('0').repeat(6 - (number + '').length).concat(number);

export const formatTicketNumber = (pfxNumber, sfxNumber) => pfxNumber + '-' + fillSfxZeros(sfxNumber);

export const computeSorting = (column, sorting) => ({
    column,
    order: computeOrder(column, sorting),
});

export const move = (arr, old_index, new_index) => {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        let k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

export const extractFileExtension = fileName => {
    if (!fileName) {
        return '';
    } else {
        const fileExtension = fileName.match(/^.*\.(.*)$/);
        return fileExtension ? fileExtension[1] : '';
    }
};

export const scrollContentWrapperToTop = () => {
    const scroll = Scroll.animateScroll;
    scroll.scrollToTop({
        duration: 250,
        smooth: true,
        containerId: 'contentWrapper'
    });
};

export function handleEmailSearch(inputValue, domain, debouncedCallback) {
    fetchEmailsPromise(inputValue, domain).then(
        ({data}) => debouncedCallback(
            data.map(email => ({value: email, label: email}))
        )
    );
}
