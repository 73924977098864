import {put, takeEvery, select} from 'redux-saga/effects';
import {errorAlert} from '../actions/alertsActions';
import {extractErrorText} from '../actions/actionUtils';
import {cloneDeep} from 'lodash';
import {
    SELECTED_ADMIN_COUNTRY_OPTIONS,
    ADMIN_COUNTRIES_CHANGED,
    ADMIN_RIGHTS_FETCH_SUCCESS,
    SELECTED_EDITOR_COUNTRY_OPTIONS,
    EDITOR_COUNTRIES_CHANGED,
    EDITOR_RIGHTS_FETCH_SUCCESS,
    SELECTED_DEALER_FORM_TYPE_OPTIONS,
    DEALER_FORM_TYPES_CHANGED,
    DEALER_RIGHTS_FETCH_SUCCESS,
    RIGHTS_ERROR,
    INITIAL_ADMIN_COUNTRY_OPTIONS,
    INITIAL_EDITOR_COUNTRY_OPTIONS,
    INITIAL_DEALER_FORM_TYPE_OPTIONS,
    EXTERNAL_EDITOR_RIGHTS_FETCH_SUCCESS,
    SELECTED_EXTERNAL_EDITOR_COUNTRY_OPTIONS,
    INITIAL_EXTERNAL_EDITOR_COUNTRY_OPTIONS, EXTERNAL_EDITOR_COUNTRIES_CHANGED,
} from '../actions/admin/actionAdminRightsManagement';
import {countryGroups, domains, getFormTypes} from '../constants/Utils';
import {
    validateAdminRoles,
    validateDealerRolesForDistribution,
    validateDealerRolesForCommercial,
    validateDealerRolesForMarketing,
    validateEditorData, validateExternalEditorData
} from '../api/validation/role';
import get from 'get-value';
import alertMessages from '../intl/common/alertMessages';
import {distributionWebsocketCommands} from '../constants/distributionWebsocketCommands';
import {commercialWebsocketCommands} from '../constants/commercialWebsocketCommands';
import {marketingWebsocketCommands} from '../constants/marketingWebsocketCommands';

function* handleFetchAdminData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `correlationId`, {default: undefined});
        const {correlationId} = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyAdminData = {};
        Object.keys(countryGroups).forEach(groupKey => emptyAdminData[countryGroups[groupKey]] = false);
        const {error} = validateAdminRoles(cloneDeep(action.payload.groupPermission));
        if (error && action.payload.groupPermission) {
            yield put({type: RIGHTS_ERROR, payload: {correlationId}});
            yield put(errorAlert(alertMessages.RIGHTS_ADMIN_RETRIEVED_ERROR, [error.toString()]));
        } else {
            const finalResult = (action.payload.groupPermission ? Object.assign(emptyAdminData, action.payload.groupPermission) : emptyAdminData);
            yield put({
                type: ADMIN_RIGHTS_FETCH_SUCCESS,
                payload: {finalResult, correlationId},
            });
            const result = Object.keys(finalResult)
                .filter(countryIso => finalResult[countryIso] === true)
                .map(countryIso => ({value: countryIso}));
            yield put({
                type: SELECTED_ADMIN_COUNTRY_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: INITIAL_ADMIN_COUNTRY_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: ADMIN_COUNTRIES_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({type: RIGHTS_ERROR});
        yield put(errorAlert(...extractErrorText(e, alertMessages.RIGHTS_ADMIN_FETCH_ERROR)));
    }
}

function* handleFetchEditorData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `editorCorrelationId`, {default: undefined});
        const {correlationId} = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const loggedAdminCountries = get(yield select(state => state.profile), `userDetail.roles.${action.payload.domain}.admin.groupPermission`, {default: {}});
        const emptyEditorData = Object.keys(loggedAdminCountries)
            .filter(groupKey => loggedAdminCountries[groupKey] === true)
            .reduce((acc, groupKey) => ({
                ...acc,
                [groupKey]: false,
            }), {});
        const {error} = validateEditorData(cloneDeep(action.payload.groupPermission));
        if (error && action.payload.groupPermission) {
            yield put({type: RIGHTS_ERROR, payload: {correlationId}});
            yield put(errorAlert(alertMessages.RIGHTS_EDITOR_RETRIEVED_ERROR, [error.toString()]));
        } else {
            const filteredResult = Object.keys(action.payload.groupPermission || {})
                .filter(countryIso => emptyEditorData[countryIso] !== undefined)
                .reduce((acc, countryIso) => ({
                    ...acc,
                    [countryIso]: action.payload.groupPermission[countryIso],
                }), {});
            const finalResult = (action.payload.groupPermission ? filteredResult : emptyEditorData);
            yield put({
                type: EDITOR_RIGHTS_FETCH_SUCCESS,
                payload: {finalResult, correlationId},
            });

            const result = Object.keys(finalResult)
                .filter(countryIso => finalResult[countryIso] === true)
                .map(countryIso => ({value: countryIso}));
            yield put({
                type: SELECTED_EDITOR_COUNTRY_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: INITIAL_EDITOR_COUNTRY_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: EDITOR_COUNTRIES_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({type: RIGHTS_ERROR});
        yield put(errorAlert(...extractErrorText(e, alertMessages.RIGHTS_EDITOR_FETCH_ERROR)));
    }
}

function* handleFetchExternalEditorData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `externalEditorCorrelationId`, {default: undefined});
        const {correlationId} = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const loggedAdminCountries = get(yield select(state => state.profile), `userDetail.roles.${action.payload.domain}.admin.groupPermission`, {default: {}});
        const emptyExternalEditorData = Object.keys(loggedAdminCountries)
            .filter(groupKey => loggedAdminCountries[groupKey] === true)
            .reduce((acc, groupKey) => ({
                ...acc,
                [groupKey]: false,
            }), {});
        const {error} = validateExternalEditorData(cloneDeep(action.payload.groupPermission));
        if (error && action.payload.groupPermission) {
            yield put({type: RIGHTS_ERROR, payload: {correlationId}});
            yield put(errorAlert(alertMessages.RIGHTS_EXTERNAL_EDITOR_RETRIEVED_ERROR, [error.toString()]));
        } else {
            const filteredResult = Object.keys(action.payload.groupPermission || {})
                .filter(countryIso => emptyExternalEditorData[countryIso] !== undefined)
                .reduce((acc, countryIso) => ({
                    ...acc,
                    [countryIso]: action.payload.groupPermission[countryIso],
                }), {});
            const finalResult = (action.payload.groupPermission ? filteredResult : emptyExternalEditorData);
            yield put({
                type: EXTERNAL_EDITOR_RIGHTS_FETCH_SUCCESS,
                payload: {finalResult, correlationId},
            });

            const result = Object.keys(finalResult)
                .filter(countryIso => finalResult[countryIso] === true)
                .map(countryIso => ({value: countryIso}));
            yield put({
                type: SELECTED_EXTERNAL_EDITOR_COUNTRY_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: INITIAL_EXTERNAL_EDITOR_COUNTRY_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: EXTERNAL_EDITOR_COUNTRIES_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({type: RIGHTS_ERROR});
        yield put(errorAlert(...extractErrorText(e, alertMessages.RIGHTS_EXTERNAL_EDITOR_FETCH_ERROR)));
    }
}

function* handleFetchDealerData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `dealerCorrelationId`, {default: undefined});
        const {correlationId} = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyDealerData = getFormTypes(action.payload.domain)
            .reduce((acc, formKey) => ({
                ...acc,
                [formKey]: false,
            }), {});

        const domain = get(action, 'payload.domain', {default: ''});
        let getError;
        if (domain === domains.DISTRIBUTION) {
            getError = validateDealerRolesForDistribution(cloneDeep(action.payload.formsPermission))
        } else if
        (domain === domains.COMMERCIAL) {
            getError = validateDealerRolesForCommercial(cloneDeep(action.payload.formsPermission))
        } else if
        (domain === domains.MARKETING) {
            getError = validateDealerRolesForMarketing(cloneDeep(action.payload.formsPermission))
        }

        const {error} = getError;
        if (error && action.payload.formsPermission) {
            yield put({type: RIGHTS_ERROR, payload: {correlationId}});
            yield put(errorAlert(alertMessages.RIGHTS_DEALER_RETRIEVED_ERROR, [error.toString()]));
        } else {
            const finalResult = (action.payload.formsPermission ? Object.assign(emptyDealerData, action.payload.formsPermission) : emptyDealerData);
            yield put({
                type: DEALER_RIGHTS_FETCH_SUCCESS,
                payload: {finalResult, correlationId},
            });

            const result = Object.keys(finalResult)
                .filter(formType => finalResult[formType] === true)
                .map(formType => ({value: formType}));
            yield put({
                type: SELECTED_DEALER_FORM_TYPE_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: INITIAL_DEALER_FORM_TYPE_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: DEALER_FORM_TYPES_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({type: RIGHTS_ERROR});
        yield put(errorAlert(...extractErrorText(e, alertMessages.RIGHTS_DEALER_FETCH_ERROR)));
    }
}


function* rightsManagementSaga() {
    yield takeEvery(distributionWebsocketCommands.DISTRIBUTION_ADMIN_ROLE_SEND, handleFetchAdminData);
    yield takeEvery(distributionWebsocketCommands.DISTRIBUTION_EDITOR_ROLE_SEND, handleFetchEditorData);
    yield takeEvery(distributionWebsocketCommands.DISTRIBUTION_EXTERNAL_EDITOR_ROLE_SEND, handleFetchExternalEditorData);
    yield takeEvery(distributionWebsocketCommands.DISTRIBUTION_DEALER_ROLE_SEND, handleFetchDealerData);
    yield takeEvery(commercialWebsocketCommands.COMMERCIAL_ADMIN_ROLE_SEND, handleFetchAdminData);
    yield takeEvery(commercialWebsocketCommands.COMMERCIAL_EDITOR_ROLE_SEND, handleFetchEditorData);
    yield takeEvery(commercialWebsocketCommands.COMMERCIAL_DEALER_ROLE_SEND, handleFetchDealerData);
    yield takeEvery(marketingWebsocketCommands.MARKETING_ADMIN_ROLE_SEND, handleFetchAdminData);
    yield takeEvery(marketingWebsocketCommands.MARKETING_EDITOR_ROLE_SEND, handleFetchEditorData);
    yield takeEvery(marketingWebsocketCommands.MARKETING_DEALER_ROLE_SEND, handleFetchDealerData);
}

export default rightsManagementSaga;
