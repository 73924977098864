import {adminWebsocketCommands} from '../constants/adminWebsocketCommands';

export const USER_SETTINGS_SAVE_PENDING = 'USER_SETTINGS_SAVE_PENDING';
export const ADMIN_USER_SETTINGS_SEND = 'ADMIN_USER_SETTINGS_SEND';
export const ADMIN_USER_SETTINGS_SEND_ERROR = 'ADMIN_USER_SETTINGS_SEND_ERROR';
export const SUPPORT_MODAL_SHOW = 'SUPPORT_MODAL_SHOW';
export const SUPPORT_MODAL_CLOSE = 'SUPPORT_MODAL_CLOSE';

export const closeSupportModal = () => ({
    type: SUPPORT_MODAL_CLOSE,
    payload: {},
});

export const showSupportModal = () => (dispatch) => {
    dispatch({
        type: SUPPORT_MODAL_SHOW,
        payload: {},
    });
};

export const saveUserSettings = ({language, locale}) => dispatch => {
    return dispatch({
        type: USER_SETTINGS_SAVE_PENDING,
        adminWebsocket: true,
        command: adminWebsocketCommands.ADMIN_USER_SETTINGS_SET,
        payload: {
            settings: {
                language,
                locale,
            },
        },
    });
};
