import React from 'react';
import {injectIntl, FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import {connect} from 'react-redux';
import {FieldArray, formValueSelector, getFormInitialValues} from 'redux-form';
import styled from 'styled-components';
import get from 'get-value';
import moment from 'moment';
import {TextareaFieldWithValidation} from '../../../../../common/fields/TextareaFieldWithValidation';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../intl/modules/formMessages';
import {
    FIELD_MESSAGE,
    FORM_FIELD_INTERNAL_NOTE,
    LABEL_INTERNAL_NOTE,
    LABEL_INTERNAL_NOTE_HISTORY, LABEL_MESSAGE,
} from '../../../../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const compare = (a, b) => {
    return moment(a.created).isAfter(b.created) ? 1 : -1;
};

const InternalNoteSection = props => {
    const {attachments = {}, userRights, readOnly, form, initialValues} = props;

    const internalNotes = get(initialValues, 'internalNoteSection.internalNotes', []);

    return (
        <>
            {((userRights.canAddInternalNotes && !readOnly) ||
                (userRights.canShowInternalNotes && internalNotes && (internalNotes.length > 0))) &&
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.INTERNAL_NOTE_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {(internalNotes && (internalNotes.length > 0)) &&
                    <div>
                        <div className="border-bottom border-bottom-secondary"
                             style={{backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0'}}>
                            <label className="col-form-label pl-0 font-weight-bold ml-3">
                                <ValidFormattedMessage message={LABEL_INTERNAL_NOTE_HISTORY}
                                                       intlMessages={formMessages}/>
                            </label>
                        </div>
                        {internalNotes.map((editorsNote, index) =>
                            <div key={index} className="row m-0 align-items-stretch">
                                <div style={{backgroundColor: '#f8f9fa'}}
                                     className="col-sm-12 col-lg-4 flex-column border-bottom">
                                    <div>
                                        <FormattedDate value={new Date(editorsNote.created)}/>
                                        {' '}
                                        <FormattedTime value={new Date(editorsNote.created)}/>
                                        {' '}
                                        <span className="font-weight-bold">{editorsNote.creatorName}</span>
                                        {' '}({editorsNote.creatorIpn})
                                    </div>
                                </div>
                                <div className="col border-bottom" style={{whiteSpace: 'pre-wrap'}}>
                                    {editorsNote.text}
                                </div>
                            </div>
                        )}
                    </div>
                    }
                    {userRights.canAddInternalNotes && !readOnly &&
                    <div className="col-sm-12 px-4 pb-3 pt-1">
                        <div className="col-sm-12 px-0">
                            <TextareaFieldWithValidation label={formMessages[LABEL_INTERNAL_NOTE]}
                                                         field={FORM_FIELD_INTERNAL_NOTE}
                                                         rows={3}/>
                        </div>
                    </div>
                    }
                </StyledDiv>
            </>}
        </>
    )
};

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
});

export default injectIntl(connect(mapStateToProps, null)(InternalNoteSection));
