import React from 'react';
import styled from 'styled-components';
import '../../../css/formControl.css';

const ErrorDiv = styled.label`
    display: inherit;
`;

export const FieldTicketNumber = (fields) => {
    const {pfxNumber, sfxNumber, isSmall} = fields;

    return (
        <div>
            <div className="input-group input-group-sm">
                <input {...pfxNumber.input} type="text"
                       className={'form-control col-4' + (isSmall ? ' form-control-sm' : '') + (pfxNumber.meta.touched && pfxNumber.meta.invalid ? ' is-invalid' : '')}
                       maxLength={2}
                />
                <div className="input-group-prepend input-group-append px-0">
                    <span className="input-group-text">-</span>
                </div>
                <input {...sfxNumber.input} type="text"
                       className={'form-control ' + (isSmall ? ' form-control-sm' : '') + (sfxNumber.meta.touched && sfxNumber.meta.invalid ? ' is-invalid' : '')}
                       minLength={6}
                       maxLength={6}
                />
            </div>
            <div className="input-group input-group-sm">
                {pfxNumber.meta.touched && pfxNumber.meta.error &&
                <ErrorDiv className="invalid-feedback col-4 form-control border-0">{pfxNumber.meta.error}</ErrorDiv>}
                {!pfxNumber.meta.error &&
                <ErrorDiv className="invalid-feedback col-4 form-control border-0">{' '}</ErrorDiv>}
                <div className="input-group-prepend input-group-append px-0">
                    <span className="input-group-text border-0 bg-white">{'  '}</span>
                </div>
                {sfxNumber.meta.touched && sfxNumber.meta.error &&
                <ErrorDiv className="invalid-feedback form-control border-0">{sfxNumber.meta.error}</ErrorDiv>}
            </div>
        </div>
    );
};




