import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {domains, getSegmentOptions, getFormStates} from '../../../constants/Utils';
import {
    fetchMarketingUserNotificationPreferences,
    updateMarketingUserNotificationPreference
} from '../../../actions/marketing/actionMarketingPreferences';
import PageHeader from '../../common/PageHeader';
import Loader from '../../Loader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import NotificationMarketingForm from './NotificationMarketingForm';
import formStatesMessages from '../../../intl/modules/formStatesMessages';
import notificationMessages from '../../../intl/modules/notificationMessages';

const domain = domains.MARKETING;

class NotificationMarketingPage extends Component {
    componentDidMount() {
        this.props.fetchMarketingUserNotificationPreferences();
    }

    handleSubmit = (values) => {
        const preferences = {
            statuses: get(values, `statuses`, []),
            segments: get(values, `segments`, []),
            subjects: [].concat(
                get(values, `subjects.marketingQuestion`, []),
                get(values, `subjects.priceVerification`, []),
                get(values, `subjects.nameVerification`, []),
            ),
        };
        this.props.updateMarketingUserNotificationPreference(preferences);
    };

    compare = (arr1, arr2) => {
        const newArray = [];
        arr1.forEach((e1) => arr2.forEach((e2) => {
            if (e1 !== e2) {
                newArray.push(e1)
            }
        }));
        return newArray
    };

    render() {
        const {marketingSubjects, isLoadingSubjects, intl: {formatMessage}, isLoadingPreferences, subjects, statuses, segments} = this.props;

        if (isLoadingPreferences
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        const statusOptions = (getFormStates(domain) || []).map(status => ({
            value: status,
            label: formStatesMessages[status] ? formatMessage(formStatesMessages[status]) : status
        }));
        const segmentOptions = (getSegmentOptions || []).map(segment => ({value: segment, label: segment}));
        const subjectOptions = {
            marketingQuestion: Array.prototype.concat(...Object.values(get(marketingSubjects, 'marketingQuestion', []))).map(subject => ({
                value: get(subject, `uuid`, ''),
                label: get(subject, `languages.default`, '')
            })),
            priceVerification: Array.prototype.concat(...Object.values(get(marketingSubjects, 'priceVerification', []))).map(subject => ({
                value: get(subject, `uuid`, ''),
                label: get(subject, `languages.default`, '')
            })),
            nameVerification: Array.prototype.concat(...Object.values(get(marketingSubjects, 'nameVerification', []))).map(subject => ({
                value: get(subject, `uuid`, ''),
                label: get(subject, `languages.default`, '')
            })),
        };

        const initialValues = {
            statuses,
            segments,
            subjects: {
                marketingQuestion: (subjects || []).filter(filterSubject => Array.prototype
                    .concat(...Object.values((marketingSubjects.marketingQuestion || [])))
                    .find(e => e.uuid === filterSubject)),
                priceVerification: (subjects || []).filter(filterSubject => Array.prototype
                    .concat(...Object.values((marketingSubjects.priceVerification || [])))
                    .find(e => e.uuid === filterSubject)),
                nameVerification: (subjects || []).filter(filterSubject => Array.prototype
                    .concat(...Object.values((marketingSubjects.nameVerification || [])))
                    .find(e => e.uuid === filterSubject)),
            },
        };

        return (
            <div>
                <PageHeader title={<FormattedMessage {...notificationMessages.TITLE}/>}/>
                {isLoadingSubjects && isLoadingPreferences && <Loader/>}
                {!isLoadingSubjects && !isLoadingPreferences && <>
                    <div className="container-fluid">
                        <NotificationMarketingForm initialValues={initialValues}
                                                   enableReinitialize={true}
                                                   onSubmit={this.handleSubmit}
                                                   statusOptions={statusOptions}
                                                   segmentOptions={segmentOptions}
                                                   subjectOptions={subjectOptions}
                                                   form={'NotificationMarketingForm'}/>
                    </div>
                </>}
            </div>
        )
    }
}

NotificationMarketingPage.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    userDetail: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    marketingSubjects: state.adminSubjectSettings.subjectSettings.subjects,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    isLoadingPreferences: state.marketingPreferences.isLoading,
    subjects: state.marketingPreferences.subjects,
    statuses: state.marketingPreferences.statuses,
    segments: state.marketingPreferences.segments,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchMarketingUserNotificationPreferences,
    updateMarketingUserNotificationPreference
})(injectIntl(NotificationMarketingPage))), ['MA_DEALER', 'MA_EDITOR']);
