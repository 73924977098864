import get from 'get-value';
import {
    DIGIT_STRING_VALIDATION_REGEX,
    VIN_FIELD_LENGTH,
    COMMON_INPUT_VALIDATION_REGEX,
    COMMON_INPUT_VALIDATION_WITH_BRACKETS_REGEX,
    FILE_MAX_SIZE, REFERENCE_NUMBER_FIELD_LENGTH
} from '../../constants/validationConstants';

export const onlyDigits = value => DIGIT_STRING_VALIDATION_REGEX.test(value) || value === null ? value : undefined;

export const positiveNumber = value => (value !== null && ((value && isNaN(Number(value))) || value < 1) ? 'POSITIVE_NUMBER' : undefined);

export const minLength = min =>
    value =>
        value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const required = (value, allValues) => {
    return (value || typeof value === 'number' || get(allValues, 'omitValidation', false) ? undefined : 'REQUIRED');
};

export const validInput = (value = '') => ((!COMMON_INPUT_VALIDATION_REGEX.test(value)) && value.length !== 0) ? 'INVALID_INPUT' : undefined;

export const validInputwithBrackets = (value = '') => ((!COMMON_INPUT_VALIDATION_WITH_BRACKETS_REGEX.test(value)) && value.length !== 0) ? 'INVALID_INPUT' : undefined;

export const validVinLength = value =>
    value && value.length !== VIN_FIELD_LENGTH ? 'MUST_BE_17_CHARS' : undefined;

export const validReferenceNumberLength = value =>
    value && value.length !== REFERENCE_NUMBER_FIELD_LENGTH ? 'MUST_BE_10_CHARS' : undefined;

export const validTicketFiles = fileList => {
    if (fileList) {
        if (fileList.length > 20)
            return 'MAXIMUM_FILES';
        if (fileList.length !== 0) {
            for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].size > FILE_MAX_SIZE) {
                    return 'MAXIMUM_FILE_SIZE';
                }
            }
        }
    }
};
export const validQuestionFiles = file => {
    if (file) {
        if (file.size > FILE_MAX_SIZE) {
            return 'MAXIMUM_FILE_SIZE';
        }
    }
};
