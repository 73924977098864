import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {cloneDeep} from 'lodash';
import {FormattedMessage} from 'react-intl';
import {
    setManagedUser, setSelectedAdminCountryOptions, setAdminCountriesChanged, fetchAdminRightsManagement,
    saveAdminRightsManagement, clearAdminRightsManagement, setInitialAdminCountryOptions,
} from '../../../actions/admin/actionAdminRightsManagement';
import {clearAlerts} from '../../../actions/alertsActions';
import {handleFetchUsersError} from '../../../actions/actionRole';
import {handleUserSearch} from '../../../utils/userUtils';
import {countryGroups} from '../../../constants/Utils';
import {Panel} from '../../common/StyledComponents';
import SelectAdminCountries from './SelectAdminCountries';
import SelectUser from './SelectUser';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import errorMessages from '../../../intl/common/errorMessages';

class AdminRightsManagementPage extends Component {
    componentDidMount() {
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setAdminCountriesChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleCountrySelectionChange = newOptions => {
        if (newOptions === null) {
            this.setSelectedOptionsChanged([]);
            this.props.setSelectedAdminCountryOptions([]);
        } else {
            this.setSelectedOptionsChanged(newOptions);
            this.props.setSelectedAdminCountryOptions(newOptions);
        }
    };

    handleAllCountriesClick = () => {
        const selectedCountries = {};

        Object.keys(this.props.adminData || {}).forEach(
            countryIso => selectedCountries[countryIso] = true
        );

        const selectedOptions = this.setAdminDataAsCountryOptions(selectedCountries);
        this.setSelectedOptionsChanged(selectedOptions);
    };

    handleSaveClick = () => {
        const selectedCountries = {};
        Object.keys(countryGroups).forEach(
            groupKey => selectedCountries[countryGroups[groupKey]] = false
        );

        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({value}) => {
                    selectedCountries[value] = true;
                }
            )
        }

        this.props.saveAdminRightsManagement(this.props.domain, this.props.managedUser, selectedCountries);
    };

    handleCancelClick = () => {
        this.props.setSelectedAdminCountryOptions(cloneDeep(this.props.initialOptions));
        this.props.setAdminCountriesChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearAdminRightsManagement();
        this.props.fetchAdminRightsManagement(this.props.domain, user);
    };

    setAdminDataAsCountryOptions = adminData => {
        const result = Object.keys(adminData || {}).map(countryIso => ({value: countryIso}));
        this.props.setSelectedAdminCountryOptions(cloneDeep(result));
        return result;
    };

    setSelectedOptionsChanged = newOptions => this.props.setAdminCountriesChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                .sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(newOptions.sort((option1, option2) => option1.value.localeCompare(option2.value)))
    );

    render() {
        const {isLoading, managedUser, adminData, domain, isDealer} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        return (
            <div>
                <PageHeader title={
                    <FormattedMessage id="admin.title.auth.mng.admin"
                                      defaultMessage="Authorization Management - {domain} - Administrator"
                                      values={{
                                          domain: <ValidFormattedMessage message={domain}
                                                                         intlMessages={formTypeMessages}/>
                                      }}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {isDealer &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.DEALER_ROLE_SET_ADMIN}/>
                </Panel>
                }
                {Object.keys(adminData).length > 0 && managedUser && managedUser.ipn && !isDealer &&
                <SelectAdminCountries handleCountrySelectionChange={this.handleCountrySelectionChange}
                                      handleAllCountriesClick={this.handleAllCountriesClick}
                                      handleSaveClick={this.handleSaveClick}
                                      handleCancelClick={this.handleCancelClick}/>
                }
            </div>
        );
    }
}

AdminRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    setSelectedAdminCountryOptions: PropTypes.func.isRequired,
    adminData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    saveAdminRightsManagement: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    setAdminCountriesChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearAdminRightsManagement: PropTypes.func.isRequired,
    fetchAdminRightsManagement: PropTypes.func.isRequired,
    setInitialAdminCountryOptions: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    isDealer: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    adminData: state.adminRightsManagement.adminData,
    initialOptions: state.adminRightsManagement.initialAdminOptions,
    selectedOptions: state.adminRightsManagement.selectedAdminOptions,
    isDealer: Object.values(state.adminRightsManagement.dealerData || {}).some(value => value),
});

export default connect(mapStateToProps, {
    setManagedUser,
    fetchAdminRightsManagement,
    setSelectedAdminCountryOptions,
    setAdminCountriesChanged,
    saveAdminRightsManagement,
    clearAdminRightsManagement,
    clearAlerts,
    handleFetchUsersError,
    setInitialAdminCountryOptions,
})(AdminRightsManagementPage);
