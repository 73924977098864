import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'history.title',
        defaultMessage: 'History'
    },
});

export default messages;
