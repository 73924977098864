import {
    getDealers,
    getEditors,
    getUserRole as restGetRole,
    getUsers as restGetUsers,
    saveUser as restPost,
} from '../api/restRole';
import {extractErrorText} from './actionUtils';
import {errorAlert, warnAlert} from './alertsActions';
import welcomeMessages from '../intl/common/welcomeMessages';
import {validateUserDetail} from '../api/validation/role';
import alertMessages from '../intl/common/alertMessages';

export const USER_ROLE_FETCH_PENDING = 'USER_ROLE_FETCH_PENDING';
export const USER_ROLE_FETCH_SUCCESS = 'USER_ROLE_FETCH_SUCCESS';
export const USER_ROLE_FETCH_ERROR = 'USER_ROLE_FETCH_ERROR';
export const USER_ROLE_SAVE_PENDING = 'USER_ROLE_SAVE_PENDING';
export const USER_ROLE_SAVE_SUCCESS = 'USER_ROLE_SAVE_SUCCESS';
export const USER_ROLE_SAVE_ERROR = 'USER_ROLE_SAVE_ERROR';
export const USER_TO_BE_CREATED = 'USER_TO_BE_CREATED';

export const fetchUserRole = (ipn, name) => {
    return async dispatch => {
        dispatch({type: USER_ROLE_FETCH_PENDING});
        try {
            const {data} = await restGetRole(ipn);
            const {error} = validateUserDetail(data);
            if (error) {
                dispatch({type: USER_ROLE_FETCH_ERROR});
                dispatch(errorAlert(alertMessages.USERS_RETRIEVED_ERROR, [error.toString()]));
            } else {
                dispatch({type: USER_ROLE_FETCH_SUCCESS, payload: data});
                if (data && data.roles && Object.keys(data.roles).length === 0) {
                    dispatch(warnAlert(welcomeMessages.NO_RIGHTS));
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                dispatch({type: USER_TO_BE_CREATED});
                dispatch({
                    type: USER_ROLE_FETCH_SUCCESS,
                    payload: {ipn: ipn, name: name, roles: {}}
                });
                dispatch(warnAlert(welcomeMessages.NO_RIGHTS));
            } else {
                dispatch({type: USER_ROLE_FETCH_ERROR});
                dispatch(errorAlert(...extractErrorText(error, alertMessages.USERS_DETAILS_FETCH_ERROR)));
            }
        }
    };
};

export const fetchUsersPromise = (textSearch) => {
    return restGetUsers(textSearch);
};

export const handleFetchUsersError = (error) => {
    return dispatch => {
        dispatch(errorAlert(...extractErrorText(error, alertMessages.USERS_FETCH_ERROR)));
    };
};

export const saveUserRole = () => {
    return dispatch => {
        dispatch({type: USER_ROLE_SAVE_PENDING});

        restPost().then(
            () => dispatch({type: USER_ROLE_SAVE_SUCCESS}),
            error => {
                dispatch({type: USER_ROLE_SAVE_ERROR});
                dispatch(errorAlert(...extractErrorText(error, alertMessages.USERS_SAVE_ERROR)));
            }
        );
    };
};

export const fetchDealersPromise = (textSearch, domain, formType) => {
    return getDealers(textSearch, domain, formType);
};

export const fetchEditorsPromise = (textSearch, domain, group) => {
    return getEditors(textSearch, domain, group);
};
