import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const MARKETING_FULL_TEXT_SEARCH_FETCH = 'MARKETING_FULL_TEXT_SEARCH_FETCH';

export const fetchMarketingFullTextSearch = (pageSize, sorted, cursor, isNextFromPivot, filtered) => (dispatch) => {
    dispatch({
        type: MARKETING_FULL_TEXT_SEARCH_FETCH,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_FULL_TEXT_SEARCH_GET,
        payload: {
            pageSize: pageSize,
            sorted: sorted,
            cursor: cursor,
            isNextFromPivot: isNextFromPivot,
            filtered: filtered
        },
    });
};
