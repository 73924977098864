import React from 'react'
import {Field, reduxForm} from 'redux-form'
import InputSelectFieldWithValidation from '../../../../common/fields/InputSelectFieldWithValidation';
import {InputCell} from '../BUAssignmentRecord';
import {FIELD_BU} from '../../../../../constants/formConstants';

const EditBUAssignmentForm = props => {
    const {countryBU} = props;

    return (
        <>
            <InputCell>
                <InputSelectFieldWithValidation
                    name="businessUnit"
                    field={FIELD_BU}
                    customOptionsFts={countryBU.map(bu => ({id: bu.id, translation: bu.name}))}/>
            </InputCell>
        </>
    )
};

export default reduxForm({})(EditBUAssignmentForm)
