import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'export.title',
        defaultMessage: 'Export'
    },
    CUSTOM_DEADLINE: {
        id: 'export.customDeadline',
        defaultMessage: 'Custom Deadline (days)'
    },
    EXPORT_TYPE: {
        id: 'export.exportType',
        defaultMessage: 'Type'
    },
    XLS: {
        id: 'export.xls',
        defaultMessage: 'XLS'
    },
    CSV: {
        id: 'export.csv',
        defaultMessage: 'CSV'
    },
    LAST_EXPORT: {
        id: 'export.lastExport',
        defaultMessage: 'Last export:'
    },
    EXPORT_CHARSET: {
        id: 'export.exportStatus',
        defaultMessage: 'Export charset:'
    }
});

export default messages;
