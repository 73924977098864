import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import get from 'get-value';
import {IconEdit, PrimaryIcon} from '../../common/Button';
import {fetchCommercialFullTextSearch} from '../../../actions/commercial/actionCommercialFullTextSearch';
import {editCommercialListButtonVisibility} from '../../../constants/commercialTicketRights';
import {moduleRoles} from '../../../utils/roles';
import Table from '../../common/table/TicketTable';
import formMessages from '../../../intl/modules/formMessages';
import {countryLanguageMapping, formNameFromSubjectKey} from '../../../constants/Utils';

class FullTextSearchCommercialTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchDefaultPageSizeCC: 10,
            searchDefaultSortCC: [{id: 'TICKET_NUMBER', desc: true}],
        };
        this.handleFetchData = this.handleFetchData.bind(this);
    }

    handleFetchData = state => {
        this.props.fetchCommercialFullTextSearch(
            state.pageSize,
            state.sorted,
            state.cursor,
            state.isNextFromPivot,
            this.normalizeFiltered(state));
        this.setState({
            searchDefaultPageSizeCC: state.pageSize,
            searchDefaultSortCC: state.sorted,
        });
    };


    normalizeFiltered = () => {
        return this.props.searchDefaultFiltersCC
            .map(filterItem => {
                if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const {
            columns, data, pages, isLoading, isNextPage, isNextFromPivot, handleEditClick, userDetail,
            searchDefaultFiltersCC, subjects,
        } = this.props;
        const language = get(userDetail, 'settings.language', 'DEFAULT')

        const translatedData = data.map(oldData => ({
            ...oldData,
            subject: (subjects !== undefined && subjects !== null) ?
                countryLanguageMapping[oldData.group] === language ?
                    [get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, []).find(subject => subject.uuid === oldData.subject), `languages.local`, oldData.subject) === null ?
                        get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                            .find(subject => subject.uuid === oldData.subject), `languages.default`, oldData.subject)
                        : get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                            .find(subject => subject.uuid === oldData.subject), `languages.local`, oldData.subject)]
                    : get(get(get(subjects, `${formNameFromSubjectKey[oldData.type]}`, []), `${oldData.group}`, [])
                        .find(subject => subject.uuid === oldData.subject), `languages.default`, oldData.subject)
                : oldData.subject,
        }));

        return (
            <div className="container-fluid text-center mb-5">
                <div className="row">
                    <div className="col">
                        <Table
                            filterable={false}
                            columns={[{
                                id: 'TICKET_NUMBER',
                                accessor: 'ticketNumber',
                                filterable: false,
                                message: formMessages.TICKET_NUMBER,
                                Cell: row => <Link to={`view/${row.original.id}`}>{row.value}</Link>
                            }, ...columns]}
                            toolButtons={[{
                                handleClick: handleEditClick,
                                component: <PrimaryIcon className="btn btn-sm"
                                                        title="Edit"
                                                        type="button"
                                                        key="editButton">
                                    <IconEdit/>
                                </PrimaryIcon>
                            }]}
                            isLoading={isLoading}
                            data={translatedData}
                            pages={pages}
                            handleFetchData={this.handleFetchData}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={searchDefaultFiltersCC}
                            defaultSorting={this.state.searchDefaultSortCC}
                            defaultPageSize={this.state.searchDefaultPageSizeCC}
                            buttonVisibility={
                                editCommercialListButtonVisibility[moduleRoles.isDealerCommercial(userDetail.roles) ?
                                    'DEALER' : 'EDITOR']}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

FullTextSearchCommercialTable.propTypes = {
    columns: PropTypes.array.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    searchDefaultFiltersCC: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    fetchCommercialFullTextSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.commercialFullTextSearch.fullTextData,
    pages: state.commercialFullTextSearch.pages,
    isNextPage: state.commercialFullTextSearch.isNextPage,
    isNextFromPivot: state.commercialFullTextSearch.isNextFromPivot,
    isLoading: state.commercialFullTextSearch.isTableLoading,
    userDetail: state.profile.userDetail,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
});

export default connect(mapStateToProps, {
    fetchCommercialFullTextSearch
})(FullTextSearchCommercialTable)
