import {defineMessages} from 'react-intl';

const buMessages = defineMessages({
    NAME: {
        id: 'admin.businessUnit.name',
        defaultMessage: 'Name'
    },
    COUNTRIES: {
        id: 'admin.businessUnit.countries',
        defaultMessage: 'Countries'
    },
    TABLE_NAME: {
        id: 'admin.businessUnit.list.table.name',
        defaultMessage: 'Business Unit'
    },
    TABLE_COUNTRIES: {
        id: 'admin.businessUnit.list.table.countries',
        defaultMessage: 'Countries'
    },
    SELECT_COUNTRY: {
        id: 'admin.businessUnit.selectCountry',
        defaultMessage: 'Select a country'
    },
    PAGE: {
        id: 'admin.businessUnit.buAssignment.page',
        defaultMessage: 'Page'
    },
    TOTAL_RESULTS: {
        id: 'admin.businessUnit.buAssignment.totalResults',
        defaultMessage: 'Total results'
    },
    BU_ASSIGNMENT_TITLE: {
        id: 'admin.businessUnit.buAssignment.title',
        defaultMessage: 'Assign BU to BIR'
    },
    BU_ASSIGNMENT_TABLE_DEALER_NUMBER: {
        id: 'admin.businessUnit.buAssignment.table.dealerNumber',
        defaultMessage: 'Dealer number'
    },
    BU_ASSIGNMENT_TABLE_DEALER_NAME: {
        id: 'admin.businessUnit.buAssignment.table.dealerName',
        defaultMessage: 'Dealer name'
    },
    BU_ASSIGNMENT_TABLE_BUSINESS_UNIT: {
        id: 'admin.businessUnit.buAssignment.table.businessUnit',
        defaultMessage: 'Business unit'
    },
    BU_ASSIGNMENT_TABLE_NO_RESULTS: {
        id: 'admin.businessUnit.buAssignment.table.noResults',
        defaultMessage: 'No results'
    },
    RECORDS_PER_PAGE: {
        id: 'admin.businessUnit.buAssignment.recordsPerPage',
        defaultMessage: 'Per page'
    },
    SHOW_FIRST: {
        id: 'admin.businessUnit.buAssignment.table.pagination.showFirst',
        defaultMessage: 'Show first'
    },
    SHOW_PREV: {
        id: 'admin.businessUnit.buAssignment.table.pagination.showPrev',
        defaultMessage: 'Show prev'
    },
    SHOW_NEXT: {
        id: 'admin.businessUnit.buAssignment.table.pagination.showNext',
        defaultMessage: 'Show next'
    },
    SHOW_LAST: {
        id: 'admin.businessUnit.buAssignment.table.pagination.showLast',
        defaultMessage: 'Show last'
    },
});

export default buMessages;
