import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const MARKETING_LIST_FETCH = 'MARKETING_LIST_FETCH';

export const fetchMarketingList = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: MARKETING_LIST_FETCH,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_LIST_GET,
        payload: {
            pageSize: pageSize,
            sorted: sorted,
            cursor: cursor,
            isNextFromPivot: isNextFromPivot,
            filtered: filtered
        }
    });
};
