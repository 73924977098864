import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Field, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import v4 from 'uuid/v4';
import {FieldWithValidation} from '../../common/fields/FormField';
import {ModalBody, ModalFooter, ModalHeader, ModalWindow} from '../../common/modal/Modal';
import {required} from '../../common/validationCommons';
import {BoldPrimaryButton, SecondaryButton} from '../../common/Button';
import {StyledForm} from '../../common/StyledComponents';
import buttonMessages from '../../../intl/common/buttonMessages';
import presetResponseMessages from '../../../intl/admin/presetResponseMessages';
import {TextareaFieldWithValidation} from '../../common/fields/TextareaFieldWithValidation';

const Button = styled(SecondaryButton)`
  margin-right: 10px;
`;

class PresetResponseDialog extends Component {
    constructor(props) {
        super(props);
    }

    save = data => {
        const responseId = v4();
        this.props.saveModal(data, responseId);
    };

    close = () => {
        this.props.reset();
        this.props.closeModal();
        this.setState({data: null});
    };

    render() {
        const {show, handleSubmit, dirty, invalid, intl: {formatMessage}} = this.props;

        return (
            <ModalWindow isOpen={show} onRequestClose={this.close} shouldCloseOnOverlayClick={false}>
                <StyledForm onSubmit={handleSubmit(this.save)}>
                    <ModalHeader>
                        <FormattedMessage {...presetResponseMessages.MODAL_TITLE}/>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group mb-0">
                            <div className="row">
                                <div className="col-2 py-2">
                                    <label>
                                        <FormattedMessage {...presetResponseMessages.PRESET_RESPONSE_NAME}/>
                                    </label>
                                </div>
                                <div className="col-10 py-2">
                                    <Field name="presetName"
                                           component={FieldWithValidation}
                                           type="text"
                                           placeholder={formatMessage(presetResponseMessages.PRESET_RESPONSE_NAME)}
                                           value="text"
                                           className="form-control"
                                           validate={[required]}
                                           isSmall/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <label>
                                        <FormattedMessage {...presetResponseMessages.PRESET_RESPONSE_MESSAGE}/>
                                    </label>
                                </div>
                                <div className="col-10">
                                    <TextareaFieldWithValidation
                                        field={'presetMessage'}
                                        placeholder={formatMessage(presetResponseMessages.PRESET_RESPONSE_MESSAGE)}
                                        isRequired
                                        rows={4}/>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row">
                            <div className="col">
                                <Button type="button" className="btn"
                                        onClick={this.close}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </Button>
                                <BoldPrimaryButton type="submit" className="btn"
                                                   disabled={!dirty || invalid}>
                                    <FormattedMessage {...buttonMessages.SAVE}/>
                                </BoldPrimaryButton>
                            </div>
                        </div>
                    </ModalFooter>
                </StyledForm>
            </ModalWindow>
        )
    }
}

PresetResponseDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    intl: PropTypes.any.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveModal: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
};

export default reduxForm({
    form: 'addPresetResponseModalForm',
    enableReinitialize: true,
})(injectIntl((PresetResponseDialog)));
