import {fetchEditorsPromise} from '../actions/actionRole';

export function handleEditorSearch(inputValue, debouncedCallback, errorCallback, domain, group) {
    fetchEditorsPromise(inputValue, domain, group).then(
        ({data}) => debouncedCallback(
            data.sort((user1, user2) => user1.name.localeCompare(user2.name))
                .map(mapEditorToSelectionOption)
        ),
        error => errorCallback(error)
    );
}

export function mapEditorToSelectionOption(user) {
    return Object.assign(user, {value: user.ipn, label: extractEditorLabelForSelectionOption(user)});
}

export function extractEditorLabelForSelectionOption(user) {
    return `${user.name}`;
}
