import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {cloneDeep} from 'lodash';
import {
    setManagedUser, setSelectedDealerFormTypeOptions, setDealerFormTypesChanged, fetchDealerRightsManagement,
    saveDealerRightsManagement, clearDealerRightsManagement, clearEditorRightsManagement, fetchEditorRightsManagement,
    setInitialDealerFormTypeOptions, fetchExternalEditorRightsManagement, clearExternalEditorRightsManagement,
} from '../../../actions/admin/actionAdminRightsManagement';
import {clearAlerts} from '../../../actions/alertsActions';
import {handleUserSearch} from '../../../utils/userUtils';
import {Panel} from '../../common/StyledComponents';
import checkRoles from '../../common/roleChecker/RoleChecker';
import SelectUser from './SelectUser';
import SelectDealerFormTypes from './SelectDealerFormTypes';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import errorMessages from '../../../intl/common/errorMessages';
import {handleFetchUsersError} from '../../../actions/actionRole';

class DealerRightsManagementPage extends Component {
    componentDidMount() {
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setDealerFormTypesChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleFormTypeSelectionChange = newOptions => {
        if (newOptions === null) {
            this.setSelectedOptionsChanged([]);
            this.props.setSelectedDealerFormTypeOptions([]);
        } else {
            this.setSelectedOptionsChanged(newOptions);
            this.props.setSelectedDealerFormTypeOptions(newOptions);
        }
    };

    handleAllFormTypesClick = () => {
        const selectedFormTypes = {};

        Object.keys(this.props.dealerData || {}).forEach(
            type => selectedFormTypes[type] = true
        );

        const selectedOptions = this.setDealerDataAsFormTypeOptions(selectedFormTypes);
        this.setSelectedOptionsChanged(selectedOptions);
    };

    handleSaveClick = () => {
        const selectedFormTypes = {};

        Object.keys(this.props.dealerData || {}).forEach(
            type => selectedFormTypes[type] = false
        );
        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({value}) => {
                    selectedFormTypes[value] = true;
                }
            )
        }

        this.props.saveDealerRightsManagement(this.props.domain, this.props.managedUser, selectedFormTypes);
    };

    handleCancelClick = () => {
        this.props.setSelectedDealerFormTypeOptions(cloneDeep(this.props.initialOptions));
        this.props.setDealerFormTypesChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearDealerRightsManagement();
        this.props.clearEditorRightsManagement();
        this.props.clearExternalEditorRightsManagement();
        this.props.fetchEditorRightsManagement(this.props.domain, user);
        this.props.fetchExternalEditorRightsManagement(this.props.domain, user);
        this.props.fetchDealerRightsManagement(this.props.domain, user);
    };

    setDealerDataAsFormTypeOptions = (dealerData) => {
        const result = Object.keys(dealerData || {}).map(countryIso => ({value: countryIso}));
        this.props.setSelectedDealerFormTypeOptions(cloneDeep(result));

        return result;
    };

    setSelectedOptionsChanged = newOptions => {
        this.props.setDealerFormTypesChanged(
            JSON.stringify(
                cloneDeep(this.props.initialOptions)
                    .sort((option1, option2) => option1.value.localeCompare(option2.value))
            ) !== JSON.stringify(
            newOptions
                .sort((option1, option2) => option1.value.localeCompare(option2.value))
            )
        );
    };

    render() {
        const {isLoading, managedUser, dealerData, domain, isEditor, isExternalEditor} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        return (
            <div>
                <PageHeader title={
                    <FormattedMessage id="admin.title.auth.mng.dealer"
                                      defaultMessage="Authorization Management - {domain} - Dealer"
                                      values={{domain: <FormattedMessage {...formTypeMessages[domain]} />}}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {isEditor &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.EDITOR_ROLE_SET} />
                </Panel>
                }
                {isExternalEditor &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.EXTERNAL_EDITOR_ROLE_SET_TO_DEALER} />
                </Panel>
                }
                {managedUser && managedUser.ipn && !managedUser.dealerNumber &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.DEALER_ROLE_NO_BIR} />
                </Panel>
                }
                {dealerData && Object.keys(dealerData).length > 0 && managedUser && managedUser.ipn && managedUser.dealerNumber && !isEditor && !isExternalEditor &&
                <SelectDealerFormTypes handleFormTypeSelectionChange={this.handleFormTypeSelectionChange}
                                       handleAllFormTypesClick={this.handleAllFormTypesClick}
                                       handleSaveClick={this.handleSaveClick}
                                       handleCancelClick={this.handleCancelClick}/>
                }
            </div>
        );
    }
}

DealerRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    setSelectedDealerFormTypeOptions: PropTypes.func.isRequired,
    dealerData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    saveDealerRightsManagementPageData: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    setDealerFormTypesChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearDealerRightsManagement: PropTypes.func.isRequired,
    fetchDealerRightsManagement: PropTypes.func.isRequired,
    clearEditorRightsManagement: PropTypes.func.isRequired,
    fetchEditorRightsManagement: PropTypes.func.isRequired,
    setInitialDealerFormTypeOptions: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    isExternalEditor: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    isEditor: Object.values(state.adminRightsManagement.editorData || {}).some(value => value),
    isExternalEditor: Object.values(state.adminRightsManagement.externalEditorData || {}).some(value => value),
    managedUser: state.adminRightsManagement.managedUser,
    dealerData: state.adminRightsManagement.dealerData,
    initialOptions: state.adminRightsManagement.initialDealerOptions,
    selectedOptions: state.adminRightsManagement.selectedDealerOptions,
});

export default checkRoles(connect(mapStateToProps, {
    setManagedUser,
    clearAlerts,
    clearEditorRightsManagement,
    fetchEditorRightsManagement,
    clearExternalEditorRightsManagement,
    fetchExternalEditorRightsManagement,
    clearDealerRightsManagement,
    fetchDealerRightsManagement,
    setSelectedDealerFormTypeOptions,
    setDealerFormTypesChanged,
    saveDealerRightsManagement,
    setInitialDealerFormTypeOptions,
    handleFetchUsersError,
})(DealerRightsManagementPage), ['DB_ADMIN', 'CC_ADMIN', 'MA_ADMIN']);
