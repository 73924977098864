import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import set from 'set-value';
import {COMMERCIAL_FORM, OFFER_FOR_EMPLOYEES_FORM} from '../../../constants/formConstants';
import {resetCommercialTicket, createCommercialTicket} from '../../../actions/commercial/actionCommercialTicket';
import {initNewCommercialTicket, initNewOfferForEmployeesTicket, transformNewCommercialTicketForSave} from '../Utils';
import {clearAlerts} from '../../../actions/alertsActions';
import {createRights} from '../../../constants/commercialTicketRights';
import {createReqFields} from '../../../constants/commercialRequiredFields';
import UploadingCommercialAttachment from './UploadingCommercialAttachment';
import CommercialTicketMasterForm from './forms/CommercialTicketMasterForm';
import checkRoles from '../../common/roleChecker/RoleChecker';
import NoBirComponent from '../../common/NoBirComponent';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import PageHeader from '../../common/PageHeader';
import Loader from '../../Loader';
import formTypeMessages from '../../../intl/modules/formTypeMessages';
import OfferForEmployeesForm from './forms/OfferForEmployeesForm';

class NewCommercialTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetCommercialTicket();
    }

    handleSubmit = (values) => {
        const dataForSend = transformNewCommercialTicketForSave(values.newValues);
        this.props.createCommercialTicket(dataForSend);
        this.setState({ticketSubmittedAndShouldRedirect: true});

    };

    render() {
        const {
            type,
            ticketData,
            attachmentsData,
            isLoadingSubjects,
        } = this.props;

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingCommercialAttachment attachments={attachmentsData.attachmentsForUpload}
                                                  attachmentsUrl={ticketData.newAttachments}
                                                  correlationId={ticketData.correlationId}
                                                  isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                                  formType={type}/>
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating
            && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/commercial/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (!type
            || this.state.ticketSubmittedAndShouldRedirect
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        let initializationValue
        if(type === COMMERCIAL_FORM){
            initializationValue = initNewCommercialTicket(this.props.userDetail);
        }
        if(type === OFFER_FOR_EMPLOYEES_FORM){
            const subject = get(get(get(this.props.subjects, `offerForEmployees.${this.props.group}`, []),0, {default: ''}), 'uuid', {default: ''});
            initializationValue = initNewOfferForEmployeesTicket(this.props.userDetail, subject);
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<ValidFormattedMessage message={type} intlMessages={formTypeMessages}/>}/>

                <div>
                    {type === COMMERCIAL_FORM &&
                    <CommercialTicketMasterForm form={type}
                                                initialValues={set(initializationValue, 'ticketSection.type', COMMERCIAL_FORM)}
                                                userRights={createRights}
                                                reqFields={createReqFields[type]}
                                                enableReinitialize={true}
                                                handleSubmit={this.handleSubmit}/>
                    }
                    {type === OFFER_FOR_EMPLOYEES_FORM &&
                    <OfferForEmployeesForm form={type}
                                                initialValues={set(initializationValue, 'ticketSection.type', OFFER_FOR_EMPLOYEES_FORM)}
                                                userRights={createRights}
                                                reqFields={createReqFields[type]}
                                                enableReinitialize={true}
                                                handleSubmit={this.handleSubmit}/>
                    }
                </div>
            </div>
        )
    }
}

NewCommercialTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    createCommercialTicket: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    userDetail: PropTypes.object.isRequired,
    ticketData: PropTypes.object.isRequired,
    attachmentsData: PropTypes.object.isRequired,
    clientIds: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    userDetail: state.profile.userDetail,
    ticketData: state.commercialTicket,
    attachmentsData: state.commercialAttachment,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
    group: state.profile.userDetail.group,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    createCommercialTicket,
    resetCommercialTicket,
})(NewCommercialTicketPage)), ['CC_DEALER']);
