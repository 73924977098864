import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'get-value';
import {
    MARKETING_PATH,
    MARKETING_LIST_PATH,
    MARKETING_HISTORY_PATH,
    MARKETING_FULL_TEXT_SEARCH_PATH,
    MARKETING_NEW_GENERAL_QUESTION_PATH,
    MARKETING_NEW_SPARE_PART_PRICE_VERIFICATION_PATH,
    MARKETING_NEW_SPARE_PART_NAME_VERIFICATION_PATH,
    MARKETING_NOTIFICATION_PATH,
    MARKETING_EXPORT_PATH,
} from '../../routes/paths';
import {
    GENERAL_QUESTION_FORM, SPARE_PART_NAME_VERIFICATION_FORM, SPARE_PART_PRICE_VERIFICATION_FORM
} from '../../constants/formConstants';
import {fetchSubjects} from '../../actions/admin/actionAdminSubjectSettings';
import {domains} from '../../constants/Utils';
import {moduleRoles} from '../../utils/roles';
import NotFoundPage from '../NotFoundPage';
import NewMarketingTicketPage from './ticket/NewMarketingTicketPage';
import ViewMarketingTicketPage from './ticket/ViewMarketingTicketPage';
import EditMarketingTicketPage from './ticket/EditMarketingTicketPage';
import ListMarketingPage from './list/ListMarketingPage';
import HistoryMarketingPage from './history/HistoryMarketingPage';
import FullTextSearchMarketingPage from './fullTextSearch/FullTextSearchMarketingPage';
import NotificationMarketingPage from './notification/NotificationMarketingPage';
import ExportMarketingPage from './export/ExportMarketingPage';

const domain = domains.MARKETING;

class Marketing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managedGroups: get(this.props.loggedUser, `roles.${domain}.dealer`) ?
                [get(this.props.loggedUser, 'group', 'XX')] :
                moduleRoles.getEditorGroups(domain, get(this.props.loggedUser, `roles`, {})).map(({group}) => group),
            subjectKeys: ['marketingQuestion', 'priceVerification', 'nameVerification']
        }
    }

    componentDidMount() {
        this.props.fetchSubjects(domain, this.state.managedGroups, this.state.subjectKeys);
    }

    render() {
        return (
            <Switch>
                <Route exact path={MARKETING_PATH}
                       render={() => <Redirect to={MARKETING_LIST_PATH}/>}/>

                <Route exact path={MARKETING_PATH + '/:view(view)/:ticketUuid(.*)'}
                       component={ViewMarketingTicketPage}/>
                <Route exact path={MARKETING_PATH + '/:edit(edit)/:ticketUuid(.*)'}
                       component={EditMarketingTicketPage}/>
                <Route exact path={MARKETING_LIST_PATH}
                       render={() => <ListMarketingPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={MARKETING_HISTORY_PATH}
                       render={() => <HistoryMarketingPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={MARKETING_FULL_TEXT_SEARCH_PATH}
                       render={() => <FullTextSearchMarketingPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={MARKETING_EXPORT_PATH}
                       render={() => <ExportMarketingPage managedGroups={this.state.managedGroups}/>}/>
                <Route exact path={MARKETING_NOTIFICATION_PATH}
                       component={NotificationMarketingPage}/>

                <Route exact path={MARKETING_NEW_GENERAL_QUESTION_PATH}
                       render={() => <NewMarketingTicketPage type={GENERAL_QUESTION_FORM}/>}/>
                <Route exact path={MARKETING_NEW_SPARE_PART_PRICE_VERIFICATION_PATH}
                       render={() => <NewMarketingTicketPage type={SPARE_PART_PRICE_VERIFICATION_FORM}/>}/>
                <Route exact path={MARKETING_NEW_SPARE_PART_NAME_VERIFICATION_PATH}
                       render={() => <NewMarketingTicketPage type={SPARE_PART_NAME_VERIFICATION_FORM}/>}/>

                <Route component={NotFoundPage}/>
            </Switch>
        )
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail,
});
export default withRouter(connect(mapStateToProps, {fetchSubjects})(Marketing));
