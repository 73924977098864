import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    addFrequentQuestion, fetchFrequentQuestion, moveFrequentQuestion
} from '../../../actions/admin/actionAdminFrequentQuestion';
import {PrimaryButton} from '../../common/Button';
import {Table, Wrapper} from '../../common/StyledComponents';
import {moduleRoles} from '../../../utils/roles';
import FrequentQuestionDialog from './FrequentQuestionDialog';
import FrequentQuestionRow from './FrequentQuestionRow';
import Loader from '../../Loader';
import buttonMessages from '../../../intl/common/buttonMessages';
import frequentQuestionMessages from '../../../intl/admin/frequentQuestionMessages';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import adminMessages from '../../../intl/admin/adminMessages';

const Button = styled(PrimaryButton)`
  margin-right: 5px;
`;

class FrequentQuestionContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {isOpenAddFrequentQuestionDialog: false};
    }

    componentDidMount() {
        this.props.fetchFrequentQuestion(this.props.domain, this.props.selectedGroup);
    }

    showAddModal = () =>
        this.setState({
            isOpenAddFrequentQuestionDialog: true,
        });

    closeModal = () =>
        this.setState({
            isOpenAddFrequentQuestionDialog: false
        });

    saveModal = ({groups = [], message = ''}, problemId, attachmentId, attachment) => {
        this.setState({
            isOpenAddFrequentQuestionDialog: false
        });
        const item = {
            message: message,
            uuid: problemId,
            groups: groups.map(group => group.value),
            userName: this.props.profile.name,
            updated: new Date().getTime()
        };
        if (attachment) {
            item.attachment = {
                uuid: attachmentId,
                name: attachment.name,
                type: attachment.type
            }
        }
        this.props.addFrequentQuestion(this.props.domain, item);
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }
        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }
        //swap
        const item = {
            group: this.props.selectedGroup,
            position: result.destination.index,
            uuid: this.props.frequentQuestions[result.source.index].uuid,
        };
        this.props.moveFrequentQuestion(this.props.domain, item, result.source.index);
    };

    render() {
        const {isLoading, frequentQuestions, domain, intl: {formatMessage}, selectedGroup, roles} = this.props;
        return (
            <Wrapper>
                <h5><FormattedMessage {...frequentQuestionMessages.FREQUENT_QUESTION}/></h5>
                {isLoading && <Loader/>}
                {!isLoading && <>
                    <Button
                        disabled={frequentQuestions.length > 9}
                        className="btn btn-sm mb-sm-3"
                        onClick={this.showAddModal}>
                        <FormattedMessage {...buttonMessages.ADD}/>
                    </Button>
                    {frequentQuestions.length === 0 &&
                    <div>
                        <FormattedMessage {...frequentQuestionMessages.EMPTY}/>
                    </div>
                    }
                    {frequentQuestions.length !== 0 &&
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="card rounded">
                            <Table className="table table-hover">
                                <thead className="thead-light">
                                <tr className="d-flex">
                                    <th className="col-1"/>
                                    <th className="col-2">
                                        <FormattedMessage {...frequentQuestionMessages.COUNTRY}/>
                                    </th>
                                    <th className="col-3">
                                        <FormattedMessage {...frequentQuestionMessages.FREQUENT_QUESTION}/>
                                    </th>
                                    <th className="col-2">
                                        <FormattedMessage {...frequentQuestionMessages.ATTACHMENT}/>
                                    </th>
                                    <th className="col-2">
                                        <FormattedMessage {...frequentQuestionMessages.LAST_UPDATE}/>
                                    </th>
                                    <th className="col-2">
                                        <FormattedMessage {...adminMessages.ACTION}/>
                                    </th>
                                </tr>
                                </thead>
                                <Droppable droppableId="table">
                                    {provided => {
                                        return (
                                            <tbody
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                            {frequentQuestions.map((frequentQuestion, index) =>
                                                <Draggable key={index} draggableId={`id-${index}`} index={index}>
                                                    {provided => {
                                                        return (
                                                            <FrequentQuestionRow
                                                                provided={provided}
                                                                index={index}
                                                                data={frequentQuestion}
                                                                domain={this.props.domain}
                                                            />
                                                        )
                                                    }}
                                                </Draggable>
                                            )}
                                            </tbody>
                                        )
                                    }}
                                </Droppable>
                            </Table>
                        </div>
                    </DragDropContext>
                    }
                    <FrequentQuestionDialog domain={domain}
                                            show={this.state.isOpenAddFrequentQuestionDialog}
                                            closeModal={this.closeModal}
                                            saveModal={this.saveModal}
                                            group={formatMessage(countryGroupMessages[selectedGroup])}
                                            initialValues={{
                                                groups: [moduleRoles.getAdminGroups(domain, roles)
                                                    .map(group => ({
                                                        value: group.group,
                                                        label: formatMessage(countryGroupMessages[group.group]),
                                                        isFixed: true
                                                    })).find(group => group.value === selectedGroup)]
                                            }}/>
                </>}
            </Wrapper>
        );
    }
}

FrequentQuestionContainer.propTypes = {
    roles: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    frequentQuestions: PropTypes.array.isRequired,
    moveFrequentQuestion: PropTypes.func.isRequired,
    addFrequentQuestion: PropTypes.func.isRequired,
    fetchFrequentQuestion: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    selectedGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminFrequentQuestion.isLoading,
    frequentQuestions: state.adminFrequentQuestion.frequentQuestions,
    moveFrequentQuestion: state.moveFrequentQuestion,
    addFrequentQuestion: state.addFrequentQuestion,
    fetchFrequentQuestion: state.fetchFrequentQuestion,
    roles: state.profile.userDetail.roles,
    profile: state.profile.userDetail,
    intl: state.intl
});
export default connect(mapStateToProps, {
    moveFrequentQuestion,
    addFrequentQuestion,
    fetchFrequentQuestion
})(injectIntl(FrequentQuestionContainer));
