import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Async from 'react-select/async';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {debounce} from 'throttle-debounce';
import {DEFAULT_DEBOUNCE_TIMEOUT} from '../../../constants/validationConstants';
import {mapUserToSelectionOption} from '../../../utils/userUtils';
import {Label} from '../../common/StyledComponents';
import authManagementMessages from '../../../intl/admin/rightsManagementMessages';

const SelectUser = props => {
    const {initialValues, handleUserSelection, handleUserSearch, intl: {formatMessage}} = props;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-2">
                    <Label>
                        <FormattedMessage {...authManagementMessages.SELECT_USER}/>
                    </Label>
                </div>
                <div className="col-4">
                    <Async placeholder={formatMessage(authManagementMessages.SELECT_USER_PLACEHOLDER)}
                           noOptionsMessage={() => formatMessage(authManagementMessages.SELECT_USER_SEARCH)}
                           loadOptions={debounce(DEFAULT_DEBOUNCE_TIMEOUT, handleUserSearch)}
                           defaultValue={
                               initialValues && Object.getOwnPropertyNames(initialValues).length > 0 ?
                                   mapUserToSelectionOption(initialValues) : {}
                           }
                           onChange={handleUserSelection}/>
                </div>
            </div>
        </div>
    );
};

SelectUser.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleUserSelection: PropTypes.func.isRequired,
    handleUserSearch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    initialValues: state.adminRightsManagement.managedUser
});

export default connect(mapStateToProps)((injectIntl(SelectUser)))
