import React from 'react';
import {FormSection, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {
    MESSAGE_SECTION,
    ATTACHMENT_SECTION,
    TICKET_SECTION,
    DEALER_SECTION,
    INTERNAL_NOTE_SECTION, SUBSTITUTION_SECTION
} from '../../../../constants/formConstants';
import {scrollContentWrapperToTop} from '../../../../utils/utils';
import {distributionFieldLabelMapping} from '../../../../constants/distributionFieldLabelMapping';
import {StyledDiv} from '../../../common/StyledComponents';
import TicketSection from './sections/ticket/TicketSection';
import AttachmentSection from './sections/attachment/AttachmentSection';
import MessageSection from './sections/message/MessageSection';
import RenderSyncErrors from '../../../common/RenderSyncErrors';
import SubmitButtons from './sections/SubmitButtons';
import TicketStatusHistory from '../../../common/TicketStatusHistory';
import DealerSection from './sections/ticket/DealerSection';
import formMessages from '../../../../intl/modules/formMessages';
import InternalNoteSection from './sections/internalNote/InternalNoteSection';
import SubstitutionSection from './sections/substitution/SubstitutionSection';

const DistributionTicketMasterForm = (props) => {
    const {form, userRights, readOnly, handleSubmit, reqFields, isEditor, isSubstituted, editMode} = props;

    return (
        <StyledDiv className="border border-dark px-4 py-2 bg-light">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={distributionFieldLabelMapping}/>
            {userRights.canShowSubstitutionSection && isEditor && <>
                <FormSection name={SUBSTITUTION_SECTION}>
                    <SubstitutionSection form={form} userRights={userRights} readOnly={readOnly} reqFields={reqFields}/>
                </FormSection>
                <hr/>
            </>
            }

            {((!isEditor) || (isEditor && isSubstituted) || (!userRights.canShowSubstitutionSection)) && <>
                <FormSection name={DEALER_SECTION}>
                    <DealerSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
                </FormSection>
                <FormSection name={TICKET_SECTION}>
                    <TicketSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly} editMode={editMode}/>
                </FormSection>
                <FormSection name={MESSAGE_SECTION}>
                    <MessageSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
                </FormSection>
                <FormSection name={INTERNAL_NOTE_SECTION}>
                    <InternalNoteSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
                </FormSection>
                <FormSection name={ATTACHMENT_SECTION}>
                    <AttachmentSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
                </FormSection>
                <TicketStatusHistory form={form}/>
                <SubmitButtons form={form}
                               onSubmit={handleSubmit}
                               onSubmitFail={scrollContentWrapperToTop}
                               userRights={userRights}
                               readOnly={readOnly}
                />
            </>
            }
        </StyledDiv>
    )
};

DistributionTicketMasterForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.object.isRequired,
    editMode: PropTypes.any,
};

export default reduxForm({})(DistributionTicketMasterForm);
