import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';

export const MARKETING_UPDATE_USER_LIST_PREFERENCE = 'MARKETING_UPDATE_USER_LIST_PREFERENCE';
export const MARKETING_UPDATE_USER_HISTORY_PREFERENCE = 'MARKETING_UPDATE_USER_HISTORY_PREFERENCE';
export const MARKETING_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE = 'MARKETING_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE';
export const MARKETING_FETCH_PREFERENCES = 'MARKETING_FETCH_PREFERENCES';
export const MARKETING_USER_NOTIFICATION_PREFERENCE_FETCH = 'MARKETING_USER_NOTIFICATION_PREFERENCE_FETCH';
export const MARKETING_USER_NOTIFICATION_PREFERENCE_UPDATE = 'MARKETING_USER_NOTIFICATION_PREFERENCE_UPDATE';

export const fetchMarketingPreferences = () => dispatch => {
    return dispatch({
        type: MARKETING_FETCH_PREFERENCES,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_PREFERENCES_GET,
    });
};

export const updateMarketingUserListPreference = (preferences) => dispatch => {
    return dispatch({
        type: MARKETING_UPDATE_USER_LIST_PREFERENCE,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_USER_LIST_PREFERENCE_SET,
        payload: {preferences: {hiddenColumns: preferences}}
    });
};

export const updateMarketingUserHistoryPreference = (preferences) => dispatch => {
    return dispatch({
        type: MARKETING_UPDATE_USER_HISTORY_PREFERENCE,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_USER_HISTORY_PREFERENCE_SET,
        payload: {preferences: {hiddenColumns: preferences}}
    });
};

export const updateMarketingUserFullTextSearchPreference = (preferences) => dispatch => {
    return dispatch({
        type: MARKETING_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_USER_FULL_TEXT_SEARCH_PREFERENCE_SET,
        payload: {preferences: {hiddenColumns: preferences}}
    });
};

export const fetchMarketingUserNotificationPreferences = () => dispatch => {
    return dispatch({
        type: MARKETING_USER_NOTIFICATION_PREFERENCE_FETCH,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_USER_NOTIFICATION_PREFERENCE_GET,
    });
};

export const updateMarketingUserNotificationPreference = (preferences) => dispatch => {
    return dispatch({
        type: MARKETING_USER_NOTIFICATION_PREFERENCE_UPDATE,
        marketingWebsocket: true,
        command: marketingWebsocketCommands.MARKETING_USER_NOTIFICATION_PREFERENCE_SET,
        payload: {preferences}
    });
};
