import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import get from 'get-value';
import {
    lockDistributionTicket,
    resetRedirectAfterLockDistributionTicket
} from '../../../actions/distribution/actionDistributionLockTicket';
import {clearAlerts} from '../../../actions/alertsActions';
import {
    fetchDistributionPreferences,
    updateDistributionUserFullTextSearchPreference
} from '../../../actions/distribution/actionDistributionPreferences';
import {fetchDistributionFullTextSearch} from '../../../actions/distribution/actionDistributionFullTextSearch';
import {fetchSubjects} from '../../../actions/admin/actionAdminSubjectSettings';
import {StyledPicky} from '../../common/StyledComponents';
import checkRoles from '../../common/roleChecker/RoleChecker';
import FullTextSearchDistributionForm from './FullTextSearchDistributionForm';
import FullTextSearchDistributionTable from './FullTextSearchDistributionTable';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import fullTextSearchMessages from '../../../intl/modules/fullTextSearchMessages';
import tableMessages from '../../../intl/common/tableMessages';
import formStatesMessages from '../../../intl/modules/formStatesMessages';
import formMessages from '../../../intl/modules/formMessages';
import commonMessages from '../../../intl/common/commonMessages';
import {getPriority} from '../../../constants/Utils';
import formTicketValuesMessages from '../../../intl/common/formTicketValuesMessages';

class FullTextSearchDistributionPage extends Component {
    constructor(props) {
        super(props);
        const {intl} = props;
        this.state = {
            ratchet: true,
            searchDefaultFiltersDB: [],
            columnLibrary: [
                {
                    id: 'CREATION_DATE',
                    accessor: 'created',
                    filterable: false,
                    message: formMessages.CREATION_DATE,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'BRAND',
                    accessor: 'brand',
                    filterable: false,
                    message: formMessages.BRAND,
                },
                {
                    id: 'DEALER_NAME',
                    accessor: 'dealerName',
                    filterable: false,
                    message: formMessages.DEALER_NAME,
                },
                {
                    id: 'CREATOR_NAME',
                    accessor: 'creatorName',
                    filterable: false,
                    message: formMessages.CREATOR_NAME,
                },
                {
                    id: 'PRIORITY',
                    accessor: 'priority',
                    filterable: false,
                    message: formMessages.PRIORITY,
                    Cell: e =>
                        <div>{formTicketValuesMessages[e.value] ? intl.formatMessage(formTicketValuesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'SUBJECT',
                    accessor: 'subject',
                    filterable: false,
                    message: formMessages.SUBJECT,
                },
                {
                    id: 'BUSINESS_UNIT',
                    accessor: 'businessUnit',
                    filterable: false,
                    message: formMessages.BUSINESS_UNIT,
                    Cell: e =>
                        <div>{e.value ? get(this.props.countryBU.find(bu => bu.id == e.value), "name") : ""}</div>
                },
                {
                    id: 'ASSIGNED_TO',
                    accessor: 'lastEditor',
                    filterable: false,
                    message: formMessages.ASSIGNED_TO,
                },
                {
                    id: 'STATUS',
                    accessor: 'status',
                    filterable: false,
                    message: formMessages.STATUS,
                    Cell: e =>
                        <div>
                            {formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}
                        </div>,
                },
                {
                    id: 'CLOSING_DATE',
                    accessor: 'closed',
                    filterable: false,
                    message: tableMessages.CLOSING_DATE,
                },
                {
                    id: 'VIN',
                    accessor: 'vin',
                    filterable: false,
                    message: formMessages.VIN,
                },
            ],
        }
    }

    componentWillMount() {
        this.props.resetRedirectAfterLockDistributionTicket();
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.fetchDistributionPreferences();
    }

    handleEditClick = ({id}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockDistributionTicket(id);
            this.setState({ratchet: false});
        }
    };

    selectMultipleOption = (value) => {
        this.props.updateDistributionUserFullTextSearchPreference(
            this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    handleSubmit = (values) => {
        let newId;
        const filter = Object.entries(values)
            .filter(e => e[1])
            .map(e => {
                if (e[0] === 'pfxNumber') {
                    newId = 'PFX_NUMBER';
                } else if (e[0] === 'sfxNumber') {
                    newId = 'SFX_NUMBER';
                }
                return {
                    id: (e[0] === 'pfxNumber' || e[0] === 'sfxNumber') ? newId : e[0],
                    value: e[1]
                }
            });
        this.setState({searchDefaultFiltersDB: filter});
    };

    render() {
        const {intl, distributionPreferences, isLoadingSubjects, lockTicket, subjects, fullTextSearchData, managedGroups, userDetail, countryBU} = this.props;

        if (lockTicket.redirect && !this.state.ratchet) {
            this.props.history.push(`/distribution/edit/${lockTicket.uuid}`);
        }

        if (distributionPreferences.isLoading
            || isLoadingSubjects
        ) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...fullTextSearchMessages.TITLE}/>}/>
                <FullTextSearchDistributionForm
                    form={'distributionFullTextSearchForm'}
                    subjects={subjects}
                    fullTextSearchData={fullTextSearchData}
                    onSubmit={this.handleSubmit}
                    managedGroups={managedGroups}
                    language={get(userDetail, 'settings.language', 'DEFAULT')}
                    countryBU={countryBU}/>
                <div className="d-flex justify-content-end my-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold">
                            <FormattedMessage {...tableMessages.VISIBLE_COLUMNS}/>
                            {':'}
                        </span>
                        <StyledPicky
                            value={this.state.columnLibrary.filter(item =>
                                !get(distributionPreferences, 'menuFullTextSearch', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={this.state.columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <FullTextSearchDistributionTable
                    columns={this.state.columnLibrary.filter(item =>
                        !get(distributionPreferences, 'menuFullTextSearch', {default: []}).includes(item.id))}
                    searchDefaultFiltersDB={this.state.searchDefaultFiltersDB}
                    handleEditClick={this.handleEditClick}
                />
            </div>
        )
    }
}

FullTextSearchDistributionPage.propTypes = {
    intl: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    resetRedirectAfterLockDistributionTicket: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    fetchDistributionPreferences: PropTypes.func.isRequired,
    fetchDistributionFullTextSearch: PropTypes.func.isRequired,
    lockDistributionTicket: PropTypes.func.isRequired,
    updateDistributionUserFullTextSearchPreference: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    distributionPreferences: state.distributionPreferences,
    lockTicket: state.distributionListOrderLockTicketCheck,
    isLoadingSubjects: state.adminSubjectSettings.isLoading,
    subjects: state.adminSubjectSettings.subjectSettings.subjects,
    userDetail: state.profile.userDetail,
    fullTextSearchData: state.distributionFullTextSearch.fullTextData,
    countryBU: state.adminBUAssignment.countryBU,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    resetRedirectAfterLockDistributionTicket,
    clearAlerts,
    fetchDistributionPreferences,
    fetchDistributionFullTextSearch,
    lockDistributionTicket,
    updateDistributionUserFullTextSearchPreference,
    fetchSubjects,
})(injectIntl(FullTextSearchDistributionPage))), ['DB_DEALER', 'DB_EDITOR'])
