import React from 'react';
import {FormattedMessage} from 'react-intl';
import {reduxForm} from 'redux-form'
import PropTypes from 'prop-types';
import {TextareaFieldWithValidation} from '../../common/fields/TextareaFieldWithValidation';
import {InputFieldWithValidation} from '../../common/fields/InputFieldWithValidation';
import {BoldPrimaryButton, SecondaryButton} from '../../common/Button';
import notificationMessages from '../../../intl/admin/notificationMessages';
import buttonMessages from '../../../intl/common/buttonMessages';

let NotificationForm = (props) => {
    const {handleSubmit, pristine, reset, submitting} = props;
    return (
        <form className="form-group" onSubmit={handleSubmit}>
            <InputFieldWithValidation label={notificationMessages.SUBJECT}
                                      field="subject"
                                      isSmall/>
            <TextareaFieldWithValidation label={notificationMessages.MESSAGE}
                                         field="body"
                                         rows="6"/>
            <div className="form-row">
                <div className="col-sm mt-2">
                    <BoldPrimaryButton type="submit"
                                       disabled={pristine || submitting}
                                       className="btn float-right">
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                    <SecondaryButton type="button"
                                     disabled={pristine || submitting}
                                     className="btn float-right mr-2"
                                     onClick={reset || submitting}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </SecondaryButton>
                </div>
            </div>
        </form>
    )
};

NotificationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired
};

export default reduxForm({})(NotificationForm)
