import {
    MARKETING_TICKET_STATUS_IN_PROGRESS_DEALER,
    MARKETING_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    MARKETING_TICKET_STATUS_NEW,
    MARKETING_TICKET_STATUS_REOPEN, MARKETING_TICKET_STATUS_WORK_IN_PROGRESS
} from './formConstants';

export const editMarketingListButtonVisibility = {
    DEALER: [
        MARKETING_TICKET_STATUS_IN_PROGRESS_DEALER,
        MARKETING_TICKET_STATUS_WORK_IN_PROGRESS,
    ],
    EDITOR: [
        MARKETING_TICKET_STATUS_NEW,
        MARKETING_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
        MARKETING_TICKET_STATUS_IN_PROGRESS_DEALER,
        MARKETING_TICKET_STATUS_WORK_IN_PROGRESS,
        MARKETING_TICKET_STATUS_REOPEN
    ]
};

export const createRights = {
    // dealer section
    canShowDealerSection: false,
    // ticket section
    canEditSubject: true,
    canEditReferenceNumber: true,
    canEditSegment: true,
    canEditPriority: true,
    canEditBrand: true,
    canEditModel: true,
    canEditTechnicalType: true,
    canEditCurrentSparePartName: true,
    canEditFamily: true,
    canEditPclPrice: true,
    canEditProductionDate: true,
    canEditSuggestedSparePartName: true,
    canEditSparePartLocation: true,
    canEditLevelEquipment: true,
    canOpenFrequentQuestion: true,
    canEditAsd: true,
    // message section
    canShowAutomaticResponse: false,
    canAddMessage: true,
    canAddSendCopyTo: true,
    // attachment section
    canAddAttach: true,
    // buttons
    canSave: true,
    canClose: false,
    canForwardToDealer: false,
    canForwardToNPDC: false,
    canWorkInProgress: false,
    canReopen: false,
    canSkipModalReopen: false,
};

export const editRights = {
    DEALER: {
        NEW: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: false,
            canAddSendCopyTo: false,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        },
        CLOSED: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: false,
            canAddSendCopyTo: false,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: true,
            canSkipModalReopen: false,
        },
        WAIT_SUBSIDIARY: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: false,
            canAddSendCopyTo: false,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        },
        WAIT_DEALER: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: true,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        },
        WAIT_WORK_IN_PROGRESS: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: true,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        },
        REOPEN: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: false,
            canAddSendCopyTo: false,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        }
    },
    EDITOR: {
        NEW: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: true,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: false,
            canClose: true,
            canForwardToDealer: true,
            canForwardToNPDC: false,
            canWorkInProgress: true,
            canReopen: false,
            canSkipModalReopen: false,
        },
        CLOSED: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: false,
            canAddMessage: false,
            canAddSendCopyTo: false,
            // attachment section
            canAddAttach: false,
            // buttons
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: true,
        },
        WAIT_SUBSIDIARY: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: true,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: false,
            canClose: true,
            canForwardToDealer: true,
            canForwardToNPDC: false,
            canWorkInProgress: true,
            canReopen: false,
            canSkipModalReopen: false,
        },
        WAIT_DEALER: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: true,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: true,
            canClose: true,
            canForwardToDealer: false,
            canForwardToNPDC: false,
            canWorkInProgress: true,
            canReopen: false,
            canSkipModalReopen: false,
        },
        WAIT_WORK_IN_PROGRESS: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: true,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: true,
            canClose: true,
            canForwardToDealer: true,
            canForwardToNPDC: false,
            canWorkInProgress: false,
            canReopen: false,
            canSkipModalReopen: false,
        },
        REOPEN: {
            // dealer section
            canShowDealerSection: true,
            // ticket section
            canEditSubject: false,
            canEditReferenceNumber: false,
            canEditSegment: false,
            canEditPriority: false,
            canEditBrand: false,
            canEditModel: false,
            canEditTechnicalType: false,
            canEditCurrentSparePartName: false,
            canEditFamily: false,
            canEditPclPrice: false,
            canEditProductionDate: false,
            canEditSuggestedSparePartName: false,
            canEditSparePartLocation: false,
            canEditLevelEquipment: false,
            canOpenFrequentQuestion: false,
            canEditAsd: false,
            // message section
            canShowAutomaticResponse: true,
            canAddMessage: true,
            canAddSendCopyTo: true,
            // attachment section
            canAddAttach: true,
            // buttons
            canSave: false,
            canClose: true,
            canForwardToDealer: true,
            canForwardToNPDC: false,
            canWorkInProgress: true,
            canReopen: false,
            canSkipModalReopen: false,
        }
    }
};
