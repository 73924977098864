import React, {Component} from 'react';
import {pingWebsocket} from '../actions/actionWebsocket';
import {modules} from '../constants/Utils';
import {connect} from 'react-redux';

class WebsocketHeartbeat extends Component {
    componentDidMount() {
        this.pingAllWebsockets();
        setInterval(this.pingAllWebsockets, 540 * 1000);
    }

    pingAllWebsockets = () => {
        const {pingWebsocket} = this.props;
        pingWebsocket(modules.DISTRIBUTION);
        pingWebsocket(modules.COMMERCIAL);
        pingWebsocket(modules.ADMINISTRATION);
        pingWebsocket(modules.MARKETING);
        pingWebsocket(modules.APIGEE);
    };

    render() {
        return (<></>);
    }
}

export default connect(null, {
    pingWebsocket
})(WebsocketHeartbeat);
