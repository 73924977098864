import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ModalBody, ModalFooter, ModalHeader, ModalWindow} from '../modal/Modal';
import buttonMessages from '../../../intl/common/buttonMessages';
import {IconCopy, PrimaryIcon, SecondaryButton} from '../Button';

function AlertDetailsModal({show, handleClose, text, details, values}) {
    return (
        <ModalWindow isOpen={show} onRequestClose={handleClose}>
            <ModalHeader>
                <div className="row">
                    <div className="col-sm-11">
                        {text instanceof Object && Object.keys(text).length !== 0 &&
                        <FormattedMessage {...text} values={values}/>}
                        {(text instanceof String || typeof text === 'string') && text}
                    </div>
                    <div className="col-sm-1">
                        <CopyToClipboard text={JSON.stringify({text, details}, null, 2)}>
                            <PrimaryIcon className="btn btn-sm" title="Copy to clipboard" type="button">
                                <IconCopy/>
                            </PrimaryIcon>
                        </CopyToClipboard>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col">
                        <div>
                            <pre>{JSON.stringify(details, null, 2)}</pre>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="col">
                        <SecondaryButton type="button" className="btn" onClick={handleClose}>
                            <FormattedMessage {...buttonMessages.CLOSE}/>
                        </SecondaryButton>
                    </div>
                </div>
            </ModalFooter>
        </ModalWindow>
    )
}

AlertDetailsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string
    ]),
    details: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string
    ])
};

export default AlertDetailsModal;
