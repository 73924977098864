import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    ADMINISTRATION_DISTRIBUTION_ADMIN_PATH,
    ADMINISTRATION_DISTRIBUTION_NOTIFICATION_PATH,
    ADMINISTRATION_DISTRIBUTION_DEALER_PATH,
    ADMINISTRATION_DISTRIBUTION_EDITOR_PATH,
    ADMINISTRATION_DISTRIBUTION_TICKET_SETTING_PATH,
    ADMINISTRATION_DISTRIBUTION_FREQUENT_QUESTION_PATH,
    ADMINISTRATION_DISTRIBUTION_WORKING_HOURS_PATH,
    ADMINISTRATION_MARKETING_NOTIFICATION_PATH,
    ADMINISTRATION_MARKETING_TICKET_SETTING_PATH,
    ADMINISTRATION_MARKETING_FREQUENT_QUESTION_PATH,
    ADMINISTRATION_MARKETING_WORKING_HOURS_PATH,
    ADMINISTRATION_MARKETING_ADMIN_PATH,
    ADMINISTRATION_MARKETING_DEALER_PATH,
    ADMINISTRATION_MARKETING_EDITOR_PATH,
    ADMINISTRATION_MARKETING_GENERAL_QUESTION_PATH,
    ADMINISTRATION_MARKETING_SPARE_PART_PRICE_VERIFICATION_PATH,
    ADMINISTRATION_MARKETING_SPARE_PART_NAME_VERIFICATION_PATH,
    ADMINISTRATION_DISTRIBUTION_SUBJECT_SETTING_PATH,
    ADMINISTRATION_COMMERCIAL_WORKING_HOURS_PATH,
    ADMINISTRATION_COMMERCIAL_SUBJECT_SETTING_PATH,
    ADMINISTRATION_COMMERCIAL_FREQUENT_QUESTION_PATH,
    ADMINISTRATION_COMMERCIAL_TICKET_SETTING_PATH,
    ADMINISTRATION_COMMERCIAL_NOTIFICATION_PATH,
    ADMINISTRATION_COMMERCIAL_ADMIN_PATH,
    ADMINISTRATION_COMMERCIAL_DEALER_PATH,
    ADMINISTRATION_COMMERCIAL_EDITOR_PATH,
    ADMINISTRATION_DISTRIBUTION_PRESET_RESPONSE_PATH,
    ADMINISTRATION_COMMERCIAL_PRESET_RESPONSE_PATH,
    ADMINISTRATION_MARKETING_PRESET_RESPONSE_PATH,
    ADMINISTRATION_COMMERCIAL_SUBJECT_OFFER_FOR_EMPLOYEES,
    ADMINISTRATION_DISTRIBUTION_EXTERNAL_EDITOR_PATH,
    ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_NEW_PATH,
    ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_LIST_PATH, ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_ASSIGNMENT_PATH,
} from '../../../../routes/paths';
import {moduleRoles} from '../../../../utils/roles';
import {domains} from '../../../../constants/Utils';
import menuMessages from '../../../../intl/common/menuMessages';

export const getAdministrationMenuAdmin = roles => {
    const result = [];
    if (moduleRoles.isAdmin(roles, domains.DISTRIBUTION) || moduleRoles.isPowerUser(roles, domains.DISTRIBUTION)) {
        let content = [];
        if (moduleRoles.isAdmin(roles, domains.DISTRIBUTION)) {
            content = [
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_NOTIFICATION}/>,
                    to: ADMINISTRATION_DISTRIBUTION_NOTIFICATION_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_TICKET_SETTING}/>,
                    to: ADMINISTRATION_DISTRIBUTION_TICKET_SETTING_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_FREQUENT_QUESTION}/>,
                    to: ADMINISTRATION_DISTRIBUTION_FREQUENT_QUESTION_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_PRESET_RESPONSE}/>,
                    to: ADMINISTRATION_DISTRIBUTION_PRESET_RESPONSE_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SUBJECT_SETTING}/>,
                    to: ADMINISTRATION_DISTRIBUTION_SUBJECT_SETTING_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_WORKING_HOURS}/>,
                    to: ADMINISTRATION_DISTRIBUTION_WORKING_HOURS_PATH
                },
            ]
        }

        const authorizationManagementDistributionSubmenu = buildAuthorizationManagementDistributionSubmenu(roles);
        if (authorizationManagementDistributionSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT}/>,
                    content: authorizationManagementDistributionSubmenu
                }
            );
        }
        const businessUnitsDistributionSubmenu = buildBusinessUnitsDistributionSubmenu(roles);
        if (businessUnitsDistributionSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_BUSINESS_UNIT_MANAGEMENT}/>,
                    content: businessUnitsDistributionSubmenu
                }
            );
        }
        result.push({
            label: <FormattedMessage {...menuMessages.DISTRIBUTION}/>,
            content
        });
    }
    if (moduleRoles.isAdmin(roles, domains.COMMERCIAL) || moduleRoles.isPowerUser(roles, domains.COMMERCIAL)) {
        let content = [];
        if (moduleRoles.isAdmin(roles, domains.COMMERCIAL)) {
            content = [
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_NOTIFICATION}/>,
                    to: ADMINISTRATION_COMMERCIAL_NOTIFICATION_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_TICKET_SETTING}/>,
                    to: ADMINISTRATION_COMMERCIAL_TICKET_SETTING_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_FREQUENT_QUESTION}/>,
                    to: ADMINISTRATION_COMMERCIAL_FREQUENT_QUESTION_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_PRESET_RESPONSE}/>,
                    to: ADMINISTRATION_COMMERCIAL_PRESET_RESPONSE_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_WORKING_HOURS}/>,
                    to: ADMINISTRATION_COMMERCIAL_WORKING_HOURS_PATH
                },
            ]
        }

        const subjectSettingsCommercialSubmenu = buildSubjectSettingsCommercialSubmenu(roles);
        if (subjectSettingsCommercialSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SUBJECT_SETTING}/>,
                    content: subjectSettingsCommercialSubmenu
                }
            );
        }

        const authorizationManagementCommercialSubmenu = buildAuthorizationManagementCommercialSubmenu(roles);
        if (authorizationManagementCommercialSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT}/>,
                    content: authorizationManagementCommercialSubmenu
                }
            );
        }
        result.push({
            label: <FormattedMessage {...menuMessages.COMMERCIAL}/>,
            content
        });
    }
    if (moduleRoles.isAdmin(roles, domains.MARKETING) || moduleRoles.isPowerUser(roles, domains.MARKETING)) {
        let content = [];
        if (moduleRoles.isAdmin(roles, domains.MARKETING)) {
            content = [
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_NOTIFICATION}/>,
                    to: ADMINISTRATION_MARKETING_NOTIFICATION_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_TICKET_SETTING}/>,
                    to: ADMINISTRATION_MARKETING_TICKET_SETTING_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_FREQUENT_QUESTION}/>,
                    to: ADMINISTRATION_MARKETING_FREQUENT_QUESTION_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_PRESET_RESPONSE}/>,
                    to: ADMINISTRATION_MARKETING_PRESET_RESPONSE_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_WORKING_HOURS}/>,
                    to: ADMINISTRATION_MARKETING_WORKING_HOURS_PATH
                },
            ]
        }

        const subjectSettingsMarketingSubmenu = buildSubjectSettingsMarketingSubmenu(roles);
        if (subjectSettingsMarketingSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SUBJECT_SETTING}/>,
                    content: subjectSettingsMarketingSubmenu
                }
            );
        }
        const authorizationManagementMarketingSubmenu = buildAuthorizationManagementMarketingSubmenu(roles);
        if (authorizationManagementMarketingSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT}/>,
                    content: authorizationManagementMarketingSubmenu
                }
            );
        }
        result.push({
            label: <FormattedMessage {...menuMessages.MARKETING}/>,
            content
        });
    }
    return result;
};

const buildAuthorizationManagementDistributionSubmenu = roles => {
    const result = [];
    if (moduleRoles.isPowerUser(roles, domains.DISTRIBUTION)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT_ADMIN}/>,
                to: ADMINISTRATION_DISTRIBUTION_ADMIN_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles, domains.DISTRIBUTION)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT_DEALER}/>,
                to: ADMINISTRATION_DISTRIBUTION_DEALER_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT_EDITOR}/>,
                to: ADMINISTRATION_DISTRIBUTION_EDITOR_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT_EXTERNAL_EDITOR}/>,
                to: ADMINISTRATION_DISTRIBUTION_EXTERNAL_EDITOR_PATH
            }
        );
    }
    return result;
};
const buildBusinessUnitsDistributionSubmenu = roles => {
    const result = [];
    if (moduleRoles.isAdmin(roles, domains.DISTRIBUTION)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_BUSINESS_UNIT_NEW}/>,
                to: ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_NEW_PATH
            },
            {
                label: <FormattedMessage {...menuMessages.ADMIN_BUSINESS_UNIT_LIST}/>,
                to: ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_LIST_PATH
            },
            {
                label: <FormattedMessage {...menuMessages.ADMIN_BUSINESS_ASSIGNMENT}/>,
                to: ADMINISTRATION_DISTRIBUTION_BUSINESS_UNIT_ASSIGNMENT_PATH
            }
        );
    }
    return result;
};
const buildAuthorizationManagementCommercialSubmenu = roles => {
    const result = [];
    if (moduleRoles.isPowerUser(roles, domains.COMMERCIAL)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT_ADMIN}/>,
                to: ADMINISTRATION_COMMERCIAL_ADMIN_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles, domains.COMMERCIAL)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT_DEALER}/>,
                to: ADMINISTRATION_COMMERCIAL_DEALER_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT_EDITOR}/>,
                to: ADMINISTRATION_COMMERCIAL_EDITOR_PATH
            }
        );
    }
    return result;
};

const buildSubjectSettingsCommercialSubmenu = roles => {
    const result = [];
    if (moduleRoles.isAdmin(roles, domains.COMMERCIAL)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_SUBJECT_SETTING}/>,
                to: ADMINISTRATION_COMMERCIAL_SUBJECT_SETTING_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_SUBJECT_OFFER_FOR_EMPLOYEES_SETTING}/>,
                to: ADMINISTRATION_COMMERCIAL_SUBJECT_OFFER_FOR_EMPLOYEES
            }
        );
    }
    return result;
};

const buildAuthorizationManagementMarketingSubmenu = roles => {
    const result = [];

    if (moduleRoles.isPowerUser(roles, domains.MARKETING)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT_ADMIN}/>,
                to: ADMINISTRATION_MARKETING_ADMIN_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles, domains.MARKETING)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT_DEALER}/>,
                to: ADMINISTRATION_MARKETING_DEALER_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_AUTH_MANAGEMENT_EDITOR}/>,
                to: ADMINISTRATION_MARKETING_EDITOR_PATH
            }
        );
    }
    return result;
};

const buildSubjectSettingsMarketingSubmenu = roles => {
    const result = [];
    if (moduleRoles.isAdmin(roles, domains.MARKETING)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_GENERAL_QUESTION}/>,
                to: ADMINISTRATION_MARKETING_GENERAL_QUESTION_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PART_PRICE_VERIFICATION}/>,
                to: ADMINISTRATION_MARKETING_SPARE_PART_PRICE_VERIFICATION_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PART_NAME_VERIFICATION}/>,
                to: ADMINISTRATION_MARKETING_SPARE_PART_NAME_VERIFICATION_PATH
            }
        );
    }
    return result;
};

